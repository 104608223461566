import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardSpreadGameScoreTable } from './DashboardSpreadGameScoreTable'
import { fetchSpreadGameScores, getIsLoading, updateBlackList } from './reducer'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getEntities, getFirstEntity } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Component, Room, SpreadGame, SpreadGameScore } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardSpreadGame: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const [blackListCandidates, setBlackListCandidates] = useState<SpreadGameScore[]>([])
  const pageSize = 8

  // Selectors
  const dispatch = useDispatch()

  const isLoading = useSelect(state => getIsLoading(state.spreadGame))
  const spreadGame = useSelect(state =>
    getFirstEntity<SpreadGame>(
      state.entity,
      'spread_game',
      ({ component_id }) => component_id === component.id,
    ),
  )
  const spreadGameScores = useSelect(state =>
    getEntities<SpreadGameScore>(
      state.entity,
      'spread_game_score',
      ({ game_id }) => game_id === spreadGame?.id,
    ).sort((a, b) => b.score - a.score),
  )

  // MARK: - Effects

  useEffect(() => {
    if (spreadGame) dispatch(fetchSpreadGameScores(component.id, 0, pageSize))
  }, [dispatch])

  // MARK: - Handlers

  const handleSpreadGameScoreDropdownItemSelect = async (
    option: number,
    spreadGameScore: SpreadGameScore,
  ) => {
    if (option === 0) {
      if (spreadGame?.black_list.includes(spreadGameScore.user_id)) {
        dispatch(
          updateBlackList(
            component,
            (spreadGame?.black_list ?? []).filter(userId => userId !== spreadGameScore.user_id),
          ),
        )
      } else {
        setBlackListCandidates([spreadGameScore])
      }
    }
  }

  const handleSpreadGameScoreBulkDropdownItemSelect = async (
    option: number,
    scores: SpreadGameScore[],
  ) => {
    if (option === 0) setBlackListCandidates(scores)
  }

  const handleReloadScoresClick = async () => {
    if (spreadGame) dispatch(fetchSpreadGameScores(component.id, 0, pageSize))
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <DashboardSpreadGameScoreTable
        spreadGameScores={spreadGameScores}
        blackList={spreadGame?.black_list ?? []}
        isLoading={isLoading}
        onReloadClick={handleReloadScoresClick}
        handleSpreadGameScoreDropdownItemSelect={handleSpreadGameScoreDropdownItemSelect}
        handleSpreadGameScoreBulkDropdownItemSelect={handleSpreadGameScoreBulkDropdownItemSelect}
      />

      <AlertModal
        error={Error.displayable(
          `Ban user${blackListCandidates.length > 1 ? 's' : ''}`,
          `Are you sure to ban user${
            blackListCandidates.length > 1 ? 's' : ''
          }? User scores will be frozen.`,
          success => {
            if (success && blackListCandidates.length) {
              dispatch(
                updateBlackList(component, [
                  ...(spreadGame?.black_list ?? []),
                  ...blackListCandidates.map(candidate => candidate.user_id),
                ]),
              )
            }
          },
          'Ban',
        )}
        visible={!!blackListCandidates.length}
        onClose={() => setBlackListCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
