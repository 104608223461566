import { AsyncThunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { analyticsService } from 'src/repository/services/analyticsService'
import { notificationService } from 'src/repository/services/notificationService'
import { purchaseService } from 'src/repository/services/purchaseService'
import { RoomPurchase, Notification, OverviewAnalytics } from 'src/repository/types'

type State = {
  isLoading: boolean
  error: IError | null
  analytics: OverviewAnalytics | null
  activePurchase: RoomPurchase | null
  purchases: RoomPurchase[]
  notifications: Notification[]
}

// MARK: - State

export const initialState: State = {
  isLoading: false,
  error: null,
  analytics: null,
  activePurchase: null,
  purchases: [],
  notifications: [],
}

// MARK: - Reducer

export const usageReducer = (
  state = initialState,
  action:
    | LoadingAction
    | ErrorAction
    | FlushAction
    | SetActivePurchaseAction
    | SetPurchasesAction
    | SetAnalyticsAction
    | SetNotificationsAction
    | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'usage/loading':
      return { ...state, isLoading: action.isLoading }

    case 'usage/error':
      return { ...state, error: action.error }

    case 'usage/setActivePurchase':
      return { ...state, activePurchase: action.activePurchase }

    case 'usage/setPurchases':
      return { ...state, purchases: action.purchases }

    case 'usage/setNotifications':
      return { ...state, notifications: action.notifications }

    case 'usage/setAnalytics':
      return { ...state, analytics: action.analytics }

    case 'usage/flush':
    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchUsage =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const [purchaseResponse, analyticsResponse, notificationsResponse] = await Promise.all([
      purchaseService.fetchPurchases(roomId),
      analyticsService.fetchOverviewAnalytics(roomId),
      notificationService.fetchDailyNotifications(roomId),
    ])

    if (purchaseResponse.success && analyticsResponse.success && notificationsResponse.success) {
      dispatch(setAnalytics(analyticsResponse.value))
      dispatch(setPurchases(purchaseResponse.value.purchases))
      dispatch(setNotifications(notificationsResponse.value))
      dispatch(setActivePurchase(purchaseResponse.value.activePurchase))
    } else {
      dispatch(setError(Error.someThingWentWrong()))
    }
    dispatch(setLoading(false))
  }

export const flush = (): FlushAction => ({
  type: 'usage/flush',
})

export const setError = (error: IError | null): ErrorAction => ({
  type: 'usage/error',
  error: error,
})

// MARK: - Selectors

export const getActivePurchase = (state: State): RoomPurchase | null => {
  return state.activePurchase
}

export const getPurchases = (state: State): RoomPurchase[] => {
  return state.purchases
}

export const getNotifications = (state: State): Notification[] => {
  return state.notifications
}

export const getDailyNotifications = (
  state: State,
  startOfTheDay: number,
  endOfTheDay: number,
): Notification[] => {
  return state.notifications.filter(
    notification =>
      notification.publish_date > startOfTheDay && notification.publish_date <= endOfTheDay,
  )
}

export const getAnalytics = (state: State): OverviewAnalytics | null => {
  return state.analytics
}

export const getIsLoading = (state: State): boolean => {
  return state.isLoading
}

export const getError = (state: State): IError | null => {
  return state.error
}

// MARK: - Action Types

type LoadingAction = {
  type: 'usage/loading'
  isLoading: boolean
}

type ErrorAction = {
  type: 'usage/error'
  error: IError | null
}

type FlushAction = {
  type: 'usage/flush'
}

type SetAnalyticsAction = {
  type: 'usage/setAnalytics'
  analytics: OverviewAnalytics
}

type SetPurchasesAction = {
  type: 'usage/setPurchases'
  purchases: RoomPurchase[]
}

type SetNotificationsAction = {
  type: 'usage/setNotifications'
  notifications: Notification[]
}

type SetActivePurchaseAction = {
  type: 'usage/setActivePurchase'
  activePurchase: RoomPurchase | null
}

// MARK: - Internal Actions

const setLoading = (isLoading: boolean): LoadingAction => ({
  type: 'usage/loading',
  isLoading: isLoading,
})

const setAnalytics = (analytics: OverviewAnalytics): SetAnalyticsAction => ({
  type: 'usage/setAnalytics',
  analytics: analytics,
})

const setPurchases = (purchases: RoomPurchase[]): SetPurchasesAction => ({
  type: 'usage/setPurchases',
  purchases: purchases,
})

const setNotifications = (notifications: Notification[]): SetNotificationsAction => ({
  type: 'usage/setNotifications',
  notifications: notifications,
})

const setActivePurchase = (activePurchase: RoomPurchase | null): SetActivePurchaseAction => ({
  type: 'usage/setActivePurchase',
  activePurchase: activePurchase,
})
