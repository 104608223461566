import { Col, Row } from '../CommonStyles'
import { PrimaryButton } from '../PrimaryButton'
import { SecondaryButton } from '../SecondaryButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LogoTitleIcon } from 'src/assets/images/svg/LogoTitleIcon'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const Header: React.FC<{
  isLoggingIn: boolean
  onCreatorLoginClick: () => void
}> = ({ isLoggingIn, onCreatorLoginClick }) => {
  // MARK: - Hooks

  const { navigate, routes } = useNavigation()
  const { isMobile, width } = useWindowDimensions()
  const { palette } = useTheme()
  const { t } = useTranslation()

  // MARK: - Handlers

  const buttonStyle: React.CSSProperties = {
    color: palette.text.primary,
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 16,
    marginRight: 24,
    alignSelf: 'center',
  }

  // MARK: - Render

  return (
    <Container>
      <InnerContainer>
        <Link to="/">
          <LogoTitleIcon size={64} style={{ marginLeft: 10 }} fill={palette.text.primary} />
        </Link>

        <Row>
          {!isMobile ? (
            <Link style={buttonStyle} to="pricing">
              {t('Pricing')}
            </Link>
          ) : null}

          {width > 500 ? (
            <SecondaryButton
              style={{ marginRight: 30, marginLeft: 12 }}
              labelStyle={{ fontSize: 16, color: palette.text.primary, fontWeight: 500 }}
              title={t('Platform login')}
              onClick={() => {
                if (!isLoggingIn) onCreatorLoginClick()
              }}
            />
          ) : null}

          <PrimaryButton
            isLoading={isLoggingIn}
            style={{ borderWidth: 1, width: 160 }}
            labelStyle={{ fontSize: 16, fontWeight: 500 }}
            title={'Request a demo'}
            onClick={() => navigate(routes.demo)}
          />
        </Row>
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  padding: 0px 32px;
  position: sticky;
  width: 100%;
  z-index: 2;

  @media ${device.smallerThan.laptop} {
    padding: 0px 16px;
  }
`

const InnerContainer = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1260px;
  position: relative;
  width: 100%;
`
