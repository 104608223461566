import { PurchaseAudienceTierSelectionItem } from './PurchaseAudienceTierSelectionItem'
import { PurchaseTierSelectionFooter } from './PurchaseTierSelectionFooter'
import { PurchaseUsageTierSelectionRow } from './PurchaseUsageTierSelectionRow'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col, LabelLarge, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getLoading } from 'src/redux/reducers/app'
import {
  getActivePurchase,
  getPriceMultiplier,
  getSelectedTiers,
  getTiers,
  setSelectedTiers,
} from 'src/redux/reducers/purchase'
import { PurchaseAudienceTier, PurchaseUsageTier } from 'src/repository/types'
import config from 'src/utils/config'
import { dashboardSidebarOptionsImage } from 'src/utils/helpers/dashboardHelper'
import { openURL } from 'src/utils/helpers/linkHelper'
import { LoadingWrapper } from 'src/utils/hocs/LoadingWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const PurchaseTierSelection: React.FC<{
  componentCount: number
  promoCode: string
  onPromoCodeChange: (promoCode: string) => void
  onContinuePurchaseClick: () => void
  onContinueFreeClick: () => void
}> = ({
  componentCount,
  promoCode,
  onPromoCodeChange,
  onContinuePurchaseClick,
  onContinueFreeClick,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isLoading = useSelect(state => getLoading(state.app, 'purchase'))
  const tiers = useSelect(state => getTiers(state.purchase))
  const priceMultiplier = useSelect(state => getPriceMultiplier(state.purchase))
  const selectedTiers = useSelect(state => getSelectedTiers(state.purchase))
  const activePurchase = useSelect(state => getActivePurchase(state.purchase))

  // MARK: - Handlers

  const handleTierClick = useCallback(
    (key: 'featureUsage' | 'audience') => (tier: PurchaseUsageTier | PurchaseAudienceTier) => {
      dispatch(setSelectedTiers({ [key]: tier }))
    },
    [],
  )

  const handleContactUsClick = useCallback(() => {
    openURL(config.platformUrl + '/demo')
  }, [config.platformUrl])

  // MARK: - Render

  return (
    <LoadingWrapper isLoading={isLoading && !tiers.audience.length}>
      <PurchaseUsageTierSelectionRow
        title={t('activeFeatureCount')}
        subTitle={t('currentlyActiveFeatureCount', { active: componentCount })}
        overused={componentCount > (selectedTiers.featureUsage?.usage_limit ?? 0)}
        tiers={tiers.featureUsage.filter(tier => tier.tier)}
        selectedTier={selectedTiers.featureUsage}
        onTierClick={handleTierClick('featureUsage')}
      />

      <Row style={{ marginBottom: 4, marginTop: 40 }}>
        <img
          src={dashboardSidebarOptionsImage('audience')}
          alt=""
          style={{ height: 44, width: 44, marginLeft: 1, marginRight: 12 }}
        />
        <Col>
          <LabelLarge>{t('memberCount')}</LabelLarge>
          <LabelNormal>{t('memberCountDescription')}</LabelNormal>
        </Col>
      </Row>

      <GridContainer>
        {tiers.audience
          .filter(tier => tier.tier)
          .map(audienceTier => (
            <PurchaseAudienceTierSelectionItem
              key={audienceTier.id}
              isSelected={audienceTier.id === selectedTiers.audience?.id}
              audienceTier={audienceTier}
              priceMultiplier={priceMultiplier}
              currencyLabel={'$'}
              onAudienceTierPurchaseClick={handleTierClick('audience')}
            />
          ))}
      </GridContainer>

      <Row style={{ marginBottom: 16, marginTop: 28 }}>
        <img
          src={
            'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F8731277?alt=media&token=2dc25b2b-d60b-4bf8-bc96-440166c95901'
          }
          alt=""
          style={{ height: 44, width: 44, marginLeft: 1, marginRight: 12 }}
        />
        <Col>
          <LabelLarge>{t('promoCode')}</LabelLarge>
          <SubTitle>
            {t('ifNonProfitOrganization')}
            <ReachUs onClick={handleContactUsClick}>{t('pleaseContactUs')}</ReachUs>
          </SubTitle>
        </Col>
      </Row>

      <Input placeholder="#LOBBI2022" value={promoCode} onChange={onPromoCodeChange} />

      <PurchaseTierSelectionFooter
        isLoading={isLoading}
        canContinueFree={!activePurchase && componentCount <= 2}
        onSecondaryButtonClick={onContinueFreeClick}
        onPurchaseClick={onContinuePurchaseClick}
      />
    </LoadingWrapper>
  )
}

// MARK: - Styles

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  height: 21px;
  line-height: 21px;
`

const ReachUs = styled.span`
  cursor: pointer;
  height: 21px;
  line-height: 21px;
  text-decoration: underline;
`
