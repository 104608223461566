import { DashboardFooter } from './DashboardFooter'
import { isEqual } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PageLoader } from 'src/features/common/PageLoader'
import { CoverImage } from 'src/features/platform/common/CoverImage'
import { RoomDatesSection } from 'src/features/platform/common/RoomSections/RoomDatesSection'
import { RoomDescriptionSection } from 'src/features/platform/common/RoomSections/RoomDescriptionSection'
import { RoomLinksSection } from 'src/features/platform/common/RoomSections/RoomLinksSection'
import { RoomLocationSection } from 'src/features/platform/common/RoomSections/RoomLocationSection'
import { RoomPrivacySection } from 'src/features/platform/common/RoomSections/RoomPrivacySection'
import { getLoading, pushError } from 'src/redux/reducers/app'
import {
  getUpdateRoomRequest,
  initializeUpdateRoomRequest,
  setUpdateRoomRequest,
  updateRoom,
} from 'src/redux/reducers/room'
import { Error } from 'src/repository/Error'
import { RoomRequest } from 'src/repository/services/roomService'
import { Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardRoomDetails: React.FC<{ room: Room }> = ({ room }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const request = useSelect(state => getUpdateRoomRequest(state.room))
  const [isRoomEdited, setIsRoomEdited] = useState(false)
  const isLoading = useSelect(state => getLoading(state.app, 'update_room'))

  // MARK: - Effects

  useEffect(() => {
    if (!request) {
      setIsRoomEdited(false)
    } else {
      const isEdited =
        room.title !== request.title ||
        room.overview !== request.overview ||
        room.main_media.url !== request.media.url ||
        room.main_media.offset !== request.media.offset ||
        room.start_date !== request.date.start ||
        room.timezone !== request.date.timezone ||
        room.end_date !== request.date.end ||
        !isEqual(room.config, request.config) ||
        !isEqual(room.access_codes[0], request.access_codes[0]) ||
        room.privacy_type !== request.privacy_type ||
        !isEqual(
          room.links.map(({ url, type, name }) => url + type + name).sort(),
          request.links.map(({ url, type, name }) => url + type + name).sort(),
        ) ||
        !isEqual(room.location, request.location) ||
        !isEqual(room.main_media, request.media)

      setIsRoomEdited(isEdited)
    }
  }, [JSON.stringify(request), room])

  useEffect(() => {
    dispatch(initializeUpdateRoomRequest(room))
  }, [room.id])

  // MARK: - Handlers

  const handleRoomSaveClick = useCallback(() => {
    if (!request?.title || !request?.media.url) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }
    dispatch(updateRoom())
  }, [request])

  const handleDiscardChangesClick = useCallback(() => {
    dispatch(initializeUpdateRoomRequest(room))
  }, [])

  const handleCoverImageChange = useCallback(
    (url?: string) => {
      if (request) {
        dispatch(setUpdateRoomRequest({ ...request, media: { ...request.media, url: url ?? '' } }))
      }
    },
    [request],
  )

  const handleCoverOffsetChange = useCallback(
    (offset: number) => {
      if (request) {
        dispatch(setUpdateRoomRequest({ ...request, media: { ...request.media, offset } }))
      }
    },
    [request],
  )

  const handleUpdatePartialRoom = useCallback(
    (key: keyof RoomRequest) => (input: any) => {
      dispatch(setUpdateRoomRequest({ [key]: input }))
    },
    [],
  )

  // MARK: -  Render

  if (!request) {
    return <PageLoader />
  }

  return (
    <Container>
      <CoverImage
        style={{ borderRadius: 12, overflow: 'hidden' }}
        orientation="landscape"
        initialCover={request.media.url}
        preferredContainerHeight={360}
        initialOffset={request.media.offset ?? 0}
        onCoverImageChange={handleCoverImageChange}
        onCoverOffsetChange={handleCoverOffsetChange}
        editEnabled
      />

      <RoomDescriptionSection
        title={request.title}
        description={request.overview}
        onTitleChange={handleUpdatePartialRoom('title')}
        onDescriptionChange={handleUpdatePartialRoom('overview')}
      />

      <RoomLocationSection
        hideIcon
        defaultLocation={room.location}
        location={request.location}
        onLocationChange={handleUpdatePartialRoom('location')}
      />

      <RoomDatesSection
        hideIcon
        startDate={request.date.start}
        endDate={request.date.end ?? 0}
        timezone={request.date.timezone ?? moment.tz?.guess()}
        onStartDateChange={startDate => {
          const date = Math.floor(startDate)
          if (date && date > 1000) handleUpdatePartialRoom('date')({ ...request.date, start: date })
          else handleUpdatePartialRoom('date')({ ...request.date, start: 0 })
        }}
        onEndDateChange={endDate => {
          const date = Math.floor(endDate)
          if (date && date > 1000) handleUpdatePartialRoom('date')({ ...request.date, end: date })
          else handleUpdatePartialRoom('date')({ ...request.date, end: 0 })
        }}
        onTimezoneChange={timezone =>
          handleUpdatePartialRoom('date')({ ...request.date, timezone })
        }
      />

      <RoomPrivacySection
        hideIcon
        selectedPrivacyType={request.privacy_type}
        accessCode={request.access_codes[0] ?? ''}
        apiKey={request.api_key ?? ''}
        apiSecret={request.api_secret ?? ''}
        onAccessCodeChange={code => dispatch(setUpdateRoomRequest({ access_codes: [code] }))}
        onPrivacyChange={privacyType =>
          dispatch(setUpdateRoomRequest({ privacy_type: privacyType }))
        }
      />

      <RoomLinksSection
        hideIcon
        links={request.links}
        banners={request.config.banners}
        onLinkDelete={index => {
          const links = [...request.links]
          links.splice(index, 1)
          dispatch(setUpdateRoomRequest({ links }))
        }}
        onLinkTypeChange={(index, type) => {
          const links = [...request.links]
          links[index] = { ...links[index], type: type }
          dispatch(setUpdateRoomRequest({ links }))
        }}
        onLinkNameChange={(index, name) => {
          const links = [...request.links]
          links[index] = { ...links[index], name: name }
          dispatch(setUpdateRoomRequest({ links }))
        }}
        onLinkUrlChange={(index, url) => {
          const links = [...request.links]
          links[index] = { ...links[index], url: url }
          dispatch(setUpdateRoomRequest({ links }))
        }}
        onBannersChange={banners =>
          dispatch(setUpdateRoomRequest({ config: { ...request.config, banners } }))
        }
      />

      <DashboardFooter
        visible={isRoomEdited}
        isLoading={isLoading}
        onDiscardClick={handleDiscardChangesClick}
        onSaveClick={handleRoomSaveClick}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 90%;
  padding-bottom: 120px;
  width: 960px;
`
