import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { Select } from 'src/features/common/Select'
import { CoverImage } from 'src/features/platform/common/CoverImage'
import { DateTimePicker } from 'src/features/platform/common/DateTimePicker'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createAnnouncement, updateAnnouncement } from 'src/redux/reducers/component'
import { Error } from 'src/repository/Error'
import { Announcement, Component, Room } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardAnnouncementModal: React.FC<{
  announcement?: Announcement | null
  room: Room
  component: Component
  onClose: () => void
}> = ({ announcement = null, room, component, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [actionName, setActionName] = useState(announcement?.action_name ?? '')
  const [link, setLink] = useState(announcement?.link ?? '')
  const [imageUrl, setImageUrl] = useState(announcement?.image_url ?? room.main_media.url)
  const [imageOffset, setImageOffset] = useState(
    announcement?.image_offset ?? room.main_media.offset,
  )
  const [title, setTitle] = useState(announcement?.title ?? '')
  const [content, setContent] = useState(announcement?.overview ?? '')
  const [notifyUsers, setNotifyUsers] = useState(announcement?.notify_users ?? true)
  const [isScheduled, setIsScheduled] = useState(announcement?.is_scheduled ?? false)
  const [openLink, setOpenLink] = useState(!!announcement?.link?.length)
  const [, setShowPicker] = useState(false)
  const [scheduleDate, setScheduleDate] = useState<number>(announcement?.publish_date ?? 0)
  const isLoading = useSelect(state => getLoading(state.app, 'announcement_list'))
  const shouldCloseWhenLoadingDone = useRef(false)

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleSaveClick = () => {
    if (!title || !content || (isScheduled && !scheduleDate)) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true
    if (announcement) {
      dispatch(
        updateAnnouncement(
          announcement.id,
          title,
          content,
          imageUrl ?? '',
          imageOffset,
          isScheduled ? scheduleDate : 0,
          isScheduled,
          link,
          actionName,
          notifyUsers,
          false,
          true,
        ),
      )
    } else {
      dispatch(
        createAnnouncement(
          room.id,
          component.id,
          title,
          content,
          imageUrl ?? '',
          imageOffset,
          isScheduled ? scheduleDate : 0,
          isScheduled,
          link,
          actionName,
          'info',
          notifyUsers,
          false,
        ),
      )
    }
  }

  // MARK: - Render

  return (
    <ModalWrapper
      style={{ paddingBottom: 160 }}
      title={announcement ? t('updateAnnouncement') : t('createAnnouncement')}
      size="tiny"
      onClose={onClose}>
      <CoverImage
        style={{ borderRadius: 12, overflow: 'hidden', marginBottom: 20, minHeight: 260 }}
        orientation="landscape"
        initialCover={imageUrl}
        preferredContainerHeight={120}
        initialOffset={imageOffset}
        onCoverImageChange={url => setImageUrl(url ?? '')}
        onCoverOffsetChange={setImageOffset}
        editEnabled
      />

      <Input label={t('title')} isRequired value={title} onChange={setTitle} />

      <Input
        inputStyle={{ minHeight: 132 }}
        type="textarea"
        isRequired
        label={t('description')}
        value={content}
        onChange={setContent}
      />

      <InfoSwitch
        title={t('schedule')}
        subtitle={t('scheduleAnnouncementDescription')}
        checked={isScheduled}
        onSwitchToggle={setIsScheduled}
      />

      {isScheduled && (
        <DateTimePicker
          date={scheduleDate ? new Date(scheduleDate * 1000) : new Date()}
          isRequired
          onPickerOpen={() => {
            setShowPicker(true)
            setScheduleDate(new Date().getTime() / 1000)
          }}
          onPickerClose={() => setShowPicker(false)}
          onDateChange={date => setScheduleDate(date)}
        />
      )}

      <InfoSwitch
        title={t('notifyUsers')}
        subtitle={t('notifyUserDescription')}
        checked={notifyUsers}
        onSwitchToggle={setNotifyUsers}
      />

      <InfoSwitch
        title={t('callToAction')}
        subtitle={t('callToActionDescription')}
        checked={openLink}
        onSwitchToggle={setOpenLink}
      />

      {openLink && (
        <FlexRow>
          <Select
            style={{ maxWidth: 180, marginRight: 12 }}
            placeholder={t('actionName')}
            value={actionName}
            onChange={setActionName}>
            <option value={'visit'}>{t('visit')}</option>
            <option value={'details'}>{t('details')}</option>
            <option value={'buy'}>{t('buy')}</option>
            <option value={'learnMore'}>{t('learnMore')}</option>
          </Select>
          <Input isRequired placeholder="http://" value={link} onChange={setLink} />
        </FlexRow>
      )}

      <FooterWrapper>
        <PrimaryButton
          isLoading={isLoading}
          style={{ minWidth: 150 }}
          title={!!announcement ? t('update') : t('create')}
          onClick={handleSaveClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
