import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, LabelLarge, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { getLocalization } from 'src/redux/reducers/app'
import { dashboardSettingsPages, DashboardSettingsType } from 'src/utils/helpers/settingsHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardSettingsHeader: React.FC<{
  settingsType: DashboardSettingsType
  selectedIndex: number
  setSelectedIndex: (index: number) => void
}> = ({ settingsType, selectedIndex, setSelectedIndex }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const localization = useSelect(state => getLocalization(state.app))

  // MARK: - Render

  return (
    <Container>
      <LabelLarge>{t('settings')}</LabelLarge>
      <Row>
        {dashboardSettingsPages(localization)
          [settingsType]()
          .map((item, index) => (
            <TabItem
              key={item.title}
              isSelected={selectedIndex === index}
              onClick={() => setSelectedIndex(index)}>
              {item.title}
            </TabItem>
          ))}
      </Row>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  height: 102px;
  justify-content: space-between;
  padding: 30px 32px 0px 32px;
`

const TabItem = styled(LabelMedium)<{ isSelected: boolean }>`
  border-bottom: 2px solid
    ${({ theme, isSelected }) => (isSelected ? theme.palette.orange : 'transparent')};
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.orange : theme.palette.text.secondary};
  cursor: pointer;
  margin-bottom: -1px;
  margin-right: 24px;
  padding-bottom: 6px;
`
