import { Link, LinkType } from 'src/repository/types'

export const linkImage = (linkType: LinkType): string => {
  switch (linkType) {
    case LinkType.facebook:
      return require('src/assets/images/socialMedia/facebook.png')
    case LinkType.twitter:
      return require('src/assets/images/socialMedia/twitter.png')
    case LinkType.instagram:
      return require('src/assets/images/socialMedia/instagram.png')
    case LinkType.linkedIn:
      return require('src/assets/images/socialMedia/linkedin.png')
    case LinkType.website:
      return require('src/assets/images/socialMedia/website.png')
    case LinkType.youtube:
      return require('src/assets/images/socialMedia/youtube.png')
    case LinkType.zoom:
      return require('src/assets/images/socialMedia/zoom.png')
    case LinkType.email:
      return require('src/assets/images/socialMedia/mail.png')
  }
}

export const editLinkPlaceHolder = (linkType: LinkType) => {
  switch (linkType) {
    case LinkType.facebook:
      return 'https://facebook.com/'
    case LinkType.zoom:
      return 'https://zoom.us/'
    case LinkType.linkedIn:
      return 'https://linkedin.com/'
    case LinkType.website:
      return 'http://'
    case LinkType.twitter:
      return 'https://twitter.com/'
    case LinkType.instagram:
      return 'https://instagram.com/'
    case LinkType.youtube:
      return 'https://www.youtube.com/'
    case LinkType.email:
      return 'info@example.com'
  }
}

export const openURL = (url: string, shouldOpenNewTab: boolean = true) => {
  shouldOpenNewTab ? window.open(url, '_blank') : (window.location.href = url)
}

export const openLocation = (latitude: number, longitude: number) => {
  const url = 'https://maps.google.com?q=' + latitude + ',' + longitude
  openURL(url)
}

export const openLink = (link: Link, shouldOpenNewTab: boolean = true) => {
  shouldOpenNewTab ? window.open(link.url, '_blank') : (window.location.href = link.url)
}
