import { Checkbox } from './Checkbox'
import { FlexCol, RoundedImage, LabelMedium, LabelSmall, FlexRow } from './CommonStyles'
import { DropdownWrapper } from './DropdownWrapper'
import { Hint } from './Hint'
import React from 'react'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { SVG } from 'src/utils/types/svg'
import styled, { useTheme } from 'styled-components'

export const DashboardTableRow: React.FC<{
  index: number
  image?: string
  imagePlaceholder?: string
  title: string
  description: string
  selected: boolean
  status?: { label?: string; hint?: string; color: string; hintWidth?: number; hintDetail?: string }
  dropdownOptions?: { title: string; icon: SVG }[]
  handleOptionsDropdownOptionClick?: (option: number) => void
  onCheckBoxClick: (isOn: boolean) => void
}> = ({
  index,
  image = '',
  imagePlaceholder,
  title,
  description,
  selected,
  status,
  onCheckBoxClick,
  dropdownOptions,
  handleOptionsDropdownOptionClick,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Container index={index}>
      <CheckBoxContainer onClick={event => event.stopPropagation()}>
        <Checkbox checked={selected} onChange={() => onCheckBoxClick(!selected)} />
      </CheckBoxContainer>

      <Column style={{ marginLeft: 16 }} minWidth={250} flex={1}>
        <FlexRow>
          {(image || imagePlaceholder) && (
            <RoundedImage
              style={{ marginTop: 2, objectFit: 'cover' }}
              size={40}
              src={image || imagePlaceholder}
            />
          )}

          <FlexCol>
            <LabelMedium style={{ ...numberOfLines(1), marginBottom: 2 }}>{title}</LabelMedium>
            <LabelSmall style={{ ...numberOfLines(1) }}>{description}</LabelSmall>
          </FlexCol>
        </FlexRow>
      </Column>

      {status ? (
        <Column minWidth={155} style={{ justifyContent: 'flex-start' }}>
          {status.label ? <Status>{status.label}</Status> : null}
          <Hint
            width={status.hintWidth}
            style={{ backgroundColor: status.color, height: 16, width: 16 }}
            alignment={'left'}
            fill={palette.background.primary}
            hint={status.hint ?? ''}
            detail={status.hintDetail ?? ''}
          />
        </Column>
      ) : null}

      {handleOptionsDropdownOptionClick && dropdownOptions ? (
        <DropdownWrapper
          style={{ alignSelf: 'center', marginRight: 8, marginLeft: 12, position: 'relative' }}
          icon={<ThreeDotsIcon size={16} />}
          position={{ right: 24, top: 0 }}
          options={dropdownOptions ?? []}
          handleOptionClick={handleOptionsDropdownOptionClick}
        />
      ) : null}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div<{ index: number }>`
  align-items: center;
  background-color: ${({ theme, index }) =>
    index % 2 === 0 ? theme.palette.background.secondary : theme.palette.background.primary};
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
  width: 100%;
`

const CheckBoxContainer = styled.div`
  align-self: flex-start;
  margin-top: 8px;
`

const Column = styled.div<{ minWidth: number; flex?: number }>`
  align-items: center;
  display: flex;
  flex: ${({ flex }) => flex ?? 0};
  flex-direction: row;
  min-width: ${({ minWidth }) => minWidth}px;
  padding-right: 32px;
`

const Status = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 4px 12px 4px 0px;
`
