import { api } from '../api'
import { User, UserKey, UserSettings } from '../types'
import { identity, pickBy } from 'lodash'

export const userService = {
  fetchUserStatus: async (email?: string, phone?: string) =>
    api.value<{
      continue: 'login' | 'register'
      auth_types: string[]
      setup?: {
        id: string
        first_name: string
        last_name: string
        user_name: string
      }
    }>({
      request: {
        method: 'POST',
        endPoint: '/continue',
        parameters: pickBy({ email, phone }, identity),
      },
    }),

  linkUserEmail: async (email: string, password: string) =>
    api.prop<{ success: boolean }>('success', {
      request: {
        method: 'POST',
        endPoint: '/user/linkEmail',
        parameters: { email, password },
      },
    }),

  fetchUserByEmail: async (email?: string, phone?: string) =>
    api.value<{ user: User; settings: UserSettings }>({
      request: {
        method: 'GET',
        endPoint: '/userByEmail',
        parameters: pickBy({ email, phone }, identity),
      },
    }),

  checkUsernameInUse: async (username: string) =>
    api.prop<{ available: boolean }>('available', {
      request: {
        method: 'GET',
        endPoint: '/checkUsername',
        parameters: { username },
      },
    }),

  fetchUser: async (id: string) =>
    api.value<{ user: User; settings: UserSettings }>({
      request: {
        method: 'GET',
        endPoint: '/user',
        parameters: { id },
      },
    }),

  createUser: async (request: CreateUserRequest) =>
    api.value<{ user: User; settings: UserSettings }>({
      request: {
        method: 'POST',
        endPoint: '/user',
        parameters: pickBy(request, identity),
      },
    }),

  updateUser: async (request: UpdateUserRequest) =>
    api.value<{ user: User; settings: UserSettings }>({
      request: {
        method: 'PUT',
        endPoint: '/user',
        parameters: pickBy(request, identity),
      },
    }),

  fetchUserKeys: async (userId: string) =>
    api.value<{ user: User; key: UserKey }>({
      request: {
        method: 'GET',
        endPoint: '/user/secret/keys',
        parameters: { user_id: userId },
      },
    }),

  refreshUserKeys: async (userId: string, apiKey: string) =>
    api.value<{ user: User; key: UserKey }>({
      request: {
        method: 'PUT',
        endPoint: '/user/secret/keys',
        parameters: { user_id: userId, api_key: apiKey },
      },
    }),

  forgotPassword: async (email: string) =>
    api.prop<{ email: string }>('email', {
      request: {
        method: 'POST',
        endPoint: '/user/forgotPassword',
        parameters: { email },
      },
    }),

  deleteUser: async () =>
    api.value({
      request: {
        method: 'DELETE',
        endPoint: '/user',
        parameters: {},
      },
    }),
}

export type CreateUserRequest = {
  email?: string
  first_name: string
  phone_number?: string
  last_name: string
  user_name: string
  image_url: string
  auth_types: string[]
  notification_token: string
}

export type UpdateUserRequest = {
  first_name: string
  last_name: string
  user_name: string
  image_url: string
}
