import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#F36538', ...props }) => (
  <svg width={size} height={size} {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M20 7L12.6154 14.5L9.53846 11.375L4 17"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 13V7H14"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SalesIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.orange} {...props} />
}
