const en = {
  time: 'Thu, Oct 15, 2020, 10:23 AM',
  translation: {
    accept: 'Accept',
    accepted: 'Accepted',
    access: 'Access',
    accessCode: 'Access Code',
    accessDescription:
      'Determine your lobbi access level. Access level of managed lobbies only be changed by customer support assistance.',
    accessError: 'Access error',
    accessLevel: 'Access Level',
    account: 'Account Settings',
    accountKeys: 'Account Keys',
    action: 'Action',
    activate: 'Enable',
    activateImmediately: 'Activate',
    activateImmediatelyDescription: 'You can activate/deactivate features after creation.',
    active: 'Enabled',
    activeFeatures: 'Owned Features {{active}}/{{total}}',
    add: 'Add',
    addCalendarEvent: 'Create Calendar Event',
    addEndTime: 'Set end time',
    addEvent: 'Add Action',
    addFeature: 'Add Feature',
    addItem: 'Add Item',
    addNew: 'Add New',
    addNewLink: 'Add new link',
    addOption: 'Add Option',
    addToSchedule: 'Add to schedule',
    admin: 'Admin',
    admins: 'Admins',
    adminsDescription:
      'Admin has full access to the lobbi, including configuring or deleting the lobbi.',
    agreeAndContinue: 'Agree and continue',
    all: 'All',
    allColors: 'All colors',
    allowAskModerator: 'Ask to Moderator',
    allowAskModeratorDescription:
      'Open channels where members can ask questions directly to the lobbi moderator.',
    allowChat: 'Chat',
    allowChatChannels: 'Chat Channels',
    allowChatChannelsDescription:
      'Create chat channels on specific topics and let members message.',
    allowChatDescription: 'Enable members to send messages.',
    allowGeneralChat: 'General Chat',
    allowGeneralChatDescription:
      'Activate the public channel where all lobbi members can message freely.',
    allowOneToOneChat: 'Personal Chats',
    allowOneToOneChatDescription: 'Allow members to create one-to-one or group chat channels.',
    allowSeeMore: 'Promote other owned lobbies',
    allowSeeMoreDescription: 'Activate the link to your profile and other lobbies.',
    allowShare: 'Sharing and invitation',
    allowShareDescription: 'Allow your lobbi to be shared by members.',
    allShapes: 'All shapes',
    analytics: 'Analytics',
    analyticsDescription: 'Monitor lobbi performance',
    announcementDescription:
      'Keep your audience up to date with news and updates and increase excitement.',
    announcementListDescriptionPlaceholder:
      'Information about the announcement list, calendar and plans...',
    announcementListTitlePlaceholder: 'Name of the announcement list',
    announcements: 'Announcements',
    answer: 'Answer',
    answered: 'Answered',
    answerPlaceHolder: 'Please enter answer here...',
    apiKey: 'API Key',
    apiSecret: 'API Secret',
    askQuestion: 'Ask anonymous question',
    audience: 'Audience',
    audienceCount: '{{count}} member',
    audienceSize: 'Audience Size',
    audienceSizeDescription: 'Member count of the lobbi',
    authContinue: 'Log in or sign up',
    authPassword: 'Enter your password',
    authRegister: 'Finish signing up',
    back: 'Back',
    banned: 'Banned',
    bannerImage: 'Banner ımage',
    bannerLinkPlaceholder: 'Banner CTA link',
    banners: 'Banners',
    basicInfo: 'Basic Info',
    billingType: 'Billing Type',
    black: 'Black',
    branding: 'Branding',
    brandingAndNavigation: 'Links and Banners',
    brandingDescription: 'Add your banners with their links, make your sponsors and brand visible.',
    broadcast: 'Broadcast',
    broadcastDescription: 'Broadcast your lobbi in strategic locations.',
    buildAPlan: 'Build a plan',
    buy: 'Buy',
    calendar: 'Calendar',
    calendarEvents: 'Calendar Events',
    calendarEventsDescription: 'Number of lobbi members who created calendar events for your lobbi',
    callToAction: 'Call to Action',
    callToActionDescription: 'Add call to action link to announcement.',
    cancel: 'Cancel',
    change: 'Change',
    changeCover: 'Change cover',
    chatDisabled: 'Chat disabled',
    chatDisabledDescription:
      'To edit your chat settings, please enable chats from the general settings.',
    chatPleaseGoLive: 'Please go live to create chat channels.',
    chats: 'Chats',
    chatsDescription: 'Chat channels for your audience to interact with.',
    chatSettings: 'Chat Settings',
    chatSettingsDescription: 'Manage channels where lobbi members can interact by messaging.',
    chooseAnImage: 'Choose an image',
    clear: 'Clear',
    clone: 'Clone',
    cloneRoom: 'Clone lobi',
    color: 'Color',
    completed: 'Compeleted',
    configuration: 'Configuration',
    configurationDescription: 'Edit lobbi settings and preferences',
    configurePlan: 'Configure Plan',
    congrats: 'Congrats!',
    content: 'Content',
    continue: 'Continue',
    continueFreePlan: 'Continue with Free Plan',
    continueWith: 'Continue with {{provider}}',
    continueWithFreePlan: 'Continue with Free Plan',
    continueWithFreePlanBlockedDescription:
      'This plan is limited with 1 daily email and 2 active features. Please decrease active feature count to continue.',
    continueWithFreePlanDescription:
      'Free Plan is limited to 1 active feature only. You can upgrade any time.',
    copiedToClipboard: 'Copied to clipboard!',
    copyLink: 'Copy Link',
    copyLinkDescription: 'Copy the lobbi link to the clipboard.',
    countDailyNotifications: '{{count}} daily notifications',
    countMembers: '{{count}} members',
    countPremiumFeatures: '{{count}} features',
    create: 'Create',
    createAnnouncement: 'Create Announcement',
    createChatChannel: 'Create Channel',
    createFAQ: 'Create Frequently Asked Question',
    createNotification: 'Create Email',
    createPoll: 'Create Poll',
    createQuestion: 'Create Question',
    createProject: 'Create project',
    createProjectDescription:
      'Name your lobbi and tell subscribers why they should come. Add details that highlight what makes it unique.',
    createWebListItem: 'Create Web List Item',
    creating: 'Creating',
    cropImage: 'Crop Image',
    currentTimezone: "Project's timezone is",
    dailyNotifications: 'Daily Notifications',
    dailyNotificationsUsage: 'Number of direct notifications sent to lobbi members',
    dangerZone: 'Danger zone',
    darkTheme: 'Dark theme',
    date: 'Date',
    dateAndTime: 'Date and Time',
    dateAndTimeDescription: 'When does your project start and end?',
    datesLinks: 'Dates & Links',
    deactivate: 'Disable',
    delete: 'Delete',
    deleteAccount: 'Delete account',
    deleteAccountWarning: 'Want to delete your account?',
    deleteAnnouncement: 'Delete Announcement',
    deleteAnnouncementMessage: 'Are you sure to delete announcement? This action is irreversible.',
    deleteAnnouncements: 'Delete Announcements',
    deleteAnnouncementsMessage:
      'Are you sure to delete announcements? This action is irreversible.',
    deleteChatChannel: 'Delete Chat Channel',
    deleteChatChannelMessage: 'Are you sure to delete chat channels? This action is irreversible.',
    deleteChatChannels: 'Delete Chat Channels',
    deleteChatChannelsMessage: 'Are you sure to delete chat channels? This action is irreversible.',
    deleteFeature: 'Delete Feature',
    deleteFeatureMessage: 'Are you sure to delete {{name}} feature? This action is irreversible.',
    deleteInvitation: 'Delete Invitation',
    deleteInvitationMessage: 'Are you sure to delete invitation? This action is irreversible.',
    deleteInvitations: 'Delete Invitations',
    deleteInvitationsMessage: 'Are you sure to delete invitations? This action is irreversible.',
    deleteItem: 'Delete Item',
    deleteItemMessage: 'Are you sure to delete item? This action is irreversible.',
    deleteItems: 'Delete Items',
    deleteItemsMessage: 'Are you sure to delete items? This action is irreversible.',
    deleteMessage: 'Are you sure to delete your account? This action is irreversible.',
    deleteNotification: 'Delete Notification',
    deleteNotificationMessage: 'Are you sure to delete notification? This action is irreversible.',
    deleteNotifications: 'Delete Notifications',
    deleteNotificationsMessage:
      'Are you sure to delete notifications? This action is irreversible.',
    deleteQuestion: 'Delete Question',
    deleteQuestionMessage: 'Are you sure to delete question? This action is irreversible.',
    deleteQuestions: 'Delete Questions',
    deleteQuestionsMessage: 'Are you sure to delete questions? This action is irreversible.',
    deleteRoom: 'Delete Lobbi',
    deleteRoomMessage: 'Are you sure to delete this lobbi? This action is irreversible.',
    deleteTrackedUrl: 'Delete Tracked Url',
    deleteTrackedUrlMessage: 'Are you sure to delete tracked urls? This action is irreversible.',
    deleteTrackedUrls: 'Delete Tracked Urls',
    deleteTrackedUrlsMessage: 'Are you sure to delete tracked urls? This action is irreversible.',
    description: 'Description',
    details: 'Details',
    discard: 'Discard',
    displayName: 'Display Name',
    done: 'Done',
    draftLobby: 'Draft lobbi',
    dragDrop: 'Drag & drop or click to add image. {{ratio}}',
    dragDropOrClick: 'Drag & drop or click',
    dragImageToReposition: 'Drag image to reposition',
    dragPollToActivate: 'Drag poll here to activate',
    dragQuestionToAnswer: 'Drag question here to answer',
    dragQuestionToReject: 'Drag question here to reject',
    edit: 'Edit',
    editAccount: 'Edit account',
    email: 'Email',
    emailAddress: 'Email address',
    emails: 'Emails',
    emailSent: 'Email Sent',
    emailSentTo: 'Email has sent to {{email}}',
    emailWillSentTo: 'Email will be sent to {{email}} when limit exceeded',
    enableAnonymousQuestions: 'Enable Anonymous Questions',
    enableAnonymousQuestionsDescription: 'Let audience to ask questions anonymously.',
    enabledNotificationsDescription:
      'Once an announcement published, members of the lobbi would receive a push notification',
    enableNotifications: 'Enable Notifications',
    end: 'End: {{date}}',
    endDate: 'End Date',
    endTime: 'End Time',
    enterprise: 'Enterprise',
    enterYourEmail: 'hello@lobbi.events',
    enterYourPhoneNumber: '+1 111 111 1111',
    eventContentDescription: "What's your project about and who should attend?",
    eventName: 'Title',
    eventUrl: `Project's url`,
    eventUrlDescription: 'Youtube, Google Meet, Twitch, Twitter wherever it is.',
    expectedAudienceSize: 'Expected Audience Size',
    facebook: 'Facebook',
    faq: 'FAQ',
    faqDescription: 'Inform your audience by listing frequently asked questions and their answers',
    faqDescriptionPlaceholder: 'More information on the frequently asked questions list...',
    faqShort: 'FAQ',
    faqTitlePlaceholder: 'Name of frequently asked question list.',
    featureClicks: 'Feature Clicks',
    featureClicksDescription: 'Number of clicks on features added to the lobbi',
    featureImage: 'Feature image',
    features: 'Features',
    feelingLucky: "I'm feeling lucky",
    fill: 'Fill',
    firstName: 'First Name',
    forgotPassword: 'Forgot your password?',
    foundation: 'Foundation',
    future: 'Future',
    gallery: 'Gallery',
    gameEvents: 'Actions & Points',
    gameEventsDescription:
      'When the referred user performs the following actions, the referrer earns points.',
    general: 'General',
    generalSettings: 'General Settings',
    getStarted: 'Get started',
    goLive: 'Go Live',
    goLiveToShare: 'Go live to share',
    goToRoom: 'Go to Lobbi',
    gradient: 'Gradient',
    groupByPacks: 'Group by packs',
    handDrawn: 'Hand drawn',
    hello: 'Hello',
    hit: 'Hit',
    home: 'Home',
    horizontal: 'Horizontal',
    howPlansWorks: 'How Lobbi Plans Work?',
    howPlansWorksCharge: 'You will only be charged based on usage of features and audience size.',
    howPlansWorksOverUsage:
      'In case of overuse, you will be contacted. Our support team will help you scale your plan.',
    howPlansWorksUpgrade:
      'You can upgrade the purchased plan whenever you want to increase lobbi limits.',
    icon: 'Icon',
    ifNonProfitOrganization: 'If non-profit organization or expecting +100,000 subscriptions, ',
    ifYouHaveBanners: 'If you have brand or sponsor banners.',
    ifYourEventHasAnEndTime: 'If your project has an end time',
    image: 'Image',
    imageRestriction: 'JPEG or PNG, < 10MB.',
    imageSizeLimit: 'JPEG or PNG, no larger than 10MB.',
    imagesLowerThan: 'The maximum size per file is 10 mb.',
    imagesWiderThan: 'Images wider than 1500 pixels work best.',
    inactive: 'Disabled',
    included: 'Included',
    information: 'Information',
    informativeLinks: 'Informative Links',
    inPersonWithPrefix: '🌍 In Person',
    instagram: 'Instagram',
    instagramPost: 'Instagram post',
    invalidBannerInput:
      'You have entered incomplete information to create a banner. Please check images and links.',
    invalidInput: 'Invalid Input',
    invalidInputDescription: 'Please fill all required input fields.',
    invitationAccepted: 'Invitation accepted at {{date}}',
    invitationRequests: 'Invitation Requests',
    invitations: 'Invitations',
    invite: 'Invite',
    inviteAudience: 'Invite Audience',
    inviteOnly: 'Invite Only',
    inviteOnlyDescription: 'Only invited users can access the lobbi.',
    inviteTeammate: 'Invite Teammate',
    join: 'Join Lobbi',
    language: 'Language',
    languageDescription: "Choose your lobbi's default language.",
    lastName: 'Last Name',
    learnMore: 'Learn more',
    letsCreateRoom: "Let's create your first lobbi",
    letsCreateRoomDescription: 'Once you create lobbies, they will appear here',
    letsGo: "Let's Go",
    lightTheme: 'Light theme',
    limit: 'Limit',
    linearColor: 'Linear color',
    link: 'Link',
    linkClicks: 'Link Clicks',
    linkClicksDescription: 'Number of clicks on links connected to the lobbi',
    linkedIn: 'LinkedIn',
    links: 'Links',
    linksDescription: "You can add your brand's links and banners to the lobbi.",
    live: 'Live',
    liveLobby: 'Live',
    lobbi: 'Lobbi',
    lobbies: 'Lobbies',
    lobbiPlan: 'Lobbi {{type}} Plan',
    location: 'Location',
    locationClicks: 'Location Clicks',
    locationClicksDescription: 'The number of clicks on the location connected to the lobbi',
    locationDescription: 'Where will your project take place or be published?',
    login: 'Login',
    logInOrSignUp: 'Log in or sign up',
    logout: 'Logout',
    logoutMessage: 'Are you sure to logout from your account?',
    manage: 'Manage ',
    managed: 'Managed',
    managedDescription: 'Access to the lobbi is provided by a third-party platform.',
    manageRoom: 'Manage Lobbi',
    manageRoomDescription:
      'Note that, access level of managed lobbies only be changed by customer support assistance.',
    media: 'Media',
    mediaDescription:
      'Images will be the first content your audience will see, so use high-quality images.',
    memberCount: 'Number of users joined in your lobbi',
    moderator: 'Moderator',
    moderators: 'Moderators',
    moderatorsDescription:
      'Moderator authority has accesses such as changing the features added to the lobbi, sending notifications.',
    name: 'Name',
    next: 'Next',
    noAdmins: 'No Admins',
    noAdminsDescription:
      'There is no admin yet. When a admin is assigned to the lobbi, it will appear here.',
    noAnnouncementsPostedYet: 'No announcements posted yet',
    noAnsweredQuestions: 'No answered questions',
    noAudienceParticipated: 'No members participated yet',
    noAudienceParticipatedDescription:
      'Once members participate the game, their scores will appear here',
    noChatChannelCreated: 'No chat channel created yet',
    noInvitationSent: 'No invitations sent yet',
    noItemAdded: 'No item added yet',
    noLimit: 'No Limit',
    noModerators: 'No Moderators',
    noModeratorsDescription:
      'There is no moderator yet. When a moderator is assigned to the lobbi, it will appear here.',
    noNotificationSent: 'No emails created yet',
    noPendingQuestions: 'No pending questions',
    noPurchasedPlans: 'No purchased plans',
    noPurchasedPlansDescription:
      'Once you purchase plans for your lobbi, you can monitor their usage here.',
    noQuestionsCreatedYet: 'No questions created yet',
    noRejectedQuestions: 'No rejected questions',
    notes: 'Notes',
    notification: 'Notification',
    notifications: 'Notifications',
    notificationsDescription: 'Send or schedule emails to your audience.',
    notifyMe: 'Notify Me',
    notifyMeDescription: 'Send email when invitation is accepted.',
    notifyUserDescription: 'Send an email to lobbi members when the announcement is posted.',
    notifyUsers: 'Notify Users',
    noUrlsTracked: 'No urls tracked yet',
    ok: 'Okay',
    onceAudienceAsksQuestion: 'Once users ask questions, they will appear here',
    onceChatChannelCreated: 'Once chat channels are created, they will appear here.',
    onceInvitationSent: 'Once you send invitations, they will appear here',
    onceItemAdded: 'Once you add items to the list, they will appear here',
    onceNotificationSent: 'Once you create emails, they will appear here',
    oncePublisherPostedAnnouncements: 'Once you post announcements, they will appear here',
    onceQuestionsCreated: 'Once you create questions, they will appear here',
    onceUrlsTracked: 'Once you create tracked urls, they will appear here',
    onceYouCreatePoll: 'Once you create polls, they will appear here',
    oneTime: 'One Time',
    oopps: 'Oopps!',
    opened: 'Opened',
    openedFromList: 'Opened From List',
    openedLive: 'Opened (Live)',
    openLink: 'Open link',
    openLinkAnnouncements: 'When the announcement is pressed, direct the user to the link.',
    openLinkDescription: 'When the notification is pressed, redirect the user to the link.',
    option: 'Option',
    or: 'or',
    outline: 'Outline',
    overview: 'Overview',
    participant: 'Participant',
    password: 'Password',
    past: 'Past',
    pasteAnImageLink: 'Paste an image link...',
    paymentDetails: 'Payment Details',
    pending: 'Pending',
    personalNotes: 'Personal Notes',
    phoneNumber: 'Phone number',
    plans: 'Plans',
    pleaseContactUs: 'please contact us ',
    pleaseContinueWith:
      'Your account supports a different login method. Please continue with {{type}}.',
    pleaseEnterTitle: 'Please enter a title',
    pleaseEnterURL: 'Please enter a valid project url',
    pleaseEnterVenue: 'Please select a venue',
    pleaseEnterZoomID: 'Please enter a zoom id',
    pleaseEnterZoomURL: 'Please enter a valid zoom url',
    pleaseGoLiveToBroadcast: 'Please go live to broadcast your lobbi.',
    pleaseSelectLocation: 'Please select location to preview',
    point: 'Point',
    polling: 'Polling',
    pollingDescription: 'Listen and analyze the opinions of your audience by creating polls.',
    pollingDescriptionPlaceholder: 'The subject of the polling and the scope of the polls',
    pollingTitlePlaceholder: 'Name or topic of the polling',
    premiumFeatures: 'Features',
    premiumFeaturesUsage: 'Number of features used in the lobbi',
    preview: 'Preview',
    previous: 'Previous',
    price: 'Price',
    privacyAccess: 'Privacy & Access',
    privacyAccessDescription: 'Who can access your lobbi and how?',
    privacyPolicy: 'Privacy Policy',
    private: 'Private',
    privateDescription: 'Users can enter the lobbi with an access code.',
    privateRoomAccessCodeError: 'Private lobbies require an access code, please enter one.',
    pro: 'Pro',
    profile: 'Profile',
    profileImage: 'Profile Image',
    promoCode: 'Promo Code',
    properties: 'Properties',
    proPlus: 'Pro +',
    public: 'Public',
    publicDescription: 'Anyone can enter the lobbi.',
    published: 'Live',
    punchLine: 'Design viral lobbi experiences',
    purchase: 'Purchase',
    purchaseCompleted:
      'Your purchase is completed, now you can generate buzz for your next big thing.',
    purchaseDate: 'Purchase Date',
    purchaseHistory: 'Purchase History',
    purchasePlan: 'Purchase Plan',
    purchaserEmail: 'Purchaser Email',
    purchaseRoom: 'Purchased Lobbi',
    purchaseSummary: 'Purchase Summary',
    qa: 'Q&A',
    qaDescription: 'Let your audience ask you questions directly.',
    qaDescriptionPlaceholder: 'The subject of the Q&A and the scope of the questions',
    qaTitlePlaceholder: 'Name or topic of the Q&A',
    qrCode: 'QRCode',
    qrCodeDescription: 'Add QRCodes to visual ads and posters, users can read with cameras.',
    question: 'Question',
    radius: 'Radius',
    reachCustomerSupportForEndDate:
      'Please reach costumer support to change end date. (info@olvr.app)',
    reachCustomerSupportForStartDate:
      'Please reach costumer support to change start date. (info@olvr.app)',
    refresh: 'Refresh',
    rejected: 'Rejected',
    reminders: 'Reminders',
    remindersDescription:
      'Number of lobbi members who set up push notifications to remember your lobbi',
    remove: 'Remove',
    reposition: 'Reposition',
    requestADemo: 'Request a Demo',
    requestDemo: 'Request a Demo',
    requests: '{{count}} requests',
    revoke: 'Revoke',
    revokeAccess: 'Revoke Access',
    revokeAccessDescription:
      'Are you sure to revoke access of the teammate? You can give access again afterwards.',
    roomAccess: 'Lobbi Access',
    rooms: 'Lobbies',
    save: 'Save',
    savePosition: 'Save position',
    scaleAudience: 'Scale audience up to {{count}} members',
    schedule: 'Schedule',
    scheduleAnnouncementDescription:
      'You can send the announcement directly or schedule it for a future date.',
    scheduled: 'Scheduled',
    scheduledTo: 'Scheduled to',
    scheduleNotificationDescription:
      'You can send notification directly or schedule for a future date.',
    score: 'Score',
    search: 'Search',
    searchAnImage: 'Search an image...',
    searchIcon: 'Search icon',
    searchIconPack: 'Search icon pack',
    searchVenue: 'Search for a venue or address..',
    seeYouAround: 'See you around 😢',
    selected: '{{number}} selected',
    selectedSpreadType: 'Select Spread Type',
    selectIcon: 'Select icon',
    send: 'Send',
    sendInvitation: 'Send invitation',
    sendInvitations: 'Manage and send invitations to your audience.',
    sendNotification: 'Create email',
    sent: 'Sent',
    sentAt: 'Sent at',
    setPushNotification: 'Set reminder',
    settings: 'Settings',
    settingsDescription: 'Determine the capabilities of your members and your lobbi.',
    sharableLink: 'Shareable link',
    somethingWentWrong: 'Something went wrong',
    spreadGame: 'Referral',
    spreadGameDescription:
      'Use the force of word of mouth to turn your happy customers into brand ambassadors.',
    spreadGameDescriptionPlaceholder:
      'The subject of the game, its rules and the prizes to be distributed...',
    spreadGameTitlePlaceholder: 'Name of the spread game',
    spreadTheWord: 'Spread the Word',
    square: 'Square',
    start: 'Start: {{date}}',
    startDate: 'Start Date',
    starter: 'Starter',
    startTime: 'Start Time',
    status: 'Status',
    submit: 'Submit',
    tbaDescription: 'Project location will be announced next week',
    tbaHint: 'When location will be announced or share some tips..',
    TBAWithPrefix: '❓ TBA',
    teammates: 'Teammates',
    teams: 'Teams',
    teamSettings: 'Team Settings',
    teamSettingsDescription: 'Invite and collaborate with teammates to manage your lobbies',
    termsConditionsInfo:
      "By selecting Agree and continue, I agree to Lobbi's Terms of Service, and acknowledge the Privacy Policy.",
    termsOfService: 'Terms of Service',
    tierCount: '{{count}} units',
    time: 'Time',
    timezone: 'Timezone',
    title: 'Title',
    toGetPromoCode: ' to get promo code.',
    tracking: 'Tracking',
    trackUrl: 'Track Url',
    twitter: 'Twitter',
    twitterDescription: 'Create tweets and share directly.',
    type: 'Type',
    unlimited: 'Unlimited',
    unsplash: 'Unsplash',
    upcoming: 'Upcoming',
    update: 'Update',
    updateAnnouncement: 'Edit Announcement',
    updateFAQ: 'Edit Frequently Asked Question',
    updateWebListItem: 'Edit Web List Item',
    upgradePlan: 'Upgrade Plan',
    upload: 'Upload',
    uploadImage: 'Upload image',
    usage: 'Usage',
    usageBilling: 'Usage & Billing',
    usageBillingDescription: 'Manage plans and payments, review lobbi limits.',
    userName: 'Username',
    venueAddress: 'Venue address',
    verificationCode: 'Verification code',
    viewAllLobbies: 'View all lobbies',
    virtualWithPrefix: '💻 Virtual',
    visit: 'Visit',
    webLinks: 'Web Links',
    webList: 'Web Site List',
    webListDescription:
      'Cross-promote your brand, product and content with a list of your web sites.',
    webListDescriptionPlaceholder: 'Subject and target of the web site list...',
    webListTitlePlaceholder: 'Name of web site list',
    webPage: 'Web Site',
    webPageDescription:
      'Increase the number of visitors to your web sites by embedding them in the lobbi',
    webPageDescriptionPlaceholder: 'Subject and target of the web site...',
    webPageTitlePlaceholder: 'Name of web site',
    webPageUrl: 'Web site url',
    website: 'Web Site',
    welcomeToLobbi: 'Welcome to Lobbi',
    worksWithAnyImage: 'Works with any image from the web.',
    yes: 'Yes',
    you: 'You',
    yourNextAmazingThing: 'Your next amazing thing',
    youSentInvitation: 'Invitation sent at {{date}}',
    youtube: 'Youtube',
    zoom: 'Zoom',
    zoomMeetingId: 'Zoom meeting id',
    zoomMeetingIdDescription: 'The meeting identifier you got from Zoom.',
    zoomMeetingIdPlaceholder: '123456789',
    zoomMeetingPassword: 'Zoom meeting password',
    zoomMeetingPasswordDescription: 'If you created a meeting with password.',
    zoomMeetingPasswordPlaceholder: '22222222',
    zoomMeetingUrl: 'Zoom meeting url',
    zoomMeetingUrlDescription: 'The URL for your audience to join meeting.',
    zoomMeetingUrlPlaceholder: 'https://zoom.us/j/987654321',
    zoomWithPrefix: 'Zoom',
    activeFeatureCount: 'Active feature count',
    currentlyActiveFeatureCount: 'Current active feature count {{active}}',
    chatAndMemberList: 'Chat and member list',
    chatAndMemberListDescription: 'Allow member interaction of lobbi members.',
    joined: 'Joined at {{date}}',
    blocked: 'Blocked at {{date}}',
    exportOrImport: 'Export / Import',
    audienceDescription: 'Manage, import and export lobbi members',
    importAudience: 'Add new members in .csv format',
    exportAudience: 'Download member report in .csv format',
    members: 'Members',
    noMembersFound: 'No members found',
    onceMembersJoinToLobby:
      'When users join to the lobbi and become members, they will appear here.',
    noMembersFoundForTheQuery:
      'Unfortunately, a member matching the query you entered could not be found. Check your query and try again.',
    removeMember: 'Remove member',
    removeAction: 'Remove',
    removeMemberMessage:
      'Are you sure you want to remove selected users? Users can join lobbi again.',
    downloadReport: 'Download report',
    block: 'Block',
    blockUser: 'Block user',
    blockMessage: 'Are you sure you want to block selected users? Users can not join lobbi again.',
    removeSelectedAudience: 'Remove selected users from the lobbi.',
    uploadError: 'Upload error',
    uploadErrorMessage: 'Failed to upload to firebase storage',
    inviteSingleUser: 'Invite single user',
    inviteBulkUsers: 'Invite bulk users',
    blockMember: 'Block member',
    unblockMember: 'Unblock member',
  },
}

export default en
