import { Thunk } from '../store'
import produce from 'immer'
import { IError } from 'src/repository/Error'
import config from 'src/utils/config'
import i18n from 'src/utils/localization'
import { Localization } from 'src/utils/types/i18n'

// MARK: - Types

type Theme = 'dark' | 'light'
type State = {
  theme: Theme
  localization: Localization
  loading: Record<string, boolean>
  errors: IError[]
}

type ActionType =
  | { type: 'app/setTheme'; theme: Theme }
  | { type: 'app/setLocalization'; localization: Localization }
  | { type: 'app/pushLoading'; key: keyof State['loading']; loading: boolean }
  | { type: 'app/setErrors'; errors: IError[] }

const initialState: State = {
  theme: (localStorage.getItem(config.keys.theme) as Theme) ?? 'light',
  localization: localStorage.getItem(config.keys.localization) as Localization,
  loading: {},
  errors: [],
}

// MARK: - Reducer

export const appReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'app/setTheme':
      return produce(state, draft => {
        draft.theme = action.theme
        return draft
      })

    case 'app/setLocalization':
      return produce(state, draft => {
        draft.localization = action.localization
        return draft
      })

    case 'app/setErrors':
      return produce(state, draft => {
        draft.errors = action.errors
        return draft
      })

    case 'app/pushLoading':
      return produce(state, draft => {
        draft.loading[action.key] = action.loading
        return draft
      })

    default:
      return state
  }
}

// MARK: Actions

export const setTheme =
  (theme: Theme): Thunk<ActionType> =>
  dispatch => {
    localStorage.setItem(config.keys.theme, theme)
    dispatch({ type: 'app/setTheme', theme: theme })
  }

export const setLocalization =
  (localization: Localization): Thunk<ActionType> =>
  dispatch => {
    localStorage.setItem(config.keys.localization, localization)
    i18n.changeLanguage(localization)
    dispatch({ type: 'app/setLocalization', localization: localization })
  }

export const pushLoading = (key: keyof State['loading']): ActionType => ({
  type: 'app/pushLoading',
  key: key,
  loading: true,
})

export const popLoading = (key: keyof State['loading']): ActionType => ({
  type: 'app/pushLoading',
  key: key,
  loading: false,
})

export const pushError =
  (error: IError): Thunk<ActionType> =>
  (dispatch, getState) => {
    dispatch({ type: 'app/setErrors', errors: getErrors(getState().app).concat(error) })
  }

export const popError = (): Thunk<ActionType> => (dispatch, getState) => {
  dispatch({ type: 'app/setErrors', errors: getState().app.errors.slice(1) })
}

// MARK: - Selectors

export const getTheme = (state: State): Theme => {
  return state.theme
}

export const getLocalization = (state: State): Localization => {
  return state.localization
}

export const getErrors = (state: State): IError[] => {
  return state.errors
}

export const getLoading = (state: State, key: keyof State['loading']): boolean => {
  return !!state.loading[key]
}
