import { firebaseStorage } from '.'
import { getDownloadURL, ref, uploadBytes, uploadString } from 'firebase/storage'

export const uploadBlobToFirebaseStorage = async (
  blobUrl: string,
  child: string,
): Promise<string> => {
  const getFileBlob = async (url: string): Promise<any> => {
    return new Promise((resolve, _) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.addEventListener('load', () => resolve(xhr.response))
      xhr.send()
    })
  }

  const fileBlob = await getFileBlob(blobUrl)
  const snapshot = await uploadBytes(ref(firebaseStorage, child), fileBlob)
  const downloadURL = await getDownloadURL(snapshot.ref)
  return downloadURL
}

// Please be use putString(message, 'base64') by cutting off data:image/jpeg;base64,
// https://stackoverflow.com/questions/39310147/upload-a-base64-image-with-firebase-storage
export const uploadBase64PngToFirebaseStorage = async (
  base64: string,
  child: string,
): Promise<string> => {
  const snapshot = await uploadString(ref(firebaseStorage, child), base64, 'base64')
  const downloadURL = await getDownloadURL(snapshot.ref)
  return downloadURL
}
