import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardWebPageTrackedUrlModal } from './DashboardWebPageTrackedUrlModal'
import { DashboardWebPageTrackedUrlTable } from './DashboardWebPageTrackedUrlTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import { deleteTrackedUrl, fetchTrackedUrls } from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Component, Room, TrackedUrl } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardWebPage: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const [showTrackedUrlModal, setShowTrackedUrlModal] = useState(false)
  const [selectedTrackedUrl, setSelectedTrackedUrl] = useState<TrackedUrl | null>(null)
  const [deleteCandidates, setDeleteCandidates] = useState<TrackedUrl[]>([])

  // Selectors
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelect(state => getLoading(state.app, 'tracked_url'))
  const trackedUrls = useSelect(state =>
    getEntities<TrackedUrl>(
      state.entity,
      'tracked_url',
      trackedUrl => trackedUrl.component_id === component.id,
    ).sort((a, b) => b.created_at - a.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchTrackedUrls(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleTrackedUrlDropdownItemSelect = async (option: number, trackedUrl: TrackedUrl) => {
    if (option === 0) setSelectedTrackedUrl(trackedUrl)
    else if (option === 1) setDeleteCandidates([trackedUrl])
  }

  const handleTrackedUrlBulkDropdownItemSelect = async (option: number, urls: TrackedUrl[]) => {
    if (option === 0) setDeleteCandidates(urls)
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <DashboardWebPageTrackedUrlTable
        trackedUrls={trackedUrls}
        isLoading={isLoading}
        onCreateTrackedUrlClick={() => setShowTrackedUrlModal(true)}
        handleTrackedUrlDropdownItemSelect={handleTrackedUrlDropdownItemSelect}
        handleTrackedUrlBulkDropdownItemSelect={handleTrackedUrlBulkDropdownItemSelect}
      />

      {(showTrackedUrlModal || !!selectedTrackedUrl) && (
        <DashboardWebPageTrackedUrlModal
          trackedUrl={selectedTrackedUrl}
          room={room}
          component={component}
          onClose={() => {
            setShowTrackedUrlModal(false)
            setSelectedTrackedUrl(null)
          }}
        />
      )}

      <AlertModal
        error={Error.displayable(
          deleteCandidates.length > 1 ? t('deleteTrackedUrls') : t('deleteTrackedUrl'),
          deleteCandidates.length > 1
            ? t('deleteTrackedUrlsMessage')
            : t('deleteTrackedUrlMessage'),
          success => {
            if (success && deleteCandidates.length) {
              deleteCandidates.forEach(candidate => dispatch(deleteTrackedUrl(candidate)))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidates.length}
        onClose={() => setDeleteCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
