import { FlexCol, Row } from '../CommonStyles'
import React from 'react'
import FooterNav from 'src/features/common/layout/partials/FooterNav'
import FooterSocial from 'src/features/common/layout/partials/FooterSocial'
import { Logo } from 'src/features/platform/common/CreatorHeaderLogo'

export const Footer: React.FC = () => {
  return (
    <Row
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '95%',
        backgroundColor: 'white',
        padding: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <FlexCol style={{ maxWidth: 1260, width: '100%' }}>
        <Row style={{ justifyContent: 'space-between', width: '100%' }}>
          <Logo />
          <FooterSocial className="" />
        </Row>
        <Row style={{ justifyContent: 'space-between', marginTop: -8 }}>
          <FooterNav className="" />
          <div style={{ fontSize: 14 }}>
            Made by{' '}
            <a style={{ color: '#F36538' }} href="https://lobbi.events">
              DevsHouse
            </a>
            . All right reserved
          </div>
        </Row>
      </FlexCol>
    </Row>
  )
}
