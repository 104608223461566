import { FlexCol } from '../common/CommonStyles'
import { Hero } from './Hero'
import { ValueProposition } from './ValueProposition'
import { getAuth } from 'firebase/auth'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { getLoading } from 'src/redux/reducers/app'
import { loginSilently } from 'src/redux/reducers/authentication'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import { appTheme } from 'src/utils/theme/appTheme'
import { ThemeProvider } from 'styled-components'

export const Home: React.FC = () => {
  // MARK: - Hooks

  const navigateToPlatform = useRef(false)

  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()

  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))

  const [isLocalLoading, setIsLocalLoading] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    if (isAuthenticated && navigateToPlatform.current) {
      navigate(routes.roomList)
      navigateToPlatform.current = false
    }
  }, [isAuthenticated, isLocalLoading])

  // MARK: - Handlers

  const handlePlatformLoginClick = useCallback(() => {
    setIsLocalLoading(true)
    navigateToPlatform.current = true
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (isAuthenticated || isLoading) {
        unsubscribe()
        return
      }

      if (user?.email || user?.phoneNumber) {
        dispatch(loginSilently(user?.email, user?.phoneNumber, null))
      } else {
        navigate(routes.authentication.continue)
      }
      unsubscribe()
      setIsLocalLoading(false)
    })
  }, [isAuthenticated, isLoading])

  const handleRequestDemoClick = useCallback(() => {
    navigate(routes.demo)
  }, [])

  // MARK: - Render

  return (
    <ThemeProvider theme={appTheme('light')}>
      <FlexCol>
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=6443548379" />
          <meta name="og:title" content={'Lobbi'} />
          <meta name="og:description" content={'Lobbies for Events'} />
          <title>{'Lobbi'}</title>
        </Helmet>
        <Hero
          onGetStartedClick={handlePlatformLoginClick}
          onRequestDemoClick={handleRequestDemoClick}
        />
        <ValueProposition />
      </FlexCol>
    </ThemeProvider>
  )
}
