import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import { head } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { FlexCol } from 'src/features/common/CommonStyles'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { Select } from 'src/features/common/Select'
import { getLoading } from 'src/redux/reducers/app'
import { getFirstEntity } from 'src/redux/reducers/entity'
import {
  Component,
  ComponentType,
  SpreadGame,
  SpreadGameEvent,
  SpreadGamePointTableItem,
} from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const CreateOrUpdateSpreadGame: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Properties

  const allEventTypes: SpreadGameEvent[] = [
    SpreadGameEvent.subscribe,
    SpreadGameEvent.setCalendar,
    SpreadGameEvent.setReminder,
  ]

  const makeNextAvailablePointTableItem = (): SpreadGamePointTableItem | null => {
    const availableEventTypes = allEventTypes.filter(
      eventType => !pointTable.map(item => item.event).includes(eventType),
    )
    const eventType = head(availableEventTypes)
    if (!eventType) return null
    return {
      id: '',
      event: eventType,
      point: 10,
      name: eventType,
    }
  }
  // MARK: - Hooks

  // Selectors
  const { t } = useTranslation()
  const spreadGame = useSelect(state =>
    getFirstEntity<SpreadGame>(
      state.entity,
      'spread_game',
      item => item.component_id === component?.id,
    ),
  )
  const isLoading = useSelect(state => getLoading(state.app, 'loading'))
  const [pointTable, setPointTable] = useState<SpreadGamePointTableItem[]>(
    spreadGame?.point_table ?? [
      {
        id: '',
        event: SpreadGameEvent.subscribe,
        point: 10,
        name: SpreadGameEvent.subscribe,
      },
    ],
  )

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.spreadGame}
      component={component}
      header={t('spreadGame')}
      titlePlaceholder={t('spreadGameTitlePlaceholder')}
      descriptionPlaceholder={t('spreadGameDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.spreadGame,
          input: {
            key: 'spread_game',
            events: pointTable.map(item => item.event),
            point_table: pointTable,
            black_list: spreadGame?.black_list ?? [],
          },
        })
      }>
      <FlexCol style={{ marginBottom: 8, marginLeft: 2 }}>
        <Title>{t('gameEvents')}</Title>
        <SubTitle>{t('gameEventsDescription')}</SubTitle>
      </FlexCol>

      {pointTable.map((item, index) => (
        <SpreadGameEventSelectionField
          key={item.event + item.point}
          disabled={!!item.id}
          gameEvent={item.event}
          point={item.point}
          availableEventTypes={allEventTypes.filter(
            eventType =>
              !pointTable.map(element => element.event).includes(eventType) ||
              item.event === eventType,
          )}
          availablePoints={Array.from({ length: 10 }, (_, elementIndex) => elementIndex + 1)}
          canBeDeleted={pointTable.length > 1}
          onPointTableItemDelete={() =>
            setPointTable(prev => prev.filter(prevItem => prevItem.event !== item.event))
          }
          onPointTableItemEventTypeChange={eventType => {
            setPointTable(prev => {
              const next = [...prev]
              next[index].event = eventType
              return next
            })
          }}
          onPointTableItemPointChange={point =>
            setPointTable(prev => {
              const next = [...prev]
              next[index].point = point
              return next
            })
          }
        />
      ))}
      {pointTable.length !== allEventTypes.length && (
        <SecondaryButton
          onClick={() => {
            const nextPointItem = makeNextAvailablePointTableItem()
            if (nextPointItem) setPointTable(prev => [...prev, nextPointItem])
          }}
          title={t('addEvent')}
          isLoading={isLoading}
          style={{ width: 180, marginTop: 4, marginBottom: 8 }}
        />
      )}
    </DashboardComponentModalContainer>
  )
}

export const SpreadGameEventSelectionField: React.FC<{
  disabled: boolean
  gameEvent: SpreadGameEvent
  point: number
  availableEventTypes: SpreadGameEvent[]
  availablePoints: number[]
  canBeDeleted: boolean
  onPointTableItemDelete: () => void
  onPointTableItemEventTypeChange: (eventType: SpreadGameEvent) => void
  onPointTableItemPointChange: (point: number) => void
}> = ({
  disabled,
  gameEvent,
  point,
  availableEventTypes,
  availablePoints,
  canBeDeleted,
  onPointTableItemDelete,
  onPointTableItemEventTypeChange,
  onPointTableItemPointChange,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  // MARK: - Properties

  const nameForEvent = (event: SpreadGameEvent): string => {
    switch (event) {
      case SpreadGameEvent.setCalendar:
        return t('addCalendarEvent')
      case SpreadGameEvent.setReminder:
        return t('setPushNotification')
      case SpreadGameEvent.subscribe:
        return t('join')
    }
  }

  // MARK: - Render

  return (
    <InputContainer>
      <EventTypeInput>
        <Select
          disabled={disabled}
          placeholder={t('action')}
          value={gameEvent}
          onChange={eventType => onPointTableItemEventTypeChange(eventType as SpreadGameEvent)}>
          {availableEventTypes.map(eventType => (
            <option key={eventType} value={eventType}>
              {nameForEvent(eventType)}
            </option>
          ))}
        </Select>
      </EventTypeInput>

      <EventPointInput>
        <Select
          disabled={disabled}
          placeholder={t('point')}
          value={point.toString()}
          onChange={item => onPointTableItemPointChange(parseInt(item, 10))}>
          {availablePoints.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </EventPointInput>

      {canBeDeleted && !disabled && (
        <DeleteIcon
          fill={palette.text.primary}
          style={{ cursor: 'pointer', marginLeft: 15 }}
          onClick={onPointTableItemDelete}
        />
      )}
    </InputContainer>
  )
}

// MARK: - Styles

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 12px;
`

const EventTypeInput = styled.div`
  flex: 1;
  margin-right: 2%;
`

const EventPointInput = styled.div`
  width: 180px;
`
const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0px;
  user-select: none;
`
