import { ImageCropper } from './ImageCropper'
import React, { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Resizer from 'react-image-file-resizer'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { HoverBox, LabelSmall } from 'src/features/common/CommonStyles'
import { FormLabel } from 'src/features/common/FormLabel'
import { Loader } from 'src/features/common/Loader'
import { uploadBlobToFirebaseStorage } from 'src/frameworks/firebase/imageUploader'
import styled, { useTheme } from 'styled-components'
import { v4 } from 'uuid'

export const ImageUploader: React.FC<{
  containerStyle?: React.CSSProperties
  style?: React.CSSProperties
  title: string
  sizes: number[]
  aspectRatio: number
  description: string
  restriction: string
  initialImageUrl?: string

  onImageSelected?: (selected: boolean, url?: string) => void
  onImageUploaded?: (urls: Record<number, string>) => void
  onImageRemoved?: () => void
}> = ({
  containerStyle,
  style,
  title,
  sizes,
  description,
  restriction,
  aspectRatio,
  initialImageUrl,
  onImageSelected,
  onImageUploaded,
  onImageRemoved,
}) => {
  // MARK: - Hooks

  const imageId = useRef(v4())
  const { palette } = useTheme()
  const [showCropModal, setShowCropModal] = useState(false)
  const [, setError] = useState<string>()
  const [originalImageUrl, setOriginalImageUrl] = useState<string | undefined>(initialImageUrl)
  const [isUploadDone, setIsUploadDone] = useState(!!initialImageUrl)
  const [blob, setBlob] = useState<Blob>()
  const croppedImageUrl = useRef(initialImageUrl)

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if (!acceptedFiles[0]) return
      if (acceptedFiles[0].size / 1024 / 1024 > 10) {
        setError('Oopps! Uploaded image is larger than 10mb. Please try again.')
      } else {
        setError(undefined)
        setOriginalImageUrl(URL.createObjectURL(acceptedFiles[0]))
        setShowCropModal(true)
      }
    },
  })

  // MARK: - Effects

  useEffect(() => {
    setBlob(undefined)
  }, [initialImageUrl])

  // MARK: - Handlers

  const uploadImage = (imageBlob: Blob) => {
    const promise = new Promise<Record<number, string>>((resolve, reject) => {
      try {
        const imageUploadRecord: Record<number, string> = {}
        for (let index = 0; index < sizes.length; index++) {
          const size = sizes[index]
          Resizer.imageFileResizer(
            imageBlob,
            size * aspectRatio,
            size,
            'JPEG',
            100,
            0,
            async newBlob => {
              const fileUrl = window.URL.createObjectURL(newBlob as any)
              imageUploadRecord[size] = await uploadBlobToFirebaseStorage(
                fileUrl,
                `room_images/${imageId.current}-${size}`,
              )
              if (index === sizes.length - 1) {
                resolve(imageUploadRecord)
              }
            },
            'blob',
          )
        }
      } catch (error) {
        reject(error)
      }
    })

    promise
      .then(record => {
        onImageUploaded?.(record)
      })
      .finally(() => {
        setIsUploadDone(true)
      })
  }

  // MARK: - Render

  return (
    <Container style={containerStyle}>
      <FormLabel style={{ marginBottom: 2 }}>{title}</FormLabel>

      {isUploadDone && (
        <ImageContainer>
          <SelectedImage aspectRatio={aspectRatio} src={croppedImageUrl.current} />
          <ModificationContainer>
            <EditIcon
              fill={palette.text.primary}
              style={{ cursor: 'pointer', marginBottom: 15 }}
              onClick={() => setShowCropModal(true)}
            />
            <DeleteIcon
              fill={palette.text.primary}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setBlob(undefined)
                onImageSelected?.(false, undefined)
                setIsUploadDone(false)
                onImageRemoved?.()
              }}
            />
          </ModificationContainer>
        </ImageContainer>
      )}

      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {!isUploadDone && (
          <Interaction style={style}>
            <LabelSmall style={{ textAlign: 'center' }}>{restriction}</LabelSmall>

            {blob && !isUploadDone && (
              <SpinnerContainer>
                <Loader visible thickness={3} size={24} />
              </SpinnerContainer>
            )}
          </Interaction>
        )}
      </div>

      {showCropModal && (
        <ImageCropper
          src={originalImageUrl}
          aspectRatio={aspectRatio}
          onSave={imageBlob => {
            setBlob(imageBlob)
            setIsUploadDone(false)
            croppedImageUrl.current = window.URL.createObjectURL(imageBlob as any)
            setShowCropModal(false)
            if (imageBlob) uploadImage(imageBlob)
          }}
          onClose={() => setShowCropModal(false)}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const ModificationContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 32px;
`

const SelectedImage = styled.img<{ aspectRatio: number }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 8px;
  width: calc(100% - 30px);
`

const Interaction = styled(HoverBox)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 22px;
  padding: 24px 20px;
`

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
