import { api } from '../api'
import { PurchaseAudienceTier, PurchaseUsageTier, Room, RoomPurchase } from '../types'

export const purchaseService = {
  fetchPurchases: async (roomId: string) =>
    await api.value<{ purchases: RoomPurchase[]; activePurchase: RoomPurchase | null }>({
      request: {
        method: 'GET',
        endPoint: '/purchase',
        parameters: { room_id: roomId },
      },
    }),

  fetchPurchaseTiers: async () =>
    api.value<{ audience: PurchaseAudienceTier[]; feature: PurchaseUsageTier[] }>({
      request: {
        method: 'GET',
        endPoint: '/purchase/tiers',
        parameters: {},
      },
    }),

  fetchOffer: async (
    roomId: string,
    audienceTier: PurchaseAudienceTier,
    featureUsageTier: PurchaseUsageTier,
    promoCode: string,
  ) =>
    api.prop<{ purchase: RoomPurchase }>('purchase', {
      request: {
        method: 'POST',
        endPoint: '/purchase/offer',
        parameters: {
          room_id: roomId,
          audience_tier: audienceTier,
          feature_usage_tier: featureUsageTier,
          promo_code: promoCode,
        },
      },
    }),

  executeFreeOffer: async (
    offerId: string,
    cardInfo?: {
      card_holder_name: string
      card_number: string
      expire_month: string
      expire_year: string
      cvc: string
    },
  ) =>
    api.value<{ room: Room; purchase: RoomPurchase }>({
      request: {
        method: 'POST',
        endPoint: '/purchase/execute',
        parameters: { offer_id: offerId, card_info: cardInfo },
      },
    }),

  deleteOffer: async (offerId: string) =>
    api.prop<{ purchase: RoomPurchase }>('purchase', {
      request: {
        method: 'DELETE',
        endPoint: '/purchase/offer',
        parameters: { offer_id: offerId },
      },
    }),
}
