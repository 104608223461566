import { api } from '../api'
import { SpreadGameScore, User } from '../types'

export const spreadGameScoreService = {
  fetchSpreadGameScores: async (componentId: string, offset: number, limit: number) =>
    api.value<{
      scores: SpreadGameScore[]
      users: User[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/spreadGame/scores',
        parameters: { offset: offset, limit: limit, component_id: componentId },
      },
    }),
}
