import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Col, LabelMedium, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { openURL } from 'src/utils/helpers/linkHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled, { css } from 'styled-components'

export const ShareableLink: React.FC<{
  style?: React.CSSProperties
  isLive: boolean
  link: string
}> = ({ style, link, isLive }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const editIconUrl =
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2Fedit.png?alt=media&token=c95b607d-6116-4956-a133-f63dc7e6b2ed'
  const iconUrl =
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F2885430?alt=media&token=0f05440d-02c2-4e57-a18b-24f977063f5e'

  // MARK: - Handlers

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(link)
    toast.success(t('copiedToClipboard'))
  }

  // MARK: - Render

  return (
    <Row style={{ marginLeft: 12, alignItems: 'center', userSelect: 'none' }}>
      <Col style={{ cursor: 'pointer' }} onClick={() => openURL(link)}>
        <img
          style={{
            width: isLive ? 26 : 30,
            height: isLive ? 26 : 30,
            minWidth: 26,
            marginLeft: 4,
          }}
          src={isLive ? iconUrl : editIconUrl}
          alt=""
        />
      </Col>

      <Container style={{ ...style, marginLeft: isLive ? 8 : 6 }}>
        <LabelMedium style={{ fontSize: 14, marginBottom: -1, marginTop: -2 }}>
          {isLive ? t('published') : t('draftLobby')}
        </LabelMedium>

        {isLive ? (
          <>
            <Link
              onClick={handleCopyButtonClick}
              style={{ ...numberOfLines(1), flex: 1, maxWidth: '104%' }}>
              {link.replace('https://', '')}
            </Link>
          </>
        ) : (
          <LabelSmall style={{ fontSize: 13, marginTop: 1 }}>{t('goLiveToShare')}</LabelSmall>
        )}
      </Container>
    </Row>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin: 0px 0px 0px 12px;
  position: relative;
`

const Link = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  font-size: 14.5px;
  margin: 0px -4px;
  padding: 0px 6px;
  position: relative;
  ${() =>
    css`
      &:hover {
        color: ${({ theme }) => theme.palette.orange};
      }
    `};
`
