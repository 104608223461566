import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { Col, FlexCol, FlexRow, LabelMedium } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { Separator } from 'src/features/common/Separator'
import { IconPicker } from 'src/features/platform/common/IconPicker'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createFAQ, updateFAQ } from 'src/redux/reducers/component'
import { Error } from 'src/repository/Error'
import { Component, ComponentType, FAQ, Room } from 'src/repository/types'
import { componentInstanceImageUrl } from 'src/utils/helpers/componentHelper'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardFAQModal: React.FC<{
  faq?: FAQ | null
  room: Room
  component: Component
  onClose: () => void
}> = ({ faq = null, room, component, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [links, setLinks] = useState(faq?.links ?? [''])
  const [question, setQuestion] = useState(faq?.question ?? '')
  const [answer, setAnswer] = useState(faq?.answer ?? '')
  const [image, setImage] = useState(componentInstanceImageUrl(ComponentType.faq))

  const isLoading = useSelect(state => getLoading(state.app, 'faq'))
  const shouldCloseWhenLoadingDone = useRef(false)

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleSaveClick = () => {
    if (!question || !answer) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true
    if (faq) dispatch(updateFAQ(faq.id, question, answer, links, true))
    else dispatch(createFAQ(room.id, component.id, question, answer, links))
  }

  // MARK: - Render

  return (
    <ModalWrapper title={faq ? t('updateFAQ') : t('createFAQ')} size="tiny" onClose={onClose}>
      <FlexRow style={{ marginBottom: 24 }}>
        <IconPicker icon={image} onIconChange={setImage} />

        <FlexCol style={{ marginLeft: 20 }}>
          <Input label={t('question')} isRequired value={question} onChange={setQuestion} />

          <Input
            inputStyle={{ minHeight: 132 }}
            type="textarea"
            isRequired
            label={t('answer')}
            value={answer}
            onChange={setAnswer}
          />
        </FlexCol>
      </FlexRow>

      <LabelMedium>{t('informativeLinks')}</LabelMedium>

      <Separator margin={10} />

      {links.map((link, index) => (
        <Col style={{ marginTop: 8 }}>
          <Input
            style={{ margin: 0 }}
            isRequired
            placeholder="http://"
            value={link}
            onChange={text =>
              setLinks(prev => {
                const copy = [...prev]
                copy[index] = text
                return copy
              })
            }
          />
          {links.length !== 1 && (
            <DeleteButton
              onClick={() =>
                setLinks(prev => {
                  const copy = [...prev]
                  copy.splice(index, 1)
                  return copy
                })
              }>
              <DeleteIcon />
            </DeleteButton>
          )}
        </Col>
      ))}

      <PrimaryButton
        title={t('addNew')}
        onClick={() => setLinks(prev => [...prev, ''])}
        style={{ maxWidth: 180 }}
      />

      <FooterWrapper>
        <PrimaryButton
          isLoading={isLoading}
          style={{ marginRight: 10, minWidth: 150 }}
          title={!!faq ? 'Update' : 'Create'}
          onClick={handleSaveClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}

// MARK: - Styles

const DeleteButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: flex-end;
  padding-bottom: 16px;
  width: 36px;
`
