import { pushError } from './app'
import { getAuth } from '@firebase/auth'
import { mergeEntities } from 'src/redux/reducers/entity'
import { setMe } from 'src/redux/reducers/me'
import { AsyncThunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { userService } from 'src/repository/services/userService'
import { User, UserKey } from 'src/repository/types'
import config from 'src/utils/config'

type State = {
  isLoading: boolean
  error: IError | null
  user: Partial<User> | null
  keys: UserKey | null
}

// MARK: - State

export const initialState: State = {
  isLoading: false,
  error: null,
  user: null,
  keys: null,
}

// MARK: - Reducer

export const accountReducer = (
  state = initialState,
  action: LoadingAction | SetUserAction | SetKeysAction | ErrorAction | FlushAction,
): State => {
  switch (action.type) {
    case 'account/loading':
      return { ...state, isLoading: action.isLoading }

    case 'account/error':
      return { ...state, error: action.error }

    case 'account/setUser':
      return { ...state, user: action.user }

    case 'account/setKeys':
      return { ...state, keys: action.keys }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setUser = (user: Partial<User>): SetUserAction => ({
  type: 'account/setUser',
  user: user,
})

export const updateMeUser = (): AsyncThunk => async (dispatch, getState) => {
  const { user } = getState().account
  if (!user?.first_name || !user.last_name || !user.user_name) {
    dispatch(setError(Error.someThingWentWrong()))
    return
  }

  dispatch(setLoading(true))
  dispatch(setError(null))

  const response = await userService.updateUser({
    user_name: user.user_name,
    image_url: user.image_url ?? '',
    first_name: user.first_name,
    last_name: user.last_name,
  })

  if (response.success) {
    const { user: responseUser, settings } = response.value
    dispatch(mergeEntities({ user: [responseUser], user_settings: [settings] }))
    dispatch(setMe(responseUser))
  } else {
    dispatch(setError(response.error))
  }
  dispatch(setLoading(false))
}

export const fetchUserKeys =
  (userId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await userService.fetchUserKeys(userId)

    if (response.success) {
      const { user, key } = response.value
      dispatch(mergeEntities({ user: [user] }))
      dispatch(setMe(user))
      dispatch(setKeys(key))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const refreshUserKeys =
  (userId: string, apiKey: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await userService.refreshUserKeys(userId, apiKey)

    if (response.success) {
      const { user, key } = response.value
      dispatch(mergeEntities({ user: [user] }))
      dispatch(setMe(user))
      dispatch(setKeys(key))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const logout =
  (removeData: boolean = true): AsyncThunk =>
  async dispatch => {
    await getAuth().signOut()
    localStorage.removeItem(config.keys.firebaseToken)
    if (removeData) dispatch({ type: 'me/logout' })
  }

export const deleteAccount = (): AsyncThunk => async dispatch => {
  userService.deleteUser().done(undefined, error => dispatch(pushError(error)))
  await getAuth().signOut()
  localStorage.removeItem(config.keys.firebaseToken)
  dispatch({ type: 'me/logout' })
}

export const setError = (error: IError | null): ErrorAction => ({
  type: 'account/error',
  error: error,
})

// MARK: - Selectors

export const getUser = (state: State) => {
  return state.user
}

export const getIsUserEdited = (state: State, user?: User): boolean => {
  if (!user) return false
  const { user: currentUser } = state
  return (
    currentUser?.first_name !== user.first_name ||
    currentUser?.last_name !== user.last_name ||
    currentUser?.user_name !== user.user_name ||
    currentUser?.image_url !== user?.image_url
  )
}

export const getIsLoading = (state: State): boolean => {
  return state.isLoading
}

export const getUserKeys = (state: State): UserKey | null => {
  return state.keys
}

export const getError = (state: State): IError | null => {
  return state.error
}

// MARK: - Action Types

type LoadingAction = {
  type: 'account/loading'
  isLoading: boolean
}

type ErrorAction = {
  type: 'account/error'
  error: IError | null
}

type SetKeysAction = {
  type: 'account/setKeys'
  keys: UserKey
}

type SetUserAction = {
  type: 'account/setUser'
  user: Partial<User>
}

type FlushAction = {
  type: 'me/logout'
}

// MARK: - Internal Actions

const setLoading = (isLoading: boolean): LoadingAction => ({
  type: 'account/loading',
  isLoading: isLoading,
})

const setKeys = (keys: UserKey): SetKeysAction => ({
  type: 'account/setKeys',
  keys: keys,
})
