import { DashboardSectionHeader } from '../../dashboard/DashboardSectionHeader'
import { BannerInput } from '../BannerInput'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinksIcon } from 'src/assets/images/svg/LinksIcon'
import { Col, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { FormLabel } from 'src/features/common/FormLabel'
import { LinkInputField } from 'src/features/platform/common/LinkInputField'
import { Banner, Link } from 'src/repository/types'
import styled, { useTheme } from 'styled-components'

export const RoomLinksSection: React.FC<{
  banners: Banner[]
  links: Link[]
  hideIcon?: boolean
  onBannersChange: (banners: Banner[]) => void
  onLinkDelete: (index: number) => void
  onLinkTypeChange: (index: number, type: number) => void
  onLinkNameChange: (index: number, name: string) => void
  onLinkUrlChange: (index: number, url: string) => void
}> = ({
  links,
  banners,
  hideIcon,
  onBannersChange,
  onLinkDelete,
  onLinkTypeChange,
  onLinkNameChange,
  onLinkUrlChange,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()
  const [bannersVisible, setBannersVisible] = useState(!!banners.length)

  // MARK: - Render

  return (
    <Container>
      <DashboardSectionHeader
        style={{ marginBottom: 6 }}
        title={t('brandingAndNavigation')}
        description={t('linksDescription')}
        Icon={!hideIcon ? LinksIcon : undefined}
      />

      <Col style={{ maxWidth: 638 }}>
        <FormLabel>{t('links')}</FormLabel>
        {[...links, { id: '', name: '', type: 0, url: '' }].map((link, index) => (
          <InputContainer key={index}>
            <LinkInputField
              link={link}
              onLinkTypeChange={type => onLinkTypeChange(index, type)}
              onLinkNameChange={name => onLinkNameChange(index, name)}
              onLinkUrlChange={url => onLinkUrlChange(index, url)}
              canBeDeleted={!!link.type}
              onLinkDelete={() => onLinkDelete(index)}
            />
          </InputContainer>
        ))}
      </Col>

      {bannersVisible ? (
        <BannerInput banners={banners} onBannersChange={onBannersChange} />
      ) : (
        <Row style={{ marginTop: 4, marginLeft: 1 }}>
          <LabelMedium style={{ marginRight: 5 }}>{t('ifYouHaveBanners') + ' '}</LabelMedium>
          <LabelMedium
            style={{ cursor: 'pointer', fontWeight: 600, color: palette.orange }}
            onClick={() => setBannersVisible(true)}>
            {t('add')}
          </LabelMedium>
        </Row>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  margin-bottom: 120px;
  margin-top: 40px;
`

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-top: -4px;
`
