import { Col, LabelHeaderLarge, LabelNormal, Row } from '../common/CommonStyles'
import { Hint } from '../common/Hint'
import { PricingOptionContainer } from './PricingOptionContainer'
import { PricingOption } from './types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { appTheme } from 'src/utils/theme/appTheme'
import styled, { ThemeProvider } from 'styled-components'

export const Pricing: React.FC = () => {
  // MARK: - Properties

  const availablePricingOptions: PricingOption[] = ['free', 'premium', 'business']

  // MARK: - Hooks

  const { navigate, routes } = useNavigation()
  const { t } = useTranslation()

  // MARK: - Handlers

  const handlePricingOptionActionClick = (option: PricingOption) => {
    if (option === 'free') console.log('free')
    else if (option === 'premium') console.log('premium')
    else if (option === 'business') navigate(routes.demo)
  }

  // MARK: - Effects

  return (
    <ThemeProvider theme={appTheme('light')}>
      <Container>
        <Title>{'Plans scale with your audience'}</Title>

        <SubTitleContainer>
          <SubTitle>
            {t('ifNonProfitOrganization')}
            <ReachUs onClick={() => navigate(routes.demo)}>{t('pleaseContactUs')}</ReachUs>
          </SubTitle>

          <Hint
            width={300}
            alignment={'left'}
            style={{ marginTop: 3, marginLeft: 6 }}
            hint={'Select non-profit organization type in request demo form'}
          />
        </SubTitleContainer>

        <InnerContainer>
          {availablePricingOptions.map(option => (
            <PricingOptionContainer
              key={option}
              preferred={option === 'premium'}
              pricingOption={option}
              onActionClick={handlePricingOptionActionClick}
            />
          ))}
        </InnerContainer>
      </Container>
    </ThemeProvider>
  )
}

const Container = styled(Col)`
  align-items: center;
  max-width: 1308px;
  margin: 0px auto;
  padding-top: 30px;
`

const InnerContainer = styled(Row)`
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  max-width: 100%;
  width: 1308px;
`

const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled(LabelHeaderLarge)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 36px;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
`

const SubTitle = styled(LabelNormal)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: 0px;
  text-align: center;
`

const ReachUs = styled.span`
  cursor: pointer;
  text-decoration: underline;
`
