import { FlexCol, LabelNormal, LabelSmall } from './CommonStyles'
import { PageLoader } from './PageLoader'
import React from 'react'
import styled from 'styled-components'

export const EmptyState: React.FC<{
  style?: React.CSSProperties
  isLoading?: boolean
  title: string
  subtitle: string
}> = ({ title, subtitle, style, isLoading = false }) => {
  return (
    <Container style={style}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <LabelNormal style={{ marginBottom: 8, fontWeight: 600, textAlign: 'center' }}>
            {title}
          </LabelNormal>
          <LabelSmall style={{ textAlign: 'center' }}>{subtitle}</LabelSmall>
        </>
      )}
    </Container>
  )
}

// MARK: - Style

const Container = styled(FlexCol)`
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding-bottom: 12%;
`
