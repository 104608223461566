import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'src/features/common/CommonStyles'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading } from 'src/redux/reducers/app'
import { fetchIcons, getIconByPack } from 'src/redux/reducers/iconPicker'
import { FlatIcon, FlatIconPack } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const IconPackModal: React.FC<{
  iconPack: FlatIconPack
  onIconPick: (icon: FlatIcon) => void
  onClose: () => void
}> = ({ iconPack, onIconPick, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()

  const icons = useSelect(state => getIconByPack(state.utils.iconPicker, iconPack.id))
  const isLoading = useSelect(state => getLoading(state.app, 'icon_picker_pack'))

  // MARK: - Effects

  useEffect(() => {
    if (!icons.length) dispatch(fetchIcons('', undefined, undefined, iconPack.id))
  }, [iconPack.id])

  // MARK: - Handlers

  const handleIconPick = useCallback(
    (icon: FlatIcon) => () => {
      onIconPick(icon)
      onClose()
    },
    [],
  )

  // MARK: - Render

  return (
    <ModalWrapper
      title={'Icon pack: ' + iconPack.description}
      size="tiny"
      width={680}
      style={{ paddingBottom: 0 }}
      onClose={onClose}
      closeOnBackgroundClick>
      {isLoading ? (
        <PageLoader style={{ paddingTop: '20%' }} />
      ) : (
        <InnerContainer>
          {icons.map(icon => (
            <IconContainer key={icon.id} onClick={handleIconPick(icon)}>
              <img style={{ width: 48, height: 48 }} src={icon.images['64']} alt="" />
            </IconContainer>
          ))}
        </InnerContainer>
      )}
    </ModalWrapper>
  )
}

// MARK: - Style

const InnerContainer = styled(Row)`
  flex-wrap: wrap;
  margin: 0px -8px;
  margin-top: 12px;
  max-height: 100%;
  overflow: scroll;
`

const IconContainer = styled(Col)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};
  border-radius: 12px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  height: 79px;
  justify-content: center;
  margin: 8px 6px 8px 6px;
  min-width: 79px;
  overflow: hidden;
  position: relative;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.orange : 'transparent')};
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
      }
    `}
`
