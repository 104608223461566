import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#F36538', ...props }) => (
  <svg width={size} height={size} {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M12 2L12.5547 1.16795C12.2188 0.944017 11.7812 0.944017 11.4453 1.16795L12 2ZM21 8L21.5547 8.83205C21.8329 8.64658 22 8.33435 22 8C22 7.66565 21.8329 7.35342 21.5547 7.16795L21 8ZM12 14L11.4453 14.8321C11.7812 15.056 12.2188 15.056 12.5547 14.8321L12 14ZM3 8L2.4453 7.16795C2.1671 7.35342 2 7.66565 2 8C2 8.33435 2.1671 8.64658 2.4453 8.83205L3 8ZM11.4453 2.83205L20.4453 8.83205L21.5547 7.16795L12.5547 1.16795L11.4453 2.83205ZM20.4453 7.16795L11.4453 13.1679L12.5547 14.8321L21.5547 8.83205L20.4453 7.16795ZM12.5547 13.1679L3.5547 7.16795L2.4453 8.83205L11.4453 14.8321L12.5547 13.1679ZM3.5547 8.83205L12.5547 2.83205L11.4453 1.16795L2.4453 7.16795L3.5547 8.83205Z"
      fill={fill}
    />
    <path
      d="M21.5547 12.8321C22.0142 12.5257 22.1384 11.9048 21.8321 11.4453C21.5257 10.9858 20.9048 10.8616 20.4453 11.1679L21.5547 12.8321ZM12 18L11.4453 18.8321C11.7812 19.056 12.2188 19.056 12.5547 18.8321L12 18ZM3.5547 11.1679C3.09517 10.8616 2.4743 10.9858 2.16795 11.4453C1.8616 11.9048 1.98577 12.5257 2.4453 12.8321L3.5547 11.1679ZM20.4453 11.1679L11.4453 17.1679L12.5547 18.8321L21.5547 12.8321L20.4453 11.1679ZM12.5547 17.1679L3.5547 11.1679L2.4453 12.8321L11.4453 18.8321L12.5547 17.1679Z"
      fill={fill}
    />
    <path
      d="M21.5547 16.8321C22.0142 16.5257 22.1384 15.9048 21.8321 15.4453C21.5257 14.9858 20.9048 14.8616 20.4453 15.1679L21.5547 16.8321ZM12 22L11.4453 22.8321C11.7812 23.056 12.2188 23.056 12.5547 22.8321L12 22ZM3.5547 15.1679C3.09517 14.8616 2.4743 14.9858 2.16795 15.4453C1.8616 15.9048 1.98577 16.5257 2.4453 16.8321L3.5547 15.1679ZM20.4453 15.1679L11.4453 21.1679L12.5547 22.8321L21.5547 16.8321L20.4453 15.1679ZM12.5547 21.1679L3.5547 15.1679L2.4453 16.8321L11.4453 22.8321L12.5547 21.1679Z"
      fill={fill}
    />
  </svg>
)

export const CreateIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.orange} {...props} />
}
