import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

export const AppRoute: React.FC<
  RouteProps & {
    component: React.FC<any>
    layout: React.FC
  }
> = ({ component: Component, layout: Layout, ...rest }) => {
  const Wrapper = Layout === undefined ? (props: any) => <>{props.children}</> : Layout

  return (
    <Route
      {...rest}
      render={props => (
        <Wrapper>
          <Component {...props} />
        </Wrapper>
      )}
    />
  )
}
