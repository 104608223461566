import { Venue } from './Venue'
import React from 'react'
import { Input } from 'src/features/common/Input'
import styled from 'styled-components'

export const VenuePropertyEditor: React.FC<{
  venue: Venue
  onVenueEdit?: (venue: Venue) => void
}> = ({ venue, onVenueEdit }) => {
  return (
    <Container>
      <Input
        label={'Venue Name'}
        value={venue.title}
        onChange={text => onVenueEdit?.({ ...venue, title: text })}
      />

      <HorizontalStack>
        <Input
          label={'Address'}
          value={venue.address}
          onChange={text => onVenueEdit?.({ ...venue, address: text })}
        />
      </HorizontalStack>

      <HorizontalStack>
        <InputField
          label={'City'}
          value={venue.city}
          onChange={text => onVenueEdit?.({ ...venue, city: text })}
        />

        <Input
          label={'Province'}
          value={venue.province}
          onChange={text => onVenueEdit?.({ ...venue, province: text })}
        />
      </HorizontalStack>

      <HorizontalStack>
        <InputField
          style={{ marginRight: 16 }}
          label={'Country'}
          value={venue.country}
          onChange={text => onVenueEdit?.({ ...venue, country: text })}
        />

        <Input
          label={'Zip Code'}
          value={venue.zip_code?.toString()}
          onChange={text => onVenueEdit?.({ ...venue, zip_code: parseInt(text, 10) })}
        />
      </HorizontalStack>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  margin-top: 10px;
  position: flex;
`

const HorizontalStack = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`

const InputField = styled(Input)`
  max-width: 96%;
`
