import React from 'react'
import styled from 'styled-components'

export const Separator: React.FC<{
  margin?: number
}> = ({ margin = 18 }) => <SeparatorItem margin={margin} />

const SeparatorItem = styled.div<{ margin: number }>`
  background-color: ${({ theme }) => theme.palette.background.separator};
  height: 1px;
  margin-bottom: ${({ margin }) => margin}px;
  margin-top: ${({ margin }) => margin}px;
  min-height: 1px;
  width: 100%;
`
