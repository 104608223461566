import { DefaultTheme, IPalette } from 'styled-components'

export const darkTheme = (): DefaultTheme => ({
  isDark: true,
  palette: {
    ...darkPalette,
    inverted: lightPalette,
  },
})

export const lightTheme = (): DefaultTheme => ({
  isDark: false,
  palette: {
    ...lightPalette,
    inverted: darkPalette,
  },
})

const lightPalette: IPalette = {
  primary: '#F36538',
  red: '#ca142c',
  orange: '#F36538',
  purple: '#acadff',
  green: '#0DBC5F',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#2E95E9',
  background: {
    primary: '#FFFFFF',
    secondary: '#FBFBFB',
    tertiary: '#FFFFFF',
    quaternary: '#FFFFFF',
    separator: 'rgba(0,0,0,0.1)',
    disabled: '#ebebeb',
  },
  text: {
    primary: '#000000',
    secondary: '#4f4f4f',
    placeholder: 'rgba(0, 0, 0, 0.4)',
  },
}

const darkPalette: IPalette = {
  primary: '#F36538',
  red: '#ca142c',
  orange: '#F36538',
  purple: '#acadff',
  green: '#24e5af',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#2E95E9',
  background: {
    primary: '#000000',
    secondary: '#050709',
    tertiary: '#151719',
    quaternary: '#25282c',
    separator: '#FFFFFF24',
    disabled: '#232323',
  },
  text: {
    primary: '#eceded',
    secondary: '#afafaf',
    placeholder: 'rgba(255, 255, 255, 0.4)',
  },
}

export const appTheme = (theme: 'light' | 'dark') => {
  switch (theme) {
    case 'dark':
      return darkTheme()
    case 'light':
      return lightTheme()
  }
}
