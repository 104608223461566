import React from 'react'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { Checkbox } from 'src/features/common/Checkbox'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { getEntity } from 'src/redux/reducers/entity'
import { SpreadGameScore, User } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardSpreadGameScoreTableRow: React.FC<{
  spreadGameScore: SpreadGameScore
  isBlackListed: boolean
  selected: boolean
  onCheckBoxClick: (isOn: boolean) => void
  onSpreadGameScoreDropdownItemSelect: (index: number) => void
}> = ({
  spreadGameScore,
  selected,
  isBlackListed,
  onCheckBoxClick,
  onSpreadGameScoreDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const user = useSelect(state => getEntity<User>(state.entity, 'user', spreadGameScore.user_id))

  // MARK: - Properties

  const dropdownOptions = [
    isBlackListed ? { title: 'Activate', icon: DeleteIcon } : { title: 'Ban', icon: DeleteIcon },
  ]

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    onSpreadGameScoreDropdownItemSelect?.(option)
  }

  // MARK: - Render

  return (
    <Container>
      <CheckBoxContainer onClick={event => event.stopPropagation()}>
        <Checkbox checked={selected} onChange={() => onCheckBoxClick(!selected)} />
      </CheckBoxContainer>

      <Column style={{ marginLeft: 16 }} minWidth={250} flex={1}>
        <ParticipantImageContainer>
          {user?.image_url ? <ParticipantImage src={user.image_url} /> : <ProfileIcon size={56} />}
          <StatusIndicator isActive={!isBlackListed} />
        </ParticipantImageContainer>

        <TextInfoContainer>
          <Title style={numberOfLines(1)}>{user?.first_name + ' ' + user?.last_name}</Title>
          <Subtitle style={numberOfLines(1)}>{'@' + user?.user_name}</Subtitle>
        </TextInfoContainer>
      </Column>

      <Column minWidth={160}>
        <Title>{spreadGameScore.score}</Title>
      </Column>

      <DropdownWrapper
        style={{
          alignSelf: 'center',
          marginRight: 8,
          marginLeft: 12,
          position: 'relative',
        }}
        icon={<ThreeDotsIcon size={18} />}
        position={{ right: 24, top: 0 }}
        options={dropdownOptions}
        handleOptionClick={handleOptionsDropdownOptionClick}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 16px 0px;
  width: 100%;
`

const CheckBoxContainer = styled.div`
  align-self: flex-start;
  margin-top: 8px;
`

const Column = styled.div<{ minWidth: number; flex?: number }>`
  align-items: center;
  display: flex;
  flex: ${({ flex }) => flex ?? 0};
  flex-direction: row;
  min-width: ${({ minWidth }) => minWidth}px;
  padding-right: 32px;
`

const TextInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 85%;
`

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-top: -1px;
`

const Subtitle = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  margin-top: 4px;
  position: relative;
`

const ParticipantImageContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.background.primary};
  border-radius: 28px;
  height: 56px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  width: 56px;
`

const ParticipantImage = styled.img`
  height: 100%;
  width: 100%;
`

const StatusIndicator = styled.div<{ isActive: boolean }>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.green : theme.palette.orange};
  border: 1px solid ${({ theme }) => theme.palette.background.primary};
  border-radius: 8px;
  bottom: 6px;
  height: 12px;
  position: absolute;
  right: 6px;
  width: 12px;
  z-index: 10;
`
