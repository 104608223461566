import { ToastContainer as _ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const ToastContainer = styled(_ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  width: 400px;

  .toast {
    background-color: #000000;
    border-radius: 12px;
    border: 1px solid #ffffff20;
  }

  button[aria-label='close'] {
    display: none;
  }

  .body {
    font-size: 17px;
    font-weight: 500;
    color: #eceded;
    line-height: 24px;
    margin-bottom: 6px;
    margin-top: 6px;
    max-height: 70px;
  }

  .progress {
  }
`
