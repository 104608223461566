import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { AnimatedFooter } from 'src/features/platform/common/AnimatedFooter'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading } from 'src/redux/reducers/app'
import { getEntity } from 'src/redux/reducers/entity'
import { updateRoomConfig } from 'src/redux/reducers/settings'
import { Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardSettingsGeneral: React.FC<{
  room: Room
}> = ({ room }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const config = useSelect(state => getEntity<Room>(state.entity, 'room', room.id)!.config)
  const isLoading = useSelect(state => getLoading(state.app, 'settings'))

  const [shareEnabled, setShareEnabled] = useState(config.share_enabled)
  const [seeMoreEnabled, setSeeMoreEnabled] = useState(config.see_more_enabled)
  const [chatEnabled, setChatEnabled] = useState(config.chat_enabled)

  const configurationChanged = () =>
    shareEnabled !== config.share_enabled ||
    seeMoreEnabled !== config.see_more_enabled ||
    chatEnabled !== config.chat_enabled

  // MARK: - Handlers

  const handleSaveClick = useCallback(() => {
    dispatch(
      updateRoomConfig(room, {
        share_enabled: shareEnabled,
        see_more_enabled: seeMoreEnabled,
        chat_enabled: chatEnabled,
        banners: config.banners,
      }),
    )
  }, [shareEnabled, chatEnabled, seeMoreEnabled])
  // MARK: - Render

  return (
    <Col>
      <InfoSwitch
        style={{ marginTop: 12 }}
        title={t('allowShare')}
        subtitle={t('allowShareDescription')}
        checked={shareEnabled}
        onSwitchToggle={setShareEnabled}
      />

      <InfoSwitch
        style={{ marginTop: 12 }}
        title={t('allowSeeMore')}
        subtitle={t('allowSeeMoreDescription')}
        checked={seeMoreEnabled}
        onSwitchToggle={setSeeMoreEnabled}
      />

      <InfoSwitch
        style={{ marginTop: 12, marginBottom: 56 }}
        title={t('chatAndMemberList')}
        subtitle={t('chatAndMemberListDescription')}
        checked={chatEnabled}
        onSwitchToggle={setChatEnabled}
      />

      <AnimatedFooter visible={configurationChanged()}>
        <PrimaryButton
          style={{ marginRight: 40, minWidth: 180 }}
          title={t('save')}
          isLoading={isLoading}
          onClick={handleSaveClick}
        />
      </AnimatedFooter>
    </Col>
  )
}
