import { mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { analyticsService } from 'src/repository/services/analyticsService'
import { notificationService } from 'src/repository/services/notificationService'
import {
  ComponentAnalytics,
  LinkAnalytics,
  NotificationAnalytics,
  OverviewAnalytics,
} from 'src/repository/types'

type AnalyticsType = 'overview' | 'link' | 'component' | 'notification'

type State = {
  isLoading: Record<AnalyticsType, boolean>
  error: IError | null
  overviewAnalytics: OverviewAnalytics | null
  overviewLogs: OverviewAnalytics[]
  linkAnalytics: Record<string, LinkAnalytics>
  linkLogs: Record<string, LinkAnalytics[]>
  componentAnalytics: Record<string, ComponentAnalytics>
  componentLogs: Record<string, ComponentAnalytics[]>
  notifications: Notification[]
  notificationAnalytics: Record<string, NotificationAnalytics>
  notificationLogs: Record<string, NotificationAnalytics[]>
}

// MARK: - State

export const initialState: State = {
  isLoading: { component: true, overview: true, link: true, notification: true },
  error: null,
  overviewAnalytics: null,
  overviewLogs: [],
  linkAnalytics: {},
  linkLogs: {},
  componentAnalytics: {},
  componentLogs: {},
  notifications: [],
  notificationAnalytics: {},
  notificationLogs: {},
}

// MARK: - Reducer

export const dashboardSettingsAnalyticsReducer = (
  state = initialState,
  action:
    | SetOverviewAnalyticsAction
    | SetOverviewLogsAction
    | SetLinkAnalyticsAction
    | SetLinkLogsAction
    | SetComponentAnalyticsAction
    | SetComponentLogsAction
    | SetNotificationAnalyticsAction
    | SetNotificationLogsAction
    | LoadingAction
    | ErrorAction
    | FlushAction
    | { type: 'me/logout' },
): State => {
  switch (action.type) {
    case 'dashboardSettingsAnalytics/logsLoading':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.analyticsType]: action.isLoading,
        },
      }

    case 'dashboardSettingsAnalytics/error':
      return { ...state, error: action.error }

    case 'dashboardSettingsAnalytics/setOverviewAnalytics':
      return { ...state, overviewAnalytics: action.analytics }

    case 'dashboardSettingsAnalytics/setOverviewLogs':
      return { ...state, overviewLogs: action.logs }

    case 'dashboardSettingsAnalytics/setComponentAnalytics':
      return {
        ...state,
        componentAnalytics: {
          ...state.componentAnalytics,
          [action.componentId]: action.analytics,
        },
      }

    case 'dashboardSettingsAnalytics/setComponentLogs':
      return {
        ...state,
        componentLogs: {
          ...state.componentLogs,
          [action.componentId]: action.logs,
        },
      }

    case 'dashboardSettingsAnalytics/setLinkAnalytics':
      return {
        ...state,
        linkAnalytics: {
          ...state.linkAnalytics,
          [action.linkId]: action.analytics,
        },
      }

    case 'dashboardSettingsAnalytics/setLinkLogs':
      return {
        ...state,
        linkLogs: {
          ...state.linkLogs,
          [action.linkId]: action.logs,
        },
      }

    case 'dashboardSettingsAnalytics/setNotificationAnalytics':
      return {
        ...state,
        notificationAnalytics: {
          ...state.notificationAnalytics,
          [action.notificationId]: action.analytics,
        },
      }

    case 'dashboardSettingsAnalytics/setNotificationLogs':
      return {
        ...state,
        notificationLogs: {
          ...state.notificationLogs,
          [action.notificationId]: action.logs,
        },
      }

    case 'dashboardSettingsAnalytics/flush':
    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchOverviewAnalytics =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('overview', true))
    dispatch(setError(null))

    const response = await analyticsService.fetchOverviewAnalytics(roomId)

    if (response.success) {
      dispatch(setOverviewAnalytics(response.value))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('overview', false))
  }

export const fetchOverviewLogs =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('overview', true))
    dispatch(setLoading('overview', true))
    dispatch(setError(null))

    const [analyticsResponse, logsResponse] = await Promise.all([
      analyticsService.fetchOverviewAnalytics(roomId),
      analyticsService.fetchOverviewLogs(roomId, 24),
    ])

    if (analyticsResponse.success && logsResponse.success) {
      dispatch(setOverviewAnalytics(analyticsResponse.value))
      dispatch(setOverviewLogs(logsResponse.value))
    } else {
      dispatch(setError(Error.someThingWentWrong()))
    }
    dispatch(setLoading('overview', false))
    dispatch(setLoading('overview', false))
  }

export const fetchLinkLogs =
  (linkId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('link', true))
    dispatch(setError(null))

    const [analyticsResponse, logsResponse] = await Promise.all([
      analyticsService.fetchLinkAnalytics(linkId),
      analyticsService.fetchLinkLogs(linkId, 24),
    ])

    if (analyticsResponse.success && logsResponse.success) {
      dispatch(setLinkAnalytics(analyticsResponse.value, linkId))
      dispatch(setLinkLogs(logsResponse.value, linkId))
    } else {
      dispatch(setError(Error.someThingWentWrong()))
    }
    dispatch(setLoading('link', false))
  }

export const fetchComponentLogs =
  (componentId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('component', true))
    dispatch(setError(null))

    const [analyticsResponse, logsResponse] = await Promise.all([
      analyticsService.fetchComponentAnalytics(componentId),
      analyticsService.fetchComponentLogs(componentId, 24),
    ])

    if (analyticsResponse.success && logsResponse.success) {
      dispatch(setComponentAnalytics(analyticsResponse.value, componentId))
      dispatch(setComponentLogs(logsResponse.value, componentId))
    } else {
      dispatch(setError(Error.someThingWentWrong()))
    }
    dispatch(setLoading('component', false))
  }

export const fetchNotifications =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('notification', true))
    dispatch(setError(null))

    const response = await notificationService.fetchNotifications(roomId, 0, 20)

    if (response.success) {
      dispatch(mergeEntities({ notification: response.value }))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading('notification', false))
  }

export const fetchNotificationLogs =
  (notificationId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading('notification', true))
    dispatch(setError(null))

    const [analyticsResponse, logsResponse] = await Promise.all([
      analyticsService.fetchNotificationAnalytics(notificationId),
      analyticsService.fetchNotificationLogs(notificationId, 24),
    ])

    if (analyticsResponse.success && logsResponse.success) {
      dispatch(setNotificationAnalytics(analyticsResponse.value, notificationId))
      dispatch(setNotificationLogs(logsResponse.value, notificationId))
    } else {
      dispatch(setError(Error.someThingWentWrong()))
    }
    dispatch(setLoading('notification', false))
  }

export const flush = (): FlushAction => ({
  type: 'dashboardSettingsAnalytics/flush',
})

export const setError = (error: IError | null): ErrorAction => ({
  type: 'dashboardSettingsAnalytics/error',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State, analyticsType: AnalyticsType): boolean => {
  return state.isLoading[analyticsType]
}

export const getError = (state: State): Error | null => {
  return state.error
}

export const getOverviewAnalytics = (state: State): OverviewAnalytics | null => {
  return state.overviewAnalytics
}

export const getOverviewLogs = (state: State): OverviewAnalytics[] => {
  return state.overviewLogs ?? []
}

export const getLinkAnalytics = (state: State, linkId: string): LinkAnalytics | null => {
  return state.linkAnalytics[linkId] ?? null
}

export const getLinkLogs = (state: State, linkId: string): LinkAnalytics[] => {
  return state.linkLogs[linkId] ?? []
}

export const getNotificationAnalytics = (
  state: State,
  notificationId: string,
): NotificationAnalytics | null => {
  return state.notificationAnalytics[notificationId] ?? null
}

export const getNotificationLogs = (
  state: State,
  notificationId: string,
): NotificationAnalytics[] => {
  return state.notificationLogs[notificationId] ?? []
}

export const getComponentAnalytics = (
  state: State,
  componentId: string,
): ComponentAnalytics | null => {
  return state.componentAnalytics[componentId] ?? null
}

export const getComponentLogs = (state: State, componentId: string): ComponentAnalytics[] => {
  return state.componentLogs[componentId] ?? []
}

// MARK: - Action Types

type LoadingAction = {
  type: 'dashboardSettingsAnalytics/logsLoading'
  analyticsType: AnalyticsType
  isLoading: boolean
}

type ErrorAction = {
  type: 'dashboardSettingsAnalytics/error'
  error: IError | null
}

type SetOverviewAnalyticsAction = {
  type: 'dashboardSettingsAnalytics/setOverviewAnalytics'
  analytics: OverviewAnalytics
}

type SetOverviewLogsAction = {
  type: 'dashboardSettingsAnalytics/setOverviewLogs'
  logs: OverviewAnalytics[]
}

type SetLinkAnalyticsAction = {
  type: 'dashboardSettingsAnalytics/setLinkAnalytics'
  analytics: LinkAnalytics
  linkId: string
}

type SetLinkLogsAction = {
  type: 'dashboardSettingsAnalytics/setLinkLogs'
  logs: LinkAnalytics[]
  linkId: string
}

type SetComponentAnalyticsAction = {
  type: 'dashboardSettingsAnalytics/setComponentAnalytics'
  analytics: ComponentAnalytics
  componentId: string
}

type SetComponentLogsAction = {
  type: 'dashboardSettingsAnalytics/setComponentLogs'
  logs: ComponentAnalytics[]
  componentId: string
}

type SetNotificationAnalyticsAction = {
  type: 'dashboardSettingsAnalytics/setNotificationAnalytics'
  analytics: NotificationAnalytics
  notificationId: string
}

type SetNotificationLogsAction = {
  type: 'dashboardSettingsAnalytics/setNotificationLogs'
  logs: NotificationAnalytics[]
  notificationId: string
}

type FlushAction = {
  type: 'dashboardSettingsAnalytics/flush'
}

// MARK: - Internal Actions

const setOverviewAnalytics = (analytics: OverviewAnalytics): SetOverviewAnalyticsAction => ({
  type: 'dashboardSettingsAnalytics/setOverviewAnalytics',
  analytics: analytics,
})

const setOverviewLogs = (logs: OverviewAnalytics[]): SetOverviewLogsAction => ({
  type: 'dashboardSettingsAnalytics/setOverviewLogs',
  logs: logs,
})

const setLinkAnalytics = (analytics: LinkAnalytics, linkId: string): SetLinkAnalyticsAction => ({
  type: 'dashboardSettingsAnalytics/setLinkAnalytics',
  analytics: analytics,
  linkId: linkId,
})

const setLinkLogs = (logs: LinkAnalytics[], linkId: string): SetLinkLogsAction => ({
  type: 'dashboardSettingsAnalytics/setLinkLogs',
  logs: logs,
  linkId: linkId,
})

const setComponentAnalytics = (
  analytics: ComponentAnalytics,
  componentId: string,
): SetComponentAnalyticsAction => ({
  type: 'dashboardSettingsAnalytics/setComponentAnalytics',
  analytics: analytics,
  componentId: componentId,
})

const setComponentLogs = (
  logs: ComponentAnalytics[],
  componentId: string,
): SetComponentLogsAction => ({
  type: 'dashboardSettingsAnalytics/setComponentLogs',
  logs: logs,
  componentId: componentId,
})

const setNotificationAnalytics = (
  analytics: NotificationAnalytics,
  notificationId: string,
): SetNotificationAnalyticsAction => ({
  type: 'dashboardSettingsAnalytics/setNotificationAnalytics',
  analytics: analytics,
  notificationId: notificationId,
})

const setNotificationLogs = (
  logs: NotificationAnalytics[],
  notificationId: string,
): SetNotificationLogsAction => ({
  type: 'dashboardSettingsAnalytics/setNotificationLogs',
  logs: logs,
  notificationId: notificationId,
})

const setLoading = (analyticsType: AnalyticsType, isLoading: boolean): LoadingAction => ({
  type: 'dashboardSettingsAnalytics/logsLoading',
  analyticsType: analyticsType,
  isLoading: isLoading,
})
