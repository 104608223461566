import { api } from '../api'
import { QAQuestion, User } from '../types'

export const qaQuestionService = {
  fetchQAQuestions: async (componentId: string) =>
    api.value<{ qa_questions: QAQuestion[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/qa/questions/all',
        parameters: { component_id: componentId },
      },
    }),

  answerQAQuestion: async (qaQuestionId: string, answer: string) =>
    api.prop<{ qa_question: QAQuestion }>('qa_question', {
      request: {
        method: 'PUT',
        endPoint: '/qa/question/answer',
        parameters: { qa_question_id: qaQuestionId, answer: answer },
      },
    }),

  rejectQAQuestion: async (qaQuestionId: string) =>
    api.prop<{ qa_question: QAQuestion }>('qa_question', {
      request: {
        method: 'DELETE',
        endPoint: '/qa/question/reject',
        parameters: { qa_question_id: qaQuestionId },
      },
    }),
}
