// MARK: - Request & Response
import { IError } from './Error'

export const success = <T>(value: T, retCode?: number): Success<T> => ({
  type: 'success',
  value: value,
  retCode: retCode,
  success: true,
})

export const failure = (error: IError, retCode?: number): Failure => ({
  type: 'failure',
  error: error,
  retCode: retCode,
  success: false,
})

export type BecomePublisherForm = {
  id: string
  email: string
  audience_size: number
  job_description: string
  links_accounts: string
  target_industry: string
  is_active: boolean
  created_at: number
  updated_at: number
}

export type CollaborationTeam = {
  id: string
  name: string
  owner_id: string
}

export enum AccessLevel {
  admin = 0,
  moderator = 1,
}

export type Collaborator = {
  id: string
  room_id: string
  invitation_id: string
  user_id: string
  team_id: string
  access_level: AccessLevel
  accessible_component_ids: string[]
  is_active: boolean
  created_at: number
  updated_at: number
}
export type RoomDateSegment = 'active' | 'past' | 'live'

export type Room = {
  id: string
  title: string
  overview: string
  start_date: number
  end_date: number
  timezone: string
  privacy_type: RoomPrivacyType
  short_link: string

  publisher_id: string
  status: RoomStatus
  publish_date: number
  purchase_ids: string[]

  main_media: { url: string; offset: number }
  location: RoomLocation

  links: Link[]
  access_codes: string[]
  tags: string[]
  config: RoomConfiguration

  created_at: number
  updated_at: number
  is_active: boolean
}

export type Banner = { link: string; image_url: string }

export type RoomConfiguration = {
  chat_enabled: boolean
  share_enabled: boolean
  see_more_enabled: boolean
  banners: Banner[]
}

export type RoomStatus = 'creating' | 'published'
export type RoomPrivacyType = 'public' | 'private' | 'managed' | 'invite-only'
export enum LinkType {
  facebook = 1,
  twitter,
  instagram,
  email,
  linkedIn,
  website,
  youtube,
  zoom,
}

export type Link = {
  id: string
  type: LinkType
  name: string
  url: string
}

export type RoomLocation =
  | {
      type: 'in_person'
      venue: {
        address: string
        title: string
        latitude: number
        longitude: number
        province: string
        city: string
        country: string
        zip_code: number
      } | null
    }
  | { type: 'virtual'; url: string }
  | { type: 'zoom'; url: string; id: string; password: string | null }
  | { type: 'tba'; description: string }

export type Component = {
  id: string
  title: string
  overview: string
  order: number
  type: number
  room_id: string
  image_url: string
  has_badge: boolean
  updated_at: number
  created_at: number
  is_active: boolean
}

export type Subscription = {
  id: string
  user_id: string
  referrer_id: string | null
  room_id: string
  event_id: string
  push_date: number
  notification_enabled: boolean
  notification_components: string[]
  notification_channels: string[]
  is_blocked: boolean
  is_active: boolean
  created_at: number
  updated_at: number
}

export type Announcement = {
  id: string
  type: string
  room_id: string
  component_id: string

  title: string
  overview: string
  image_url: string
  image_offset: number

  publish_date: number
  is_scheduled: boolean
  notify_users: boolean
  has_detail: boolean
  action_name: string
  link: string

  created_at: number
  updated_at: number
  is_active: boolean
}

export type Template = {
  id: string
  image_url: string
  overview: string
  title: string
  publisher_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type AnnouncementList = {
  id: string
  component_id: string
  room_id: string
  is_notification_enabled: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export type WebPage = {
  id: string
  component_id: string
  room_id: string
  url: string
  is_external: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export type SpreadGame = {
  id: string
  room_id: string
  component_id: string
  events: SpreadGameEvent[]
  point_table: SpreadGamePointTableItem[]
  black_list: string[]
  created_at: number
  updated_at: number
  is_active: boolean
}

export type SpreadGameScore = {
  id: string
  user_id: string
  game_id: string
  score: number
  updated_at: number
}

export type SpreadGamePointTableItem = {
  id: string
  name: string
  event: SpreadGameEvent
  point: number
}

export enum SpreadGameEvent {
  subscribe = 'room.home.subscribed',
  setCalendar = 'room.calendar.created',
  setReminder = 'room.reminder.created',
}
export type User = {
  id: string
  firebase_uid: string
  auth_types: string[]
  email: string
  phone_number: string
  first_name: string
  last_name: string
  user_name: string
  image_url: string
  notification_token: string
}

export type TokenContainer = {
  idToken: string
  refreshToken: string
  expiresIn: string
  localId: string
}

export type TokenHolder = {
  id_token: string
  refresh_token: string
  user_id: string
}

export type RefreshTokenContainer = {
  access_token: string
  expires_in: string
  token_type: string
  refresh_token: string
  id_token: string
  user_id: string
  project_id: string
}

export type Invitation = {
  id: string
  email: string
  notes: string
  first_name: string
  last_name: string
  room_id: string
  image_url: string
  image_offset: number

  status: 'accepted' | 'pending'
  notify_on_accept: boolean
  notify_on_accept_email: string
  invitation_type: 'member' | 'collaborator'
  invited_user_id: string
  collaborator_access_level: AccessLevel | null
  invited_by_id: string
  created_at: number
}

export type InvitationRequest = {
  id: string
  user_id: string
  room_id: string
  email: string
  first_name: string
  last_name: string
  image_url: string
  notes: string
  created_at: number
  invitation_type: 'member' | 'collaborator'
}

export type InvitationGroup = {
  id: string
  name: string
  overview: string
  invitation_ids: string[]
  publisher_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type RoomStatistics = {
  room_id: string
  calendar_event_count: number
  local_push_count: number
  subscription_count: number
  spread_count: number
  updated_at: number
}

export type UserSettings = {
  id: string
  auto_set_notification: boolean
  auto_set_calendar_event: boolean
  updated_at: number
}

export type Me = {
  id: string
  user: User
}

export type AuthType =
  | { type: 'email'; email: string; password: string }
  | { type: 'phone'; phone: string; password: string }
  | { type: 'google' }
  | { type: 'facebook' }
  | { type: 'apple' }

export type ThirdPartyToken = {
  idToken: string
  accessToken: string
}

export type AuthUser = {
  id: string | undefined
  userName: string | undefined
  firebaseToken: string | undefined
  thirdPartyToken: ThirdPartyToken
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  phoneNumber: string | undefined
  imageUrl: string | undefined
  isPublisher: boolean
  authType: AuthType
  nonce: string | undefined
}

export enum ComponentType {
  announcements = 0,
  webPage = 1,
  spreadGame = 2,
  list = 3,
  webList = 4,
  ordering = 5,
  sponsors = 6,
  chat = 7,
  polling = 8,
  qa = 9,
  faq = 10,
}

export type ComponentTypeKey =
  | 'announcement_list'
  | 'web_page'
  | 'spread_game'
  | 'list'
  | 'web_links'
  | 'ordering'
  | 'sponsors'
  | 'chat'
  | 'polling'
  | 'qa'
  | 'faq'

export const componentTypeKey = (type: ComponentType): ComponentTypeKey => {
  switch (type) {
    case ComponentType.announcements:
      return 'announcement_list'
    case ComponentType.webPage:
      return 'web_page'
    case ComponentType.spreadGame:
      return 'spread_game'
    case ComponentType.list:
      return 'list'
    case ComponentType.webList:
      return 'web_links'
    case ComponentType.ordering:
      return 'ordering'
    case ComponentType.sponsors:
      return 'sponsors'
    case ComponentType.chat:
      return 'chat'
    case ComponentType.polling:
      return 'polling'
    case ComponentType.qa:
      return 'qa'
    case ComponentType.faq:
      return 'faq'
  }
}

export type TrackedUrl = {
  id: string
  room_id: string
  component_id: string
  url: string
  count: number
  name: string
  notify_me_limit: number
  notify_me_email_address: string
  created_at: number
}

export type OverviewAnalytics = {
  timestamp: number
  link_click: number
  location_click: number
  subscribe: number
  set_reminder: number
  remove_reminder: number
  unsubscribe: number
  component_selected: number
  set_calendar_event: number
  remove_calendar_event: number
  invitation_sent: number
}

export type LinkAnalytics = {
  timestamp: number
  opened: number
}

export type ComponentAnalytics = {
  timestamp: number
  viewed: number
}

export type NotificationAnalytics = {
  timestamp: number
  opened: number
  opened_from_list: number
  sent: number
}

export type Notification = {
  id: string
  room_id: string
  publisher_id: string
  title: string
  overview: string
  image_url: string
  image_offset: number
  publish_date: number
  is_scheduled: boolean
  link: string
  feature_id: string
  updated_at: number
  created_at: number
}

export type PurchaseLimit = number | 'unlimited'

export type PurchasePrice = {
  price: number
  currency: 'Lira' | 'Dollar' | 'Euro'
}

export type PurchaseAudienceTier = {
  id: string
  name: string
  tier: number
  subscription_limit: PurchaseLimit
  base_price: PurchasePrice
}

export type PurchaseUsageTier = {
  id: string
  name: string
  tier: number
  usage_limit: PurchaseLimit
  price_multiplier: number
}

export type PurchaseDiscount = {
  id: string
  creator_email: string
  promo_code: string
  created_at: number
  expire_date: number
  usage_limit: number
  white_list: string[] | null
  black_list: string[] | null
}

export type RoomPurchase = {
  id: string
  room_id: string
  user_id: string
  created_at: number
  audience_tier: PurchaseAudienceTier
  feature_tier: PurchaseUsageTier
  notification_tier: PurchaseUsageTier
  discount?: PurchaseDiscount | null
  payment_intent_secret: string | null
  payment_intent_status: 'none' | 'cancelled' | 'created' | 'payment_failed' | 'succeeded'
  purchased_at?: number
  price: number
  purchased: boolean
}

export type RoomKey = {
  api_key: string
  api_secret: string
  room_id: string | null
}

export type UserKey = {
  api_key: string
  api_secret: string
  user_id: string
}

export type WebList = {
  id: string
  component_id: string
  room_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type WebListItem = {
  id: string
  room_id: string
  component_id: string
  title: string
  overview: string
  image_url: string
  link: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type FAQList = {
  id: string
  component_id: string
  room_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type FAQ = {
  id: string
  room_id: string
  component_id: string
  question: string
  answer: string
  links: string[]
  created_at: number
  updated_at: number
  is_active: boolean
}

export type ChatChannelType = 'general' | 'topic'

export type ChatChannel = {
  id: string
  room_id: string
  name: string
  image_url: string
  image_offset: number
  type: ChatChannelType
  channel_key: string
  description: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type QA = {
  id: string
  room_id: string
  component_id: string
  anonymous_enabled: boolean
  created_at: number
  updated_at: number
  is_active: boolean
}

export type QAQuestionState = 'pending' | 'answered' | 'rejected'

export type QAQuestion = {
  id: string
  component_id: string
  user_id: string
  is_anonymous: boolean
  question: string
  answer: string | null
  state: QAQuestionState
  created_at: number
  updated_at: number
  is_active: boolean
}

export type Polling = {
  id: string
  room_id: string
  component_id: string
  created_at: number
  updated_at: number
  is_active: boolean
}

export type Poll = {
  id: string
  question: string
  component_id: string
  options: PollOption[]
  created_at: number
  updated_at: number
  is_active: boolean
  votes: Record<string, number> | null
}

export type PollOption = {
  id: string
  order: number
  answer: string
}

export type CoverImage = {
  thumb: string
  url: string
  download_location: string
  ref: {
    name: string
    portfolio: string
  }
}

export type FlatIcon = {
  id: number
  description: string
  color: string
  shape: string
  pack_id: number
  pack_name: string
  pack_items: number
  tags: string
  images: {
    '16': string
    '24': string
    '32': string
    '64': string
    '128': string
    '256': string
    '512': string
  }
}

export type FlatIconPack = {
  id: number
  description: string
  colors: string
  color: string
  shape: string
  family_id: number
  family_name: string
  pack_items: number
  tags: string
  images: {
    sprite: string
  }
}

export type FlatIconColor = 'black' | 'color' | 'gradient'
export type FlatIconShape = 'outline' | 'fill' | 'lineal-color' | 'hand-drawn'

export type FlatIconStorage = {
  icon_id: number
  download_url: string
}

export type ImageOrientation = 'landscape' | 'portrait' | 'squarish'
