import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { LabelHeaderLarge } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { Select } from 'src/features/common/Select'
import { getMeUser } from 'src/redux/reducers/me'
import { RoomDateSegment, RoomStatus } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RoomListHeader: React.FC<{
  isLoading: boolean
  onDateSegmentChange: (status: RoomDateSegment | 'all') => void
  onStatusChange: (status: RoomStatus | 'all') => void
  onSearchTextChange: (text: string) => void
}> = ({ isLoading, onDateSegmentChange, onSearchTextChange, onStatusChange }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()
  const meUser = useSelect(state => getMeUser(state.me))
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<RoomStatus | '0' | 'all'>('0')
  const [dateSegment, setDateSegment] = useState<RoomDateSegment | '0' | 'all'>('0')

  // MARK: - Effects

  useEffect(() => {
    onSearchTextChange(searchText)
  }, [searchText, onSearchTextChange])

  useEffect(() => {
    if (status !== '0') onStatusChange(status)
  }, [status, onStatusChange])

  useEffect(() => {
    if (dateSegment !== '0') onDateSegmentChange(dateSegment)
  }, [dateSegment, onDateSegmentChange])

  // MARK: - Render

  return (
    <Container>
      <RowContainer>
        <TitleContainer>
          {meUser && <Title>{t('hello') + ' ' + meUser?.first_name + ','}</Title>}
        </TitleContainer>

        <InputContainer>
          <TextContainer isMobile={isMobile}>
            <Input
              placeholder={t('search')}
              hasIcon="right"
              value={searchText}
              onChange={setSearchText}>
              <SearchIcon size={16} />
            </Input>
          </TextContainer>

          <TextContainer isMobile={isMobile}>
            <Select
              placeholder={t('status')}
              value={status}
              onChange={value => setStatus(value as RoomStatus | '0' | 'all')}>
              <option value={'all'}>{t('all')}</option>
              <option value={'creating'}>{t('creating')}</option>
              <option value={'published'}>{t('live')}</option>
            </Select>
          </TextContainer>

          <TextContainer isMobile={isMobile}>
            <Select
              placeholder={t('date')}
              value={dateSegment}
              onChange={value => setDateSegment(value as RoomDateSegment | '0' | 'all')}>
              <option value={'all'}>{t('all')}</option>
              <option value={'past'}>{t('past')}</option>
              <option value={'live'}>{t('future')}</option>
            </Select>
          </TextContainer>
        </InputContainer>
      </RowContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  flex: 1;
  margin: 50px 8px 30px -10px;
`

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -1.5%;
`

const TextContainer = styled.div<{ isMobile: boolean }>`
  flex-basis: ${({ isMobile }) => (isMobile ? '100%' : '31%')};
  margin-bottom: ${({ isMobile }) => (isMobile ? 10 : 0)}px;
  margin-right: 2%;
`

const Title = styled(LabelHeaderLarge)`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 2px;
`
