export type CoverUpdateMethod = 'gallery' | 'upload' | 'link' | 'unsplash'

export const coverUpdateMethods: CoverUpdateMethod[] = ['gallery', 'upload', 'link', 'unsplash']

export const coverImages: Record<string, { title: string; images: string[] }> = {
  wallpaper: {
    title: 'wallpapers',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_0.jpg?alt=media&token=bb6c7ed6-0da7-4863-a604-c05ae2ced2e8',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_1.jpg?alt=media&token=9c247d3d-45ac-4bb4-a68a-ef1cb5bf7feb',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_10.jpg?alt=media&token=99631b45-9ae0-4882-88c5-9c6a0960eb99',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_2.jpg?alt=media&token=871d04cf-bc74-4573-bfca-96fb871ba06c',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_3.jpg?alt=media&token=be4f6298-3149-4962-bd01-529e168b5b76',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_4.jpg?alt=media&token=be46e92c-f7ee-4873-a840-36294dcf8bd3',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_5.jpg?alt=media&token=77b3b441-49f5-4180-b07c-af8f14d8fd5b',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_6.jpg?alt=media&token=c3afc809-de01-4338-8584-33542c3e5d6c',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_7.jpg?alt=media&token=08d9b805-cf25-4ff0-a924-cafee2d19b3c',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_8.jpg?alt=media&token=176521c1-18fe-4536-8958-faec15200783',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_9.jpg?alt=media&token=295f22b0-b346-476f-aff1-c76051ec5b62',
    ],
  },
  nasa: {
    title: 'nasaArchive',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_erian-mcgowan-lGNBGpFR4nQ-unsplash.jpg?alt=media&token=de15ef22-91b9-4dac-8646-ab61bcbf3193',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa--hI5dX2ObAs-unsplash.jpg?alt=media&token=e9b86250-94a0-4ec6-a367-7c293cc624f4',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-5JuLcub3dYg-unsplash.jpg?alt=media&token=aa487796-0ea3-407b-be29-af729cfc0da8',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-6-jTZysYY_U-unsplash.jpg?alt=media&token=f6a5479b-5b39-4aa2-9421-7aaf104f073b',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-7Cz6bWjdlDs-unsplash.jpg?alt=media&token=1764d188-73f5-41a7-a4b8-7867814d0fb8',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-E7q00J_8N7A-unsplash.jpg?alt=media&token=878ecc56-23c8-4e47-b6bf-c62a368553b2',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-NuE8Nu3otjo-unsplash.jpg?alt=media&token=13f630e5-1875-4923-b80a-e186ea7b1314',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-dCgbRAQmTQA-unsplash.jpg?alt=media&token=3e224d56-09ae-48d8-a5f2-eb2f262d69b5',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-hpt0AJPZ0Aw-unsplash.jpg?alt=media&token=1d9619d4-8d46-4b46-b244-c3e760cf543b',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-vltMzn0jqsA-unsplash.jpg?alt=media&token=d53aaee8-9823-41ae-867a-bb51fc400930',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-xFO2Xt33xgI-unsplash.jpg?alt=media&token=d55a403c-e8b9-4556-be7b-5b85d511da13',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_nasa-yZygONrUBe8-unsplash.jpg?alt=media&token=3e67dd3b-0934-4621-bf4b-5b4595ad3b44',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_planet-volumes-4IrVnSpwk48-unsplash.jpg?alt=media&token=6af84758-c817-46b5-ba01-496b8cca22f2',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_spacex--p-KCm6xB9I-unsplash.jpg?alt=media&token=6ba74c21-440f-4c09-8e6b-9e9e2e26b020',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fcover_vincent-ledvina-xG3YWsaljIo-unsplash.jpg?alt=media&token=228137dc-76f3-4537-b635-290ccb4f8e17',
    ],
  },
  painting: {
    title: 'painting',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coveryannis-papanastasopoulos-kKzbyDeb62M-unsplash.jpg?alt=media&token=21f0e414-3df7-482a-bfac-a6c8aba05266',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coversteve-johnson-dvMKIGmSQn8-unsplash.jpg?alt=media&token=65bef774-8dec-42b9-8c4e-9680fe9ecbd8',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coversteve-johnson-Xx_d26R37E4-unsplash.jpg?alt=media&token=26501597-0647-44e1-ac78-6d487d6722ed',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverpawel-czerwinski-yn97LNy0bao-unsplash.jpg?alt=media&token=1bb6df2f-9b36-41af-8449-d0ad560b22c7',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverpawel-czerwinski-xubOAAKUwXc-unsplash.jpg?alt=media&token=0ee2036e-a2d6-481c-99dc-539b031a3ab2',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverpawel-czerwinski-BFxnx0UHVGM-unsplash.jpg?alt=media&token=87df926d-4fb6-49a6-91bc-a5a76b739f54',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_covereuropeana-H-4WME4eoOo-unsplash.jpg?alt=media&token=bf2037f4-f9bd-4691-8907-d490a67db2cd',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-wKlHsooRVbg-unsplash.jpg?alt=media&token=e3e60d2f-694d-47a9-90c3-13002d011d3e',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-qgWUv52K6Fk-unsplash.jpg?alt=media&token=ceab6532-66a4-4772-af93-688d3c587344',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-nbneQlI2M1A-unsplash.jpg?alt=media&token=252b5ec5-0bab-4e44-98cb-fa3b0656ffda',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-RRn7VvZCbas-unsplash.jpg?alt=media&token=70e8b59d-53e2-46c8-b32d-0268bf413d62',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-8wcoY3wcbL0-unsplash.jpg?alt=media&token=a2c24900-3444-4d01-95ed-a8eb17c16028',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coverbirmingham-museums-trust-4lDX-xTLl3Q-unsplash.jpg?alt=media&token=7bdb10a1-bc0f-4140-a515-a2e388a2b378',
      'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/cover%2Fpainting_coveralice-dietrich-FwF_fKj5tBo-unsplash.jpg?alt=media&token=bd40300b-77f7-4cfc-8d75-f576dae2b91e',
    ],
  },
}
