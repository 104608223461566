import { AnimatedFooter } from './common/AnimatedFooter'
import { RoomDatesSection } from './common/RoomSections/RoomDatesSection'
import { RoomDescriptionSection } from './common/RoomSections/RoomDescriptionSection'
import { RoomLinksSection } from './common/RoomSections/RoomLinksSection'
import { RoomLocationSection } from './common/RoomSections/RoomLocationSection'
import { RoomPrivacySection } from './common/RoomSections/RoomPrivacySection'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LabelMedium, Row } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { CoverImage } from 'src/features/platform/common/CoverImage'
import {
  createRoom,
  flush,
  getCreatedRoomId,
  getCreateRoomRequest,
  getIsLoading,
  getPrivateKeyPair,
  setCreateRoomRequest,
  setRandomTheme,
} from 'src/redux/reducers/createRoom'
import { RoomRequest } from 'src/repository/services/roomService'
import { validateURL } from 'src/utils/helpers/validationHelper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import { useTheme } from 'styled-components'

export const CreateRoom: React.FC<{
  visible: boolean
  onClose: () => void
}> = ({ visible, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { routes, navigate } = useNavigation()

  const request = useSelect(state => getCreateRoomRequest(state.createRoom))
  const createdRoomId = useSelect(state => getCreatedRoomId(state.createRoom))
  const isLoading = useSelect(state => getIsLoading(state.createRoom, 'create'))

  const inputMissingError = useMemo(() => {
    if (!request.title) {
      return t('pleaseEnterTitle')
    } else if (request.location.type === 'virtual' && !validateURL(request.location.url)) {
      return t('pleaseEnterURL')
    } else if (request.location.type === 'zoom' && !validateURL(request.location.url)) {
      return t('pleaseEnterZoomURL')
    } else if (request.location.type === 'zoom' && !request.location.id) {
      return t('pleaseEnterZoomID')
    } else if (request.location.type === 'in_person' && !request.location.venue) {
      return t('pleaseEnterVenue')
    } else {
      return ''
    }
  }, [request])

  // MARK: - Effects

  useEffect(() => {
    dispatch(flush())
    dispatch(setRandomTheme())
  }, [visible])

  useEffect(() => {
    if (createdRoomId) {
      navigate(routes.dashboard(createdRoomId).info)
      onClose()
    }
  }, [createdRoomId])

  useEffect(() => {
    if (request.privacy_type === 'managed' && !request.api_key) dispatch(getPrivateKeyPair())
  }, [request.privacy_type])

  useEffect(() => {
    if (moment.tz?.guess() && !request.date.timezone) {
      dispatch(
        dispatch(setCreateRoomRequest({ date: { ...request.date, timezone: moment.tz.guess() } })),
      )
    }
  }, [moment.tz])

  // MARK: - Handler

  const handleCoverImageChange = useCallback(
    (url?: string) => {
      dispatch(setCreateRoomRequest({ ...request, media: { ...request.media, url: url ?? '' } }))
    },
    [request],
  )

  const handleCoverImageOffsetChange = useCallback(
    (offset: number) => {
      dispatch(setCreateRoomRequest({ ...request, media: { ...request.media, offset } }))
    },
    [request],
  )

  const handleUpdatePartialRoom = useCallback(
    (key: keyof RoomRequest) => (input: any) => {
      dispatch(setCreateRoomRequest({ ...request, [key]: input }))
    },
    [request],
  )

  // MARK: - Render

  if (!visible) return null

  return (
    <ModalWrapper title={t('createProject')} size="tiny" onClose={onClose}>
      <Row>
        <CoverImage
          orientation="landscape"
          style={{ borderRadius: 12, overflow: 'hidden' }}
          initialCover={request.media.url}
          preferredContainerHeight={240}
          initialOffset={request.media.offset}
          onCoverImageChange={handleCoverImageChange}
          onCoverOffsetChange={handleCoverImageOffsetChange}
          editEnabled
        />
      </Row>

      <RoomDescriptionSection
        title={request.title}
        description={request.overview}
        onTitleChange={handleUpdatePartialRoom('title')}
        onDescriptionChange={handleUpdatePartialRoom('overview')}
      />

      <RoomLocationSection
        hideIcon
        location={request.location}
        onLocationChange={handleUpdatePartialRoom('location')}
      />

      <RoomDatesSection
        hideIcon
        startDate={request.date.start}
        endDate={request.date.end ?? 0}
        timezone={request.date.timezone ?? moment.tz?.guess()}
        onStartDateChange={startDate => {
          const date = Math.floor(startDate)
          if (date && date > 1000) handleUpdatePartialRoom('date')({ ...request.date, start: date })
          else handleUpdatePartialRoom('date')({ ...request.date, start: 0 })
        }}
        onEndDateChange={endDate => {
          const date = Math.floor(endDate)
          if (date && date > 1000) handleUpdatePartialRoom('date')({ ...request.date, end: date })
          else handleUpdatePartialRoom('date')({ ...request.date, end: 0 })
        }}
        onTimezoneChange={timezone =>
          handleUpdatePartialRoom('date')({ ...request.date, timezone })
        }
      />

      <RoomPrivacySection
        hideIcon
        selectedPrivacyType={request.privacy_type}
        accessCode={request.access_codes[0] ?? ''}
        apiKey={request.api_key ?? ''}
        apiSecret={request.api_secret ?? ''}
        onAccessCodeChange={code => dispatch(setCreateRoomRequest({ access_codes: [code] }))}
        onPrivacyChange={privacyType =>
          dispatch(setCreateRoomRequest({ privacy_type: privacyType }))
        }
      />

      <RoomLinksSection
        hideIcon
        links={request.links}
        banners={request.config.banners}
        onLinkDelete={index => {
          const links = [...request.links]
          links.splice(index, 1)
          dispatch(setCreateRoomRequest({ links }))
        }}
        onLinkTypeChange={(index, type) => {
          const links = [...request.links]
          links[index] = { ...links[index], type: type }
          dispatch(setCreateRoomRequest({ links }))
        }}
        onLinkNameChange={(index, name) => {
          const links = [...request.links]
          links[index] = { ...links[index], name: name }
          dispatch(setCreateRoomRequest({ links }))
        }}
        onLinkUrlChange={(index, url) => {
          const links = [...request.links]
          links[index] = { ...links[index], url: url }
          dispatch(setCreateRoomRequest({ links }))
        }}
        onBannersChange={banners =>
          dispatch(setCreateRoomRequest({ config: { ...request.config, banners } }))
        }
      />

      <AnimatedFooter visible style={{ paddingLeft: 24, zIndex: 3 }}>
        <LabelMedium style={{ color: palette.orange, alignSelf: 'center', flex: 1 }}>
          {inputMissingError}
        </LabelMedium>

        <PrimaryButton
          style={{ marginRight: 24, minWidth: 160 }}
          isLoading={isLoading}
          title={t('create')}
          onClick={() => dispatch(createRoom())}
        />
      </AnimatedFooter>
    </ModalWrapper>
  )
}
