import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'

export const FormLabel: React.FC<{
  style?: React.CSSProperties
  labelHidden?: boolean
  id?: string
  isRequired?: boolean
}> = ({ children, style, labelHidden = false, id = '', isRequired = false, ...props }) => {
  const classes = classNames('form-label', labelHidden && 'screen-reader')

  return (
    <Text {...props} style={style} className={classes} htmlFor={id}>
      {children}
      {isRequired ? ' *' : ''}
    </Text>
  )
}

// MARK: - Styles

const Text = styled.label`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: -1px;
`
