import { DashboardSectionHeader } from '../../dashboard/DashboardSectionHeader'
import { DateTimePicker } from '../DateTimePicker'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateIcon } from 'src/assets/images/svg/DateIcon'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { Col, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Select } from 'src/features/common/Select'
import { useTheme } from 'styled-components'

export const RoomDatesSection: React.FC<{
  disabled?: boolean
  hideIcon?: boolean
  startDate: number
  endDate: number
  timezone: string
  onStartDateChange: (startDate: number) => void
  onEndDateChange: (endDate: number) => void
  onTimezoneChange: (timezone: string) => void
}> = ({
  disabled = false,
  startDate,
  hideIcon,
  endDate,
  timezone,
  onStartDateChange,
  onEndDateChange,
  onTimezoneChange,
}) => {
  // MARK: - Hooks

  const [timezonePickerVisible, setTimezonePickerVisible] = useState(false)
  const [endTimePickerVisible, setEndTimePickerVisible] = useState(endDate > 0)

  const { t } = useTranslation()
  const { palette } = useTheme()
  const [visiblePicker, setVisiblePicker] = useState<'start' | 'end' | null>(null)

  // MARK: - Render

  return (
    <Col style={{ marginTop: 20, marginBottom: 20 }}>
      <DashboardSectionHeader
        title={t('dateAndTime')}
        description={t('dateAndTimeDescription')}
        Icon={!hideIcon ? DateIcon : undefined}
      />

      <DateTimePicker
        title={t('startTime')}
        date={new Date(startDate * 1000)}
        disabled={disabled}
        forceHide={visiblePicker === 'end'}
        onPickerOpen={() => setVisiblePicker('start')}
        onPickerClose={() => {
          if (visiblePicker === 'start') setVisiblePicker(null)
        }}
        onDateChange={date => onStartDateChange(date)}
      />

      {endTimePickerVisible && (
        <Row>
          <DateTimePicker
            title={t('endTime')}
            style={{ marginTop: -8, width: 420, marginBottom: timezonePickerVisible ? 0 : 4 }}
            date={new Date((endDate || startDate + 3600) * 1000)}
            disabled={disabled}
            forceHide={visiblePicker === 'start'}
            onPickerOpen={() => setVisiblePicker('end')}
            onPickerClose={() => {
              if (visiblePicker === 'end') setVisiblePicker(null)
            }}
            onDateChange={date => onEndDateChange(date)}
          />

          <DeleteIcon
            onClick={() => {
              onEndDateChange(0)
              setEndTimePickerVisible(false)
            }}
            style={{ cursor: 'pointer', marginLeft: 14, padding: 1 }}
          />
        </Row>
      )}

      {!timezonePickerVisible && (
        <Row style={{ marginTop: -4, marginLeft: 1 }}>
          <LabelSmall style={{ marginRight: 5 }}>{t('currentTimezone')}</LabelSmall>
          <LabelSmall style={{ fontWeight: 600, marginRight: 8 }}>{timezone + ' '}</LabelSmall>
          <LabelSmall
            style={{ cursor: 'pointer', fontWeight: 500, color: palette.orange }}
            onClick={() => setTimezonePickerVisible(true)}>
            {t('change')}
          </LabelSmall>
        </Row>
      )}

      {timezonePickerVisible && (
        <Select
          label={t('timezone')}
          style={{ maxWidth: 420, marginTop: -8, marginBottom: 8 }}
          value={timezone}
          onChange={value => onTimezoneChange(value)}>
          {moment.tz.names().map(zone => (
            <option key={zone} value={zone} label={zone} />
          ))}
        </Select>
      )}

      {!endTimePickerVisible && (
        <Row style={{ marginTop: 4, marginLeft: 1 }}>
          <LabelSmall style={{ marginRight: 5 }}>{t('ifYourEventHasAnEndTime')}</LabelSmall>
          <LabelSmall
            style={{ cursor: 'pointer', fontWeight: 500, color: palette.orange }}
            onClick={() => setEndTimePickerVisible(true)}>
            {t('add')}
          </LabelSmall>
        </Row>
      )}
    </Col>
  )
}
