import i18n from '../localization'
import { Localization } from '../types/i18n'
import React from 'react'
import { AudienceIcon } from 'src/assets/images/svg/AudienceIcon'
import { IntegrationIcon } from 'src/assets/images/svg/IntegrationIcon'
import { InvitationIcon } from 'src/assets/images/svg/InvitationIcon'
import { LockIcon } from 'src/assets/images/svg/LockIcon'
import { RoomPrivacyType } from 'src/repository/types'

// remove 'managed' for now
const allPrivacyTypes: RoomPrivacyType[] = ['public', 'invite-only', 'private']

const icon = (privacyType: RoomPrivacyType) => {
  switch (privacyType) {
    case 'private':
      return <LockIcon size={32} />
    case 'managed':
      return <IntegrationIcon size={32} />
    case 'public':
      return <AudienceIcon size={32} />
    case 'invite-only':
      return <InvitationIcon size={29} />
  }
}

const title = (lng: Localization): Record<RoomPrivacyType, string> => ({
  managed: i18n.t('managed', { lng }),
  public: i18n.t('public', { lng }),
  private: i18n.t('private', { lng }),
  'invite-only': i18n.t('inviteOnly', { lng }),
})

const subtitle = (lng: Localization): Record<RoomPrivacyType, string> => ({
  managed: i18n.t('managedDescription', { lng }),
  public: i18n.t('publicDescription', { lng }),
  private: i18n.t('privateDescription', { lng }),
  'invite-only': i18n.t('inviteOnlyDescription', { lng }),
})

export const privacyTypes = {
  all: allPrivacyTypes,
  title: title,
  subtitle: subtitle,
  icon: icon,
}
