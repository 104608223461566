import { LabelHeader, LabelNormal } from './CommonStyles'
import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'src/repository/Error'
import styled from 'styled-components'

export const AlertModal: React.FC<{
  error: IError | null
  visible?: boolean
  hideCancel?: boolean
  onClose?: (okClick: boolean) => void
}> = ({ error, visible = true, hideCancel = false, onClose = () => {} }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Handlers

  const handleBackgroundClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    onClose(false)
  }, [])

  const handleOkayButtonClick = useCallback(() => {
    onClose(true)
  }, [])

  // MARK: - Render

  return error ? (
    <div className={classNames('modal', visible && 'is-active')} onClick={handleBackgroundClick}>
      <Container
        className="modal-inner br-8 center-content pl-16 pr-16 pb-16 no-selection"
        onClick={event => event.stopPropagation()}>
        <LabelHeader style={{ textAlign: 'center' }}>{error.title || t('error')}</LabelHeader>
        <LabelNormal style={{ marginTop: 16, textAlign: 'center' }}>{error.message}</LabelNormal>

        <ButtonContainer className={'button-group'}>
          {!hideCancel && error?.actionTitle && (
            <SecondaryButton
              style={{ minWidth: 180, marginLeft: 8, marginRight: 8 }}
              title={t('cancel')}
              size="sm"
              onClick={handleOkayButtonClick}
            />
          )}

          {!hideCancel && !error?.actionTitle && (
            <PrimaryButton
              style={{ minWidth: 180, marginLeft: 8, marginRight: 8 }}
              title={t('ok')}
              size="sm"
              onClick={handleOkayButtonClick}
            />
          )}

          {error.actionTitle && (
            <PrimaryButton
              style={{ minWidth: 180, marginLeft: 8, marginRight: 8 }}
              title={error.actionTitle}
              size="sm"
              onClick={() => {
                error.completion?.(true)
                onClose(true)
              }}
            />
          )}
        </ButtonContainer>
      </Container>
    </div>
  ) : null
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.background.separator};
  padding: 30px 16px;
`

const ButtonContainer = styled.div`
  margin-top: 30px;
`
