import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CloneIcon } from 'src/assets/images/svg/CloneIcon'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { VisibilityOffIcon } from 'src/assets/images/svg/VisibilityOffIcon'
import { VisibilityOnIcon } from 'src/assets/images/svg/VisibilityOnIcon'
import { LabelMedium, Row } from 'src/features/common/CommonStyles'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { Component } from 'src/repository/types'
import { componentImage } from 'src/utils/helpers/componentHelper'
import styled, { useTheme } from 'styled-components'

export const DashboardComponentSideBarItem: React.FC<{
  component: Component
  index: number
  totalCount: number
  isSelected: boolean
  showDropdown: boolean
  onComponentSelect: () => void
  onComponentDropdownOptionSelect?: (component: Component, option: number) => void
}> = ({
  isSelected,
  index,
  totalCount,
  component,
  showDropdown,
  onComponentSelect,
  onComponentDropdownOptionSelect,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()

  const lastItemDistance = totalCount - index
  const shouldMoveDropdown = lastItemDistance < 3 && totalCount > 2
  const dropdownInset = shouldMoveDropdown ? (3 - lastItemDistance) * -66 : 0
  const ComponentImagePlaceHolder = componentImage(component.type)

  const options = useMemo(() => {
    return [
      { title: t('edit'), icon: EditIcon },
      { title: t('clone'), icon: CloneIcon },
      component.is_active
        ? { title: t('deactivate'), icon: VisibilityOffIcon }
        : { title: t('activate'), icon: VisibilityOnIcon },
      { title: t('delete'), icon: DeleteIcon },
    ]
  }, [component.is_active])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = useCallback(
    (option: number) => {
      onComponentDropdownOptionSelect?.(component, option)
    },
    [component],
  )

  // MARK: - Render

  return (
    <Container isSelected={isSelected} onClick={onComponentSelect} className="no-select">
      <Row>
        {component.image_url ? (
          <Image
            src={component.image_url}
            isSelected={isSelected || !!component.image_url.length}
            width={24}
            height={24}
          />
        ) : (
          <Placeholder
            style={{ marginLeft: isSelected ? -4 : 0 }}
            isSelected={isSelected || !!component.image_url.length}>
            <ComponentImagePlaceHolder
              fill={isSelected ? palette.text.primary : palette.text.secondary}
              width={24}
              height={24}
            />
          </Placeholder>
        )}

        {showDropdown && <Indicator isSelected={isSelected} isActive={component.is_active} />}

        <Title className="text-single-line" isSelected={isSelected}>
          {component.title}
        </Title>
      </Row>

      {isSelected && showDropdown && (
        <DropdownIcon>
          <DropdownWrapper
            style={{ position: 'fixed' }}
            icon={<ThreeDotsIcon size={14} fill={palette.text.primary} />}
            position={{ right: -230, top: 14 + dropdownInset }}
            options={options}
            handleOptionClick={handleOptionsDropdownOptionClick}
          />
        </DropdownIcon>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.background.secondary : theme.palette.background.primary};
  border-left: ${({ isSelected }) => (isSelected ? 4 : 0)}px solid
    ${({ theme }) => theme.palette.orange};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 7px 16px;
  padding-left: ${({ isSelected }) => (isSelected ? 12 : 16)}px;
  position: relative;
`

const Title = styled(LabelMedium)<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
`

const Image = styled.img<{ isSelected: boolean }>`
  height: 24px;
  margin-right: 12px;
  width: 24px;
`

const Placeholder = styled.div<{ isSelected: boolean }>`
  height: 24px;
  margin-right: 8px;
  width: 24px;
`

const Indicator = styled.div<{ isActive: boolean; isSelected: boolean }>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.green : theme.palette.orange};
  border: 1px solid ${({ theme }) => theme.palette.background.primary};
  border-radius: 8px;
  bottom: 4px;
  height: 12px;
  left: ${({ isSelected }) => (isSelected ? 28 : 32)}px;
  position: absolute;
  width: 12px;
`

const DropdownIcon = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: -6px;
  width: 24px;
  z-index: 1;
`
