import { Venue } from './Venue'
import React from 'react'
import styled from 'styled-components'

export const ValidVenuePropertyContainer: React.FC<{ venue: Venue }> = props => {
  // MARK: - Properties

  const { venue } = props

  // MARK: - Render

  return (
    <Container>
      <InfoContainer>
        <VenueName>{venue.title}</VenueName>
        <VenueAddress>{venue.address}</VenueAddress>
      </InfoContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${({ theme }) => theme.palette.background.separator};
  border-style: outset;
  border-top-width: 0px;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const VenueName = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 600;
`

const VenueAddress = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 500;
  margin-top: -6px;
`
