import i18n from 'src/utils/localization'

export interface IError extends Error {
  title?: string
  message: string
  type: ErrorType
  displayable: boolean
  completion: (success: boolean) => void
  actionTitle: string | undefined
}

export enum ErrorType {
  soft = 0,
  hard = 1,
}

export const Error = {
  someThingWentWrong: (completion?: (success: boolean) => void, actionTitle?: string): IError => ({
    name: 'Something went wrong',
    title: 'Oopps!',
    message: i18n.t('somethingWentWrong'),
    type: ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
  displayable: (
    title: string,
    message: string,
    completion?: (success: boolean) => void,
    actionTitle?: string,
  ): IError => ({
    name: 'Displayable',
    title: title,
    message: message,
    type: ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
  default: (
    message: string,
    completion?: (success: boolean) => void,
    actionTitle?: string,
  ): IError => ({
    name: 'Default',
    title: '',
    message: message,
    type: ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
  apiDisplayable: (
    apiError: {
      title?: string
      message: string
      type?: ErrorType
    },
    completion?: (success: boolean) => void,
    actionTitle?: string,
  ): IError => ({
    name: 'Api Displayable',
    title: apiError.title,
    message: apiError.message,
    type: apiError.type ?? ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
  message: (
    message: string,
    completion?: (success: boolean) => void,
    actionTitle?: string,
  ): IError => ({
    name: 'Message',
    message: message,
    type: ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
  accessDenied: (completion?: (success: boolean) => void, actionTitle?: string): IError => ({
    name: 'Access Denied',
    title: 'Access Denied',
    message: 'Your authorization expired, please try again',
    type: ErrorType.hard,
    displayable: true,
    completion: completion ?? (_ => {}),
    actionTitle: actionTitle,
  }),
}
