import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L5 7L12 10L19 7L12 4Z" stroke={fill} strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66009 10.3159L4.80304 11.5404C4.6192 11.6192 4.5 11.7999 4.5 11.9999C4.5 12.2 4.6192 12.3807 4.80304 12.4595L11.803 15.4595C11.9288 15.5134 12.0712 15.5134 12.197 15.4595L19.197 12.4595C19.3808 12.3807 19.5 12.2 19.5 11.9999C19.5 11.7999 19.3808 11.6192 19.197 11.5404L16.3399 10.3159L15.0706 10.8599L17.7307 11.9999L12 14.456L6.2693 11.9999L8.92939 10.8599L7.66009 10.3159Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66009 15.3159L4.80304 16.5404C4.6192 16.6192 4.5 16.7999 4.5 16.9999C4.5 17.2 4.6192 17.3807 4.80304 17.4595L11.803 20.4595C11.9288 20.5134 12.0712 20.5134 12.197 20.4595L19.197 17.4595C19.3808 17.3807 19.5 17.2 19.5 16.9999C19.5 16.7999 19.3808 16.6192 19.197 16.5404L16.3399 15.3159L15.0706 15.8599L17.7307 16.9999L12 19.456L6.2693 16.9999L8.92939 15.8599L7.66009 15.3159Z"
      fill={fill}
    />
  </svg>
)

export const FeaturesIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
