import { AnimatedFooter } from '../common/AnimatedFooter'
import { AccountDangerZone } from './AccountDangerZone'
import { AccountInfo } from './AccountInfo'
import { AccountKeys } from './AccountKeys'
import { AccountLanguage } from './AccountLanguage'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import {
  fetchUserKeys,
  getIsUserEdited,
  getUser,
  getUserKeys,
  refreshUserKeys,
  setUser,
  updateMeUser,
} from 'src/redux/reducers/account'
import { getLoading } from 'src/redux/reducers/app'
import { getMeUser } from 'src/redux/reducers/me'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const AccountModal: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const meUser = useSelect(state => getMeUser(state.me))
  const user = useSelect(state => getUser(state.account))
  const keys = useSelect(state => getUserKeys(state.account))
  const isEdited = useSelect(state => getIsUserEdited(state.account, meUser))
  const isLoading = useSelect(state => getLoading(state.app, 'account'))

  // MARK: - Effects

  useEffect(() => {
    if (meUser && !user) dispatch(setUser(meUser))
    if (meUser && !keys) dispatch(fetchUserKeys(meUser.id))
  }, [meUser?.id, dispatch])

  // MARK: - Handlers

  const handleSaveProfileClick = useCallback(() => {
    dispatch(updateMeUser())
  }, [])

  const handleRefreshAPISecretClick = useCallback(() => {
    if (keys && meUser) dispatch(refreshUserKeys(meUser.id, keys.api_key))
  }, [keys, meUser])

  // MARK: - Render

  return (
    <ModalWrapper title={t('account')} size="tiny" onClose={onClose}>
      {user ? (
        <>
          <AccountInfo
            user={user}
            onImageChange={imageUrl => dispatch(setUser({ ...user, image_url: imageUrl }))}
            onNameChange={firstName => dispatch(setUser({ ...user, first_name: firstName }))}
            onSurnameChange={lastName => dispatch(setUser({ ...user, last_name: lastName }))}
            onUserNameChange={userName => dispatch(setUser({ ...user, user_name: userName }))}
            onEmailChange={email => dispatch(setUser({ ...user, email: email }))}
            onEnterPress={handleSaveProfileClick}
          />

          <AccountKeys
            apiKey={keys?.api_key}
            apiSecret={keys?.api_secret}
            onAPIRefreshSecret={handleRefreshAPISecretClick}
          />

          <AccountLanguage />
          <AccountDangerZone />
        </>
      ) : (
        <PageLoader />
      )}

      <AnimatedFooter visible={isEdited}>
        <PrimaryButton
          style={{ minWidth: 140, marginRight: 24 }}
          title={t('save')}
          isLoading={isLoading}
          onClick={handleSaveProfileClick}
        />
      </AnimatedFooter>
    </ModalWrapper>
  )
}
