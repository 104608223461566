import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 750 750"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M205.48,275.74H398.79a57.31,57.31,0,0,1,57.31,57.31V460.31a10.92,10.92,0,0,1-10.92,10.92H251.87a57.31,57.31,0,0,1-57.31-57.31V286.66A10.92,10.92,0,0,1,205.48,275.74Z"
      fill={fill}
    />
    <path
      d="M539,288.11,471.9,326.84V434L539,472.77a11,11,0,0,0,16.46-9.5V297.62A11,11,0,0,0,539,288.11Z"
      fill={fill}
    />
  </svg>
)

export const ZoomIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
