import { pushError } from 'src/redux/reducers/app'
import { deleteEntities, mergeEntities } from 'src/redux/reducers/entity'
import { Thunk } from 'src/redux/store'
import { chatService } from 'src/repository/services/chatService'
import { ChatChannel } from 'src/repository/types'

export const fetchChatChannels =
  (roomId: string): Thunk =>
  dispatch =>
    chatService
      .fetchChatChannels(roomId, 'topic', 100)
      .loading('chat')
      .done(
        chats => dispatch(mergeEntities({ chat_channel: chats })),
        error => dispatch(pushError(error)),
      )

export const createChatChannel =
  (roomId: string, name: string, imageUrl: string, description: string): Thunk =>
  dispatch =>
    chatService
      .createChatChannel(roomId, name, imageUrl, description, 'topic')
      .loading('chat')
      .done(
        chat => dispatch(mergeEntities({ chat_channel: [chat] })),
        error => dispatch(pushError(error)),
      )

export const updateChatChannel =
  (
    chatChannelId: string,
    name: string,
    imageUrl: string,
    description: string,
    isActive: boolean,
  ): Thunk =>
  dispatch =>
    chatService
      .updateChatChannel(chatChannelId, name, imageUrl, description, isActive)
      .loading('chat')
      .done(
        chat => dispatch(mergeEntities({ chat_channel: [chat] })),
        error => dispatch(pushError(error)),
      )

export const deleteChatChannel =
  (chat: ChatChannel): Thunk =>
  dispatch =>
    chatService
      .deleteChatChannel(chat.id)
      .first(() => dispatch(deleteEntities({ chat_channel: [chat.id] })))
      .done(undefined, error => dispatch(pushError(error)))
