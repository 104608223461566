import { api } from '../api'
import {
  ComponentAnalytics,
  LinkAnalytics,
  NotificationAnalytics,
  OverviewAnalytics,
} from '../types'

export const analyticsService = {
  fetchOverviewAnalytics: async (roomId: string) =>
    api.prop<{ room: OverviewAnalytics }>('room', {
      request: {
        method: 'GET',
        endPoint: '/analytics/overview',
        parameters: { room_id: roomId },
      },
    }),

  fetchOverviewLogs: async (roomId: string, limit: number) =>
    api.prop<{ room: OverviewAnalytics[] }>('room', {
      request: {
        method: 'GET',
        endPoint: '/analytics/overview/logs',
        parameters: { room_id: roomId, limit },
      },
    }),

  fetchLinkAnalytics: async (linkId: string) =>
    api.prop<{ link: LinkAnalytics }>('link', {
      request: {
        method: 'GET',
        endPoint: '/analytics/link',
        parameters: { link_id: linkId },
      },
    }),

  fetchLinkLogs: async (linkId: string, limit: number) =>
    api.prop<{ link: LinkAnalytics[] }>('link', {
      request: {
        method: 'GET',
        endPoint: '/analytics/link/logs',
        parameters: { link_id: linkId, limit },
      },
    }),

  fetchComponentAnalytics: async (componentId: string) =>
    api.prop<{ component: ComponentAnalytics }>('component', {
      request: {
        method: 'GET',
        endPoint: '/analytics/component',
        parameters: { component_id: componentId },
      },
    }),

  fetchComponentLogs: async (componentId: string, limit: number) =>
    api.prop<{ component: ComponentAnalytics[] }>('component', {
      request: {
        method: 'GET',
        endPoint: '/analytics/component/logs',
        parameters: { component_id: componentId, limit },
      },
    }),

  fetchNotificationAnalytics: async (notificationId: string) =>
    api.prop<{ notification: NotificationAnalytics }>('notification', {
      request: {
        method: 'GET',
        endPoint: '/analytics/notification',
        parameters: { notification_id: notificationId },
      },
    }),

  fetchNotificationLogs: async (notificationId: string, limit: number) =>
    api.prop<{ notification: NotificationAnalytics[] }>('notification', {
      request: {
        method: 'GET',
        endPoint: '/analytics/notification/logs',
        parameters: { notification_id: notificationId, limit },
      },
    }),
}
