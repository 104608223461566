import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import styled from 'styled-components'

export const ImageCropper: React.FC<{
  aspectRatio: number
  src?: string
  blob?: undefined
  onSave: (blob: Blob | undefined) => void
  onClose: () => void
}> = ({ aspectRatio, src, onSave, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const imageRef = useRef<HTMLImageElement>()
  const [blob, setBlob] = useState<Blob>()
  const [imageRatio, setImageRatio] = useState<number | null>(null)
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect: aspectRatio,
    unit: '%',
    width: 50,
  })

  // MARK: - Handlers

  const onCropComplete = (imageCrop: ReactCrop.Crop) => {
    makeClientCrop(imageCrop)
  }

  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image
    setImageRatio(image.naturalWidth / image.naturalHeight)
  }

  const onCropChange = (imageCrop: ReactCrop.Crop) => {
    setCrop(imageCrop)
  }

  const makeClientCrop = async (imageCrop: ReactCrop.Crop) => {
    if (imageRef.current && imageCrop.width && imageCrop.height) {
      const imageBlob = await getCroppedImg(imageRef.current, imageCrop)
      setBlob(imageBlob)
    }
  }

  const getCroppedImg = (image: HTMLImageElement, imageCrop: ReactCrop.Crop): Promise<Blob> => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const { width, height, x, y } = imageCrop
    const ctx = canvas.getContext('2d')

    canvas.width = width ?? 0
    canvas.height = height ?? 0

    ctx?.drawImage(
      image,
      (x ?? 0) * scaleX,
      (y ?? 0) * scaleY,
      (width ?? 0) * scaleX,
      (height ?? 0) * scaleY,
      0,
      0,
      width ?? 0,
      height ?? 0,
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(imageBlob => {
        if (!imageBlob) return
        resolve(imageBlob)
      }, 'image/png')
    })
  }

  // MARK: - Render
  const ratio = imageRatio ?? 1
  return (
    <ModalWrapper title={t('cropImage')} size="tiny" onClose={onClose}>
      {src && (
        <CropContainer>
          {imageRatio === null && <PageLoader />}

          <ReactCrop
            key={imageRatio}
            src={src}
            crop={crop}
            ruleOfThirds
            crossorigin={'anonymous'}
            style={{
              alignSelf: 'center',
              maxHeight: ratio > 1 ? 700 / ratio : undefined,
            }}
            imageStyle={{
              objectFit: 'contain',
              alignSelf: 'center',
              maxHeight: `calc(94vh - 176px)`,
              maxWidth: 700,
              opacity: imageRatio === null ? 0 : 1,
              minHeight: ratio <= 1 ? `calc(94vh - 176px)` : undefined,
              minWidth: ratio > 1 ? 700 : undefined,
            }}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
        </CropContainer>
      )}

      <FooterWrapper>
        <SecondaryButton title={t('discard')} onClick={onClose} style={{ marginRight: 12 }} />
        <PrimaryButton
          title={t('save')}
          onClick={() => {
            if (blob) onSave(blob)
          }}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}

// MARK: - Styles

const CropContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 0px solid ${({ theme }) => theme.palette.background.separator};
  border-top-width: 1px;
  display: flex;
  flex: 1;
  height: calc(100% - 10px);
  justify-content: center;
  max-height: calc(100% - 10px);
  overflow: scroll;
  width: 100%;
`
