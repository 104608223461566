import React from 'react'
import { Col, LabelLarge, LabelNormal } from 'src/features/common/CommonStyles'
import { SVG } from 'src/utils/types/svg'

export const DashboardSectionHeader: React.FC<{
  title: string
  description: string
  Icon?: SVG
  isStrokeIcon?: boolean
  style?: React.CSSProperties
}> = ({ style, title, description, Icon, isStrokeIcon }) => {
  // MARK: - Render

  return (
    <Col style={{ marginBottom: 12, ...style }}>
      <LabelLarge
        style={{
          fontWeight: 600,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          marginLeft: Icon ? -30 : 0,
          marginBottom: 0,
        }}>
        {Icon && <Icon style={{ marginRight: 6, maxHeight: 20 }} />}
        {title}
      </LabelLarge>
      <LabelNormal style={{ fontSize: 14, opacity: 0.6 }}>{description}</LabelNormal>
    </Col>
  )
}
