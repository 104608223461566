import { AnnouncementListModal } from './AnnouncementListModal'
import { ComponentTypeSelection } from './ComponentTypeSelection'
import { FAQListModal } from './FAQListModal'
import { PollingModal } from './PollingModal'
import { QAModal } from './QAModal'
import { CreateOrUpdateSpreadGame } from './SpreadGameModal'
import { WebListModal } from './WebListModal'
import { WebPageModal } from './WebPageModal'
import { SaveComponentInput } from './types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import {
  createComponent,
  flushComponentModal,
  getComponent,
  updateComponent,
} from 'src/redux/reducers/component'
import { Component, ComponentType, Room } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardComponentModal: React.FC<{
  room: Room
  componentType: ComponentType | null
  component?: Component
  onComponentSave: (component: Component) => void
  onClose: () => void
}> = ({ room, component, componentType: initialType, onComponentSave, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const savedComponent = useSelect(state => getComponent(state.component))
  const [selectedComponentType, setSelectedComponentType] = useState<ComponentType | null>(
    component?.type ?? initialType,
  )

  // MARK: - Effects

  useEffect(() => {
    if (savedComponent?.id) {
      onComponentSave({ ...savedComponent })
      onClose()
      dispatch(flushComponentModal())
    }
    // eslint-disable-next-line
  }, [savedComponent?.id])

  // MARK: - Handlers

  const handleComponentTypeClick = useCallback((componentType: ComponentType) => {
    setSelectedComponentType(componentType)
  }, [])

  const handleSaveClick = useCallback(
    (saveInput: SaveComponentInput) => {
      const { title, type, overview, image, activated, input } = saveInput
      if (component) {
        dispatch(updateComponent(component.id, title, overview, image, activated, input))
      } else {
        dispatch(
          // @ts-ignore
          createComponent(room.id, type, title, overview, image, activated, input),
        )
      }
    },
    [component, room],
  )

  // MARK: - Render

  const title = useCallback((componentType: ComponentType) => {
    switch (componentType) {
      case ComponentType.announcements:
        return t('announcements')
      case ComponentType.webPage:
        return t('webPage')
      case ComponentType.spreadGame:
        return t('referral')
      case ComponentType.webList:
        return t('webList')
      case ComponentType.faq:
        return t('faq')
      case ComponentType.qa:
        return t('qa')
      case ComponentType.polling:
        return t('polling')
      default:
        return null
    }
  }, [])

  const renderComponentCreation = useCallback(
    (componentType: ComponentType) => {
      const props = {
        component,
        onBackClick: () => setSelectedComponentType(null),
        onSaveClick: handleSaveClick,
      }
      switch (componentType) {
        case ComponentType.announcements:
          return <AnnouncementListModal {...props} />
        case ComponentType.webPage:
          return <WebPageModal {...props} />
        case ComponentType.spreadGame:
          return <CreateOrUpdateSpreadGame {...props} />
        case ComponentType.webList:
          return <WebListModal {...props} />
        case ComponentType.faq:
          return <FAQListModal {...props} />
        case ComponentType.qa:
          return <QAModal {...props} />
        case ComponentType.polling:
          return <PollingModal {...props} />
        default:
          return null
      }
    },
    [component, handleSaveClick, setSelectedComponentType],
  )

  return (
    <ModalWrapper
      title={selectedComponentType === null ? t('addFeature') : title(selectedComponentType)}
      size="tiny"
      onClose={onClose}>
      <FlexCol>
        {selectedComponentType === null ? (
          <ComponentTypeSelection onComponentTypeClick={handleComponentTypeClick} />
        ) : (
          renderComponentCreation(selectedComponentType!)
        )}
      </FlexCol>
    </ModalWrapper>
  )
}
