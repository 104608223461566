import { api } from '../api'
import { Poll } from '../types'

export const pollService = {
  fetchPolls: async (componentId: string) =>
    api.prop<{ polls: Poll[] }>('polls', {
      request: {
        method: 'GET',
        endPoint: '/polling/polls',
        parameters: { component_id: componentId, ignore_status: true },
      },
    }),

  createPoll: async (componentId: string, question: string, options: string[], isActive: boolean) =>
    api.prop<{ poll: Poll }>('poll', {
      request: {
        method: 'POST',
        endPoint: '/polling/poll',
        parameters: {
          component_id: componentId,
          question: question,
          options: options,
          is_active: isActive,
        },
      },
    }),

  updatePoll: async (pollId: string, isActive: boolean) =>
    api.prop<{ poll: Poll }>('poll', {
      request: {
        method: 'PUT',
        endPoint: '/polling/poll',
        parameters: { poll_id: pollId, is_active: isActive },
      },
    }),
}
