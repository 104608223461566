import { useState, useEffect } from 'react'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { ...windowDimensions, isMobile: windowDimensions.width < 1024 }
}

export const deviceWidth = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '640px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  smallerThan: {
    mobileS: `(max-width: ${deviceWidth.mobileS})`,
    mobileM: `(max-width: ${deviceWidth.mobileM})`,
    mobileL: `(max-width: ${deviceWidth.mobileL})`,
    mobileXL: `(max-width: ${deviceWidth.mobileXL})`,
    tablet: `(max-width: ${deviceWidth.tablet})`,
    laptop: `(max-width: ${deviceWidth.laptop})`,
    laptopL: `(max-width: ${deviceWidth.laptopL})`,
    desktop: `(max-width: ${deviceWidth.desktop})`,
    desktopL: `(max-width: ${deviceWidth.desktop})`,
  },
  largerThan: {
    mobileS: `(min-width: ${deviceWidth.mobileS})`,
    mobileM: `(min-width: ${deviceWidth.mobileM})`,
    mobileL: `(min-width: ${deviceWidth.mobileL})`,
    mobileXL: `(min-width: ${deviceWidth.mobileXL})`,
    tablet: `(min-width: ${deviceWidth.tablet})`,
    laptop: `(min-width: ${deviceWidth.laptop})`,
    laptopL: `(min-width: ${deviceWidth.laptopL})`,
    desktop: `(min-width: ${deviceWidth.desktop})`,
    desktopL: `(min-width: ${deviceWidth.desktop})`,
  },
}
