import React from 'react'
import { Link } from 'react-router-dom'
import { LogoIcon } from 'src/assets/images/svg/LogoIcon'

export const Logo: React.FC = () => {
  return (
    <div className="brand">
      <h1 className="m-0">
        <Link to="/creator/rooms">
          <LogoIcon size={48} />
        </Link>
      </h1>
    </div>
  )
}
