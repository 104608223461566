import produce from 'immer'
import { head } from 'lodash'
import { pushError } from 'src/redux/reducers/app'
import { mergeEntities } from 'src/redux/reducers/entity'
import { Action, AsyncThunk, Thunk } from 'src/redux/store'
import { purchaseService } from 'src/repository/services/purchaseService'
import { PurchaseAudienceTier, PurchaseUsageTier, RoomPurchase } from 'src/repository/types'

type State = {
  tiers: {
    audience: PurchaseAudienceTier[]
    featureUsage: PurchaseUsageTier[]
  }
  selectedTiers: {
    audience: PurchaseAudienceTier | null
    featureUsage: PurchaseUsageTier | null
  }
  offer: RoomPurchase | null
  purchase: RoomPurchase | null
  existingPurchases: RoomPurchase[]
  activePurchase: RoomPurchase | null
}

type ActionType =
  | {
      type: 'purchase/setSelectedPurchaseTiers'
      audience?: PurchaseAudienceTier | null
      featureUsage?: PurchaseUsageTier | null
    }
  | {
      type: 'purchase/setPurchaseTiers'
      audience: PurchaseAudienceTier[]
      featureUsage: PurchaseUsageTier[]
    }
  | { type: 'purchase/setPurchase'; purchase: RoomPurchase }
  | { type: 'purchase/setOffer'; offer: RoomPurchase | null }
  | { type: 'purchase/setExistingPurchases'; existingPurchases: RoomPurchase[] }
  | { type: 'purchase/setActivePurchase'; activePurchase: RoomPurchase | null }
  | Action<'purchase/flush'>
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  tiers: { audience: [], featureUsage: [] },
  selectedTiers: { audience: null, featureUsage: null },
  offer: null,
  purchase: null,
  existingPurchases: [],
  activePurchase: null,
}

// MARK: - Reducer

export const purchaseReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'purchase/setPurchase':
      return produce(state, draft => {
        draft.purchase = action.purchase
        return draft
      })

    case 'purchase/setOffer':
      return produce(state, draft => {
        draft.offer = action.offer
        return draft
      })

    case 'purchase/setExistingPurchases':
      return produce(state, draft => {
        draft.existingPurchases = action.existingPurchases
        return draft
      })

    case 'purchase/setActivePurchase':
      return produce(state, draft => {
        draft.activePurchase = action.activePurchase
        return draft
      })

    case 'purchase/setPurchaseTiers':
      return produce(state, draft => {
        draft.tiers = {
          audience: action.audience,
          featureUsage: action.featureUsage,
        }
        return draft
      })

    case 'purchase/setSelectedPurchaseTiers':
      return produce(state, draft => {
        draft.selectedTiers = {
          audience: action.audience ?? draft.selectedTiers.audience,
          featureUsage: action.featureUsage ?? draft.selectedTiers.featureUsage,
        }
        return draft
      })

    case 'purchase/flush':
    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchPurchaseTiers =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    const [tiersResponse, purchasesResponse] = await Promise.all([
      purchaseService.fetchPurchaseTiers(),
      purchaseService.fetchPurchases(roomId),
    ]).loading('purchase')

    if (tiersResponse.success && purchasesResponse.success) {
      let { audience, feature } = tiersResponse.value
      dispatch({ type: 'purchase/setPurchaseTiers', audience: audience, featureUsage: feature })
      dispatch({
        type: 'purchase/setExistingPurchases',
        existingPurchases: purchasesResponse.value.purchases,
      })
      dispatch({
        type: 'purchase/setActivePurchase',
        activePurchase: purchasesResponse.value.activePurchase,
      })

      const isNotFree = (tier: { tier: number }) => !!tier.tier
      const activePurchase = purchasesResponse.value.activePurchase

      if (activePurchase) {
        const { audience_tier, feature_tier } = activePurchase
        audience = audience.filter(isNotFree).filter(({ tier }) => tier >= audience_tier.tier)
        feature = feature.filter(isNotFree).filter(({ tier }) => tier >= feature_tier.tier)

        dispatch(
          setSelectedTiers({
            audience: head(audience),
            featureUsage: head(feature),
          }),
        )
      } else {
        dispatch(
          setSelectedTiers({
            audience: head(audience.filter(isNotFree)),
            featureUsage: head(feature.filter(isNotFree)),
          }),
        )
      }
    }
  }

export const fetchOffer =
  (
    roomId: string,
    audienceTier: PurchaseAudienceTier,
    featureTier: PurchaseUsageTier,
    promoCode: string,
  ): Thunk =>
  dispatch =>
    purchaseService
      .fetchOffer(roomId, audienceTier, featureTier, promoCode)
      .loading('purchase')
      .done(
        offer => dispatch({ type: 'purchase/setOffer', offer }),
        error => dispatch(pushError(error)),
      )

export const executeFreeOffer =
  (offerId: string): Thunk =>
  dispatch =>
    purchaseService
      .executeFreeOffer(offerId)
      .loading('purchase')
      .done(
        ({ purchase, room }) => {
          dispatch({ type: 'purchase/setPurchase', purchase })
          dispatch(mergeEntities({ room: [room] }))
        },
        error => dispatch(pushError(error)),
      )

export const deleteOffer =
  (offerId: string): Thunk =>
  dispatch =>
    purchaseService
      .deleteOffer(offerId)
      .first(() => dispatch({ type: 'purchase/setOffer', offer: null }))
      .loading('purchase')
      .done(undefined, error => dispatch(pushError(error)))

export const setSelectedTiers = (tiers: {
  audience?: PurchaseAudienceTier | null
  featureUsage?: PurchaseUsageTier | null
}): ActionType => ({
  type: 'purchase/setSelectedPurchaseTiers',
  audience: tiers.audience,
  featureUsage: tiers.featureUsage,
})

export const flushPurchase = (): ActionType => ({
  type: 'purchase/flush',
})

// MARK: - Selectors

export const getTiers = (state: State) => {
  return state.tiers
}

export const getExistingPurchases = (state: State): RoomPurchase[] => {
  return state.existingPurchases
}

export const getActivePurchase = (state: State): RoomPurchase | null => {
  return state.activePurchase
}

export const getSelectedTiers = (state: State) => {
  return state.selectedTiers
}

export const getPriceMultiplier = (state: State) => {
  const { featureUsage } = state.selectedTiers
  const featureMultiplier = featureUsage?.price_multiplier ?? 1
  return featureMultiplier
}

export const getFreeTiers = (state: State) => {
  const isFree = ({ tier }: { tier: number }) => !tier
  return {
    audience: head(state.tiers.audience.filter(isFree)),
    featureUsage: head(state.tiers.featureUsage.filter(isFree)),
  }
}

export const getPurchase = (state: State): RoomPurchase | null => {
  return state.purchase
}

export const getOffer = (state: State): RoomPurchase | null => {
  return state.offer
}

export const getIsPurchaseCompleted = (state: State): boolean => {
  return state.purchase?.purchased ?? false
}
