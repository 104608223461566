import { DashboardComponentModalFooter } from './ComponentModalFooter'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol, FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { IconPicker } from 'src/features/platform/common/IconPicker'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading } from 'src/redux/reducers/app'
import { Component, ComponentType } from 'src/repository/types'
import { componentImageUrl } from 'src/utils/helpers/componentHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardComponentModalContainer: React.FC<{
  component?: Component
  componentType: ComponentType
  header: string
  titlePlaceholder: string
  descriptionPlaceholder: string
  onSaveClick: (input: {
    title: string
    overview: string
    image: string
    activated: boolean
  }) => void
  onBackClick: () => void
}> = ({
  component,
  componentType,
  header,
  titlePlaceholder,
  descriptionPlaceholder,
  onSaveClick,
  onBackClick,
  children,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'component'))

  const [title, setTitle] = useState(component?.title ?? '')
  const [imageUrl, setImageUrl] = useState(component?.image_url || componentImageUrl(componentType))
  const [overview, setOverview] = useState(component?.overview ?? '')
  const [activated, setActivated] = useState(component?.is_active ?? true)

  // MARK: - Handlers

  const handleSaveClick = useCallback(() => {
    onSaveClick({ title, overview, image: imageUrl, activated })
  }, [title, overview, imageUrl, activated])

  // MARK: - Render

  return (
    <Container>
      <FlexRow>
        <IconPicker icon={imageUrl} onIconChange={setImageUrl} />
        <FlexCol>
          <Input
            label={t('title')}
            placeholder={titlePlaceholder}
            value={title}
            isRequired
            onChange={text => setTitle(text)}
          />

          <Input
            inputStyle={{ minHeight: 132 }}
            type="textarea"
            label={t('description')}
            placeholder={descriptionPlaceholder}
            value={overview}
            onChange={text => setOverview(text)}
          />
        </FlexCol>
      </FlexRow>

      {children}

      {!component && (
        <InfoSwitch
          title={t('activateImmediately')}
          subtitle={t('activateImmediatelyDescription')}
          checked={activated}
          onSwitchToggle={setActivated}
        />
      )}

      <DashboardComponentModalFooter
        isLoading={isLoading}
        component={component}
        onBackClick={onBackClick}
        onSaveClick={handleSaveClick}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  flex-wrap: wrap;
  padding: 8px 0px 24px 0px;
`
