import { api } from '../api'
import { CoverImage, ImageOrientation } from '../types'

export const imageService = {
  fetchRandomCoverImages: async (count: number, orientation: ImageOrientation) =>
    api.prop<{ images: CoverImage[] }>('images', {
      request: {
        method: 'GET',
        endPoint: '/image/random',
        parameters: { count: count, orientation: orientation },
      },
    }),

  queryCoverImages: async (
    query: string,
    offset: number,
    limit: number,
    orientation: ImageOrientation,
  ) =>
    api.prop<{ images: CoverImage[] }>('images', {
      request: {
        method: 'GET',
        endPoint: '/image/query',
        parameters: {
          query: query,
          offset: offset,
          limit: limit,
          orientation: orientation,
        },
      },
    }),

  trackCoverImageDownload: async (image: CoverImage) =>
    api.prop<{ url: string }>('url', {
      request: {
        method: 'POST',
        endPoint: '/image/track',
        parameters: { download_location: image.download_location },
      },
    }),
}
