import React from 'react'
import { useTranslation } from 'react-i18next'
import { HoverBox, LabelLarge, LabelNormal } from 'src/features/common/CommonStyles'
import { PurchaseAudienceTier } from 'src/repository/types'
import styled, { keyframes } from 'styled-components'

export const PurchaseAudienceTierSelectionItem: React.FC<{
  audienceTier: PurchaseAudienceTier
  isSelected: boolean
  priceMultiplier: number
  currencyLabel: string
  onAudienceTierPurchaseClick: (audienceTier: PurchaseAudienceTier) => void
}> = ({
  audienceTier,
  isSelected,
  priceMultiplier,
  currencyLabel,
  onAudienceTierPurchaseClick,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Container onClick={() => onAudienceTierPurchaseClick(audienceTier)}>
      <ItemContainer selected={isSelected}>
        <LeftContainer>
          <ItemTitle selected={isSelected}>{t(audienceTier.name)}</ItemTitle>
          <ItemSubtitle selected={isSelected}>
            {t('audienceCount', { count: audienceTier.subscription_limit as number })}
          </ItemSubtitle>
        </LeftContainer>

        <RightContainer>
          <ItemPrice selected={isSelected}>
            {(audienceTier.base_price.price * priceMultiplier).toFixed(0) + currencyLabel}
          </ItemPrice>
        </RightContainer>
      </ItemContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-bottom: 20px;
  width: 49%;
`

const ShimmerKeyframe = keyframes`
  0% { background-position: -400px top; }
  70% { background-position: 400px top; }
  100% { background-position: 400px top; }
`

const ItemContainer = styled(HoverBox)<{ selected: boolean }>`
  align-items: center;
  animation-duration: 2.2s;
  animation-iteration-count: 1;
  animation-name: ${({ selected }) => (selected ? ShimmerKeyframe : '')};
  background: #f36538 -webkit-gradient(linear, 100% 0, 0 0, from(#f36538), color-stop(0.5, rgba(255, 255, 255, 0.2)), to(#f36538));
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.orange : theme.palette.background.primary};
  background-position: -400px;
  background-repeat: no-repeat;
  background-size: ${({ selected }) => (selected ? '80% 100%' : '0px 0px')};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0px 16px 8px 16px;
  width: 100%;
`

const LeftContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RightContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`

const ItemTitle = styled(LabelLarge)<{ selected: boolean }>`
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  font-size: 18px;
  margin-bottom: 2px;
  margin-top: 10px;
`

const ItemPrice = styled(LabelLarge)<{ selected: boolean }>`
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  font-size: 18px;
  margin-top: 0px;
`

const ItemSubtitle = styled(LabelNormal)<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.palette.white : theme.palette.text.secondary};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  text-align: left;
  white-space: pre-wrap;
`
