import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FlexCol, Row } from 'src/features/common/CommonStyles'
import { FormLabel } from 'src/features/common/FormLabel'
import { Input } from 'src/features/common/Input'
import { ImageUploader } from 'src/features/platform/common/ImageUploader'
import { Banner } from 'src/repository/types'
import appConfig from 'src/utils/config'

export const BannerInput: React.FC<{
  banners: Banner[]
  onBannersChange: (banners: Banner[]) => void
}> = ({ banners, onBannersChange }) => {
  // MARK: - Hooks

  const { sizes } = appConfig
  const { t } = useTranslation()

  // MARK: - Handlers

  const handleChangeBanner = useCallback(
    (index: number, link: string, imageUrl: string) => {
      if (!link && !imageUrl) {
        const copy = [...banners]
        onBannersChange(copy.splice(index, 1))
        return
      }

      const copy = [...banners]
      if (copy.length > index) copy[index] = { link, image_url: imageUrl }
      else copy.push({ link, image_url: imageUrl })
      onBannersChange(copy)
    },
    [banners],
  )

  // MARK: - Render

  return (
    <FlexCol style={{ marginTop: 6 }}>
      <FormLabel>{t('banners')}</FormLabel>

      <Row style={{ overflow: 'scroll' }}>
        {[...banners, { id: '', link: '', image_url: '' }].map((banner, index) => (
          <Col key={index} style={{ marginRight: 32 }}>
            <ImageUploader
              sizes={[sizes.webBannerImage]}
              title={''}
              description={t('dragDrop', { ratio: '(106:46)' })}
              initialImageUrl={banner.image_url}
              restriction={t('imageSizeLimit')}
              aspectRatio={106 / 46}
              containerStyle={{ minHeight: 215, minWidth: 520 }}
              style={{ height: 215, width: 520 }}
              onImageUploaded={urls =>
                handleChangeBanner(index, banner.link, urls[sizes.webBannerImage] ?? '')
              }
              onImageRemoved={() => {
                const copy = [...banners]
                copy.splice(index, 1)
                onBannersChange(copy)
              }}
            />
            <Input
              style={{ marginTop: 12, marginRight: banner.image_url ? 32 : 0 }}
              placeholder={t('bannerLinkPlaceholder')}
              value={banner.link}
              onChange={text => handleChangeBanner(index, text, banner.image_url)}
            />
          </Col>
        ))}
      </Row>
    </FlexCol>
  )
}
