import React from 'react'
import { PageLoader } from 'src/features/common/PageLoader'
import styled from 'styled-components'

export const LoadingWrapper: React.FC<{
  isLoading: boolean
  style?: React.CSSProperties
}> = ({ isLoading, style, children }) => {
  return isLoading ? (
    <Container style={style}>
      <PageLoader />
    </Container>
  ) : (
    <>{children}</>
  )
}

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 50vh;
`
