import { CreateRoom } from '../CreateRoom'
import { RoomCloneModal } from './RoomCloneModal'
import { RoomListHeader } from './RoomListHeader'
import { RoomListItem } from './RoomListItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { FlexCol, LabelHeader, LabelNormal } from 'src/features/common/CommonStyles'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { flush } from 'src/redux/reducers/createRoom'
import { getEntities } from 'src/redux/reducers/entity'
import { getIsAuthenticated, getMeId } from 'src/redux/reducers/me'
import {
  cloneRoom,
  deleteRoom,
  fetchCollaboratedRooms,
  getIsLoading,
} from 'src/redux/reducers/roomList'
import { Error } from 'src/repository/Error'
import {
  Collaborator,
  Link,
  Room,
  RoomDateSegment,
  RoomPrivacyType,
  RoomStatus,
} from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const RoomList: React.FC = () => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate, routes } = useNavigation()

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isLoading = useSelect(state => getIsLoading(state.roomList))
  const meUserId = useSelect(state => getMeId(state.me))

  const [cloneCandidate, setCloneCandidate] = useState<Room | null>(null)
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<RoomStatus | 'all'>('all')
  const [deleteCandidate, setDeleteCandidate] = useState<Room | null>(null)
  const [dateSegment, setDateSegment] = useState<RoomDateSegment | 'all'>('all')

  const collaborators = useSelect(state =>
    getEntities<Collaborator>(
      state.entity,
      'collaborator',
      collaborator => collaborator.user_id === meUserId,
    ),
  )
  const rooms = useSelect(state =>
    getEntities<Room>(state.entity, 'room', room => {
      const ownFilter = !!collaborators.find(({ room_id }) => room.id === room_id)
      const queryFilter = room.title.toLowerCase().includes(searchText.toLowerCase())
      const statusFilter = room.status === status || status === 'all'
      const dateSegmentFilter = (() => {
        const timestamp = Math.floor(new Date().getTime() / 1000)
        if (dateSegment === 'all') return true
        else if (dateSegment === 'past') return room.start_date < timestamp
        else return room.start_date > timestamp
      })()

      return ownFilter && queryFilter && statusFilter && dateSegmentFilter
    }).sort((a, b) => b.created_at - a.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchCollaboratedRooms())
  }, [isAuthenticated])

  // MARK: - Handlers

  const handleSearchTextChange = (text: string) => {
    setSearchText(text)
  }

  const handleStatusChange = (roomStatus: RoomStatus | 'all') => {
    setStatus(roomStatus)
  }

  const handleDateSegmentChange = (segment: RoomDateSegment | 'all') => {
    setDateSegment(segment)
  }

  const handleRoomSelect = (room: Room) => {
    navigate(routes.dashboard(room.id).info)
  }

  const handleRoomDropdownOptionSelect = (room: Room, option: number) => {
    if (option === 0) {
      navigate(routes.dashboard(room.id).info)
    } else if (option === 1) {
      setCloneCandidate(room)
    } else if (option === 2) {
      setDeleteCandidate(room)
    }
  }

  const handleCloneClick = (
    title: string,
    overview: string,
    start: number,
    end: number,
    links: Link[],
    privacyType: RoomPrivacyType,
    accessCode: string,
    apiKey: string | null,
    apiSecret: string | null,
    imageUrl?: string,
    imageOffset?: number,
  ) => {
    if (!cloneCandidate) return
    const clone: Room = {
      ...cloneCandidate,
      title,
      overview,
      links: links,
      privacy_type: privacyType,
      access_codes: [accessCode],
      start_date: start,
      end_date: end,
      main_media: { url: imageUrl ?? '', offset: imageOffset ?? 0 },
    }

    dispatch(cloneRoom(clone, apiKey, apiSecret))
    setCloneCandidate(null)
  }

  // MARK: - Render

  return (
    <FlexCol className="container">
      <RoomListHeader
        isLoading={isLoading && !!rooms.length}
        onSearchTextChange={handleSearchTextChange}
        onStatusChange={handleStatusChange}
        onDateSegmentChange={handleDateSegmentChange}
      />

      <InnerContainer>
        {isLoading && !rooms.length ? (
          <PageLoader />
        ) : rooms.length ? (
          rooms.map(room => (
            <RoomListItem
              key={room.id}
              room={room}
              onRoomSelect={handleRoomSelect}
              onRoomDropdownOptionSelect={handleRoomDropdownOptionSelect}
            />
          ))
        ) : (
          <EmptyViewContainer>
            <LabelHeader style={{ marginBottom: 20 }}>{t('letsCreateRoom')}</LabelHeader>
            <LabelNormal style={{ fontSize: 16, marginBottom: 30 }}>
              {t('letsCreateRoomDescription')}
            </LabelNormal>
            <PrimaryButton
              title={t('createProject')}
              onClick={() => setShowCreateRoomModal(true)}
            />
          </EmptyViewContainer>
        )}
      </InnerContainer>

      <AlertModal
        error={Error.displayable(
          t('deleteRoom'),
          t('deleteRoomMessage'),
          success => {
            if (success && deleteCandidate) {
              dispatch(deleteRoom(deleteCandidate))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidate}
        onClose={() => setDeleteCandidate(null)}
      />

      <CreateRoom
        visible={showCreateRoomModal}
        onClose={() => {
          dispatch(flush())
          setShowCreateRoomModal(false)
        }}
      />

      {cloneCandidate && (
        <RoomCloneModal
          isLoading={isLoading}
          room={cloneCandidate}
          onCloneClick={handleCloneClick}
          onClose={() => setCloneCandidate(null)}
        />
      )}
    </FlexCol>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const EmptyViewContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 200px;
  width: 100%;
`
