import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getFirstEntity } from 'src/redux/reducers/entity'
import { AnnouncementList, Component, ComponentType } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

export const AnnouncementListModal: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const announcementList = useSelect(state =>
    getFirstEntity<AnnouncementList>(
      state.entity,
      'announcement_list',
      item => item.component_id === component?.id,
    ),
  )
  const [notificationEnabled, setNotificationEnabled] = useState(
    announcementList?.is_notification_enabled ?? true,
  )

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.announcements}
      component={component}
      header={t('announcements')}
      titlePlaceholder={t('announcementListTitlePlaceholder')}
      descriptionPlaceholder={t('announcementListDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.announcements,
          input: {
            key: 'announcement_list',
            is_notification_enabled: notificationEnabled,
          },
        })
      }>
      <InfoSwitch
        title={t('enableNotifications')}
        subtitle={t('enabledNotificationsDescription')}
        checked={notificationEnabled}
        onSwitchToggle={setNotificationEnabled}
      />
    </DashboardComponentModalContainer>
  )
}
