import { Checkbox } from './Checkbox'
import { FlexRow, LabelMedium, RoundedButton, Row } from './CommonStyles'
import { DropdownWrapper } from './DropdownWrapper'
import { FileInput } from './FileInput'
import { Hint } from './Hint'
import { Input } from './Input'
import { Loader } from './Loader'
import { PrimaryButton } from './PrimaryButton'
import { Select } from './Select'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { DownloadIcon } from 'src/assets/images/svg/DownloadIcon'
import { PublishIcon } from 'src/assets/images/svg/PublishIcon'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { ThreeDotsIcon } from 'src/assets/images/svg/ThreeDotsIcon'
import { getLoading } from 'src/redux/reducers/app'
import { useSelect } from 'src/utils/hooks/useSelect'
import { device } from 'src/utils/hooks/useWindowDimensions'
import { SVG } from 'src/utils/types/svg'
import styled from 'styled-components'

export const DashboardTableHeader: React.FC<{
  primaryActionTitle: string
  searchText?: string
  status?: string
  importTitle?: string
  exportTitle?: string
  deleteTitle?: string
  statusOptions?: { key: string; label: string }[]
  selectTitle?: string
  exportLoadingKey?: string
  columns: { label: string; width: number }[]
  totalCount: number
  selectedCount: number
  dropdownOptions: { title: string; icon: SVG }[]
  setSearchText?: (text: string) => void
  setStatus?: (status: string) => void
  onExportClick?: () => void
  onDeleteClick?: () => void
  onImportClick?: (file: File) => void
  onPrimaryActionClick: () => void
  toggleAllSelected: (isOn: boolean) => void
  handleBulkDropdownOptionSelect: (option: number) => void
}> = ({
  primaryActionTitle,
  searchText,
  status,
  importTitle,
  exportTitle,
  selectTitle,
  deleteTitle,
  exportLoadingKey,
  statusOptions,
  totalCount,
  selectedCount,
  onExportClick,
  onDeleteClick,
  onImportClick,
  columns,
  onPrimaryActionClick,
  setSearchText,
  setStatus,
  toggleAllSelected,
  dropdownOptions,
  handleBulkDropdownOptionSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const isAllSelected = !!totalCount && selectedCount === totalCount
  const isExportLoading = useSelect(state => getLoading(state.app, exportLoadingKey || 'export'))

  // MARK: - Handlers

  const handleSelectAllClick = useCallback(() => {
    toggleAllSelected(!isAllSelected)
  }, [isAllSelected, toggleAllSelected])

  const handleExportClick = useCallback(() => {
    if (!isExportLoading) onExportClick?.()
  }, [isExportLoading, onExportClick])

  // MARK: - Render

  return (
    <div>
      <HeaderContainer>
        <ButtonContainer>
          <PrimaryButton title={primaryActionTitle} onClick={onPrimaryActionClick} />

          <Row>
            {selectedCount === 0 && onImportClick && (
              <Hint hint={importTitle} width={200} offset={{ top: 40 }} delay={500}>
                <FileInput onFileSelect={onImportClick}>
                  <RoundedButton size={40} style={{ marginLeft: 16 }}>
                    <PublishIcon />
                  </RoundedButton>
                </FileInput>
              </Hint>
            )}

            {onExportClick && (
              <Hint hint={exportTitle} width={200} offset={{ top: 40 }} delay={500}>
                <RoundedButton onClick={handleExportClick} size={40} style={{ marginLeft: 12 }}>
                  {isExportLoading ? <Loader size={16} thickness={2} /> : <DownloadIcon />}
                </RoundedButton>
              </Hint>
            )}

            {selectedCount > 0 && (
              <>
                <Hint hint={deleteTitle} width={200} offset={{ top: 40 }} delay={500}>
                  <RoundedButton onClick={onDeleteClick} size={40} style={{ marginLeft: 12 }}>
                    <DeleteIcon />
                  </RoundedButton>
                </Hint>

                <DropdownWrapper
                  style={{ position: 'relative', marginLeft: 12 }}
                  icon={
                    <RoundedButton size={40} style={{ width: 120 }}>
                      <LabelMedium style={{ paddingRight: 6 }}>
                        {t('selected', { number: selectedCount })}
                      </LabelMedium>
                      <ThreeDotsIcon size={12} />
                    </RoundedButton>
                  }
                  position={{ right: 20, top: 44 }}
                  options={dropdownOptions}
                  handleOptionClick={handleBulkDropdownOptionSelect}
                />
              </>
            )}
          </Row>
        </ButtonContainer>

        <InputContainer>
          {setSearchText ? (
            <TextContainer style={{ marginRight: '2%' }}>
              <Input
                type="search"
                placeholder={t('search')}
                hasIcon="right"
                value={searchText}
                onChange={setSearchText}>
                <SearchIcon size={20} />
              </Input>
            </TextContainer>
          ) : null}

          {statusOptions ? (
            <TextContainer>
              <Select placeholder={selectTitle || t('status')} value={status} onChange={setStatus}>
                {statusOptions.map(item => (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </TextContainer>
          ) : null}
        </InputContainer>
      </HeaderContainer>

      <TableHeaderContainer>
        <div style={{ marginBottom: 4 }}>
          <Checkbox checked={isAllSelected} onChange={handleSelectAllClick} />
        </div>

        {columns.map((column, index) => (
          <Column
            key={index}
            style={{
              marginLeft: index === 0 ? 16 : 0,
              marginRight: index === columns.length - 1 ? 48 : 0,
            }}
            minWidth={column.width}
            flex={index === 0 ? 1 : 0}>
            {column.label}
          </Column>
        ))}
      </TableHeaderContainer>
    </div>
  )
}

// MARK: - Styles

const HeaderContainer = styled(FlexRow)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 16px 20px 0px 12px;

  @media ${device.smallerThan.tablet} {
    flex-direction: column;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;

  @media ${device.smallerThan.tablet} {
    justify-content: space-between;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`

const TextContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 0px;
  margin-right: 2%;

  @media ${device.smallerThan.tablet} {
    flex-basis: 49%;
    margin-top: 12px;
    margin-right: 0%;
  }
`

const TableHeaderContainer = styled.div`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  height: 60px;
  margin-top: 20px;
  padding: 10px 16px 10px 16px;
`

const Column = styled.div<{ minWidth: number; flex?: number }>`
  color: ${({ theme }) => theme.palette.text.primary};
  flex: ${({ flex }) => flex ?? 0};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  min-width: ${({ minWidth }) => minWidth}px;
`
