import { GoogleMapMarker } from './GoogleMapMarker'
import GoogleMapReact from 'google-map-react'
import React from 'react'
import config from 'src/utils/config'
import styled, { useTheme } from 'styled-components'

export const GoogleMap: React.FC<{
  center: { lng: number; lat: number }
  zoom?: number
  style?: React.CSSProperties
  circleRadius?: number
}> = ({ center, zoom, style, circleRadius }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Container style={style}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.firebaseConfig.apiKey }}
        center={center}
        onGoogleApiLoaded={({ map, maps }) =>
          circleRadius
            ? new google.maps.Circle({
                strokeColor: palette.orange,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: palette.orange,
                fillOpacity: 0.3,
                map,
                center: center,
                radius: circleRadius,
              })
            : null
        }
        yesIWantToUseGoogleMapApiInternals
        options={{
          clickableIcons: false,
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          keyboardShortcuts: false,
          styles: [
            { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
            {
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#242f3e' }],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [{ color: '#746855' }],
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{ color: '#263c3f' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#6b9a76' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{ color: '#38414e' }],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#212a37' }],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#9ca5b3' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{ color: '#746855' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#1f2835' }],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#f3d19c' }],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{ color: '#2f3948' }],
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#d59563' }],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#17263c' }],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#515c6d' }],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#17263c' }],
            },
          ],
        }}
        draggable={false}
        defaultZoom={zoom ?? 15}>
        <GoogleMapMarker lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  height: 200px;
  width: 100%;
`
