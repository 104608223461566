import { api } from '../api'
import { ChatChannel, ChatChannelType } from '../types'

export const chatService = {
  fetchChatChannels: async (roomId: string, type: ChatChannelType, limit: number) =>
    api.prop<{ chat_channels: ChatChannel[] }>('chat_channels', {
      request: {
        method: 'GET',
        endPoint: '/chatChannel/all',
        parameters: { room_id: roomId, type: type, limit: limit },
      },
    }),

  createChatChannel: async (
    roomId: string,
    name: string,
    imageUrl: string,
    description: string,
    type: ChatChannelType,
  ) =>
    api.prop<{ chat_channel: ChatChannel }>('chat_channel', {
      request: {
        method: 'POST',
        endPoint: '/chatChannel',
        parameters: {
          room_id: roomId,
          name: name,
          image_url: imageUrl,
          description: description,
          type: type,
        },
      },
    }),

  updateChatChannel: async (
    chatChannelId: string,
    name: string,
    imageUrl: string,
    description: string,
    isActive: boolean,
  ) =>
    api.prop<{ chat_channel: ChatChannel }>('chat_channel', {
      request: {
        method: 'PUT',
        endPoint: '/chatChannel',
        parameters: {
          chat_channel_id: chatChannelId,
          name: name,
          image_url: imageUrl,
          description: description,
          is_active: isActive,
        },
      },
    }),

  deleteChatChannel: async (chatChannelId: string) =>
    api.prop<{ chat_channel: ChatChannel }>('chat_channel', {
      request: {
        method: 'DELETE',
        endPoint: '/chatChannel',
        parameters: { chat_channel_id: chatChannelId },
      },
    }),
}
