import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81702 16.2456C6.11379 16.6778 6.45649 17.0799 6.84073 17.4441C7.89279 18.4412 9.21126 19.1121 10.6366 19.3756C12.062 19.6392 13.5332 19.484 14.8722 18.9289C16.2113 18.3738 17.3607 17.4426 18.1816 16.2479C19.0025 15.0532 19.4595 13.6462 19.4975 12.1971C19.5355 10.7481 19.1528 9.31913 18.3956 8.08306C17.6385 6.84699 16.5394 5.85682 15.2313 5.23233C14.3961 4.83363 13.4986 4.5949 12.5848 4.52344L12.862 5.55801C13.5308 5.64749 14.1852 5.84104 14.8004 6.13477C15.9341 6.67599 16.8867 7.53414 17.5429 8.6054C18.1991 9.67666 18.5308 10.9151 18.4979 12.1709C18.4649 13.4268 18.0688 14.6461 17.3574 15.6816C16.646 16.717 15.6498 17.524 14.4893 18.0051C13.3288 18.4862 12.0538 18.6207 10.8184 18.3923C9.5831 18.1639 8.44043 17.5825 7.52865 16.7183C7.28283 16.4853 7.05664 16.2344 6.85159 15.9683L5.81702 16.2456Z"
      fill={fill}
    />
    <path
      d="M5.91239 4.06647C6.68924 3.47037 7.54796 2.99272 8.46042 2.64739C8.87978 2.48868 9.08946 2.40932 9.28694 2.51053C9.48442 2.61174 9.54649 2.84338 9.67063 3.30667L11.7412 11.0341C11.8632 11.4894 11.9242 11.7171 11.8206 11.8964C11.7171 12.0758 11.4894 12.1368 11.0341 12.2588L3.30667 14.3294C2.84338 14.4535 2.61174 14.5156 2.42535 14.3952C2.23896 14.2747 2.20284 14.0535 2.13061 13.6109C1.97344 12.6481 1.95774 11.6656 2.08555 10.6947C2.25696 9.39275 2.68314 8.13728 3.33975 7C3.99636 5.86272 4.87054 4.8659 5.91239 4.06647Z"
      stroke={fill}
    />
  </svg>
)

export const AnalyticsIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
