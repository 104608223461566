import { Col, FlexCol } from './CommonStyles'
import { Footer } from './layout/Footer'
import { Header } from './layout/Header'
import { getAuth } from '@firebase/auth'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getLoading } from 'src/redux/reducers/app'
import { loginSilently } from 'src/redux/reducers/authentication'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import { appTheme } from 'src/utils/theme/appTheme'
import styled, { ThemeProvider } from 'styled-components'

export const LayoutDefault: React.FC = ({ children }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()
  const shouldNavigateToCreator = useRef(false)
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const [isLocalLoading, setIsLocalLoading] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    if (isAuthenticated && shouldNavigateToCreator.current) {
      navigate(routes.roomList)
      shouldNavigateToCreator.current = false
    }
  }, [isAuthenticated, isLocalLoading])

  // MARK: - Handlers

  const handleCreatorLoginClick = () => {
    setIsLocalLoading(true)
    shouldNavigateToCreator.current = true
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (isAuthenticated || isLoading) {
        unsubscribe()
        return
      }

      if (user?.email || user?.phoneNumber) {
        dispatch(loginSilently(user?.email, user?.phoneNumber, null))
      } else {
        navigate(routes.authentication.continue)
      }
      unsubscribe()
      setIsLocalLoading(false)
    })
  }

  // MARK: - Render

  return (
    <Container>
      <ThemeProvider theme={appTheme('light')}>
        <Header
          isLoggingIn={isLoading || isLocalLoading}
          onCreatorLoginClick={handleCreatorLoginClick}
        />
      </ThemeProvider>

      <FlexCol>{children}</FlexCol>

      <Footer />
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  min-height: 100vh;
`
