import React from 'react'
import { Motion, spring } from 'react-motion'
import styled from 'styled-components'

export const AnimatedFooter: React.FC<{
  visible: boolean
  style?: React.CSSProperties
}> = ({ visible, children, style }) => (
  <Motion style={{ y: spring(visible ? 0 : 72) }}>
    {({ y }) => (
      <Container
        style={{
          display: visible ? 'flex' : 'none',
          WebkitTransform: `translate3d(0, ${y}px, 0)`,
          transform: `translate3d(0, ${y}px, 0)`,
          ...style,
        }}>
        <InnerContainer>{children}</InnerContainer>
      </Container>
    )}
  </Motion>
)

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-top: 1px solid ${({ theme }) => theme.palette.background.separator};
  bottom: 0px;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: center;
  left: 0px;
  padding-left: 20%;
  position: absolute;
  right: 0px;
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  width: 1200px;
`
