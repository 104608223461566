import { CoverImage } from '../common/CoverImage'
import { RoomDatesSection } from '../common/RoomSections/RoomDatesSection'
import { RoomDescriptionSection } from '../common/RoomSections/RoomDescriptionSection'
import { RoomPrivacySection } from '../common/RoomSections/RoomPrivacySection'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Row } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import {
  getKey,
  getPrivateKeyPair,
  removePrivateKeyPair,
  setError,
} from 'src/redux/reducers/roomList'
import { Error } from 'src/repository/Error'
import { Link, Room, RoomPrivacyType } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const RoomCloneModal: React.FC<{
  room: Room
  isLoading: boolean
  onCloneClick: (
    title: string,
    overview: string,
    start: number,
    end: number,
    links: Link[],
    privacyType: RoomPrivacyType,
    accessCode: string,
    apiKey: string | null,
    apiSecret: string | null,
    imageUrl?: string,
    imageOffset?: number,
  ) => void
  onClose: () => void
}> = ({ isLoading, room, onCloneClick, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const key = useSelect(state => getKey(state.roomList))

  const [coverUrl, setCoverUrl] = useState<string | undefined>(room.main_media.url ?? '')
  const [coverOffset, setCoverOffset] = useState(room.main_media.offset ?? 0)

  const [startDate, setStartDate] = useState(room.start_date)
  const [endDate, setEndDate] = useState(room.end_date)
  const [timezone, setTimezone] = useState(room.timezone ?? moment.tz.guess())
  const [title, setTitle] = useState(room.title)
  const [description, setDescription] = useState(room.overview)
  const [privacyType, setPrivacyType] = useState<RoomPrivacyType>(room.privacy_type)
  const [accessCode, setAccessCode] = useState<string>(room.access_codes[0] ?? '')

  // MARK: - Effects

  useEffect(() => {
    if (privacyType === 'managed' && !key) {
      dispatch(getPrivateKeyPair())
    }
  }, [privacyType, key, dispatch])

  // MARK: - Handlers

  const handleCloneClick = () => {
    if (privacyType === 'managed' && (!key?.api_key || !key?.api_secret)) {
      const error = Error.displayable(
        t('invalidInput'),
        'Please fill privacy fields to create lobby.',
      )
      dispatch(setError(error))
    } else if (privacyType === 'private' && !accessCode.length) {
      const error = Error.displayable(
        t('invalidInput'),
        'Please fill access code to create private lobby.',
      )
      dispatch(setError(error))
    } else if (!title || !description) {
      const error = Error.displayable(
        t('invalidInput'),
        'Please fill description fields to create lobby.',
      )
      dispatch(setError(error))
    } else if (!startDate) {
      const error = Error.displayable(t('invalidInput'), 'Please fill start date to create lobby.')
      dispatch(setError(error))
    } else {
      onCloneClick(
        title,
        description,
        startDate,
        endDate,
        room.links.map(link => ({ ...link, id: '' })),
        privacyType,
        accessCode,
        key?.api_key ?? null,
        key?.api_secret ?? null,
        coverUrl,
        coverOffset,
      )
      dispatch(removePrivateKeyPair())
    }
  }

  const handleClose = useCallback(() => {
    dispatch(removePrivateKeyPair())
    onClose()
  }, [])

  // MARK: - Render

  return (
    <ModalWrapper title={t('cloneRoom')} size={'tiny'} onClose={handleClose}>
      <Row>
        <CoverImage
          orientation="landscape"
          style={{ borderRadius: 12, overflow: 'hidden', minHeight: 260 }}
          initialCover={coverUrl}
          preferredContainerHeight={240}
          initialOffset={coverOffset}
          onCoverImageChange={setCoverUrl}
          onCoverOffsetChange={setCoverOffset}
          editEnabled
        />
      </Row>

      <RoomDescriptionSection
        title={title}
        description={description}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
      />

      <RoomDatesSection
        hideIcon
        startDate={startDate}
        endDate={endDate}
        timezone={timezone}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onTimezoneChange={setTimezone}
      />

      <RoomPrivacySection
        hideIcon
        apiKey={key?.api_key ?? ''}
        apiSecret={key?.api_secret ?? ''}
        accessCode={accessCode}
        selectedPrivacyType={privacyType}
        onAccessCodeChange={setAccessCode}
        onPrivacyChange={setPrivacyType}
      />

      <FooterWrapper>
        {!isLoading && (
          <SecondaryButton
            style={{ marginRight: 16 }}
            title={'Cancel'}
            isLoading={isLoading}
            onClick={handleClose}
          />
        )}

        <PrimaryButton
          style={{ marginRight: 40, minWidth: 180 }}
          title={'Clone'}
          isLoading={isLoading}
          onClick={handleCloneClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
