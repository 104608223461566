import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { FAQ } from 'src/repository/types'

export const DashboardFAQTable: React.FC<{
  faqs: FAQ[]
  isLoading: boolean
  onCreateFAQClick: () => void
  handleFAQDropdownItemSelect: (index: number, faq: FAQ) => void
  handleFAQBulkDropdownItemSelect: (index: number, faqs: FAQ[]) => void
}> = ({
  faqs,
  isLoading,
  onCreateFAQClick,
  handleFAQDropdownItemSelect,
  handleFAQBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [selectedFAQIds, setSelectedFAQIds] = useState<string[]>([])

  // MARK: - Properties

  const currentFAQs = faqs.filter(faq =>
    (faq.question + faq.answer).toLowerCase().includes(searchText.toLowerCase()),
  )

  // MARK: - Effects

  useEffect(() => {
    setSelectedFAQIds(prev => {
      const faqIds = faqs.map(item => item.id)
      return prev.filter(id => faqIds.includes(id))
    })
  }, [faqs])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleFAQBulkDropdownItemSelect(
      option,
      faqs.filter(faq => selectedFAQIds.includes(faq.id)),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('createQuestion')}
        onPrimaryActionClick={onCreateFAQClick}
        searchText={searchText}
        setSearchText={setSearchText}
        columns={[{ label: t('overview'), width: 250 }]}
        dropdownOptions={[{ title: t('delete'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentFAQs.length}
        selectedCount={selectedFAQIds.length}
        toggleAllSelected={isOn => {
          setSelectedFAQIds(isOn ? currentFAQs.map(item => item.id) : [])
        }}
      />

      <DashboardTable
        data={currentFAQs}
        isLoading={isLoading}
        emptyStateTitle={t('noItemAdded')}
        emptyStateSubtitle={t('onceItemAdded')}
        renderItem={(faq: FAQ, index: number) => (
          <DashboardTableRow
            key={faq.id}
            index={index}
            title={faq.question}
            description={faq.answer}
            dropdownOptions={[
              { title: t('edit'), icon: EditIcon },
              { title: t('delete'), icon: DeleteIcon },
            ]}
            handleOptionsDropdownOptionClick={option => handleFAQDropdownItemSelect(option, faq)}
            selected={selectedFAQIds.includes(faq.id)}
            onCheckBoxClick={isOn => {
              setSelectedFAQIds(prev =>
                isOn ? [...prev, faq.id] : prev.filter(item => faq.id !== item),
              )
            }}
          />
        )}
      />
    </DashboardTableContainer>
  )
}
