import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { VisibilityOffIcon } from 'src/assets/images/svg/VisibilityOffIcon'
import { VisibilityOnIcon } from 'src/assets/images/svg/VisibilityOnIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { ChatChannel } from 'src/repository/types'
import { useTheme } from 'styled-components'

export const DashboardChatTable: React.FC<{
  chatChannels: ChatChannel[]
  isLoading: boolean
  onCreateChatChannelClick: () => void
  handleChatChannelDropdownItemSelect: (index: number, chatChannel: ChatChannel) => void
  handleChatChannelBulkDropdownItemSelect: (index: number, chatChannels: ChatChannel[]) => void
}> = ({
  chatChannels,
  isLoading,
  onCreateChatChannelClick,
  handleChatChannelDropdownItemSelect,
  handleChatChannelBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [selectedChatChannelIds, setSelectedChatChannelIds] = useState<string[]>([])
  const [status, setStatus] = useState('all')

  // MARK: - Properties

  let currentChatChannels = chatChannels.filter(chatChannel =>
    (chatChannel.name + chatChannel.description).toLowerCase().includes(searchText.toLowerCase()),
  )

  if (status === 'inactive') {
    currentChatChannels = currentChatChannels.filter(channel => !channel.is_active)
  } else if (status === 'active') {
    currentChatChannels = currentChatChannels.filter(channel => channel.is_active)
  }

  // MARK: - Effects

  useEffect(() => {
    setSelectedChatChannelIds(prev => {
      const announcementIds = chatChannels.map(item => item.id)
      return prev.filter(id => announcementIds.includes(id))
    })
  }, [chatChannels])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleChatChannelBulkDropdownItemSelect(
      option,
      chatChannels.filter(chatChannel => selectedChatChannelIds.includes(chatChannel.id)),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('createChatChannel')}
        onPrimaryActionClick={onCreateChatChannelClick}
        searchText={searchText}
        setSearchText={setSearchText}
        status={status}
        setStatus={setStatus}
        statusOptions={[
          { key: 'active', label: t('active') },
          { key: 'inactive', label: t('inactive') },
          { key: 'all', label: t('all') },
        ]}
        columns={[
          { label: t('overview'), width: 250 },
          { label: t('status'), width: 156 },
        ]}
        dropdownOptions={[{ title: t('delete'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentChatChannels.length}
        selectedCount={selectedChatChannelIds.length}
        toggleAllSelected={isOn => {
          setSelectedChatChannelIds(
            isOn ? currentChatChannels.map(chatChannel => chatChannel.id) : [],
          )
        }}
      />

      <DashboardTable
        data={currentChatChannels}
        isLoading={isLoading}
        emptyStateTitle={t('noChatChannelCreated')}
        emptyStateSubtitle={t('onceChatChannelCreated')}
        renderItem={(chatChannel: ChatChannel, index: number) => (
          <DashboardTableRow
            key={chatChannel.id}
            index={index}
            image={chatChannel.image_url}
            title={chatChannel.name}
            description={chatChannel.description}
            status={{
              hint: chatChannel.is_active ? t('active') : t('inactive'),
              hintWidth: chatChannel.is_active ? 72 : 100,
              color: chatChannel.is_active ? palette.green : palette.orange,
            }}
            dropdownOptions={[
              { title: t('edit'), icon: EditIcon },
              {
                title: chatChannel.is_active ? t('deactivate') : t('activate'),
                icon: chatChannel.is_active ? VisibilityOffIcon : VisibilityOnIcon,
              },
              { title: t('delete'), icon: DeleteIcon },
            ]}
            handleOptionsDropdownOptionClick={option =>
              handleChatChannelDropdownItemSelect(option, chatChannel)
            }
            selected={selectedChatChannelIds.includes(chatChannel.id)}
            onCheckBoxClick={isOn => {
              setSelectedChatChannelIds(prev =>
                isOn ? [...prev, chatChannel.id] : prev.filter(item => chatChannel.id !== item),
              )
            }}
          />
        )}
      />
    </DashboardTableContainer>
  )
}
