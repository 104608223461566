import { FadeIn, LabelSmall } from './CommonStyles'
import React, { useCallback, useRef, useState } from 'react'
import { InfoIcon } from 'src/assets/images/svg/InfoIcon'
import styled from 'styled-components'

export const Hint: React.FC<{
  style?: React.CSSProperties
  fill?: string
  offset?: { top?: number; bottom?: number; left?: number; right?: number }
  alignment?: 'right' | 'left'
  hint?: string
  width?: number
  detail?: string
  delay?: number
}> = ({ style, hint, offset, fill, width, detail, alignment = 'right', children, delay = 0 }) => {
  // MARK: - Hooks

  const timer = useRef<NodeJS.Timer | null>(null)
  const [showHint, setShowHint] = useState(false)

  // MARK: - Handlers

  const toggleHint = useCallback(
    (isOn: boolean) => () => {
      if (timer.current) clearTimeout(timer.current)
      if (isOn) timer.current = setTimeout(() => setShowHint(true), delay)
      else setShowHint(false)
    },
    [],
  )

  // MARK: - Render

  return (
    <>
      <Container style={style} onMouseEnter={toggleHint(true)} onMouseLeave={toggleHint(false)}>
        {children ?? <InfoIcon style={{ width: 16, height: 16, minWidth: 16 }} fill={fill} />}

        {showHint && hint && (
          <HintContainer
            style={{
              left: alignment === 'right' ? 24 + (offset?.left ?? 0) : undefined,
              right: alignment === 'left' ? 24 + (offset?.right ?? 0) : undefined,
              top: -18 - (offset?.top ?? 0),
              bottom: offset?.bottom,
              minWidth: width,
            }}>
            <HintLabel>{hint}</HintLabel>
            <HintLabel>{detail}</HintLabel>
          </HintContainer>
        )}
      </Container>
    </>
  )
}

// MARK: - Styles

const Container = styled.div`
  border-radius: 1000px;
  cursor: pointer;
  height: 16px;
  position: relative;
  user-select: none;
`

const HintContainer = styled(FadeIn)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.palette.text.primary};
  max-width: 320px;
  min-width: 120px;
  padding: 8px 16px 8px 16px;
  position: absolute;
  top: -18px;
  width: auto;
  z-index: 10;
`

const HintLabel = styled(LabelSmall)`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`
