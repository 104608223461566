import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Component, ComponentType } from 'src/repository/types'

export const FAQListModal: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.faq}
      component={component}
      header={t('faq')}
      titlePlaceholder={t('faqTitlePlaceholder')}
      descriptionPlaceholder={t('faqDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.faq,
          input: { key: 'faq_list' },
        })
      }
    />
  )
}
