import styled, { css, CSSProperties } from 'styled-components'

const Label = styled.div<{ size?: number; align?: 'left' | 'right' | 'center'; color?: string }>`
  color: ${({ theme, color }) => color ?? theme.palette.text.primary};
  font-size: ${({ size }) => size || 18}px;
  letter-spacing: 0.2px;
  text-align: ${({ align }) => align || 'left'};
`

export const LabelExtraBold = styled(Label)`
  font-weight: 800;
`

export const LabelBold = styled(Label)`
  font-weight: 600;
`

export const LabelSemiBold = styled(Label)<{ size?: number }>`
  font-weight: 500;
`

export const LabelRegular = styled(Label)<{ size?: number }>`
  font-weight: 400;
`

export const LabelLight = styled(Label)<{ size?: number }>`
  font-weight: 300;
`

export const LabelHeaderLarge = styled(LabelBold)`
  font-size: 26px;
  line-height: 34px;
`

export const LabelHeader = styled(LabelBold)`
  font-size: 20px;
  line-height: 26px;
`

export const LabelLarge = styled(LabelBold)`
  font-size: 17px;
  line-height: 24px;
`

export const LabelMedium = styled(LabelSemiBold)`
  font-size: 15px;
  line-height: 21px;
`

export const LabelNormal = styled(LabelRegular)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  line-height: 21px;
`

export const LabelSmall = styled(LabelNormal)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  line-height: 19px;
`

export const RoundedImage = styled.img<{ size: number }>`
  border-radius: 4px;
  height: ${({ size }) => size}px;
  margin-right: 12px;
  min-width: ${({ size }) => size}px;
  overflow: hidden;
  width: ${({ size }) => size}px;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexCol = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const Box = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 8px;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  flex: 1;
`

export const HoverBox = styled(Box)`
  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => theme.palette.orange};
      }
    `}
`

export const ShadowBox = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: ${({ theme }) => (theme.isDark ? 1 : 0)}px solid
    ${({ theme }) => theme.palette.background.separator};
  border-radius: 6px;
  box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px;
`

export const SlideRight = styled.div<{ duration?: number; ease?: 'ease-in' | 'ease-out' }>`
  animation-duration: ${({ duration }) => duration ?? 0.2}s;
  animation-iteration-count: 1;
  animation-name: slideRight;
  animation-timing-function: ${({ ease }) => ease ?? 'ease-out'};
`

export const RoundedButton = styled(Row)<{ size?: number }>`
  align-items: center;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  height: ${({ size }) => size || 48}px;
  justify-content: center;
  padding: 4px;
  width: ${({ size }) => size || 48}px;
  z-index: 3;

  ${() =>
    css`
      &:hover {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
      }
    `};
`

export const FadeIn = styled.div<{ duration?: number; ease?: 'ease-in' | 'ease-out' }>`
  animation-duration: ${({ duration }) => duration ?? 0.2}s;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ${({ ease }) => ease ?? 'ease-in'};
`

export const absoluteFill: Pick<CSSProperties, 'position' | 'top' | 'left' | 'right' | 'bottom'> = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

export const centered: CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center',
}
