import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 9.36107C8.5 8.24948 7.02611 7.95485 6.63508 8.99493C5.6892 11.5108 5 13.7615 5 14.9783C5 18.8563 8.13401 22 12 22C15.866 22 19 18.8563 19 14.9783C19 13.671 18.2045 11.1704 17.1498 8.42956C15.7836 4.87912 15.1005 3.1039 14.2573 3.00829C13.9874 2.97769 13.6931 3.0327 13.4523 3.15871C12.7 3.5525 12.7 5.48869 12.7 9.36107C12.7 10.5245 11.7598 11.4676 10.6 11.4676C9.4402 11.4676 8.5 10.5245 8.5 9.36107Z"
      stroke={fill}
    />
  </svg>
)

export const NotificationIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
