import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useDebouncedState = <T>(initialValue: T, delay: number = 300) => {
  // MARK: - Hooks

  const [state, setState] = useState(initialValue)
  const [temp, setTemp] = useState(initialValue)

  const debouncedSetSearchQuery = useCallback(
    debounce((value: T) => setState(value), delay),
    [],
  )

  // MARK: - Effects

  useEffect(() => {
    debouncedSetSearchQuery(temp)
  }, [temp])

  // MARK: - Return

  const out: [T, T, (_: T) => void] = [state, temp, setTemp]
  return out
}
