import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloneIcon } from 'src/assets/images/svg/CloneIcon'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { SettingsIcon } from 'src/assets/images/svg/SettingsIcon'
import { Col, LabelMedium, LabelNormal } from 'src/features/common/CommonStyles'
import { DropDown } from 'src/features/common/DropDown'
import { Room } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled, { css } from 'styled-components'

export const RoomListItem: React.FC<{
  room: Room
  onRoomSelect: (room: Room) => void
  onRoomDropdownOptionSelect: (room: Room, option: number) => void
}> = ({ room, onRoomSelect, onRoomDropdownOptionSelect }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)
  const drop = React.useRef<HTMLDivElement | null>(null)
  const [displaySettings, setDisplaySettings] = useState(false)

  // MARK: - Properties

  const startTime = room?.start_date ?? 0
  const options = [
    { title: t('edit'), icon: EditIcon },
    { title: t('clone'), icon: CloneIcon },
    { title: t('delete'), icon: DeleteIcon },
  ]

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleSettingsCloseClick)
    return () => {
      document.removeEventListener('click', handleSettingsCloseClick)
    }
  })

  // MARK: - Handlers

  const handleSettingsCloseClick = (event: MouseEvent | any) => {
    if (!event.target?.closest(`.${drop.current?.className}`) && displaySettings) {
      event.stopPropagation()
      setDisplaySettings(false)
    }
  }

  const handleSettingsClick = (event: any) => {
    event.stopPropagation()
    setDisplaySettings(prev => !prev)
  }

  const handleSettingsDropdownOptionClick = (option: number) => {
    setDisplaySettings(false)
    onRoomDropdownOptionSelect(room, option)
  }

  // MARK: - Render

  return (
    <Container
      onPointerEnter={() => setFocused(true)}
      onPointerLeave={() => {
        setFocused(false)
        setDisplaySettings(false)
      }}
      onClick={() => onRoomSelect(room)}>
      <Image src={room.main_media?.url} />

      <Col>
        <LabelMedium style={{ ...numberOfLines(2), marginTop: 8, marginBottom: 6 }}>
          {room.title}
        </LabelMedium>
        <LabelNormal>{prettyDateString(startTime)}</LabelNormal>

        <Status>{t(room.status)}</Status>

        {focused && (
          <IconContainer ref={drop} onClick={handleSettingsClick}>
            <Settings>
              <SettingsIcon size={14} />
              {displaySettings && (
                <DropDown data={options} onItemClick={handleSettingsDropdownOptionClick} />
              )}
            </Settings>
          </IconContainer>
        )}
      </Col>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  border-color: transparent;
  cursor: pointer;
  margin: 0px 0.8% 20px 0.8%;
  overflow: hidden;
  position: relative;
  width: 280px;
`

const Image = styled.img`
  border-radius: 12px;
  height: 165px;
  object-fit: cover;
  width: 100%;
`

const Status = styled(LabelMedium)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 20px;
  font-size: 13px;
  left: 6px;
  line-height: 24px;
  padding: 0px 10px;
  position: absolute;
  top: 132px;
`

const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 4px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`

const Settings = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100;
`
