import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FadeIn, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import styled from 'styled-components'

export const RoomLocationTBA: React.FC<{
  description: string
  onDescriptionChange: (text: string) => void
}> = ({ description, onDescriptionChange }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [focused, setFocused] = useState(false)

  // MARK: - Render

  return (
    <Row style={{ height: 80 }}>
      <Input
        style={{ width: 420, maxWidth: 420 }}
        label={t('information')}
        placeholder={t('tbaDescription')}
        inputStyle={{ marginBottom: -4, width: 420 }}
        status={'default'}
        value={description}
        onChange={onDescriptionChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />

      {focused && (
        <DescriptionContainer>
          <LabelMedium>{t('tbaHint')}</LabelMedium>
        </DescriptionContainer>
      )}
    </Row>
  )
}

// MARK: - Styles

const DescriptionContainer = styled(FadeIn)`
  align-items: center;
  align-self: center;
  border-left: 2px solid ${({ theme }) => theme.palette.text.primary};
  display: flex;
  height: 72%;
  margin-left: 20px;
  padding-left: 20px;
`
