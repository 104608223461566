import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M9 17H19" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 17H5.002V17.002H5V17Z"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 12H19" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 12H5.002V12.002H5V12Z"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 7H19" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 7H5.002V7.002H5V7Z"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ListIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
