import { random } from 'lodash'
import { mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk, Thunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { roomService, RoomRequest } from 'src/repository/services/roomService'
import { Link } from 'src/repository/types'
import config from 'src/utils/config'
import { CreateRoomTheme, createRoomThemes } from 'src/utils/helpers/createRoomHelper'
import { localTime, timeZone } from 'src/utils/helpers/timeHelper'

type State = {
  isLoading: { create: boolean; keyPair: boolean }
  createdRoomId: string | null
  theme: CreateRoomTheme | null
  request: RoomRequest
  error: { create: IError | null; keyPair: IError | null }
}

// MARK: - State

export const initialState: State = {
  isLoading: { create: false, keyPair: false },
  error: { create: null, keyPair: null },
  createdRoomId: null,
  theme: null,
  request: {
    title: '',
    overview: '',
    date: { start: localTime() + 60 * 60, timezone: timeZone() },
    privacy_type: 'public',
    access_codes: [],
    tags: [],
    api_key: null,
    api_secret: null,
    links: [],
    media: { url: config.defaultCover, offset: 0 },
    location: { type: 'tba', description: '' },
    config: {
      chat_enabled: true,
      share_enabled: true,
      see_more_enabled: true,
      banners: [],
    },
  },
}

// MARK: - Reducer

export const createRoomReducer = (
  state = initialState,
  action:
    | SetLoadingAction
    | SetErrorAction
    | SetCreateRoomRequestAction
    | SetCreatedRoomIdAction
    | SetThemeAction
    | FlushAction,
): State => {
  switch (action.type) {
    case 'createRoom/setLoading':
      return { ...state, isLoading: { ...state.isLoading, [action.key]: action.isLoading } }

    case 'createRoom/error':
      return { ...state, error: { ...state.error, [action.key]: action.error } }

    case 'createRoom/setCreateRoomRequest':
      return { ...state, request: { ...state.request, ...action.createRoomRequest } }

    case 'createRoom/setCreatedRoomId':
      return { ...state, createdRoomId: action.createdRoomId }

    case 'createRoom/setTheme':
      return { ...state, theme: action.theme }

    case 'createRoom/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setRandomTheme = (): Thunk => (dispatch, getState) => {
  const localization = getState().app.localization
  const themes = createRoomThemes(localization)
  const randomIndex = random(themes.length - 1, false)
  const theme = themes[randomIndex]
  dispatch(setTheme(theme))
  dispatch(setCreateRoomRequest({ media: { url: theme.cover, offset: theme.offset } }))
}

export const getPrivateKeyPair = (): AsyncThunk => async dispatch => {
  dispatch(setLoading('keyPair', true))
  dispatch(setError('keyPair', null))

  const response = await roomService.generateRoomKeys()
  if (response.success) {
    const { api_key, api_secret } = response.value
    dispatch(setCreateRoomRequest({ api_key, api_secret }))
  } else {
    dispatch(setError('keyPair', response.error))
  }

  dispatch(setLoading('keyPair', false))
}

export const createRoom = (): AsyncThunk => async (dispatch, getState) => {
  const { request: createRoomRequest } = getState().createRoom
  if (!createRoomRequest) {
    dispatch(setError('create', Error.default('CreateRoomRequest not found')))
    return
  }

  dispatch(setLoading('create', true))
  dispatch(setError('create', null))

  const response = await roomService.createRoom(createRoomRequest)

  if (response.success) {
    const { room } = response.value
    dispatch(mergeEntities({ room: [room] }))
    dispatch(setCreatedRoomId(room.id))
  } else {
    dispatch(setError('create', response.error))
  }
  dispatch(setLoading('create', false))
}

export const setCreateRoomRequest = (
  roomRequest: Partial<RoomRequest & { links: Link[] }>,
): SetCreateRoomRequestAction => ({
  type: 'createRoom/setCreateRoomRequest',
  createRoomRequest: roomRequest,
})

export const setError = (key: keyof State['error'], error: IError | null): SetErrorAction => ({
  type: 'createRoom/error',
  key: key,
  error: error,
})

export const flush = (): FlushAction => ({
  type: 'createRoom/flush',
})

// MARK: - Selectors

export const getCreateRoomTheme = (state: State): CreateRoomTheme | null => {
  return state.theme
}

export const getCreateRoomRequest = (state: State): RoomRequest => {
  return state.request
}

export const getIsLoading = (state: State, key: keyof State['isLoading']): boolean => {
  return state.isLoading[key]
}

export const getCreatedRoomId = (state: State): string | null => {
  return state.createdRoomId
}

export const getError = (state: State, key: keyof State['error']): IError | null => {
  return state.error[key]
}

// MARK: - Action Types

type SetCreateRoomRequestAction = {
  type: 'createRoom/setCreateRoomRequest'
  createRoomRequest: Partial<RoomRequest>
}

type SetLoadingAction = {
  type: 'createRoom/setLoading'
  key: keyof State['isLoading']
  isLoading: boolean
}

type SetCreatedRoomIdAction = {
  type: 'createRoom/setCreatedRoomId'
  createdRoomId: string
}

type SetErrorAction = {
  type: 'createRoom/error'
  key: keyof State['error']
  error: IError | null
}

type SetThemeAction = {
  type: 'createRoom/setTheme'
  theme: CreateRoomTheme
}

type FlushAction = {
  type: 'createRoom/flush'
}

// MARK: - Internal Actions

const setCreatedRoomId = (createdRoomId: string): SetCreatedRoomIdAction => ({
  type: 'createRoom/setCreatedRoomId',
  createdRoomId: createdRoomId,
})

const setTheme = (theme: CreateRoomTheme): SetThemeAction => ({
  type: 'createRoom/setTheme',
  theme: theme,
})

const setLoading = (key: keyof State['isLoading'], isLoading: boolean): SetLoadingAction => ({
  type: 'createRoom/setLoading',
  key: key,
  isLoading: isLoading,
})
