import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Component, ComponentType } from 'src/repository/types'

export const WebListModal: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.webList}
      component={component}
      header={t('webLinks')}
      titlePlaceholder={t('webListTitlePlaceholder')}
      descriptionPlaceholder={t('webListDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.webList,
          input: { key: 'web_list' },
        })
      }
    />
  )
}
