import { api } from '../api'
import { Invitation, InvitationRequest, Subscription, User } from '../types'

export const audienceService = {
  fetchAudience: async (roomId: string, offset: number, limit: number) =>
    api.value<{ subscriptions: Subscription[]; users: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/audience',
        parameters: { room_id: roomId, offset, limit },
      },
    }),

  searchAudience: async (roomId: string, query: string, offset: number, limit: number) =>
    api.value<{ users: User[]; subscriptions: Subscription[] }>({
      request: {
        method: 'GET',
        endPoint: '/audience/search',
        parameters: { room_id: roomId, query, offset, limit },
      },
    }),

  exportAudience: async (roomId: string, userIds: string[]) =>
    api.value<{ csv: string; filename: string }>({
      request: {
        method: 'POST',
        endPoint: '/audience/export',
        parameters: { room_id: roomId, user_ids: userIds },
      },
    }),

  fetchInvitation: async (invitationId: string) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'GET',
        endPoint: '/audience/invitation/single',
        parameters: { invitation_id: invitationId },
      },
    }),

  fetchInvitations: async (roomId: string) =>
    api.value<{
      invitations: Invitation[]
      invitation_requests: InvitationRequest[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/audience/invitation',
        parameters: { room_id: roomId },
      },
    }),

  createInvitation: async (request: Partial<Invitation>) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'POST',
        endPoint: '/audience/invitation',
        parameters: { ...request },
      },
    }),

  deleteInvitation: async (invitationId: string) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'DELETE',
        endPoint: '/audience/invitation',
        parameters: { invitation_id: invitationId },
      },
    }),

  respondInvitationRequest: async (roomId: string, requestId: string, accept: boolean) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'PUT',
        endPoint: '/audience/invitation/request',
        parameters: { room_id: roomId, invitation_request_id: requestId, accept: accept },
      },
    }),

  removeMembers: async (roomId: string, userIds: string[]) =>
    api.value<{ subscriptions: Subscription[] }>({
      request: {
        method: 'POST',
        endPoint: '/audience/remove',
        parameters: { room_id: roomId, user_ids: userIds },
      },
    }),

  blockMembers: async (roomId: string, userIds: string[]) =>
    api.value<{ subscriptions: Subscription[] }>({
      request: {
        method: 'POST',
        endPoint: '/audience/block',
        parameters: { room_id: roomId, user_ids: userIds },
      },
    }),

  unblockMembers: async (roomId: string, userIds: string[]) =>
    api.value<{ subscriptions: Subscription[] }>({
      request: {
        method: 'POST',
        endPoint: '/audience/unblock',
        parameters: { room_id: roomId, user_ids: userIds },
      },
    }),
}
