import {
  CreateComponentInstanceInput,
  UpdateComponentInstanceInput,
} from 'src/repository/services/componentService'
import { ComponentType } from 'src/repository/types'

export type SaveComponentInput = {
  type: ComponentType
  title: string
  overview: string
  image: string
  activated: boolean
  input: CreateComponentInstanceInput | UpdateComponentInstanceInput
}

export const availableComponentTypes: ComponentType[] = [
  ComponentType.announcements,
  ComponentType.webList,
  ComponentType.polling,
  ComponentType.qa,
  ComponentType.faq,
]
