import { availableComponentTypes } from '../DashboardComponent/types'
import { DashboardSidebarItem } from './DashboardSidebarItem'
import { DashboardSidebarRoomSelector } from './DashboardSidebarRoomSelector'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AddIcon } from 'src/assets/images/svg/AddIcon'
import { RightArrowIcon } from 'src/assets/images/svg/RightArrowIcon'
import { Col, FadeIn, FlexRow, LabelMedium, Row, ShadowBox } from 'src/features/common/CommonStyles'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { Separator } from 'src/features/common/Separator'
import { ShareableLink } from 'src/features/platform/common/ShareableLink'
import { DashboardComponentSideBar } from 'src/features/platform/dashboard/DashboardSideBar/DashboardComponentSideBar'
import { getLoading, getLocalization } from 'src/redux/reducers/app'
import { getDrawerCollapsed, setDrawerCollapsed } from 'src/redux/reducers/room'
import { Component, ComponentType, Room } from 'src/repository/types'
import { componentImageUrl, componentName } from 'src/utils/helpers/componentHelper'
import { DashboardSidebarOption } from 'src/utils/helpers/dashboardHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { css } from 'styled-components'

export const DashboardSideBar: React.FC<{
  room: Room
  options: DashboardSidebarOption[]
  components: Component[]
  currentIndex: number
  generalItemCount: number
  onOptionSelect: (index: number) => void
  onAddComponent: (componentType?: ComponentType) => void
  onPurchaseClick: () => void
  onComponentDropdownOptionSelect: (component: Component, option: number) => void
}> = ({
  room,
  options,
  components,
  generalItemCount,
  onOptionSelect,
  currentIndex,
  onAddComponent,
  onPurchaseClick,
  onComponentDropdownOptionSelect,
}) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isMobile } = useWindowDimensions()

  const localization = useSelect(state => getLocalization(state.app))
  const isLoading = useSelect(state => getLoading(state.app, 'room'))

  const drawerCollapsed = useSelect(state => getDrawerCollapsed(state.room))

  const [showRoomSelector, setShowRoomSelector] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    dispatch(setDrawerCollapsed(isMobile))
  }, [isMobile])

  // MARK: - Handlers

  const handleRoomSelectorToggle = useCallback(
    (isOn: boolean) => () => {
      setShowRoomSelector(isOn)
    },
    [components.length, room.id],
  )

  const handleComponentItemSelect = useCallback(
    (index: number) => {
      onOptionSelect(index + generalItemCount)
    },
    [generalItemCount, components.length, room.id],
  )

  const handleFoundationItemSelect = useCallback(
    (index: number) => {
      onOptionSelect(index)
    },
    [room.id],
  )

  const handleAddComponentClick = useCallback(
    (componentType?: ComponentType) => () => {
      onAddComponent(componentType)
    },
    [onAddComponent, room.id],
  )

  // MARK: - Render

  return (
    <>
      {isMobile && !drawerCollapsed && (
        <FadeIn style={{ zIndex: 10 }}>
          <Col
            onClick={() => dispatch(setDrawerCollapsed(true))}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              backgroundColor: 'rgba(0,0,0,0.2)',
              height: 'calc(100vh - 72px)',
              width: '100vw',
              zIndex: 10,
            }}
          />
        </FadeIn>
      )}

      <Container
        visible={!isMobile || !drawerCollapsed}
        style={{
          position: isMobile ? 'absolute' : 'relative',
          height: isMobile ? 'calc(100vh - 72px)' : undefined,
          marginTop: isMobile ? -1 : undefined,
          paddingTop: isMobile ? 16 : undefined,
        }}>
        {!isMobile && (
          <Title onClick={handleRoomSelectorToggle(!showRoomSelector)}>
            <LabelMedium style={{ fontSize: 16, fontWeight: '600', ...numberOfLines(2) }}>
              {room.title}
            </LabelMedium>

            {/* <ButtonContainer>{collapsed ? <MoveLeftIcon /> : <MoveRightIcon />}</ButtonContainer> */}

            {showRoomSelector && (
              <DashboardSidebarRoomSelector
                selectedRoomId={room.id}
                onClose={handleRoomSelectorToggle(false)}
              />
            )}

            <RightArrowIcon
              size={20}
              style={{ marginRight: -2, marginLeft: 6, minWidth: 5, width: 20 }}
            />
          </Title>
        )}

        <ShareableLink link={room.short_link} isLive={room.status === 'published'} />

        <PrimaryButton
          style={{ height: 36, marginLeft: 14, marginRight: 14, marginTop: 12, minHeight: 36 }}
          title={t(room?.status === 'published' ? 'upgradePlan' : 'goLive')}
          size={'sm'}
          onClick={onPurchaseClick}
        />

        <Header style={{ marginTop: 16 }}>
          <LabelMedium>{t('foundation')}</LabelMedium>
        </Header>

        <Separator margin={8} />

        <InnerContainer>
          {options.map((option, index) => (
            <DashboardSidebarItem
              key={option.title}
              index={index}
              currentIndex={currentIndex}
              isSelected={index === Math.round(currentIndex)}
              option={option}
              onOptionSelect={handleFoundationItemSelect}
            />
          ))}

          <Header>
            <LabelMedium>{components.length === 0 ? t('addFeature') : t('features')}</LabelMedium>
            <AddIcon
              style={{ cursor: 'pointer', marginRight: -4 }}
              onClick={handleAddComponentClick()}
            />
          </Header>

          <Separator margin={8} />

          {isLoading ? (
            <PageLoader />
          ) : components.length ? (
            <DashboardComponentSideBar
              components={components}
              currentIndex={currentIndex - generalItemCount}
              showDropdown
              onComponentSelect={handleComponentItemSelect}
              onComponentDropdownOptionSelect={onComponentDropdownOptionSelect}
            />
          ) : (
            <FlexRow style={{ flexWrap: 'wrap' }}>
              {availableComponentTypes.map(componentType => (
                <ItemContainer key={componentType} onClick={handleAddComponentClick(componentType)}>
                  <img
                    src={componentImageUrl(componentType)}
                    style={{ width: 48, height: 48, marginTop: 16 }}
                    alt=""
                  />
                  <LabelMedium
                    style={{ textAlign: 'center', marginBottom: 4, marginTop: 10, fontSize: 14 }}>
                    {componentName(componentType, localization)}
                  </LabelMedium>
                </ItemContainer>
              ))}
            </FlexRow>
          )}
        </InnerContainer>
      </Container>
    </>
  )
}

// MARK: - Styles

const Container = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  transform: ${({ visible }) => (visible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.25s ease-out;
  width: 300px;
  z-index: 10;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
`

const Header = styled(Row)`
  align-items: flex-end;
  justify-content: space-between;
  margin: 12px 16px 0px 16px;
`

const ItemContainer = styled(ShadowBox)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 6px 2.5% 10px 2.5%;
  padding: 4px 4px 4px 4px;
  width: 44%;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.orange : 'transparent')};
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
      }
    `}
`

const Title = styled(Row)`
  cursor: pointer;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px 16px;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `};
`
