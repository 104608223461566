import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol, FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { IconPicker } from 'src/features/platform/common/IconPicker'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createWebListItem, updateWebListItem } from 'src/redux/reducers/component'
import { Error } from 'src/repository/Error'
import { Component, ComponentType, Room, WebListItem } from 'src/repository/types'
import { componentInstanceImageUrl } from 'src/utils/helpers/componentHelper'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardWebListItemModal: React.FC<{
  webListItem?: WebListItem | null
  room: Room
  component: Component
  onClose: () => void
}> = ({ webListItem = null, room, component, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [link, setLink] = useState(webListItem?.link ?? '')
  const [image, setImage] = useState(
    webListItem?.image_url ?? componentInstanceImageUrl(ComponentType.webList),
  )
  const [title, setTitle] = useState(webListItem?.title ?? '')
  const [content, setContent] = useState(webListItem?.overview ?? '')

  const isLoading = useSelect(state => getLoading(state.app, 'web_list'))
  const shouldCloseWhenLoadingDone = useRef(false)

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleSaveClick = () => {
    if (!title || !content || !link) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true
    if (webListItem) {
      dispatch(updateWebListItem(webListItem.id, title, content, image ?? '', link, true))
    } else {
      dispatch(createWebListItem(room.id, component.id, title, content, image ?? '', link))
    }
  }

  // MARK: - Render

  return (
    <ModalWrapper
      title={webListItem ? t('updateWebListItem') : t('createWebListItem')}
      size="tiny"
      onClose={onClose}>
      <FlexRow>
        <IconPicker icon={image} onIconChange={setImage} />

        <FlexCol style={{ marginLeft: 20 }}>
          <Input label={t('title')} isRequired value={title} onChange={setTitle} />

          <Input
            inputStyle={{ minHeight: 132 }}
            type="textarea"
            isRequired
            label={t('description')}
            value={content}
            onChange={setContent}
          />
        </FlexCol>
      </FlexRow>

      <Input
        style={{ marginTop: 12 }}
        isRequired
        placeholder="http://"
        label={t('link')}
        value={link}
        onChange={text => setLink(text)}
      />

      <FooterWrapper>
        <PrimaryButton
          isLoading={isLoading}
          style={{ marginRight: 10, minWidth: 150 }}
          title={!!webListItem ? t('update') : t('create')}
          onClick={handleSaveClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
