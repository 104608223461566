import { GoogleMap } from './GoogleMap'
import { ValidVenuePropertyContainer } from './ValidVenuePropertyContainer'
import { isVenueValid, Venue } from './Venue'
import { VenuePropertyEditor } from './VenuePropertyEditor'
import { VenueSearchField } from './VenueSearchField'
import React, { useEffect, useState } from 'react'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import styled, { useTheme } from 'styled-components'

export const VenuePicker: React.FC<{
  isRequired?: boolean
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  initialVenue: Venue | null
  onVenueSelected?: (venue: Venue | null) => void
}> = ({ style, containerStyle, isRequired = false, initialVenue, onVenueSelected }) => {
  // MARK: - Hooks

  const [venue, setVenue] = useState<Venue | null>(initialVenue)
  const [editing, setEditing] = useState(false)
  const { palette } = useTheme()

  // MARK: - Properties

  const venueValid = venue && isVenueValid(venue) && !editing

  // MARK: - Effects

  useEffect(() => {
    onVenueSelected?.(venue)
  }, [venue?.address])

  // MARK: - Render

  return (
    <Container style={containerStyle}>
      <VenueContainer>
        {venue && (
          <>
            <GoogleMap
              center={{ lat: venue.latitude, lng: venue.longitude }}
              style={{
                borderRadius: 8,
                borderBottomLeftRadius: venueValid ? 0 : 8,
                borderBottomRightRadius: venueValid ? 0 : 8,
                marginBottom: venueValid ? 0 : 10,
                overflow: 'hidden',
              }}
            />

            {venueValid && !editing && <ValidVenuePropertyContainer venue={venue} />}

            {(!venueValid || editing) && (
              <VenuePropertyEditor venue={venue} onVenueEdit={item => setVenue(item)} />
            )}
          </>
        )}

        {!venue && !editing && (
          <VenueSearchField onVenueSelected={location => setVenue(location)} style={style} />
        )}
      </VenueContainer>

      {venue && (
        <ModificationContainer height={venueValid ? 300 : 250}>
          {!editing && venueValid && (
            <EditIcon
              fill={palette.text.primary}
              style={{ cursor: 'pointer', marginBottom: 15 }}
              onClick={() => setEditing(true)}
            />
          )}
          <DeleteIcon
            fill={palette.text.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditing(false)
              setVenue(null)
            }}
          />
        </ModificationContainer>
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const ModificationContainer = styled.div<{ height: number }>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  justify-content: center;
  min-width: 35px;
`

const VenueContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
