import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import {
  FlexCol,
  RoundedImage,
  LabelLarge,
  LabelMedium,
  LabelSmall,
} from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { InvitationRequest } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled from 'styled-components'

export const DashboardAudienceRequestListItem: React.FC<{
  invitationRequest: InvitationRequest
  onAccept: (request: InvitationRequest) => void
  onReject: (request: InvitationRequest) => void
}> = ({ invitationRequest, onAccept, onReject }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Container>
      {invitationRequest.image_url ? (
        <RoundedImage
          size={50}
          src={invitationRequest.image_url}
          style={{ alignSelf: 'flex-start', marginTop: 4 }}
        />
      ) : (
        <ProfileIcon style={{ alignSelf: 'flex-start', marginTop: 2, marginRight: 12 }} size={50} />
      )}
      <FlexCol>
        <LabelLarge style={{ ...numberOfLines(1), marginBottom: 4 }}>
          {invitationRequest.first_name + ' ' + invitationRequest.last_name}
        </LabelLarge>
        <LabelMedium style={{ ...numberOfLines(1), marginBottom: 4 }}>
          {invitationRequest.email}
        </LabelMedium>
        {invitationRequest.notes ? (
          <LabelSmall style={{ ...numberOfLines(2) }}>{invitationRequest.notes}</LabelSmall>
        ) : null}
      </FlexCol>

      <PrimaryButton
        style={{ margin: 12 }}
        title={t('accept')}
        onClick={() => onAccept(invitationRequest)}
      />
      <SecondaryButton title={t('cancel')} onClick={() => onReject(invitationRequest)} />

      <Separator />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  position: relative;
  width: 100%;
`

const Separator = styled.div`
  background-color: ${({ theme }) => theme.palette.background.separator};
  bottom: 0px;
  height: 1px;
  left: 0px;
  position: absolute;
  right: 0px;
`
