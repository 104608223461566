import i18n from 'src/utils/localization'
import { Localization } from 'src/utils/types/i18n'

export type DashboardSidebarOption = {
  title: string
  icon: string
  subItems: string[]
}

export const dashboardSidebarOptions = (lng: Localization): DashboardSidebarOption[] => [
  {
    title: i18n.t('details', { lng }),
    icon: dashboardSidebarOptionsImage('details'),
    subItems: [],
  },
  {
    title: i18n.t('chats', { lng }),
    icon: dashboardSidebarOptionsImage('chats'),
    subItems: [],
  },
  {
    title: i18n.t('audience', { lng }),
    icon: dashboardSidebarOptionsImage('audience'),
    subItems: [],
  },
  {
    title: i18n.t('settings', { lng }),
    icon: dashboardSidebarOptionsImage('settings'),
    subItems: [],

    // subItems: [i18n.t('configuration'), i18n.t('usageBilling'), i18n.t('teamSettings')],
  },
]

export const dashboardSidebarOptionsImage = (
  section: 'details' | 'chats' | 'audience' | 'settings',
): string => {
  if (section === 'details') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F785822?alt=media&token=b03ec4f3-ad2a-4ce9-b07d-dfd7720577a8'
  } else if (section === 'chats') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F3073390?alt=media&token=430f0e89-955c-42c3-845c-06faef073fa0'
  } else if (section === 'audience') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F535466?alt=media&token=d52479ae-9ae6-4af7-b743-2714c2f03347'
  } else if (section === 'settings') {
    return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F2965333?alt=media&token=54a40e51-b667-4ddd-9ac4-31d5bd37c401'
  } else {
    return ''
  }
}
