import React from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { Select } from 'src/features/common/Select'
import { Link, LinkType } from 'src/repository/types'
import { editLinkPlaceHolder } from 'src/utils/helpers/linkHelper'
import styled, { useTheme } from 'styled-components'

export const LinkInputField: React.FC<{
  link: Link
  canBeDeleted: boolean
  onLinkDelete: () => void
  onLinkTypeChange: (type: number) => void
  onLinkNameChange: (name: string) => void
  onLinkUrlChange: (url: string) => void
}> = ({
  link,
  canBeDeleted,
  onLinkDelete,
  onLinkTypeChange,
  onLinkNameChange,
  onLinkUrlChange,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()

  // MARK: - Render

  return (
    <FlexRow style={{ alignItems: 'center', height: 52 }}>
      <LinkTypeInput>
        <Select
          placeholder={t('type')}
          value={link.type?.toString() ?? '0'}
          onChange={text => onLinkTypeChange(parseInt(text, 10))}>
          <option value={`${LinkType.facebook}`}>{t('facebook')}</option>
          <option value={`${LinkType.twitter}`}>{t('twitter')}</option>
          <option value={`${LinkType.instagram}`}>{t('instagram')}</option>
          <option value={`${LinkType.email}`}>{t('email')}</option>
          <option value={`${LinkType.linkedIn}`}>{t('linkedIn')}</option>
          <option value={`${LinkType.website}`}>{t('website')}</option>
          <option value={`${LinkType.youtube}`}>{t('youtube')}</option>
          <option value={`${LinkType.zoom}`}>{t('zoom')}</option>
        </Select>
      </LinkTypeInput>

      <LinkNameInput>
        <Input
          style={{ marginTop: 16, marginBottom: 4 }}
          value={link.name}
          placeholder={link.type ? t('displayName') : ''}
          onChange={text => onLinkNameChange(text)}
        />
      </LinkNameInput>

      <LinkUrlInput>
        <Input
          style={{ marginTop: 16, marginBottom: 4 }}
          value={link.url}
          placeholder={link.type ? editLinkPlaceHolder(link.type) : ''}
          onChange={text => onLinkUrlChange(text)}
        />
      </LinkUrlInput>

      {canBeDeleted ? (
        <DeleteIcon
          fill={palette.text.primary}
          style={{ cursor: 'pointer', marginLeft: 15 }}
          onClick={onLinkDelete}
        />
      ) : (
        <div style={{ width: 18, marginLeft: 15 }} />
      )}
    </FlexRow>
  )
}

// MARK: - Styles

const LinkTypeInput = styled.div`
  margin-right: 1%;
  width: 120px;
`

const LinkNameInput = styled.div`
  margin-right: 1%;
  width: 20%;
`

const LinkUrlInput = styled.div`
  flex: 1;
  width: 60%;
`
