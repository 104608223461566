import { Store } from 'redux'
import { pushLoading, popLoading } from 'src/redux/reducers/app'
import store from 'src/redux/store'
import { IError } from 'src/repository/Error'

export {}

declare global {
  type Success<T> = {
    type: 'success'
    retCode?: number
    value: T
    success: true
  }

  type Failure = {
    type: 'failure'
    retCode?: number
    error: IError
    success: false
  }

  type Result<T> = Success<T> | Failure

  class CancelablePromise<T> extends Promise<T> {
    readonly cancel: () => void
  }

  interface Promise<T> {
    asCancelable(): CancelablePromise<T>
    together: <U>(promise: Promise<Result<U>>) => CancelablePromise<[Result<T>, Result<U>]>
    first: (action: () => void) => CancelablePromise<T>
    loading(key: string, persistLoading?: (value: T) => boolean): CancelablePromise<T>
    wait: <U>(
      this: Promise<Result<U>>,
      success?: (value: T extends Result<U> ? U : unknown) => void,
      failure?: (error: IError) => void,
    ) => CancelablePromise<U>
    done: <U>(
      this: Promise<Result<U>>,
      success?: (value: T extends Result<U> ? U : unknown) => void,
      failure?: (error: IError) => void,
    ) => void
  }
}

/* eslint-disable no-extend-native */
Promise.prototype.together = async function <T, U>(
  promise: Promise<T>,
): CancelablePromise<[Result<T>, Result<U>]> {
  return new Promise(async (resolve, reject) => {
    try {
      const responses: [Result<T>, Result<U>] = await Promise.all([this, promise])
      resolve(responses)
    } catch (error) {
      reject(error)
    }
  }).asCancelable()
}

/* eslint-disable no-extend-native */
Promise.prototype.first = async function <U>(action: () => void): CancelablePromise<U> {
  return new Promise(async (resolve, reject) => {
    try {
      action()
      const response: Result<U> = await this
      resolve(response)
    } catch (error) {
      reject(error)
    }
  }).asCancelable()
}

/* eslint-disable no-extend-native */
Promise.prototype.loading = async function <U>(
  key: string,
  persistLoading?: (value: Result<U>) => boolean,
  reduxStore: Store = store,
): CancelablePromise<void> {
  return new Promise(async (resolve, reject) => {
    try {
      reduxStore.dispatch(pushLoading(key))
      const response: Result<U> = await this

      if (persistLoading?.(response)) {
        resolve(response)
      } else {
        reduxStore.dispatch(popLoading(key))
        resolve(response)
      }
    } catch (error) {
      reject(error)
    }
  }).asCancelable()
}

/* eslint-disable no-extend-native */
Promise.prototype.wait = async function <U>(
  this: Promise<Result<U>>,
  success?: (value: U) => void,
  failure?: (error: IError) => void,
): CancelablePromise<U> {
  return new Promise<U>(async (resolve, reject) => {
    try {
      const response: Result<U> = await this
      if (
        typeof response['success'] === 'boolean' &&
        (response.type === 'success' || response.type === 'failure')
      ) {
        if (response.success) success?.(response.value)
        else failure?.(response.error)
        resolve(response.value)
      } else {
        reject(NSError.apiDisplayable({ title: 'Unwrap error', message: 'Not a Result Promise' }))
      }
    } catch (error) {
      reject(error)
    }
  }).asCancelable()
}

/* eslint-disable no-extend-native */
Promise.prototype.done = async function <U>(
  this: Promise<Result<U>>,
  success?: (value: U) => void,
  failure?: (error: IError) => void,
): void {
  const response: Result<U> = await this
  if (
    typeof response['success'] === 'boolean' &&
    (response.type === 'success' || response.type === 'failure')
  ) {
    if (response.success) return success?.(response.value)
    else failure?.(response.error)
  } else {
    throw NSError.apiDisplayable({ title: 'Unwrap error', message: 'Not a Result Promise' })
  }
}

/* eslint-disable no-extend-native */
Promise.prototype.asCancelable = function () {
  let cancel: (reason: { cancelled: boolean }) => void
  const wrappedPromise = new Promise((resolve, reject) => {
    cancel = reject
    Promise.resolve(this).then(resolve).catch(reject)
  }) as any
  wrappedPromise.cancel = () => {
    cancel({ cancelled: true })
  }
  return wrappedPromise as CancelablePromise<unknown>
}
