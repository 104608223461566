import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FadeIn, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import styled from 'styled-components'

export const RoomLocationZoom: React.FC<{
  input: { url: string; id: string; password: string }
  onInputChange: (input: { url: string; id: string; password: string }) => void
}> = ({ input, onInputChange }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [selectedInput, setSelectedInput] = useState({ url: false, id: false, password: false })

  // MARK: - Render

  return (
    <Row>
      <Col>
        <Row>
          <Input
            style={{ width: 420, maxWidth: 420 }}
            isRequired
            label={t('zoomMeetingUrl')}
            placeholder={t('zoomMeetingUrlPlaceholder')}
            value={input.url}
            onChange={text => onInputChange({ ...input, url: text })}
            onFocus={() => setSelectedInput(prev => ({ ...prev, url: true }))}
            onBlur={() => setSelectedInput(prev => ({ ...prev, url: false }))}
          />

          {selectedInput.url && (
            <DescriptionContainer>
              <LabelMedium>{t('zoomMeetingUrlDescription')}</LabelMedium>
            </DescriptionContainer>
          )}
        </Row>

        <Row>
          <Input
            style={{ width: 420, maxWidth: 420 }}
            isRequired
            label={t('zoomMeetingId')}
            placeholder={t('zoomMeetingIdPlaceholder')}
            value={input.id}
            onChange={text => onInputChange({ ...input, id: text })}
            onFocus={() => setSelectedInput(prev => ({ ...prev, id: true }))}
            onBlur={() => setSelectedInput(prev => ({ ...prev, id: false }))}
          />

          {selectedInput.id && (
            <DescriptionContainer>
              <LabelMedium>{t('zoomMeetingIdDescription')}</LabelMedium>
            </DescriptionContainer>
          )}
        </Row>

        <Row>
          <Input
            style={{ width: 420, maxWidth: 420 }}
            label={t('zoomMeetingPassword')}
            placeholder={t('zoomMeetingPasswordPlaceholder')}
            value={input.password}
            onChange={text => onInputChange({ ...input, password: text })}
            onFocus={() => setSelectedInput(prev => ({ ...prev, password: true }))}
            onBlur={() => setSelectedInput(prev => ({ ...prev, password: false }))}
          />

          {selectedInput.password && (
            <DescriptionContainer>
              <LabelMedium>{t('zoomMeetingPasswordDescription')}</LabelMedium>
            </DescriptionContainer>
          )}
        </Row>
      </Col>
    </Row>
  )
}

// MARK: - Styles

const DescriptionContainer = styled(FadeIn)`
  align-items: center;
  align-self: center;
  border-left: 2px solid ${({ theme }) => theme.palette.text.primary};
  display: flex;
  height: 72%;
  margin-left: 20px;
  padding-left: 20px;
`
