import { Calendar } from 'react-date-range'
import styled from 'styled-components'

export const SingleDatePicker = styled(Calendar)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 8px;
  min-width: 98%;
  overflow: hidden;

  .rdrWeekDays {
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border-color: ${({ theme }) => theme.palette.background.separator};
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 14px;
    margin-top: -10px;
    margin: -8px -10px 6px -10px;
    padding: 0px 10px;
  }

  .rdrWeekDay {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin: -3px 0px;
    max-width: 36px;
  }

  .rdrMonth {
    min-width: 100%;
    max-width: 250px;
  }

  .rdrDay {
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 14px;
    max-height: 30px;
    max-width: 30px;
    margin: 2px;
    padding: 0px;
    &:hover {
      background: ${({ theme }) => theme.palette.background.secondary};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .rdrSelected {
    border-radius: 4px;
    min-height: 28px;
    max-width: 30px;
    margin-top: -4px;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: 0;
    border: 0;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: ${({ theme }) => theme.palette.orange};
    margin-bottom: -5px;
  }

  .rdrDayNumber span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 400;
  }

  .rdrDayNumber {
    &:after {
      color: transparent;
    }
  }

  .rdrDayPassive span {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .rdrDayDisabled {
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${({ theme }) => theme.palette.background.disabled};
  }

  // Header
  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    margin: -6px 0px -10px 0px;
  }

  .rdrMonthAndYearWrapper {
    background-color: ${({ theme }) => theme.palette.background.secondary};
  }

  .rdrMonthAndYearPickers select {
    background-color: ${({ theme }) => theme.palette.background.secondary};
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    font-size: 14px;
  }

  .rdrYearPicker,
  .rdrMonthPicker {
    color: ${({ theme }) => theme.palette.text.primary};
    border-radius: 4px;
    margin: -5px 0px;
    &:hover {
      background: ${({ theme }) => theme.palette.background.tertiary};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .rdrDateDisplayWrapper {
    max-width: 260px;
  }

  .rdrMonths {
    max-width: 260px;
    margin-bottom: -10px;
  }

  .rdrMonthAndYearWrapper {
    max-width: 260px;
  }
  // Buttons
  .rdrNextPrevButton {
    background-color: ${({ theme }) => theme.palette.background.secondary};
    border-radius: 100;
  }

  .rdrNextButton i {
    border-left-color: ${({ theme }) => theme.palette.text.primary};
    margin-right: 0px;
  }

  .rdrPprevButton i {
    border-right-color: ${({ theme }) => theme.palette.text.primary};
    margin-left: 0px;
  }
`
