import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Component, ComponentType } from 'src/repository/types'

export const QAModal: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.qa}
      component={component}
      header={t('qa')}
      titlePlaceholder={t('qaTitlePlaceholder')}
      descriptionPlaceholder={t('qaDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.qa,
          input: { key: 'qa', anonymous_enabled: false },
        })
      }
    />
  )
}
