import { RoomLocationEventUrl } from './RoomLocationEventUrl'
import { RoomLocationTBA } from './RoomLocationTBA'
import { RoomLocationVenue } from './RoomLocationVenue'
import { RoomLocationZoom } from './RoomLocationZoom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocationIcon } from 'src/assets/images/svg/LocationIcon'
import { ZoomIcon } from 'src/assets/images/svg/ZoomIcon'
import { Col } from 'src/features/common/CommonStyles'
import { TabBar } from 'src/features/common/TabBar'
import { DashboardSectionHeader } from 'src/features/platform/dashboard/DashboardSectionHeader'
import { RoomLocation } from 'src/repository/types'

type LocationType = 'in_person' | 'virtual' | 'zoom' | 'tba'

export const RoomLocationSection: React.FC<{
  defaultLocation?: RoomLocation
  hideIcon?: boolean
  location: RoomLocation
  onLocationChange: (location: RoomLocation) => void
}> = ({ defaultLocation, hideIcon, location, onLocationChange }) => {
  // MARK: - Hooks

  const locationTypes: LocationType[] = ['zoom', 'virtual', 'in_person', 'tba']
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(
    locationTypes.findIndex(item => item === location.type),
  )

  const venue = (() => {
    if (location.type === 'in_person') return location.venue
    return null
  })()

  const tbaDescription = (() => {
    if (location.type === 'tba') return location.description
    return ''
  })()

  const url = (() => {
    if (location.type === 'virtual') return location.url
    return ''
  })()

  const zoomInfo = (() => {
    if (location.type === 'zoom')
      return { id: location.id, url: location.url, password: location.password ?? '' }
    else return { url: '', id: '', password: '' }
  })()

  // MARK: - Effects

  useEffect(() => {
    const selectedLocationType = locationTypes[selectedTab]
    if (location.type !== selectedLocationType && defaultLocation?.type === selectedLocationType) {
      onLocationChange(defaultLocation)
      return
    }

    if (location.type !== selectedLocationType) {
      switch (selectedLocationType) {
        case 'in_person':
          onLocationChange({ type: 'in_person', venue: null })
          break
        case 'tba':
          onLocationChange({ type: 'tba', description: '' })
          break
        case 'virtual':
          onLocationChange({ type: 'virtual', url: '' })
          break
        case 'zoom':
          onLocationChange({ type: 'zoom', id: '', url: '', password: '' })
          break
      }
    }
  }, [selectedTab])

  // MARK: - Render

  return (
    <Col style={{ marginTop: 10, marginBottom: 20 }}>
      <DashboardSectionHeader
        title={t('location')}
        description={t('locationDescription')}
        Icon={!hideIcon ? LocationIcon : undefined}
      />

      <TabBar
        style={{ marginBottom: 8 }}
        itemWidth={151.5}
        onIndexChange={setSelectedTab}
        initialIndex={selectedTab}
        items={[
          {
            title: t('zoomWithPrefix'),
            icon: <ZoomIcon size={32} style={{ marginLeft: -12 }} />,
            content: (
              <RoomLocationZoom
                input={zoomInfo}
                onInputChange={info => onLocationChange({ type: 'zoom', ...info })}
              />
            ),
          },
          {
            title: t('virtualWithPrefix'),
            content: (
              <RoomLocationEventUrl
                url={url}
                onUrlChange={text => onLocationChange({ type: 'virtual', url: text })}
              />
            ),
          },
          {
            title: t('inPersonWithPrefix'),
            content: (
              <RoomLocationVenue
                initialVenue={venue}
                handleVenueSelected={item => onLocationChange({ type: 'in_person', venue: item })}
              />
            ),
          },
          {
            title: t('TBAWithPrefix'),
            content: (
              <RoomLocationTBA
                description={tbaDescription}
                onDescriptionChange={description => onLocationChange({ type: 'tba', description })}
              />
            ),
          },
        ]}
      />
    </Col>
  )
}
