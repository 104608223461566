import { availableComponentTypes } from './types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { FlexRow, LabelLarge, LabelSmall, ShadowBox } from 'src/features/common/CommonStyles'
import { getLocalization } from 'src/redux/reducers/app'
import { ComponentType } from 'src/repository/types'
import {
  componentDescription,
  componentImageUrl,
  componentName,
} from 'src/utils/helpers/componentHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const ComponentTypeSelection: React.FC<{
  onComponentTypeClick: (componentType: ComponentType) => void
}> = ({ onComponentTypeClick }) => {
  // MARK: - Hooks

  const targetRef = useRef<HTMLDivElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  // MARK: - Effects

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [])

  // MARK: - Render

  return (
    <Container ref={targetRef}>
      {dimensions.width &&
        availableComponentTypes.map(componentType => (
          <ComponentTypeItem
            key={componentType}
            width={dimensions.width / 3}
            height={dimensions.width / 3 + 32}
            componentType={componentType}
            onComponentTypeClick={onComponentTypeClick}
          />
        ))}
    </Container>
  )
}

const ComponentTypeItem: React.FC<{
  width: number
  height: number
  componentType: ComponentType
  onComponentTypeClick: (componentType: ComponentType) => void
}> = ({ width, height, componentType, onComponentTypeClick }) => {
  // MARK: - Hooks

  const localization = useSelect(state => getLocalization(state.app))

  // MARK: - Render

  return (
    <ItemContainer width={width} height={height}>
      <InnerContainer onClick={() => onComponentTypeClick(componentType)}>
        <img
          src={componentImageUrl(componentType)}
          style={{ width: 72, height: 72, marginTop: 16 }}
          alt=""
        />
        <LabelLarge style={{ textAlign: 'center', marginBottom: 4, marginTop: 16 }}>
          {componentName(componentType, localization)}
        </LabelLarge>
        <LabelSmall style={{ textAlign: 'center' }}>
          {componentDescription(componentType, localization)}
        </LabelSmall>
      </InnerContainer>
    </ItemContainer>
  )
}

// MARK: - Styles

const ItemContainer = styled.div<{ width: number; height: number }>`
  align-items: center;
  display: flex;
  height: ${({ height }) => height}px;
  justify-content: center;
  margin-bottom: 8px;
  width: ${({ width }) => width}px;
`

const InnerContainer = styled(ShadowBox)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 97%;
  justify-content: flex-start;
  margin: 10px 8px 10px 8px;
  max-width: 97%;
  padding: 16px 8px 8px 8px;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.orange : 'transparent')};
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
      }
    `}
`

const Container = styled(FlexRow)`
  flex-wrap: wrap;
  margin: 0px -8px;
  padding: 0px 0px 24px 0px;
`
