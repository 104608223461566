import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { Component } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'

export const DashboardComponentModalFooter: React.FC<{
  isLoading: boolean
  component?: Component
  onBackClick: () => void
  onSaveClick: () => void
}> = ({ isLoading, component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <FooterWrapper>
      {!isLoading && !component && (
        <SecondaryButton
          title={t('back')}
          style={{ marginRight: 16, width: 120 }}
          onClick={isLoading ? () => {} : onBackClick}
        />
      )}

      <PrimaryButton
        isLoading={isLoading}
        style={{ width: 120 }}
        title={t('save')}
        onClick={onSaveClick}
      />
    </FooterWrapper>
  )
}
