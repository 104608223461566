import { map } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { DownloadIcon } from 'src/assets/images/svg/DownloadIcon'
import { VisibilityOffIcon } from 'src/assets/images/svg/VisibilityOffIcon'
import { VisibilityOnIcon } from 'src/assets/images/svg/VisibilityOnIcon'
import { DashboardTable, DashboardTableContainer } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { User } from 'src/repository/types'
import { imageUrls } from 'src/utils/config'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { useTheme } from 'styled-components'

type Audience = User & { is_blocked: boolean; modified_at: number }

export const DashboardAudienceTable: React.FC<{
  users: Audience[]
  isLoading: boolean
  isPageLoading: boolean
  query: string
  onQueryChange: (query: string) => void
  onSendInviteClick: () => void
  onEndReached: () => void
  onDeleteClick: (selectedUsers: User[]) => void
  onImportClick?: (file: File) => void
  onExportClick: (selectedUsers: User[]) => void
  onDropdownOptionSelect: (index: number, user: Audience[]) => void
}> = ({
  users,
  query,
  onQueryChange,
  onEndReached,
  onImportClick,
  onExportClick,
  onDeleteClick,
  isLoading,
  isPageLoading,
  onSendInviteClick,
  onDropdownOptionSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  const [selectedUsers, setSelectedUsers] = useState<Audience[]>([])
  const [status, setStatus] = useState('members')

  const rowStatus = useCallback((user: Audience) => {
    return {
      hintWidth: 260,
      hint: user.is_blocked
        ? t('blocked', { date: prettyDateString(user.modified_at, true) })
        : t('joined', { date: prettyDateString(user.modified_at, true) }),
      color: user.is_blocked ? palette.red : palette.green,
    }
  }, [])

  // MARK: - Effects

  useEffect(() => {
    setSelectedUsers(prev => {
      const userIds = new Set(users.map(item => item.id))
      return prev.filter(({ id }) => userIds.has(id))
    })
  }, [users])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = useCallback(
    (option: number) => {
      onDropdownOptionSelect(option, selectedUsers)
    },
    [users, selectedUsers],
  )

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(selectedUsers)
  }, [selectedUsers])

  const handleExportClick = useCallback(() => {
    onExportClick(selectedUsers)
  }, [selectedUsers])

  const handleToggleAllSelect = useCallback(
    (isOn: boolean) => {
      setSelectedUsers(isOn ? users : [])
    },
    [users],
  )

  const handleCheckboxClick = useCallback(
    (user: Audience) => (isOn: boolean) => {
      const tempUsers = isOn
        ? [...selectedUsers, user]
        : [...selectedUsers].filter(({ id }) => user.id !== id)
      setSelectedUsers(tempUsers)
    },
    [selectedUsers],
  )

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('sendInvitation')}
        onPrimaryActionClick={onSendInviteClick}
        searchText={query}
        setSearchText={onQueryChange}
        status={status}
        setStatus={setStatus}
        deleteTitle={t('removeSelectedAudience')}
        exportTitle={t('exportAudience')}
        selectTitle={t('type')}
        exportLoadingKey={'audience_export'}
        onExportClick={handleExportClick}
        onDeleteClick={handleDeleteClick}
        statusOptions={[{ key: 'members', label: t('members') }]}
        columns={[
          { label: t('overview'), width: 250 },
          { label: t('status'), width: 156 },
        ]}
        dropdownOptions={[
          { title: t('removeMember'), icon: DeleteIcon },
          { title: t('block'), icon: VisibilityOffIcon },
          { title: t('downloadReport'), icon: DownloadIcon },
        ]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={users.length}
        selectedCount={selectedUsers.length}
        toggleAllSelected={handleToggleAllSelect}
      />

      <DashboardTable
        data={users}
        isLoading={isLoading}
        isPageLoading={isPageLoading}
        onEndReached={onEndReached}
        emptyStateTitle={t('noMembersFound')}
        emptyStateSubtitle={query ? t('noMembersFoundForTheQuery') : t('onceMembersJoinToLobby')}
        renderItem={(user: User & { is_blocked: boolean; modified_at: number }, index: number) => (
          <DashboardTableRow
            key={user.id}
            index={index}
            image={user.image_url}
            imagePlaceholder={imageUrls.userPlaceholder}
            title={user.first_name + ' ' + user.last_name}
            description={user.user_name}
            status={rowStatus(user)}
            dropdownOptions={[
              { title: t('removeMember'), icon: DeleteIcon },
              user.is_blocked
                ? { title: t('unblockMember'), icon: VisibilityOnIcon }
                : { title: t('blockMember'), icon: VisibilityOffIcon },
            ]}
            handleOptionsDropdownOptionClick={option => onDropdownOptionSelect(option, [user])}
            selected={map(selectedUsers, 'id').includes(user.id)}
            onCheckBoxClick={handleCheckboxClick(user)}
          />
        )}
      />
    </DashboardTableContainer>
  )
}
