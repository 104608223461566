import React from 'react'
import { useTranslation } from 'react-i18next'
import config from 'src/utils/config'
import styled, { useTheme } from 'styled-components'

export const PrivacyCheckBox: React.FC<{
  onCheckBoxClick: (isOn: boolean) => void
}> = ({ onCheckBoxClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  // MARK: - Render

  // We are using localization strings to create clickable links inside a label.
  const renderCheckboxLabel = () => {
    const infoString = t('termsConditionsInfo')

    // Localization strings and keys
    const [termsWord, termsKey] = [t('termsOfService'), '_terms_']
    const [privacyWord, privacyKey] = [t('privacyPolicy'), '_privacy_']
    const [agreeWord, agreeKey] = [t('agreeAndContinue'), '_agree_']

    const findReplace = (info: string, word: string, key: string) => {
      const subStrings = info.split(word)
      subStrings.splice(subStrings.length - 1, 0, key)
      return subStrings.filter(s => s.length !== 0)
    }

    const words = findReplace(infoString, termsWord, termsKey)
      .map(word => {
        return word.includes(privacyWord) ? findReplace(word, privacyWord, privacyKey) : word
      })
      .flatMap(word => word)
      .map(word => {
        return word.includes(agreeWord) ? findReplace(word, agreeWord, agreeKey) : word
      })
      .flatMap(word => word)

    return (
      <label
        htmlFor={'privacy-and-terms'}
        style={{ textAlign: 'left', lineHeight: 1, marginTop: 12, marginBottom: 8 }}>
        {words.map(word => {
          if (word === termsKey) {
            return (
              <a
                key={termsKey}
                target="_blank"
                rel="noopener noreferrer"
                href={config.platformUrl + '/privacy-policy'}
                style={{
                  fontSize: 13,
                  color: palette.orange,
                  textDecoration: 'underline',
                }}>
                {termsWord}
              </a>
            )
          } else if (word === privacyKey) {
            return (
              <a
                key={privacyKey}
                target="_blank"
                rel="noopener noreferrer"
                href={config.platformUrl + '/privacy-policy'}
                style={{
                  fontSize: 13,
                  color: palette.orange,
                  textDecoration: 'underline',
                }}>
                {privacyWord}
              </a>
            )
          } else if (word === agreeKey) {
            return (
              <span
                key={agreeKey}
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: palette.text.primary,
                }}>
                {agreeWord}
              </span>
            )
          } else {
            return (
              <span
                key={word}
                style={{
                  fontSize: 13,
                  color: palette.text.primary,
                }}>
                {word}
              </span>
            )
          }
        })}
      </label>
    )
  }

  return <Container>{renderCheckboxLabel()}</Container>
}

// MARK: - Styles

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
`
