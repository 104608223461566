import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, LabelLarge } from 'src/features/common/CommonStyles'
import { Loader } from 'src/features/common/Loader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'

export const PurchasePaymentForm: React.FC = () => {
  // MARK: - Hooks

  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const [, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // MARK: - Handlers

  const handleSubmit = async () => {
    if (!stripe || !elements) return

    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: window.location.href },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? '')
    } else {
      setMessage('An unexpected error occurred.')
    }
    setIsLoading(false)
  }

  // MARK: - Render

  return (
    <Col>
      <LabelLarge style={{ marginBottom: 12 }}>{t('paymentDetails')}</LabelLarge>
      {!stripe || !elements ? (
        <Loader />
      ) : (
        <>
          <PaymentElement id="payment-element" />
          <PrimaryButton
            style={{ marginTop: 20, width: '100%' }}
            isLoading={isLoading}
            disabled={isLoading || !stripe || !elements}
            title={t('purchase')}
            onClick={() => handleSubmit()}
          />
        </>
      )}
    </Col>
  )
}
