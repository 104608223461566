import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Authentication } from 'src/features/authentication'
import { AppRoute } from 'src/features/common/AppRoute'
import { LayoutDefault } from 'src/features/common/LayoutDefault'
import { Home } from 'src/features/home'
import { NotFound } from 'src/features/notFound'
import { CreatorLayout } from 'src/features/platform/common/CreatorLayout'
import { RoomDashboard } from 'src/features/platform/dashboard'
import { RoomList } from 'src/features/platform/roomList'
import { Pricing } from 'src/features/pricing'
import { PrivacyPolicy } from 'src/features/privacyPolicy'
import { InvitationRedirect } from 'src/features/redirects/InvitationRedirect'
import { RequestDemo } from 'src/features/requestDemo'

const Routes = () => (
  <Switch>
    <AppRoute exact path="/privacy-policy" component={PrivacyPolicy} layout={LayoutDefault} />

    <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />

    <AppRoute exact path="/demo" component={RequestDemo} layout={LayoutDefault} />

    <Route
      path="/authentication"
      render={({ location }) => {
        const params = new URLSearchParams(location.search)
        const invitationId = params.get('invitation_id')
        return <Authentication invitationId={invitationId} />
      }}
    />

    <AppRoute exact path="/creator/rooms" component={RoomList} layout={CreatorLayout} />

    <Route
      path="/invitation/:invitationId"
      render={({ match }) => <InvitationRedirect invitationId={match.params.invitationId} />}
    />

    <Route
      path="/creator/dashboard/room/:roomId"
      render={({ match }) => (
        <CreatorLayout roomId={match.params.roomId}>
          <RoomDashboard roomId={match.params.roomId} />
        </CreatorLayout>
      )}
    />

    <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
    <AppRoute component={NotFound} layout={LayoutDefault} />
  </Switch>
)

export default Routes
