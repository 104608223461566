import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { TrackedUrl } from 'src/repository/types'
import { useTheme } from 'styled-components'

export const DashboardWebPageTrackedUrlTable: React.FC<{
  trackedUrls: TrackedUrl[]
  isLoading: boolean
  onCreateTrackedUrlClick: () => void
  handleTrackedUrlDropdownItemSelect: (index: number, trackedUrl: TrackedUrl) => void
  handleTrackedUrlBulkDropdownItemSelect: (index: number, trackedUrls: TrackedUrl[]) => void
}> = ({
  trackedUrls,
  isLoading,
  onCreateTrackedUrlClick,
  handleTrackedUrlDropdownItemSelect,
  handleTrackedUrlBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [selectedTrackedUrlIds, setSelectedTrackedUrlIds] = useState<string[]>([])
  const [status, setStatus] = useState('all')

  // MARK: - Properties

  let currentTrackedUrls = trackedUrls.filter(trackedUrl =>
    trackedUrl.url.toLowerCase().includes(searchText.toLowerCase()),
  )

  const isCompleted = (trackedUrl: TrackedUrl) =>
    trackedUrl.notify_me_limit && trackedUrl.count >= trackedUrl.notify_me_limit

  if (status === 'completed') {
    currentTrackedUrls = currentTrackedUrls.filter(isCompleted)
  } else if (status === 'pending') {
    currentTrackedUrls = currentTrackedUrls.filter(trackedUrl => !isCompleted(trackedUrl))
  }

  // MARK: - Effects

  useEffect(() => {
    setSelectedTrackedUrlIds(prev => {
      const trackedUrlIds = trackedUrls.map(item => item.id)
      return prev.filter(id => trackedUrlIds.includes(id))
    })
  }, [trackedUrls])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleTrackedUrlBulkDropdownItemSelect(
      option,
      trackedUrls.filter(trackedUrl => selectedTrackedUrlIds.includes(trackedUrl.id)),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('trackUrl')}
        onPrimaryActionClick={onCreateTrackedUrlClick}
        searchText={searchText}
        setSearchText={setSearchText}
        status={status}
        setStatus={setStatus}
        statusOptions={[
          { key: 'pending', label: t('pending') },
          { key: 'completed', label: t('completed') },
          { key: 'all', label: t('all') },
        ]}
        columns={[
          { label: t('overview'), width: 250 },
          { label: t('status'), width: 180 },
        ]}
        dropdownOptions={[{ title: t('delete'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentTrackedUrls.length}
        selectedCount={selectedTrackedUrlIds.length}
        toggleAllSelected={isOn => {
          setSelectedTrackedUrlIds(isOn ? currentTrackedUrls.map(trackedUrl => trackedUrl.id) : [])
        }}
      />

      <DashboardTable
        data={currentTrackedUrls}
        isLoading={isLoading}
        emptyStateTitle={t('noUrlsTracked')}
        emptyStateSubtitle={t('onceUrlsTracked')}
        renderItem={(trackedUrl: TrackedUrl, index: number) => (
          <DashboardTableRow
            key={trackedUrl.id}
            index={index}
            title={trackedUrl.name}
            description={trackedUrl.url}
            status={{
              label: trackedUrl.count >= trackedUrl.notify_me_limit ? t('active') : t('inactive'),
              hint: '',
              color:
                trackedUrl.count >= trackedUrl.notify_me_limit ? palette.green : palette.orange,
            }}
            dropdownOptions={[
              { title: t('edit'), icon: EditIcon },
              { title: t('delete'), icon: DeleteIcon },
            ]}
            handleOptionsDropdownOptionClick={option =>
              handleTrackedUrlDropdownItemSelect(option, trackedUrl)
            }
            selected={selectedTrackedUrlIds.includes(trackedUrl.id)}
            onCheckBoxClick={isOn => {
              setSelectedTrackedUrlIds(prev =>
                isOn ? [...prev, trackedUrl.id] : prev.filter(item => trackedUrl.id !== item),
              )
            }}
          />
        )}
      />
    </DashboardTableContainer>
  )
}
