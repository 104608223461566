import { LabelMedium, Row } from './CommonStyles'
import { Loader } from './Loader'
import React, { useCallback } from 'react'
import styled, { keyframes, useTheme } from 'styled-components'

export const PrimaryButton: React.FC<{
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  title: string
  onClick: () => void
  shimmer?: boolean
  isLoading?: boolean
  disabled?: boolean
  size?: 'default' | 'sm'
}> = ({ style, shimmer, title, labelStyle, onClick, isLoading, disabled, size = 'sm' }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Handlers

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled) return

      event.stopPropagation()
      onClick()
    },
    [disabled, onClick],
  )

  // MARK: - Render

  return (
    <Container
      shimmer={shimmer}
      style={{
        userSelect: 'none',
        backgroundColor: disabled ? palette.background.disabled : palette.orange,
        cursor: disabled ? 'not-allowed' : 'pointer',
        height: size === 'sm' ? 40 : 54,
        ...style,
      }}
      onClick={handleButtonClick}>
      {isLoading ? (
        <Loader size={20} thickness={2} color={palette.white} />
      ) : (
        <LabelMedium
          style={{
            color: palette.white,
            letterSpacing: 0.3,
            fontSize: 14,
            fontWeight: 600,
            ...labelStyle,
          }}>
          {title}
        </LabelMedium>
      )}
    </Container>
  )
}

// MARK: - Styles

const ShimmerKeyframe = keyframes`
  0% { background-position: -200px top; }
  50% { background-position: 200px top; }
  100% { background-position: 200px top; }
`

const Container = styled(Row)<{ shimmer?: boolean }>`
  align-items: center;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: ${({ shimmer }) => (shimmer ? ShimmerKeyframe : '')};
  background: #f36538 -webkit-gradient(linear, 100% 0, 0 0, from(#f36538), color-stop(0.5, rgba(255, 255, 255, 0.2)), to(#f36538));
  background-position: -200px;
  background-repeat: no-repeat;
  background-size: ${({ shimmer }) => (shimmer ? '80% 100%' : '0px 0px')};
  border-radius: 6px;
  justify-content: center;
  padding: 0px 16px;
`
