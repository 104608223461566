import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { WebListItem } from 'src/repository/types'

export const DashboardWebListItemTable: React.FC<{
  webListItems: WebListItem[]
  isLoading: boolean
  onCreateWebListItemClick: () => void
  handleWebListItemDropdownItemSelect: (index: number, webListItem: WebListItem) => void
  handleWebListItemBulkDropdownItemSelect: (index: number, webListItems: WebListItem[]) => void
}> = ({
  webListItems,
  isLoading,
  onCreateWebListItemClick,
  handleWebListItemDropdownItemSelect,
  handleWebListItemBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [selectedWebListItemIds, setSelectedWebListItemIds] = useState<string[]>([])
  // MARK: - Properties

  const currentWebListItems = webListItems.filter(webListItem =>
    (webListItem.title + webListItem.overview).toLowerCase().includes(searchText.toLowerCase()),
  )

  // MARK: - Effects

  useEffect(() => {
    setSelectedWebListItemIds(prev => {
      const webListItemIds = webListItems.map(item => item.id)
      return prev.filter(id => webListItemIds.includes(id))
    })
  }, [webListItems])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleWebListItemBulkDropdownItemSelect(
      option,
      webListItems.filter(webListItem => selectedWebListItemIds.includes(webListItem.id)),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('addItem')}
        onPrimaryActionClick={onCreateWebListItemClick}
        searchText={searchText}
        setSearchText={setSearchText}
        columns={[{ label: t('overview'), width: 250 }]}
        dropdownOptions={[{ title: t('delete'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentWebListItems.length}
        selectedCount={selectedWebListItemIds.length}
        toggleAllSelected={isOn => {
          setSelectedWebListItemIds(isOn ? currentWebListItems.map(item => item.id) : [])
        }}
      />

      <DashboardTable
        data={currentWebListItems}
        isLoading={isLoading}
        emptyStateTitle={t('noItemAdded')}
        emptyStateSubtitle={t('onceItemAdded')}
        renderItem={(webListItem: WebListItem, index: number) => (
          <DashboardTableRow
            key={webListItem.id}
            index={index}
            image={webListItem.image_url}
            title={webListItem.title}
            description={webListItem.overview}
            dropdownOptions={[
              { title: t('edit'), icon: EditIcon },
              { title: t('delete'), icon: DeleteIcon },
            ]}
            handleOptionsDropdownOptionClick={option =>
              handleWebListItemDropdownItemSelect(option, webListItem)
            }
            selected={selectedWebListItemIds.includes(webListItem.id)}
            onCheckBoxClick={isOn => {
              setSelectedWebListItemIds(prev =>
                isOn ? [...prev, webListItem.id] : prev.filter(item => webListItem.id !== item),
              )
            }}
          />
        )}
      />
    </DashboardTableContainer>
  )
}
