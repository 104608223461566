import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 13V15C19 17.8284 19 19.2426 18.1213 20.1213C17.2426 21 15.8284 21 13 21H11C8.17157 21 6.75736 21 5.87868 20.1213C5 19.2426 5 17.8284 5 15V9C5 6.17157 5 4.75736 5.87868 3.87868C6.75736 3 8.17157 3 11 3H12"
      stroke={fill}
      strokeLinecap="round"
    />
    <path
      d="M18.5 3C18.5 2.72386 18.2761 2.5 18 2.5C17.7239 2.5 17.5 2.72386 17.5 3L18.5 3ZM17.5 9C17.5 9.27614 17.7239 9.5 18 9.5C18.2761 9.5 18.5 9.27614 18.5 9L17.5 9ZM17.5 3L17.5 9L18.5 9L18.5 3L17.5 3Z"
      fill={fill}
    />
    <path
      d="M21 6.5C21.2761 6.5 21.5 6.27614 21.5 6C21.5 5.72386 21.2761 5.5 21 5.5L21 6.5ZM15 5.5C14.7239 5.5 14.5 5.72386 14.5 6C14.5 6.27614 14.7239 6.5 15 6.5L15 5.5ZM21 5.5L15 5.5L15 6.5L21 6.5L21 5.5Z"
      fill={fill}
    />
    <path d="M9 13L15 13" stroke={fill} strokeLinecap="round" />
    <path d="M9 9L13 9" stroke={fill} strokeLinecap="round" />
    <path d="M9 17L13 17" stroke={fill} strokeLinecap="round" />
  </svg>
)

export const WebPageIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
