import { FlexCol, ShadowBox } from '../common/CommonStyles'
import { AuthenticationContinue } from './AuthenticationContinue'
import { AuthenticationEnterPassword } from './AuthenticationEnterPassword'
import { AuthenticationHeader } from './AuthenticationHeader'
import { AuthenticationRegisterForm } from './AuthenticationRegisterForm'
import { AuthStep } from './types'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getAuthStep, getAuthUser, login, setAuthStep } from 'src/redux/reducers/authentication'
import { getEntity } from 'src/redux/reducers/entity'
import { Invitation } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const Authentication: React.FC<{
  invitationId: string | null
}> = ({ invitationId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate, routes, match, location } = useNavigation()
  const { width } = useWindowDimensions()
  const authUser = useSelect(state => getAuthUser(state.authentication))
  const authStep = useSelect(state => getAuthStep(state.authentication))
  const invitation = useSelect(state =>
    getEntity<Invitation>(state.entity, 'invitation', invitationId),
  )
  const route = routes.authentication

  // MARK: - Effects

  useEffect(() => {
    if (authStep === AuthStep.directLogin)
      dispatch(login(authUser.email, authUser.phoneNumber, invitation))
    if (authStep === AuthStep.enterPassword) navigate(route.login)
    if (authStep === AuthStep.registerForm) navigate(route.signUp)
    if (authStep === AuthStep.loggedIn) {
      if (invitation) navigate(routes.dashboard(invitation.room_id).info)
      else navigate(routes.roomList)
    }
  }, [authStep, dispatch])

  useEffect(() => {
    if (match(route.continue)) dispatch(setAuthStep(AuthStep.continue))
    if (match(route.login)) dispatch(setAuthStep(AuthStep.enterPassword))
    if (match(route.signUp)) dispatch(setAuthStep(AuthStep.registerForm))
  }, [location, dispatch])

  // MARK: - Render

  const title = () => {
    if (match(route.continue)) return t('authContinue')
    if (match(route.login)) return t('authPassword')
    if (match(route.signUp)) return t('authRegister')
    return ''
  }

  const renderInnerContainer = useCallback(() => {
    if (match(route.continue)) {
      return <AuthenticationContinue invitationId={invitationId} />
    }
    if (match(route.login)) {
      return <AuthenticationEnterPassword invitationId={invitationId} />
    }
    if (match(route.signUp)) {
      return <AuthenticationRegisterForm invitationId={invitationId} />
    }
    return null
  }, [location, invitationId])

  return (
    <Container>
      <AuthenticationHeader title={title()} />
      <InnerContainer width={width}>{renderInnerContainer()}</InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const InnerContainer = styled(ShadowBox)<{ width: number }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  max-width: 800px;
  min-height: 400px;
  min-width: ${({ width }) => (width < 800 ? '96%' : '800px')};
  padding: 24px 32px;
  position: relative;
  z-index: 10;

  @media ${device.smallerThan.laptop} {
    padding: 12px 18px;
    max-width: 96%;
  }
`

const Container = styled(FlexCol)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border: 0px solid ${({ theme }) => theme.palette.orange};
  border-top-width: 10px;
  min-height: 100vh;
`
