import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { IconPicker } from 'src/features/platform/common/IconPicker'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createChatChannel, updateChatChannel } from 'src/redux/reducers/chat'
import { Error } from 'src/repository/Error'
import { ChatChannel, Room } from 'src/repository/types'
import { imageUrls } from 'src/utils/config'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardChatModal: React.FC<{
  chatChannel?: ChatChannel | null
  room: Room
  onClose: () => void
}> = ({ chatChannel, room, onClose }) => {
  // MARK: - Hooks

  const shouldCloseWhenLoadingDone = useRef(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'chat'))

  const [activated, setActivated] = useState(chatChannel?.is_active ?? true)
  const [imageUrl, setImageUrl] = useState(chatChannel?.image_url ?? imageUrls.chatChannel)
  const [name, setName] = useState(chatChannel?.name ?? '')
  const [description, setDescription] = useState(chatChannel?.description ?? '')

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleCreateChatChannelClick = () => {
    if (!name) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true
    const channelId = chatChannel?.id
    if (channelId) {
      dispatch(updateChatChannel(channelId, name.trim(), imageUrl, description.trim(), activated))
    } else {
      dispatch(createChatChannel(room.id, name.trim(), imageUrl, description.trim()))
    }
  }

  // MARK: - Render

  return (
    <ModalWrapper
      title={chatChannel ? t('editChatChannel') : t('createChatChannel')}
      size="tiny"
      onClose={onClose}>
      <Row>
        <IconPicker icon={imageUrl} onIconChange={setImageUrl} />

        <TextContainer>
          <Input label={t('title')} isRequired value={name} onChange={text => setName(text)} />

          <Input
            inputStyle={{ minHeight: 134 }}
            type="textarea"
            label={t('description')}
            value={description}
            onChange={text => setDescription(text)}
          />
        </TextContainer>
      </Row>

      <InfoSwitch
        style={{ marginTop: 16 }}
        title={t('activateImmediately')}
        subtitle={t('activateImmediatelyDescription')}
        checked={activated}
        onSwitchToggle={setActivated}
      />

      <FooterWrapper>
        <PrimaryButton
          style={{ minWidth: 120 }}
          title={chatChannel ? t('update') : t('create')}
          isLoading={isLoading}
          onClick={handleCreateChatChannelClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}

// MARK: - Styles

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`
