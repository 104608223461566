import { DashboardSettingsGeneral } from './DashboardSettingsGeneral'
import { DashboardSettingsHeader } from './DashboardSettingsHeader'
import React, { useCallback, useState } from 'react'
import { FlexCol } from 'src/features/common/CommonStyles'
import { Room } from 'src/repository/types'

export const DashboardSettings: React.FC<{
  room: Room
}> = ({ room }) => {
  // MARK: - Hooks

  const [selectedIndex, setSelectedIndex] = useState(0)

  // MARK: - Render

  const renderSettings = useCallback(() => {
    switch (selectedIndex) {
      case 0:
        return <DashboardSettingsGeneral room={room} />
      default:
        return null
    }
  }, [selectedIndex])

  return (
    <FlexCol style={{ overflow: 'hidden', width: '100%' }}>
      <DashboardSettingsHeader
        settingsType={'configuration'}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <div style={{ paddingLeft: 32, paddingRight: 32 }}>
        {<FlexCol style={{ width: 720 }}>{renderSettings()}</FlexCol>}
      </div>
    </FlexCol>
  )
}
