import produce from 'immer'
import { pushError } from 'src/redux/reducers/app'
import { Action, Thunk } from 'src/redux/store'
import { imageService } from 'src/repository/services/imageService'
import { CoverImage, ImageOrientation } from 'src/repository/types'

type State = {
  coverImages: Record<string, CoverImage[]>
}

type ActionType =
  | { type: 'coverImage/setCoverImages'; query: string; images: CoverImage[] }
  | Action<'me/logout'>
// MARK: - State

export const initialState: State = {
  coverImages: {},
}

// MARK: - Reducer

export const coverImageReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'coverImage/setCoverImages':
      return produce(state, draft => {
        draft.coverImages[action.query] = action.images
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const trackCoverImageDownload =
  (image: CoverImage): Thunk<ActionType> =>
  dispatch =>
    imageService
      .trackCoverImageDownload(image)
      .loading('cover_image')
      .done(undefined, error => dispatch(pushError(error)))

export const fetchRandomCoverImages =
  (count: number, orientation: ImageOrientation): Thunk<ActionType> =>
  dispatch =>
    imageService.fetchRandomCoverImages(count, orientation).done(
      images => dispatch({ type: 'coverImage/setCoverImages', query: orientation, images }),
      error => dispatch(pushError(error)),
    )

export const queryCoverImages =
  (query: string, count: number, orientation: ImageOrientation): Thunk<ActionType> =>
  dispatch =>
    imageService.queryCoverImages(query, 0, count, orientation).done(
      images => dispatch({ type: 'coverImage/setCoverImages', query, images }),
      error => dispatch(pushError(error)),
    )

// MARK: - Selectors

export const getCoverImages = (
  state: State,
  query: string,
  orientation: ImageOrientation,
): CoverImage[] => {
  return state.coverImages[query || orientation] ?? []
}
