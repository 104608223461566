import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 22, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4C8 4.39397 8.0776 4.78407 8.22836 5.14805C8.37913 5.51203 8.6001 5.84274 8.87868 6.12132C9.15726 6.3999 9.48797 6.62087 9.85195 6.77164C10.2159 6.9224 10.606 7 11 7C11.394 7 11.7841 6.9224 12.1481 6.77164C12.512 6.62087 12.8427 6.3999 13.1213 6.12132C13.3999 5.84274 13.6209 5.51203 13.7716 5.14805C13.9224 4.78407 14 4.39396 14 4C14 3.60603 13.9224 3.21593 13.7716 2.85195C13.6209 2.48797 13.3999 2.15725 13.1213 1.87868C12.8427 1.6001 12.512 1.37912 12.148 1.22836C11.7841 1.0776 11.394 1 11 1C10.606 1 10.2159 1.0776 9.85195 1.22836C9.48797 1.37913 9.15725 1.6001 8.87868 1.87868C8.6001 2.15726 8.37912 2.48797 8.22836 2.85195C8.0776 3.21593 8 3.60604 8 4L8 4Z"
      stroke={fill}
    />
    <path
      d="M3.43781 11.9015C3.09663 12.0985 2.79758 12.3607 2.55775 12.6733C2.31792 12.9858 2.142 13.3426 2.04004 13.7231C1.93807 14.1037 1.91206 14.5006 1.96348 14.8912C2.0149 15.2818 2.14275 15.6584 2.33974 15.9996C2.53672 16.3408 2.79897 16.6398 3.11153 16.8796C3.42408 17.1195 3.78081 17.2954 4.16136 17.3974C4.5419 17.4993 4.9388 17.5253 5.32939 17.4739C5.71999 17.4225 6.09663 17.2946 6.43781 17.0977C6.779 16.9007 7.07804 16.6384 7.31787 16.3259C7.5577 16.0133 7.73362 15.6566 7.83559 15.276C7.93756 14.8955 7.96357 14.4986 7.91215 14.108C7.86072 13.7174 7.73287 13.3408 7.53589 12.9996C7.33891 12.6584 7.07665 12.3594 6.7641 12.1195C6.45154 11.8797 6.09481 11.7038 5.71427 11.6018C5.33373 11.4998 4.93683 11.4738 4.54623 11.5252C4.15564 11.5767 3.779 11.7045 3.43781 11.9015L3.43781 11.9015Z"
      stroke={fill}
    />
    <path
      d="M18.5622 11.9015C18.9034 12.0985 19.2024 12.3607 19.4422 12.6733C19.6821 12.9859 19.858 13.3426 19.96 13.7231C20.0619 14.1037 20.0879 14.5006 20.0365 14.8912C19.9851 15.2818 19.8572 15.6584 19.6603 15.9996C19.4633 16.3408 19.201 16.6398 18.8885 16.8796C18.5759 17.1195 18.2192 17.2954 17.8386 17.3974C17.4581 17.4993 17.0612 17.5253 16.6706 17.4739C16.28 17.4225 15.9034 17.2946 15.5622 17.0977C15.221 16.9007 14.922 16.6384 14.6821 16.3259C14.4423 16.0133 14.2664 15.6566 14.1644 15.276C14.0624 14.8955 14.0364 14.4986 14.0879 14.108C14.1393 13.7174 14.2671 13.3408 14.4641 12.9996C14.6611 12.6584 14.9234 12.3594 15.2359 12.1195C15.5485 11.8797 15.9052 11.7038 16.2857 11.6018C16.6663 11.4998 17.0632 11.4738 17.4538 11.5252C17.8444 11.5767 18.221 11.7045 18.5622 11.9015L18.5622 11.9015Z"
      stroke={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00254 4.12533C8.0008 4.08372 7.99993 4.04203 7.99993 4.0003C7.99993 3.65294 8.06026 3.30857 8.17787 2.98247C8.03301 3.03346 7.8894 3.08843 7.74719 3.14733C6.71592 3.5745 5.77889 4.2006 4.98959 4.9899C4.20029 5.7792 3.57419 6.71623 3.14702 7.7475C2.71986 8.77877 2.5 9.88407 2.5 11.0003C2.5 11.5265 2.54885 12.0502 2.64523 12.5648C2.86884 12.2999 3.13691 12.0755 3.43774 11.9018C3.47388 11.8809 3.51042 11.8609 3.54733 11.8415C3.51586 11.5628 3.5 11.2819 3.5 11.0003C3.5 10.0154 3.69399 9.04012 4.0709 8.13018C4.44781 7.22024 5.00026 6.39345 5.6967 5.69701C6.36051 5.03319 7.14275 4.5002 8.00254 4.12533ZM13.9973 4.12528C14.8572 4.50014 15.6395 5.03315 16.3033 5.697C16.9997 6.39344 17.5522 7.22023 17.9291 8.13018C18.306 9.04012 18.5 10.0154 18.5 11.0003C18.5 11.282 18.4841 11.5628 18.4527 11.8416C18.4895 11.8609 18.526 11.881 18.5621 11.9018C18.863 12.0755 19.1311 12.3 19.3547 12.565C19.4511 12.0503 19.5 11.5265 19.5 11.0003C19.5 9.88407 19.2801 8.77876 18.853 7.74749C18.4258 6.71622 17.7997 5.77919 17.0104 4.98989C16.2211 4.2006 15.2841 3.57449 14.2528 3.14733C14.1105 3.0884 13.9669 3.03342 13.822 2.98242C13.9396 3.30854 13.9999 3.65292 13.9999 4.0003C13.9999 4.04201 13.9991 4.08368 13.9973 4.12528ZM16.533 17.4529C16.1916 17.3917 15.8631 17.2717 15.5621 17.098C15.5261 17.0772 15.4906 17.0556 15.4555 17.0334C14.9665 17.3945 14.4343 17.6957 13.8701 17.9294C12.9602 18.3063 11.9849 18.5003 11 18.5003C10.0151 18.5003 9.03982 18.3063 8.12988 17.9294C7.56568 17.6957 7.03345 17.3945 6.54443 17.0334C6.50935 17.0556 6.47378 17.0772 6.43775 17.098C6.13681 17.2717 5.80829 17.3917 5.46694 17.4528C6.14728 18.0362 6.91668 18.5093 7.74719 18.8533C8.77846 19.2804 9.88377 19.5003 11 19.5003C12.1162 19.5003 13.2215 19.2804 14.2528 18.8533C15.0833 18.5093 15.8527 18.0362 16.533 17.4529Z"
      fill={fill}
    />
  </svg>
)

export const SpreadGameIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
