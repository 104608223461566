import { AnalyticsIcon } from 'src/assets/images/svg/AnalyticsIcon'
import { ConfigureIcon } from 'src/assets/images/svg/ConfigureIcon'
import { FeaturesIcon } from 'src/assets/images/svg/FeaturesIcon'
import { HomeIcon } from 'src/assets/images/svg/HomeIcon'
import { InvoiceIcon } from 'src/assets/images/svg/InvoiceIcon'
import { LinksIcon } from 'src/assets/images/svg/LinksIcon'
import { LockIcon } from 'src/assets/images/svg/LockIcon'
import { NotificationIcon } from 'src/assets/images/svg/NotificationIcon'
import { PlansIcon } from 'src/assets/images/svg/PlansIcon'
import { ShieldIcon } from 'src/assets/images/svg/ShieldIcon'
import { TeamIcon } from 'src/assets/images/svg/TeamIcon'
import { UsageIcon } from 'src/assets/images/svg/UsageIcon'
import { Component } from 'src/repository/types'
import i18n from 'src/utils/localization'
import { Localization } from 'src/utils/types/i18n'
import { SVG } from 'src/utils/types/svg'

export type DashboardSettingsType = 'configuration' | 'usage' | 'analytics' | 'team' | 'privacy'

export type DashboardSettingsPage = {
  type: 'item' | 'separator'
  icon?: SVG
  title: string
}

export const dashboardSettingsTypes: DashboardSettingsType[] = ['configuration', 'usage', 'team']

export const dashboardSettingsIcon = (type: DashboardSettingsType, fill: string) => {
  switch (type) {
    case 'configuration':
      return <ConfigureIcon fill={fill} size={34} style={{ marginRight: 3 }} />
    case 'analytics':
      return <AnalyticsIcon fill={fill} size={40} />
    case 'team':
      return <TeamIcon fill={fill} size={40} />
    case 'usage':
      return <UsageIcon fill={fill} size={40} />
    case 'privacy':
      return <ShieldIcon fill={fill} size={40} />
  }
}

export const dashboardSettingsTitle = (type: DashboardSettingsType, lng: Localization) => {
  switch (type) {
    case 'configuration':
      return i18n.t('configuration', { lng })
    case 'analytics':
      return i18n.t('analytics', { lng })
    case 'team':
      return i18n.t('teamSettings', { lng })
    case 'usage':
      return i18n.t('usageBilling', { lng })
    case 'privacy':
      return i18n.t('privacyAccess', { lng })
  }
}

export const dashboardSettingsDescription = (type: DashboardSettingsType, lng: Localization) => {
  switch (type) {
    case 'configuration':
      return i18n.t('configurationDescription', { lng })
    case 'analytics':
      return i18n.t('analyticsDescription', { lng })
    case 'team':
      return i18n.t('teamSettingsDescription', { lng })
    case 'usage':
      return i18n.t('usageBillingDescription', { lng })
    case 'privacy':
      return i18n.t('privacyAccessDescription', { lng })
  }
}

export const dashboardSettingsPages = (
  lng: Localization,
): Record<DashboardSettingsType, (components?: Component[]) => DashboardSettingsPage[]> => ({
  analytics: (components?: Component[]) => [
    {
      type: 'item',
      title: i18n.t('general', { lng }),
      icon: AnalyticsIcon,
    },
    {
      type: 'item',
      title: i18n.t('notifications', { lng }),
      icon: NotificationIcon,
    },
    {
      type: 'item',
      title: i18n.t('links', { lng }),
      icon: LinksIcon,
    },
    {
      type: 'item',
      title: i18n.t('features', { lng }),
      icon: FeaturesIcon,
    },
  ],
  usage: () => [
    {
      type: 'item',
      title: i18n.t('Usage', { lng }),
      icon: UsageIcon,
    },
    {
      type: 'item',
      title: i18n.t('plans', { lng }),
      icon: PlansIcon,
    },
    {
      type: 'item',
      title: i18n.t('purchaseHistory', { lng }),
      icon: InvoiceIcon,
    },
  ],
  team: () => [
    {
      type: 'item',
      title: i18n.t('teammates', { lng }),
      icon: TeamIcon,
    },
  ],
  privacy: () => [
    { type: 'item', title: i18n.t('roomAccess', { lng }), icon: LockIcon },
    // {
    //   type: "item",
    //   title: "Banned Users",
    //   icon: FlagIcon,
    // },
  ],
  configuration: () => [
    { type: 'item', title: i18n.t('generalSettings', { lng }), icon: HomeIcon },
  ],
})
