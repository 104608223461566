import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 23, fill = '#F36538', ...props }) => (
  <svg width={size} height={size} {...props} viewBox="0 0 23 18" fill="none">
    <path
      d="M17.5948 7.93189C19.1972 7.93189 20.497 6.63298 20.497 5.03064C20.497 3.42831 19.1972 2.12939 17.5948 2.12939"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9291 11.0848C19.4076 11.1178 19.8833 11.1856 20.3517 11.291C21.0026 11.4184 21.7854 11.6852 22.0641 12.2691C22.2419 12.6431 22.2419 13.0785 22.0641 13.4534C21.7863 14.0374 21.0026 14.3032 20.3517 14.437"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.28998 7.93189C4.68765 7.93189 3.38782 6.63298 3.38782 5.03064C3.38782 3.42831 4.68765 2.12939 6.28998 2.12939"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.95577 11.0848C4.47727 11.1178 4.00152 11.1856 3.5331 11.291C2.88227 11.4184 2.09943 11.6852 1.82168 12.2691C1.64293 12.6431 1.64293 13.0785 1.82168 13.4534C2.09852 14.0374 2.88227 14.3032 3.5331 14.437"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9378 11.7095C15.1846 11.7095 17.9584 12.2009 17.9584 14.1671C17.9584 16.1325 15.2029 16.6421 11.9378 16.6421C8.69003 16.6421 5.91711 16.1508 5.91711 14.1845C5.91711 12.2183 8.67261 11.7095 11.9378 11.7095Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9377 8.90492C9.79638 8.90492 8.07947 7.188 8.07947 5.04575C8.07947 2.90442 9.79638 1.1875 11.9377 1.1875C14.0791 1.1875 15.796 2.90442 15.796 5.04575C15.796 7.188 14.0791 8.90492 11.9377 8.90492Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const UsersIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.orange} {...props} />
}
