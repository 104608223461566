import React from 'react'
import { useDispatch } from 'react-redux'
import { FlexCol, FlexRow, LabelLarge, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { Switch } from 'src/features/common/Switch'
import { toggleComponent } from 'src/redux/reducers/room'
import { Component, Room } from 'src/repository/types'
import { componentImage } from 'src/utils/helpers/componentHelper'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import styled from 'styled-components'

export const DashboardComponentHeader: React.FC<{
  room: Room
  component: Component
  hideHeaderImage?: boolean
}> = ({ component, hideHeaderImage }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()

  // MARK: - Render

  const HeaderImagePlaceHolder = componentImage(component.type)

  return (
    <Container>
      <FlexRow style={{ alignItems: 'center' }}>
        {!hideHeaderImage ? (
          <>
            {component.image_url ? (
              <HeaderImage src={component.image_url} />
            ) : (
              <HeaderImageContainer>
                <HeaderImagePlaceHolder size={40} />
              </HeaderImageContainer>
            )}
          </>
        ) : null}

        <FlexCol>
          <LabelLarge>{component.title}</LabelLarge>
          <LabelNormal style={numberOfLines(1)}>{component.overview}</LabelNormal>
        </FlexCol>
      </FlexRow>

      <Row style={{ marginLeft: 16 }}>
        <Switch
          disabled={false}
          checked={component.is_active}
          onSwitchToggle={() => dispatch(toggleComponent(component, !component.is_active))}
        />
      </Row>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 40px 16px 0px 18px;
`

const HeaderImage = styled.img`
  border-radius: 8px;
  height: 48px;
  margin: 4px 16px 0px 0px;
  width: 48px;
`

const HeaderImageContainer = styled.div`
  height: 42px;
  margin: 4px 10px 0px 0px;
  width: 40px;
`
