import produce from 'immer'
import { map } from 'lodash'
import { pushError } from 'src/redux/reducers/app'
import {
  DeleteAction,
  deleteEntities,
  getEntities,
  MergeAction,
  mergeEntities,
  ReplaceAction,
  replaceEntities,
} from 'src/redux/reducers/entity'
import { Action, Thunk } from 'src/redux/store'
import { audienceService } from 'src/repository/services/audienceService'
import { Invitation, Subscription } from 'src/repository/types'
import { downloadAsCSV } from 'src/utils/helpers/csvHelper'

type State = {
  canPaginate: Record<string, boolean>
}

type ActionType =
  | { type: 'audience/setCanPaginate'; query: string; canPaginate: boolean }
  | Action<'me/logout'>
  | MergeAction
  | DeleteAction
  | ReplaceAction

export const initialState: State = {
  canPaginate: {},
}

// MARK: - Reducer

export const audienceReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'audience/setCanPaginate':
      return produce(state, draft => {
        draft.canPaginate[action.query] = action.canPaginate
        return draft
      })

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchAudience =
  (roomId: string, offset: number, limit: number): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .fetchAudience(roomId, offset, limit)
      .loading(offset ? 'audience_page' : 'audience')
      .done(
        ({ subscriptions, users }) => {
          dispatch({
            type: 'audience/setCanPaginate',
            query: '',
            canPaginate: subscriptions.length >= limit,
          })
          dispatch(mergeEntities({ subscription: subscriptions, user: users }))
        },
        error => dispatch(pushError(error)),
      )

export const searchAudience =
  (roomId: string, query: string, offset: number, limit: number): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .searchAudience(roomId, query, offset, limit)
      .loading(offset ? 'audience_page' : 'audience')
      .done(
        ({ subscriptions, users }) => {
          if (offset) {
            dispatch(mergeEntities({ subscription: subscriptions, user: users }))
          } else {
            dispatch(replaceEntities({ subscription: subscriptions }))
            dispatch(mergeEntities({ user: users }))
          }
          dispatch({
            type: 'audience/setCanPaginate',
            query,
            canPaginate: subscriptions.length >= limit,
          })
        },
        error => dispatch(pushError(error)),
      )

export const exportAudience =
  (roomId: string, userIds: string[] = []): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .exportAudience(roomId, userIds)
      .loading('audience_export')
      .done(
        ({ csv, filename }) => downloadAsCSV(csv, filename),
        error => dispatch(pushError(error)),
      )

export const cleanAudience =
  (roomId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const subscriptions = getEntities<Subscription>(
      getState().entity,
      'subscription',
      ({ room_id }: Subscription) => room_id === roomId,
    )
    dispatch(
      deleteEntities({
        subscription: map(subscriptions, 'id'),
        user: map(subscriptions, 'user_id'),
      }),
    )
  }

export const fetchInvitation =
  (invitationId: string): Thunk<ActionType> =>
  dispatch =>
    audienceService.fetchInvitation(invitationId).done(
      invitation => mergeEntities({ invitation: [invitation] }),
      error => dispatch(pushError(error)),
    )

export const fetchInvitations =
  (roomId: string): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .fetchInvitations(roomId)
      .loading('audience')
      .done(
        value =>
          dispatch(
            mergeEntities({
              invitation: value.invitations,
              invitation_request: value.invitation_requests,
            }),
          ),
        error => dispatch(pushError(error)),
      )

export const createInvitation =
  (
    email: string,
    firstName: string,
    lastName: string,
    notes: string,
    roomId: string,
    notifyOnAccept: boolean,
    notifyOnAcceptEmail: string,
  ): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .createInvitation({
        email: email,
        first_name: firstName,
        last_name: lastName,
        notes: notes,
        room_id: roomId,
        notify_on_accept: notifyOnAccept,
        notify_on_accept_email: notifyOnAcceptEmail,
      })
      .loading('invitation')
      .done(
        invitation => dispatch(mergeEntities({ invitation: [invitation] })),
        error => dispatch(pushError(error)),
      )

export const deleteInvitation =
  (invitation: Invitation): Thunk<ActionType> =>
  async dispatch =>
    audienceService
      .deleteInvitation(invitation.id)
      .first(() => dispatch(deleteEntities({ invitation: [invitation.id] })))
      .done(undefined, error => {
        dispatch(mergeEntities({ invitation: [invitation] }))
        dispatch(pushError(error))
      })

export const respondInvitationRequest =
  (roomId: string, requestId: string, accept: boolean): Thunk<ActionType> =>
  dispatch =>
    audienceService
      .respondInvitationRequest(roomId, requestId, accept)
      .first(() => dispatch(deleteEntities({ invitation_request: [requestId] })))
      .done(
        invitation => dispatch(mergeEntities({ invitation: [invitation] })),
        error => dispatch(pushError(error)),
      )

export const removeMembers =
  (roomId: string, userIds: string[]): Thunk<ActionType> =>
  (dispatch, getState) =>
    audienceService
      .removeMembers(roomId, userIds)
      .first(() => {
        const subscriptions = getEntities<Subscription>(
          getState().entity,
          'subscription',
          ({ room_id, user_id }) => room_id === roomId && userIds.includes(user_id),
        )
        dispatch(deleteEntities({ subscription: map(subscriptions, 'id') }))
      })
      .done(undefined, error => dispatch(pushError(error)))

export const blockMembers =
  (roomId: string, userIds: string[]): Thunk<ActionType> =>
  dispatch =>
    audienceService.blockMembers(roomId, userIds).done(
      ({ subscriptions }) => dispatch(mergeEntities({ subscription: subscriptions })),
      error => dispatch(pushError(error)),
    )

export const unblockMembers =
  (roomId: string, userIds: string[]): Thunk<ActionType> =>
  dispatch =>
    audienceService.unblockMembers(roomId, userIds).done(
      ({ subscriptions }) => dispatch(mergeEntities({ subscription: subscriptions })),
      error => dispatch(pushError(error)),
    )

// MARK: - Selectors

export const getCanPaginate = (state: State, query: string): boolean => {
  return state.canPaginate[query] ?? true
}
