import { CoverImageModalGallery } from './CoverImageModalGallery'
import { CoverImageModalLink } from './CoverImageModalLink'
import { CoverImageModalTabBar } from './CoverImageModalTabBar'
import { CoverImageModalUnsplash } from './CoverImageModalUnsplash'
import { CoverImageModalUpload } from './CoverImageModalUpload'
import { CoverUpdateMethod } from './types'
import React, { useCallback, useEffect, useState } from 'react'
import { FlexCol, ShadowBox } from 'src/features/common/CommonStyles'
import { ImageOrientation } from 'src/repository/types'
import styled from 'styled-components'

export const CoverImageModal: React.FC<{
  coverHeight: number
  orientation: ImageOrientation
  onImageRemove: () => void
  onImageSelect: (image: string) => void
  onClose: () => void
}> = ({ orientation, coverHeight, onImageRemove, onImageSelect, onClose }) => {
  // MARK: - Hooks

  const modal = React.useRef<HTMLDivElement | null>(null)
  const [selectedMethod, setSelectedMethod] = useState<CoverUpdateMethod>('gallery')

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleDropdownCloseClick)
    document.getElementById('modal')?.addEventListener('click', handleDropdownCloseClick)
    return () => {
      document.removeEventListener('click', handleDropdownCloseClick)
      document.getElementById('modal')?.removeEventListener('click', handleDropdownCloseClick)
    }
  }, [])

  // MARK: - Handlers

  const handleDropdownCloseClick = (event: MouseEvent | any) => {
    if (!modal.current?.contains(event.target)) onClose()
  }

  // MARK: - Render

  const renderTab = useCallback(() => {
    switch (selectedMethod) {
      case 'gallery':
        return <CoverImageModalGallery onImageSelect={onImageSelect} />
      case 'unsplash':
        return <CoverImageModalUnsplash orientation={orientation} onImageSelect={onImageSelect} />
      case 'upload':
        return <CoverImageModalUpload onImageSelect={onImageSelect} />
      case 'link':
        return (
          <CoverImageModalLink
            onImageSelect={image => {
              onImageSelect(image)
              onClose()
            }}
          />
        )
    }
  }, [selectedMethod])

  return (
    <Container ref={modal} coverHeight={coverHeight} onClick={event => event.stopPropagation()}>
      <CoverImageModalTabBar
        selectedMethod={selectedMethod}
        onMethodSelect={setSelectedMethod}
        onCoverImageRemove={onImageRemove}
      />
      <InnerContainer>{renderTab()}</InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)<{ coverHeight: number }>`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  max-height: 400px;
  max-width: 480px;
  position: absolute;
  right: 5%;
  top: ${({ coverHeight }) => coverHeight - 12}px;
  width: 100%;
  z-index: 5;
`

const InnerContainer = styled(FlexCol)`
  max-height: 362px;
  overflow: scroll;
`
