import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardQAColumn } from './DashboardQAColumn'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { Col, FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import {
  fetchQAQuestions,
  moveQAQuestionToAnswered,
  rejectQAQuestion,
} from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Component, QAQuestion, QAQuestionState, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardQA: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'qa'))
  const qaQuestions = useSelect(state =>
    getEntities<QAQuestion>(
      state.entity,
      'qa_question',
      qaQuestion => qaQuestion.component_id === component.id,
    ).sort((first, second) => second.updated_at - first.updated_at),
  )

  const [draggedQuestionState, setDraggedQuestionState] = useState<QAQuestionState | null>(null)
  const [searchText, setSearchText] = useState('')

  const qaQuestionsWithState = useCallback(
    (state: QAQuestionState): QAQuestion[] => {
      return qaQuestions.filter(question => question.state === state)
    },
    [qaQuestions],
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchQAQuestions(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleDragEnd = (result: DropResult) => {
    const { destination, draggableId } = result
    const question = qaQuestions.find(({ id }) => id === draggableId)
    if (!question) return

    if (destination?.droppableId === 'answered' && question.state !== 'answered') {
      dispatch(moveQAQuestionToAnswered(question))
    } else if (destination?.droppableId === 'rejected' && question.state !== 'rejected') {
      dispatch(rejectQAQuestion(question))
    }
    setDraggedQuestionState(null)
  }

  const handleDragStart = (result: DropResult) => {
    const { source } = result
    setDraggedQuestionState(source.droppableId as QAQuestionState)
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />
      <Col>
        <HeaderContainer>
          <PrimaryButton title={t('askQuestion')} onClick={() => {}} />

          <FlexRow style={{ justifyContent: 'flex-end' }}>
            <TextContainer>
              <Input
                type="search"
                placeholder={t('search')}
                hasIcon="right"
                value={searchText}
                onChange={setSearchText}>
                <SearchIcon size={16} />
              </Input>
            </TextContainer>
          </FlexRow>
        </HeaderContainer>

        <ColumnContainer>
          {isLoading && !qaQuestions.length ? (
            <PageLoader style={{ height: 550 }} />
          ) : (
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <DashboardQAColumn
                state={'pending'}
                dropDisabled
                questions={qaQuestionsWithState('pending')}
              />
              <DashboardQAColumn
                state={'answered'}
                dropDisabled={draggedQuestionState === 'answered'}
                questions={qaQuestionsWithState('answered')}
              />
              <DashboardQAColumn
                state={'rejected'}
                dropDisabled={draggedQuestionState === 'rejected'}
                questions={qaQuestionsWithState('rejected')}
              />
            </DragDropContext>
          )}
        </ColumnContainer>
      </Col>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  height: 72px;
  margin: 0px 20px;
  margin-top: 16px;
  padding: 16px 16px 0px 12px;
`

const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100vh - 280px);
  margin: 12px 12px 0px 12px;
  min-height: calc(100vh - 280px);
  overflow: scroll;
  padding-bottom: 20px;
`

const TextContainer = styled.div`
  flex-basis: 30%;
  margin-bottom: 0px;
`
