import { IconImagePicker } from './IconImagePicker'
import { IconPickerModal } from './IconPickerModal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Col } from 'src/features/common/CommonStyles'
import { FormLabel } from 'src/features/common/FormLabel'
import { Loader } from 'src/features/common/Loader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { getLoading } from 'src/redux/reducers/app'
import { downloadIcon, flush, getDownloadedIconUrl } from 'src/redux/reducers/iconPicker'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

type Icon = { id: number; type: 'icon' } | { type: 'image'; url: string }

export const IconPicker: React.FC<{
  icon?: string
  onIconChange: (icon: string) => void
}> = ({ icon, onIconChange }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const placeholderUrl = 'https://cdn-icons-png.flaticon.com/256/1375/1375106.png'

  const [showIconPicker, setShowIconPicker] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null)

  const downloadedIconUrl = useSelect(state => getDownloadedIconUrl(state.utils.iconPicker))
  const isLoading = useSelect(state => getLoading(state.app, 'icon_picker_download'))

  // MARK: - Effects

  useEffect(() => {
    if (selectedIcon?.type === 'icon') dispatch(downloadIcon(selectedIcon.id))
    if (selectedIcon?.type === 'image') onIconChange(selectedIcon.url)
  }, [selectedIcon])

  useEffect(() => {
    if (downloadedIconUrl) onIconChange(downloadedIconUrl)
  }, [downloadedIconUrl])

  useEffect(() => {
    return () => {
      dispatch(flush())
    }
  }, [])

  // MARK: - Render

  return (
    <>
      <Container>
        <FormLabel style={{ marginBottom: 8 }}>{t('icon')}</FormLabel>

        <IconContainer>
          {isLoading ? (
            <Loader size={42} style={{ margin: 33 }} />
          ) : (
            <img
              style={{ height: 100, width: 100, marginBottom: 8 }}
              src={icon || placeholderUrl}
              alt={placeholderUrl}
            />
          )}
        </IconContainer>

        <Col style={{ marginTop: 12 }}>
          <PrimaryButton
            style={{ marginBottom: 6 }}
            title={t('selectIcon')}
            onClick={() => setShowIconPicker(true)}
          />
          <IconImagePicker onIconChange={url => setSelectedIcon({ type: 'image', url: url })}>
            <SecondaryButton style={{ width: '100%' }} title={t('uploadImage')} />
          </IconImagePicker>
        </Col>
      </Container>

      {showIconPicker && (
        <IconPickerModal
          onIconPick={({ id }) => setSelectedIcon({ id, type: 'icon' })}
          onClose={() => setShowIconPicker(false)}
        />
      )}
    </>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-right: 20px;
  min-width: 180px;
`

const IconContainer = styled(Col)`
  align-items: center;
  justify-content: center;
`
