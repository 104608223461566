import { CoverImageModal } from './CoverImageModal'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FadeIn, LabelSmall } from 'src/features/common/CommonStyles'
import { ImageOrientation } from 'src/repository/types'
import config from 'src/utils/config'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { css } from 'styled-components'

export const CoverImage: React.FC<{
  style?: React.CSSProperties
  initialCover?: string
  initialOffset?: number
  editEnabled: boolean
  orientation: ImageOrientation
  preferredContainerHeight?: number
  onCoverOffsetChange?: (offset: number) => void
  onCoverImageChange: (image: string | undefined) => void
}> = ({
  style,
  initialCover,
  initialOffset = 0,
  preferredContainerHeight = 460,
  orientation,
  editEnabled,
  onCoverImageChange,
  onCoverOffsetChange,
}) => {
  // MARK: - Hooks

  const [containerHeight, setContainerHeight] = useState(preferredContainerHeight)
  const defaultOffset = initialOffset
  const imageRef = useRef<HTMLImageElement | null>(null)
  const origin = useRef<number>(0)

  const { t } = useTranslation()
  const cover = React.useRef<HTMLDivElement | null>(null)

  const { width } = useWindowDimensions()
  const [dragEnabled, setDragEnabled] = useState(false)
  const [isTarget, setIsTarget] = useState(false)
  const [originalOffset, setOriginalOffset] = useState(defaultOffset)
  const [offset, setOffset] = useState(originalOffset)
  const [showChangeCoverModal, setShowChangeCoverModal] = useState(false)
  const [imageHeight, setImageHeight] = useState(0)

  // MARK: - Effects

  useEffect(() => {
    setOriginalOffset(initialOffset)
    setOffset(initialOffset)
  }, [initialOffset])

  useEffect(() => {
    // Remove ghost image on drag
    // Please see: https://stackoverflow.com/questions/38655964/how-to-remove-dragghost-image
    document.addEventListener(
      'dragstart',
      event => {
        const img = new Image()
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
        event.dataTransfer?.setDragImage(img, 0, 0)
      },
      false,
    )

    document.addEventListener('click', handleRepositionCloseClick)
    return () => {
      document.removeEventListener('click', handleRepositionCloseClick)
    }
  })

  useLayoutEffect(() => {
    if (cover.current) setContainerHeight(Math.min((46 / 106) * cover.current.offsetWidth))
  }, [width])

  // MARK: - Handlers

  const handleRepositionCloseClick = (event: MouseEvent | any) => {
    if (!event.target?.closest(`.${cover.current?.className}`)) {
      event.stopPropagation()
      if (dragEnabled) setDragEnabled(false)
    }
  }

  const handleDragStart = (point: number, targetOffset: number) => {
    if (dragEnabled && point) origin.current = point - targetOffset
  }

  const handleDrag = (point: number, targetOffset: number) => {
    if (dragEnabled && point) {
      const dragOffset = point - targetOffset

      let translation = originalOffset * imageHeight + dragOffset - origin.current
      translation = Math.min(0, translation)
      translation = Math.max(containerHeight - imageHeight, translation)
      setOffset(translation / imageHeight)
    }
  }

  const handleCancelReposition = () => {
    setOffset(initialOffset ?? defaultOffset)
    setOriginalOffset(initialOffset ?? defaultOffset)
    setDragEnabled(false)
  }

  const handleSaveReposition = () => {
    onCoverOffsetChange?.(offset)
    setDragEnabled(false)
  }

  const handleImageSelect = (image: string) => {
    const dragOffset = 0
    onCoverOffsetChange?.(dragOffset)
    onCoverImageChange(image)
    setOffset(dragOffset)
    setOriginalOffset(dragOffset)
  }

  const handleImageRemove = () => {
    onCoverOffsetChange?.(defaultOffset)
    onCoverImageChange('')
  }

  // MARK: - Render

  return (
    <Container
      ref={cover}
      height={containerHeight}
      onClick={event => !showChangeCoverModal && event.stopPropagation()}
      onPointerEnter={() => setIsTarget(true)}
      onPointerLeave={() => setIsTarget(false)}
      onDragStart={event => handleDragStart(event.clientY, event.currentTarget.offsetTop)}
      onDragEnd={() => setOriginalOffset(offset)}
      onDrag={event => handleDrag(event.clientY, event.currentTarget.offsetTop)}
      dragEnabled={dragEnabled}>
      <ImageContainer style={style}>
        <img
          style={{
            objectFit: 'cover',
            position: 'absolute',
            top: offset * imageHeight,
            width: '100%',
            userSelect: 'none',
          }}
          ref={imageRef}
          onLoad={event => setImageHeight((event.target as any).offsetHeight)}
          src={initialCover || config.defaultCover}
          alt={config.defaultCover}
        />
      </ImageContainer>

      {editEnabled && dragEnabled && (
        <DragInstruction style={{ pointerEvents: 'none' }}>
          {t('dragImageToReposition')}
        </DragInstruction>
      )}

      {editEnabled && dragEnabled && (
        <ButtonContainer style={{ zIndex: 2 }}>
          <Button onClick={handleSaveReposition}>{t('savePosition')}</Button>
          <Button onClick={handleCancelReposition}>{t('cancel')}</Button>
        </ButtonContainer>
      )}

      {!showChangeCoverModal &&
        editEnabled &&
        (!dragEnabled && isTarget ? (
          <ButtonContainer>
            <Button onClick={() => setShowChangeCoverModal(true)}>{t('changeCover')}</Button>
            {onCoverOffsetChange ? (
              <Button onClick={() => setDragEnabled(true)}>{t('reposition')}</Button>
            ) : null}
          </ButtonContainer>
        ) : null)}

      {showChangeCoverModal && (
        <CoverImageModal
          orientation={orientation}
          coverHeight={containerHeight}
          onImageSelect={handleImageSelect}
          onImageRemove={handleImageRemove}
          onClose={() => setShowChangeCoverModal(false)}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)<{ dragEnabled: boolean; height: number }>`
  cursor: ${({ dragEnabled }) => (dragEnabled ? 'move' : 'default')};
  height: ${({ height }) => height}px;
  position: relative;
  user-select: none;
  width: 100%;
`

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  width: 100%;
`

const ButtonContainer = styled(FadeIn)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 6px;
  bottom: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  right: 5%;
  user-select: none;
`

const Button = styled(LabelSmall)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  padding: 6px 18px;
  user-select: none;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.tertiary};
      }
    `}
`

const DragInstruction = styled(LabelSmall)`
  background-color: ${({ theme }) => theme.palette.inverted.background.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.text.primary};
  left: 50%;
  padding: 6px 20px;
  position: absolute;
  top: 45%;
  transform: translate(-50%, 0);
  user-select: none;
`
