import React from 'react'
import { Col, FlexRow, LabelBold, LabelSemiBold } from 'src/features/common/CommonStyles'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const Hero: React.FC<{
  onRequestDemoClick: () => void
  onGetStartedClick: () => void
}> = ({ onGetStartedClick, onRequestDemoClick }) => {
  // MARK: - Hooks

  const { isMobile } = useWindowDimensions()
  const { palette } = useTheme()

  // MARK: - Render

  return (
    <Container>
      <RowContainer>
        <InnerContainer>
          <Col style={{ marginBottom: 16 }}>
            <Header>{`Supercharge your`}</Header>
            <Header>{'next event'}</Header>
          </Col>

          <Col style={{ justifyContent: 'center', width: isMobile ? '100%' : undefined }}>
            <SubHeader style={{ maxWidth: 600 }}>
              {`Lobbi provides everything you need to boost for your next events pre-sales and revenue.`}
            </SubHeader>
            <ButtonContainer>
              <SecondaryButton
                style={{ width: 164 }}
                labelStyle={{ fontSize: 16, color: palette.text.primary, fontWeight: 500 }}
                title={'Get started'}
                onClick={onGetStartedClick}
              />
              <PrimaryButton
                style={{ marginLeft: 12, width: 164 }}
                labelStyle={{ fontSize: 16, fontWeight: 500 }}
                title={'Request a demo'}
                onClick={onRequestDemoClick}
              />
            </ButtonContainer>
          </Col>
        </InnerContainer>

        <FlexRow
          style={{
            justifyContent: 'center',
            alignSelf: 'center',
            maxWidth: '100%',
            marginTop: isMobile ? 24 : 0,
            paddingLeft: isMobile ? '6%' : 0,
            paddingRight: isMobile ? '6%' : 0,
          }}>
          <img
            style={{
              width: '34%',
              objectFit: 'contain',
              marginRight: '-20%',
              marginTop: '16%',
              zIndex: 2,
            }}
            src={require('src/assets/images/mobile_cover.png')}
            alt=""
          />
          <img
            style={{ width: '100%', objectFit: 'contain', marginRight: isMobile ? 0 : '-25%' }}
            src={require('src/assets/images/desktop_cover.png')}
            alt=""
          />
        </FlexRow>
      </RowContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  width: 95%;
`

const RowContainer = styled(FlexRow)`
  justify-content: flex-start;
  @media ${device.smallerThan.laptop} {
    flex-direction: column;
  }
`

const InnerContainer = styled(Col)`
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 55%;
  padding-left: calc((100% - 1260px) / 2);
  position: relative;

  @media ${device.smallerThan.laptop} {
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
    padding-left: 0px;
  }
`

const Header = styled(LabelBold)`
  color: ${({ theme }) => theme.palette.black};
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 72px;
  margin: 0px;

  @media ${device.smallerThan.laptop} {
    margin-top: 48px;
    font-size: 50px;
    line-height: 48px;
  }

  @media ${device.smallerThan.tablet} {
    margin-top: 30px;
    font-size: 40px;
    line-height: 44px;
  }
`

const SubHeader = styled(LabelSemiBold)`
  color: #575e6b;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  margin: 0px 0px 32px 4px;

  @media ${device.smallerThan.laptop} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.smallerThan.tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`
