import { api } from '../api'
import { AccessLevel, Collaborator, Invitation, User } from '../types'

export const collaboratorService = {
  fetchCollaborators: async (roomId: string) =>
    api.value<{ users: User[]; collaborators: Collaborator[] }>({
      request: {
        method: 'GET',
        endPoint: '/collaborator',
        parameters: { room_id: roomId },
      },
    }),

  createCollaborator: async (roomId: string, accessLevel: AccessLevel, invitationId: string) =>
    api.prop<{ collaborator: Collaborator }>('collaborator', {
      request: {
        method: 'POST',
        endPoint: '/collaborator',
        parameters: { room_id: roomId, access_level: accessLevel, invitation_id: invitationId },
      },
    }),

  updateCollaborator: async (collaboratorId: string, roomId: string, accessLevel: AccessLevel) =>
    api.prop<{ collaborator: Collaborator }>('collaborator', {
      request: {
        method: 'PUT',
        endPoint: '/collaborator',
        parameters: { room_id: roomId, access_level: accessLevel, collaborator_id: collaboratorId },
      },
    }),

  deleteCollaborator: async (roomId: string, collaboratorId: string) =>
    api.prop<{ collaborator: Collaborator }>('collaborator', {
      request: {
        method: 'DELETE',
        endPoint: '/collaborator',
        parameters: { room_id: roomId, collaborator_id: collaboratorId },
      },
    }),

  fetchCollaboratorInvitations: async (roomId: string) =>
    api.prop<{ invitations: Invitation[] }>('invitations', {
      request: {
        method: 'GET',
        endPoint: '/collaborator/invitation',
        parameters: { room_id: roomId },
      },
    }),

  createCollaboratorInvitation: async (request: Partial<Invitation>) =>
    api.prop<{ invitation: Invitation }>('invitation', {
      request: {
        method: 'POST',
        endPoint: '/collaborator/invitation',
        parameters: { ...request },
      },
    }),
}
