import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { DashboardTableRow } from 'src/features/common/DashboardTableRow'
import { Announcement } from 'src/repository/types'
import { dateString } from 'src/utils/helpers/dateHelper'
import { useTheme } from 'styled-components'

export const DashboardAnnouncementTable: React.FC<{
  announcements: Announcement[]
  isLoading: boolean
  onCreateAnnouncementClick: () => void
  handleAnnouncementDropdownItemSelect: (index: number, announcement: Announcement) => void
  handleAnnouncementBulkDropdownItemSelect: (index: number, announcements: Announcement[]) => void
}> = ({
  announcements,
  isLoading,
  onCreateAnnouncementClick,
  handleAnnouncementDropdownItemSelect,
  handleAnnouncementBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const [searchText, setSearchText] = useState('')
  const [selectedAnnouncementIds, setSelectedAnnouncementIds] = useState<string[]>([])
  const [status, setStatus] = useState('all')

  // MARK: - Properties

  let currentAnnouncements = announcements.filter(announcement =>
    (announcement.title + announcement.overview).toLowerCase().includes(searchText.toLowerCase()),
  )

  const isScheduled = (announcement: Announcement) =>
    announcement.is_scheduled && announcement.publish_date > Math.ceil(Date.now() / 1000)

  if (status === 'scheduled') {
    currentAnnouncements = currentAnnouncements.filter(isScheduled)
  } else if (status === 'sent') {
    currentAnnouncements = currentAnnouncements.filter(announcement => !isScheduled(announcement))
  }

  // MARK: - Effects

  useEffect(() => {
    setSelectedAnnouncementIds(prev => {
      const announcementIds = announcements.map(item => item.id)
      return prev.filter(id => announcementIds.includes(id))
    })
  }, [announcements])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleAnnouncementBulkDropdownItemSelect(
      option,
      announcements.filter(announcement => selectedAnnouncementIds.includes(announcement.id)),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('createAnnouncement')}
        onPrimaryActionClick={onCreateAnnouncementClick}
        searchText={searchText}
        setSearchText={setSearchText}
        status={status}
        setStatus={setStatus}
        statusOptions={[
          { key: 'sent', label: t('sent') },
          { key: 'scheduled', label: t('scheduled') },
          { key: 'all', label: t('all') },
        ]}
        columns={[
          { label: t('overview'), width: 250 },
          { label: t('status'), width: 156 },
        ]}
        dropdownOptions={[{ title: t('delete'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentAnnouncements.length}
        selectedCount={selectedAnnouncementIds.length}
        toggleAllSelected={isOn => {
          setSelectedAnnouncementIds(
            isOn ? currentAnnouncements.map(announcement => announcement.id) : [],
          )
        }}
      />

      <DashboardTable
        data={currentAnnouncements}
        isLoading={isLoading}
        emptyStateTitle={t('noAnnouncementsPostedYet')}
        emptyStateSubtitle={t('oncePublisherPostedAnnouncements')}
        renderItem={(announcement: Announcement, index: number) => {
          const scheduled =
            announcement.is_scheduled && announcement.publish_date > Math.ceil(Date.now() / 1000)

          const targetTimestamp = announcement.is_scheduled
            ? announcement.publish_date
            : announcement.created_at

          return (
            <DashboardTableRow
              key={announcement.id}
              index={index}
              image={announcement.image_url}
              title={announcement.title}
              description={announcement.overview}
              status={{
                hint: `${scheduled ? t('scheduledTo') : t('sentAt')}`,
                hintWidth: 160,
                color: scheduled ? palette.purple : palette.green,
                hintDetail: `${dateString(targetTimestamp)}`,
              }}
              dropdownOptions={[
                { title: t('edit'), icon: EditIcon },
                { title: t('delete'), icon: DeleteIcon },
              ]}
              handleOptionsDropdownOptionClick={option =>
                handleAnnouncementDropdownItemSelect(option, announcement)
              }
              selected={selectedAnnouncementIds.includes(announcement.id)}
              onCheckBoxClick={isOn => {
                setSelectedAnnouncementIds(prev =>
                  isOn ? [...prev, announcement.id] : prev.filter(item => announcement.id !== item),
                )
              }}
            />
          )
        }}
      />
    </DashboardTableContainer>
  )
}
