import { api } from '../api'
import { FlatIcon, FlatIconColor, FlatIconPack, FlatIconShape } from '../types'

export const iconService = {
  fetchIconPacks: async (
    query: string,
    page: number,
    limit: number,
    color?: FlatIconColor,
    shape?: FlatIconShape,
  ) =>
    api.prop<{ packs: FlatIconPack[] }>('packs', {
      request: {
        method: 'GET',
        endPoint: '/icon/packs',
        parameters: {
          query: query,
          page: page,
          limit: limit,
          color: color ?? '',
          shape: shape ?? '',
        },
      },
    }),

  fetchIcons: async (
    query: string,
    offset: number,
    limit: number,
    color?: FlatIconColor,
    shape?: FlatIconShape,
    packId?: number,
  ) =>
    api.prop<{ icons: FlatIcon[] }>('icons', {
      request: {
        method: 'GET',
        endPoint: '/icon/icons',
        parameters: {
          query: query,
          offset: offset,
          limit: limit,
          color: color ?? '',
          shape: shape ?? '',
          pack_id: packId ?? '',
        },
      },
    }),

  downloadIcon: async (iconId: number) =>
    api.value<{ base64: string; icon_id: string; should_upload: boolean; download_url: string }>({
      request: {
        method: 'GET',
        endPoint: '/icon/download',
        parameters: { icon_id: iconId },
      },
    }),

  uploadIcon: async (iconId: string, downloadUrl: string) =>
    api.prop<{ storage: { id: string; download_url: string } }>('storage', {
      request: {
        method: 'POST',
        endPoint: '/icon/upload',
        parameters: { icon_id: iconId, download_url: downloadUrl },
      },
    }),
}
