import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1299 14.3H11.5V18.7299L11.5 18.762C11.5 19.18 11.5 19.5357 11.5274 19.7907C11.5412 19.9191 11.566 20.0698 11.6263 20.2046C11.6916 20.3507 11.83 20.5404 12.0887 20.5974C12.3474 20.6544 12.5527 20.5403 12.6733 20.4352C12.7846 20.3382 12.8704 20.2119 12.9369 20.1012C13.069 19.8813 13.2184 19.5585 13.394 19.1791L13.4075 19.15L16.1388 13.2503L16.1559 13.2134C16.4372 12.6058 16.672 12.0988 16.7935 11.6859C16.92 11.2564 16.9625 10.8046 16.699 10.3923C16.4356 9.97997 16.0077 9.82878 15.5649 9.7631C15.1391 9.69995 14.5803 9.69997 13.9108 9.7L13.8701 9.7H12.5V5.27013L12.5 5.23804C12.5 4.82003 12.5 4.4643 12.4726 4.20926C12.4588 4.08088 12.434 3.93022 12.3737 3.79539C12.3084 3.64932 12.17 3.4596 11.9113 3.40262C11.6526 3.34563 11.4473 3.45966 11.3267 3.56479C11.2154 3.66183 11.1296 3.78811 11.0631 3.89881C10.931 4.11873 10.7816 4.44155 10.606 4.82089L10.5925 4.85L7.8612 10.7497L7.8441 10.7866C7.5628 11.3942 7.32804 11.9012 7.20647 12.3141C7.08003 12.7436 7.03747 13.1954 7.30095 13.6077C7.56444 14.02 7.99231 14.1712 8.43515 14.2369C8.86092 14.3 9.41965 14.3 10.0892 14.3L10.1299 14.3Z"
      stroke={fill}
    />
  </svg>
)

export const PlansIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
