import { api } from '../api'
import { Notification } from '../types'

export const notificationService = {
  fetchNotifications: async (roomId: string, offset: number, limit: number) =>
    api.prop<{ notifications: Notification[] }>('notifications', {
      request: {
        method: 'GET',
        endPoint: '/notification/all',
        parameters: { room_id: roomId, offset, limit },
      },
    }),

  fetchDailyNotifications: async (roomId: string) =>
    api.prop<{ notifications: Notification[] }>('notifications', {
      request: {
        method: 'GET',
        endPoint: '/notification/daily',
        parameters: { room_id: roomId },
      },
    }),

  createNotification: async (
    roomId: string,
    title: string,
    overview: string,
    imageUrl: string,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    featureId: string,
  ) =>
    api.prop<{ notification: Notification }>('notification', {
      request: {
        method: 'POST',
        endPoint: '/notification',
        parameters: {
          room_id: roomId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          publish_date: publishDate,
          is_scheduled: isScheduled,
          link: link,
          feature_id: featureId,
        },
      },
    }),

  updateNotification: async (
    notificationId: string,
    title: string,
    overview: string,
    imageUrl: string,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    featureId: string,
  ) =>
    api.prop<{ notification: Notification }>('notification', {
      request: {
        method: 'PUT',
        endPoint: '/notification',
        parameters: {
          notification_id: notificationId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          publish_date: publishDate,
          is_scheduled: isScheduled,
          link: link,
          feature_id: featureId,
        },
      },
    }),

  deleteNotification: async (notificationId: string) =>
    api.prop<{ notification: Notification }>('notification', {
      request: {
        method: 'DELETE',
        endPoint: '/notification',
        parameters: { notification_id: notificationId },
      },
    }),
}
