import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 6C17 6.35064 16.9398 6.68722 16.8293 7L20 7C20.5523 7 21 7.44772 21 8V11.1707C20.6872 11.0602 20.3506 11 20 11C18.3431 11 17 12.3431 17 14C17 15.6569 18.3431 17 20 17C20.3506 17 20.6872 16.9398 21 16.8293V20C21 20.5523 20.5523 21 20 21H8C7.44772 21 7 20.5523 7 20V16.8293C6.68722 16.9398 6.35064 17 6 17C4.34315 17 3 15.6569 3 14C3 12.3431 4.34315 11 6 11C6.35064 11 6.68722 11.0602 7 11.1707V8C7 7.44772 7.44772 7 8 7L11.1707 7C11.0602 6.68722 11 6.35064 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IntegrationIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
