import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createInvitation } from 'src/redux/reducers/audience'
import { getMeUser } from 'src/redux/reducers/me'
import { Error } from 'src/repository/Error'
import { Invitation, Room } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardAudienceInvitationModal: React.FC<{
  room: Room
  invitation?: Invitation | null
  onClose: () => void
}> = ({ room, invitation, onClose }) => {
  // MARK: - Hooks

  const shouldCloseWhenLoadingDone = useRef(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const meUser = useSelect(state => getMeUser(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'invitation'))

  // States
  const [email, setEmail] = useState(invitation?.email ?? '')
  const [firstName, setFirstName] = useState(invitation?.first_name ?? '')
  const [lastName, setLastName] = useState(invitation?.last_name ?? '')
  const [notes, setNotes] = useState(invitation?.notes ?? '')
  const [notifyOnAccept, setNotifyOnAccept] = useState(invitation?.notify_on_accept ?? false)

  const [notifyOnAcceptEmailAddress, setNotifyOnAcceptEmailAddress] = useState(
    (invitation?.notify_on_accept_email || meUser?.email) ?? '',
  )
  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [onClose, isLoading])

  // MARK: - Handlers

  const handleCreateInvitationClick = () => {
    if (!email) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true

    dispatch(
      createInvitation(
        email,
        firstName,
        lastName,
        notes,
        room.id,
        notifyOnAccept,
        notifyOnAcceptEmailAddress,
      ),
    )
  }

  // MARK: - Render

  return (
    <ModalWrapper
      style={{ paddingBottom: 160 }}
      title={t('inviteAudience')}
      height="dynamic"
      size="tiny"
      onClose={onClose}>
      <TopContainer>
        <TextContainer>
          <HorizontalTextContainer>
            <Input
              style={{ maxWidth: '48%' }}
              label={t('firstName')}
              isRequired
              value={firstName}
              onChange={text => setFirstName(text)}
            />

            <Input
              style={{ maxWidth: '48%' }}
              label={t('lastName')}
              isRequired
              value={lastName}
              onChange={text => setLastName(text)}
            />
          </HorizontalTextContainer>

          <Input label={t('email')} isRequired value={email} onChange={text => setEmail(text)} />

          <Input
            inputStyle={{ minHeight: 132 }}
            type="textarea"
            label={t('personalNotes')}
            value={notes}
            onChange={text => setNotes(text)}
          />
        </TextContainer>
      </TopContainer>

      <InfoSwitch
        title={t('notifyMe')}
        style={{ marginTop: 8 }}
        subtitle={t('notifyMeDescription')}
        checked={notifyOnAccept}
        onSwitchToggle={setNotifyOnAccept}
      />

      {notifyOnAccept && (
        <TextContainer>
          <Input
            type="email"
            placeholder={t('emailAddress')}
            value={notifyOnAcceptEmailAddress}
            onChange={text => setNotifyOnAcceptEmailAddress(text)}
          />
        </TextContainer>
      )}

      <FooterWrapper>
        <PrimaryButton
          style={{ minWidth: 120 }}
          title={invitation ? t('update') : t('invite')}
          isLoading={isLoading}
          onClick={handleCreateInvitationClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}

// MARK: - Styles

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const HorizontalTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`
