type Config = {
  keys: {
    firebaseToken: string
    livePreview: string
    activeRoomId: string
    localization: string
    theme: string
  }
  sizes: {
    thumbnailImage: number
    mainImage: number
    webBannerImage: number
  }
  envName: string
  title: string
  platformUrl: string
  apiUrl: string
  lobbiUrl: string
  firebase_api_key: string
  google_maps_api_key: string
  icons_8_api_key: string
  bugsnag_api_key: string
  stripe_api_key: string
  segment_write_key: string

  firebaseConfig: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
  defaultCover: string
}

export const imageUrls = {
  defaultCover:
    'https://images.unsplash.com/photo-1516477173607-7ea60164df72?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwxM3x8WW9nYSUyMG9yYW5nZXxlbnwwfDB8fHwxNjU4MjQ3MzM2&ixlib=rb-1.2.1&q=80&w=1080',
  chatChannel:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1299946?alt=media&token=b2fa1f68-1bb4-4388-871a-709179f4f679',
  userPlaceholder:
    'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F166246?alt=media&token=f9757cb2-7c2f-44df-a2d3-7aef41821f52',
}

const local: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    livePreview: 'LivePreview',
    activeRoomId: 'LOBBI_ACTIVE_ROOM_ID_KEY',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'local',
  title: 'Lobbi - Local',
  platformUrl: 'http://localhost:3000/',
  apiUrl: 'http://localhost:5001/oliver-development-585f8/us-central1/api/api',
  lobbiUrl: 'http://localhost:3001',
  firebase_api_key: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: '5192fe2ced3058c72c4ee4548dc87bd8',
  stripe_api_key:
    'pk_test_51KZxdjEjREKVUIPbFEv1WV2UrZTDWD1evjUrVGWGnddBEDgF1kdo9gd4Qky0NHUuB0r68feZpvwxv1LaCILdnsXf002139prqf',
  segment_write_key: 'KwJ0sUFaU5HqgACvvN1C7U0cOjgQv5BB',

  firebaseConfig: {
    apiKey: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
    authDomain: 'oliver-development-585f8.firebaseapp.com',
    databaseURL: 'https://oliver-development-585f8-default-rtdb.firebaseio.com',
    projectId: 'oliver-development-585f8',
    storageBucket: 'oliver-development-585f8.appspot.com',
    messagingSenderId: '379822110505',
    appId: '1:379822110505:web:a50b783cf6db6eba7aa938',
    measurementId: 'G-EFC23JXVRP',
  },
  defaultCover: imageUrls.defaultCover,
}

const dev: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    livePreview: 'LivePreview',
    activeRoomId: 'LOBBI_ACTIVE_ROOM_ID_KEY',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'development',
  title: 'Lobbi - Development',
  platformUrl: 'https://development.lobbi.events',
  apiUrl: 'https://api-lzs5auurnq-uc.a.run.app/api',
  lobbiUrl: 'https://development.app.lobbi.events',
  firebase_api_key: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: '5192fe2ced3058c72c4ee4548dc87bd8',
  stripe_api_key:
    'pk_test_51KZxdjEjREKVUIPbFEv1WV2UrZTDWD1evjUrVGWGnddBEDgF1kdo9gd4Qky0NHUuB0r68feZpvwxv1LaCILdnsXf002139prqf',
  segment_write_key: 'KwJ0sUFaU5HqgACvvN1C7U0cOjgQv5BB',

  firebaseConfig: {
    apiKey: 'AIzaSyDAurdzjESq8LauTTmmHHicfbylLXdmasI',
    authDomain: 'oliver-development-585f8.firebaseapp.com',
    databaseURL: 'https://oliver-development-585f8-default-rtdb.firebaseio.com',
    projectId: 'oliver-development-585f8',
    storageBucket: 'oliver-development-585f8.appspot.com',
    messagingSenderId: '379822110505',
    appId: '1:379822110505:web:a50b783cf6db6eba7aa938',
    measurementId: 'G-EFC23JXVRP',
  },
  defaultCover: imageUrls.defaultCover,
}

const staging: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    livePreview: 'LivePreview',
    activeRoomId: 'LOBBI_ACTIVE_ROOM_ID_KEY',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'staging',
  title: 'Lobbi - Staging',
  platformUrl: 'https://staging.lobbi.events',
  apiUrl: 'https://api-a2zbawhcpq-uc.a.run.app/api',
  lobbiUrl: 'https://staging.app.lobbi.events',
  firebase_api_key: 'AIzaSyBH6CTwl9gg10qZBK78O7Hpy7Twa3tpum4',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: 'f58be71e432118d4c39e49927020c555',
  stripe_api_key:
    'pk_test_51KZxdjEjREKVUIPbFEv1WV2UrZTDWD1evjUrVGWGnddBEDgF1kdo9gd4Qky0NHUuB0r68feZpvwxv1LaCILdnsXf002139prqf',
  segment_write_key: 'd9FJ4jqg3kzo3Hg1z3PeBi28Dprd14Jd',

  firebaseConfig: {
    apiKey: 'AIzaSyBH6CTwl9gg10qZBK78O7Hpy7Twa3tpum4',
    authDomain: 'oliver-staging-e5ef3.firebaseapp.com',
    databaseURL: 'https://oliver-staging-e5ef3-default-rtdb.firebaseio.com',
    projectId: 'oliver-staging-e5ef3',
    storageBucket: 'oliver-staging-e5ef3.appspot.com',
    messagingSenderId: '201617735461',
    appId: '1:201617735461:web:d2c8d00b3b0d99936be4cc',
    measurementId: 'G-7QS874VZLC',
  },
  defaultCover: imageUrls.defaultCover,
}

const prod: Config = {
  keys: {
    firebaseToken: 'LOBBI_FIREBASE_TOKEN_KEY',
    livePreview: 'LivePreview',
    activeRoomId: 'LOBBI_ACTIVE_ROOM_ID_KEY',
    localization: 'LOBBI_LOCALIZATION_KEY',
    theme: 'LOBBI_THEME_KEY',
  },
  sizes: {
    thumbnailImage: 400,
    mainImage: 1600,
    webBannerImage: 1200,
  },
  envName: 'production',
  title: 'Lobbi',
  platformUrl: 'https://lobbi.events',
  apiUrl: 'https://api-5rckteu34q-uc.a.run.app/api',
  lobbiUrl: 'https://app.lobbi.events',
  firebase_api_key: 'AIzaSyAiIguNzKol13aaCsbO8pb3yTsoFTY42gM',
  google_maps_api_key: 'AIzaSyBziSbMtYGji3Ro06Zu3-5XamMJohwGmWQ',
  icons_8_api_key: 'JuU7UUdazTMpWmxFf4ZLY40hyJwbLGPqh8d8vn0S',
  bugsnag_api_key: '4e3c697fb9d66372aebf332a8c1c6d55',
  segment_write_key: 'YWnOWbRACwT3JOsaAzOyyZx7oVTv1HY1',
  stripe_api_key:
    'pk_live_51KZxdjEjREKVUIPb0qFAnvYeZu2nJrLbgkFrkmGXMV1ljP3KAckb5gbjVbBvhGPyVV1dOH6wHxzEMpY2kqXLpu7t00WLkryrO1',

  firebaseConfig: {
    apiKey: 'AIzaSyAiIguNzKol13aaCsbO8pb3yTsoFTY42gM',
    authDomain: 'oliver-826d8.firebaseapp.com',
    databaseURL: 'https://oliver-826d8-default-rtdb.firebaseio.com',
    projectId: 'oliver-826d8',
    storageBucket: 'oliver-826d8.appspot.com',
    messagingSenderId: '522547224886',
    appId: '1:522547224886:web:956a29cc0dec183e7c5397',
    measurementId: 'G-N0BLX0VP7Z',
  },
  defaultCover: imageUrls.defaultCover,
}

const currentConfig = () => {
  if (window.location.origin === prod.platformUrl) {
    return prod
  } else if (window.location.origin === staging.platformUrl) {
    return staging
  } else if (window.location.origin === dev.platformUrl) {
    return dev
  } else {
    return dev ?? local
  }
}

export default currentConfig()
