import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import styled from 'styled-components'

export const PurchaseTierSelectionFooter: React.FC<{
  isLoading: boolean
  canContinueFree: boolean
  onSecondaryButtonClick: () => void
  onPurchaseClick: () => void
}> = ({ isLoading, canContinueFree, onSecondaryButtonClick, onPurchaseClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Container>
      <InnerContainer>
        {!isLoading && (
          <SecondaryButton
            style={{ marginRight: 16 }}
            title={t('continueFreePlan')}
            isLoading={isLoading}
            onClick={onSecondaryButtonClick}
          />
        )}

        <PrimaryButton
          style={{ marginRight: 24, minWidth: 180 }}
          shimmer
          title={t('purchase')}
          isLoading={isLoading}
          onClick={onPurchaseClick}
        />
      </InnerContainer>
    </Container>
  )
}
// MARK: - Styles

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-top: 1px solid ${({ theme }) => theme.palette.background.separator};
  bottom: 0px;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: flex-end;
  left: 0px;
  position: absolute;
  right: 0px;
`

const InnerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
`
