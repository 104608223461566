import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NavigateIcon } from 'src/assets/images/svg/NavigateIcon'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import {
  FadeIn,
  FlexCol,
  FlexRow,
  LabelMedium,
  LabelSmall,
  Row,
  ShadowBox,
} from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { Separator } from 'src/features/common/Separator'
import { getEntities } from 'src/redux/reducers/entity'
import { getMeId } from 'src/redux/reducers/me'
import { fetchCollaboratedRooms } from 'src/redux/reducers/roomList'
import { Collaborator, Room } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css, useTheme } from 'styled-components'

export const DashboardSidebarRoomSelector: React.FC<{
  selectedRoomId: string
  style?: React.CSSProperties
  onClose: () => void
}> = ({ onClose, style, selectedRoomId }) => {
  // MARK: - Hooks

  const modal = useRef<HTMLDivElement | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { palette } = useTheme()
  const { navigate, routes } = useNavigation()

  const [searchText, setSearchText] = useState('')

  const meUserId = useSelect(state => getMeId(state.me))
  const collaborators = useSelect(state =>
    getEntities<Collaborator>(
      state.entity,
      'collaborator',
      collaborator => collaborator.user_id === meUserId,
    ),
  )
  const rooms = useSelect(state =>
    getEntities<Room>(state.entity, 'room', room => {
      return (
        !!collaborators.find(({ room_id }) => room.id === room_id) &&
        room.title.toLowerCase().includes(searchText.toLowerCase())
      )
    }).sort((a, b) => b.created_at - a.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleModalOutsideClick)
    return () => {
      document.removeEventListener('click', handleModalOutsideClick)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchCollaboratedRooms())
  }, [])

  // MARK: - Handlers

  const handleModalOutsideClick = useCallback((event: MouseEvent | any) => {
    if (modal.current && !modal.current.contains(event.target)) {
      event.stopPropagation()
      onClose()
    }
  }, [])

  const handleViewAllClick = useCallback(() => {
    navigate(routes.roomList)
  }, [])

  const handleRoomClick = useCallback(
    (room: Room) => () => {
      navigate(routes.dashboard(room.id).info)
    },
    [],
  )

  const handleContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
    },
    [],
  )

  // MARK: - Render

  return (
    <FadeIn>
      <Container style={style} ref={modal} onClick={handleContainerClick}>
        <Input
          style={{ maxHeight: 42 }}
          inputStyle={{ borderWidth: 0, borderBottomWidth: 1, borderRadius: 0 }}
          placeholder={t('search')}
          hasIcon="right"
          value={searchText}
          onChange={setSearchText}>
          <SearchIcon size={20} />
        </Input>

        <FlexCol style={{ overflow: 'scroll' }}>
          {rooms.map(room => (
            <RowContainer
              key={room.id}
              isSelected={room.id === selectedRoomId}
              onClick={handleRoomClick(room)}>
              <RoomImage src={room.main_media.url} alt={''} />
              <FlexRow style={{ alignItems: 'center' }}>
                <LabelMedium style={numberOfLines(1)}>{room.title}</LabelMedium>
              </FlexRow>
            </RowContainer>
          ))}
        </FlexCol>

        <Separator margin={0} />

        <Row>
          <ViewAllLobbies onClick={handleViewAllClick}>{t('viewAllLobbies')}</ViewAllLobbies>
          <NavigateIcon fill={palette.orange} size={14} />
        </Row>
      </Container>
    </FadeIn>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  display: flex;
  flex-direction: column;
  height: 360px;
  left: 306px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  user-select: none;
  width: 300px;
  z-index: 2;
`

const ViewAllLobbies = styled(LabelSmall)`
  color: ${({ theme }) => theme.palette.orange};
  cursor: pointer;
  padding: 7px;
  padding-right: 4px;
`

const RowContainer = styled(Row)<{ isSelected: boolean }>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.background.secondary : 'transparent'};
  border-left: ${({ isSelected }) => (isSelected ? 4 : 0)}px solid
    ${({ theme }) => theme.palette.orange};
  margin: 2px 0px;
  padding: 4px 8px 4px 12px;
  padding-left: ${({ isSelected }) => (isSelected ? 8 : 12)}px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `};
`

const RoomImage = styled.img`
  border-radius: 4px;
  height: 30px;
  margin-right: 6px;
  margin-top: 2px;
  width: 30px;
`
