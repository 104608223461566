import { LabelLarge } from './CommonStyles'
import { Separator } from './Separator'
import React from 'react'
import { InfoIcon } from 'src/assets/images/svg/InfoIcon'
import styled from 'styled-components'

export const InfoBox: React.FC<{
  title: string
  elements: string[]
}> = ({ title, elements }) => (
  <Container>
    <TitleContainer>
      <IconContainer>
        <InfoIcon />
      </IconContainer>
      <LabelLarge>{title}</LabelLarge>
    </TitleContainer>

    <Separator />
    <ul>
      {elements.map(element => (
        <Element key={element}>{element}</Element>
      ))}
    </ul>
  </Container>
)

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.blue};
  border-radius: 1000px;
  height: 18px;
  margin-right: 8px;
  margin-top: 3px;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
`

const Element = styled.li`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  line-height: 24px;
`
