import classNames from 'classnames'
import React from 'react'
import { useTheme } from 'styled-components'

export const Checkbox: React.FC<{
  className?: string
  name?: string
  value?: string
  disabled?: boolean
  checked: boolean
  onChange?: (isOn: boolean) => void
}> = ({ className = '', name = '', value = '', disabled = false, checked, children, onChange }) => {
  // MARK: - Properties

  const classes = classNames('form-checkbox', className)
  const { palette } = useTheme()
  // MARK: - Render

  return (
    <label className={classes}>
      <input
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={event => onChange?.(!!event.target.valueAsNumber)}
        style={{ borderColor: palette.text.primary }}
      />
      {children}
    </label>
  )
}
