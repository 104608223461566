import { useHistory, useLocation } from 'react-router-dom'

export const useNavigation = () => {
  // MARK: - Hooks

  const history = useHistory()
  const { search } = useLocation()

  const routes = {
    home: '',
    pricing: '/pricing',
    privacy: '/privacy-policy',
    demo: '/demo',
    roomList: '/creator/rooms',
    authentication: {
      continue: `/authentication`,
      login: `/authentication/login${search}`,
      signUp: `/authentication/signUp${search}`,
    },
    dashboard: (roomId: string) => ({
      info: `/creator/dashboard/room/${roomId}/info`,
      dates: `/creator/dashboard/room/${roomId}/dates`,
      chats: `/creator/dashboard/room/${roomId}/chats`,
      audience: `/creator/dashboard/room/${roomId}/audience`,
      settings: `/creator/dashboard/room/${roomId}/settings`,
      component: (componentId: string) =>
        `/creator/dashboard/room/${roomId}/component/${componentId}`,
    }),
  }

  // MARK: - Return

  return {
    reload: () => window.location.reload(),
    routes: routes,
    location: history.location.pathname,
    match: (route: string) => history.location.pathname === route.split('?')[0],
    goBack: () => history.goBack(),
    navigate: (route: string) => history.push(route),
  }
}
