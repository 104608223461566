import { DashboardHeader } from '../DashboardHeader'
import { DashboardAudienceInvitationModal } from './DashboardAudienceInvitationModal'
import { DashboardAudienceRequestModal } from './DashboardAudienceRequestModal'
import { DashboardAudienceTable } from './DashboardAudienceTable'
import { head, map, orderBy, reduce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import {
  blockMembers,
  cleanAudience,
  exportAudience,
  fetchAudience,
  removeMembers,
  searchAudience,
  unblockMembers,
} from 'src/redux/reducers/audience'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Room, Subscription, User } from 'src/repository/types'
import { dashboardSidebarOptionsImage } from 'src/utils/helpers/dashboardHelper'
import { useDebouncedState } from 'src/utils/hooks/useDebouncedState'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardAudience: React.FC<{
  room: Room
}> = ({ room }) => {
  // MARK: - Hooks

  const audiencePageSize = 20

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'audience'))
  const isPageLoading = useSelect(state => getLoading(state.app, 'audience_page'))

  const subscriptions = useSelect(state => {
    const belongsToRoom = ({ room_id }: Subscription) => room_id === room.id
    const rawSubscriptions = getEntities<Subscription>(state.entity, 'subscription', belongsToRoom)
    return orderBy(rawSubscriptions ?? [], 'created_at', 'desc')
  })
  const userIds = useMemo(() => new Set(map(subscriptions, 'user_id')), [subscriptions])
  const subscriptionRecord: Record<string, Subscription> = useMemo(() => {
    return reduce(subscriptions, (acc, element) => ({ ...acc, [element.user_id]: element }), {})
  }, [subscriptions])

  const users = useSelect(state => {
    let temp = getEntities<User>(state.entity, 'user', ({ id }) => userIds.has(id))
    temp = temp.map((user, index) => {
      const subscription = subscriptionRecord[user.id]
      return {
        ...user,
        is_blocked: subscription?.is_blocked ?? false,
        created_at: subscription.created_at ?? index,
        modified_at: subscription?.is_blocked
          ? subscription?.updated_at ?? 0
          : subscription?.created_at ?? 0,
      }
    })
    return orderBy(temp, 'created_at', 'desc') as (User & {
      is_blocked: boolean
      modified_at: number
    })[]
  })

  const [searchable, query, setQuery] = useDebouncedState('')
  const [showRequestsModal, setShowRequestsModal] = useState(false)
  const [showInvitationModal, setShowInvitationModal] = useState(false)
  const [deletionCandidates, setDeletionCandidates] = useState<User[]>([])
  const [blockCandidates, setBlockCandidates] = useState<User[]>([])

  const isBlockAlert = useMemo(() => {
    return !!blockCandidates.length
  }, [blockCandidates])

  // MARK: - Effects

  useEffect(() => {
    return () => {
      dispatch(cleanAudience(room.id))
    }
  }, [room.id])

  useEffect(() => {
    if (searchable.length) dispatch(searchAudience(room.id, searchable, 0, audiencePageSize))
    else dispatch(fetchAudience(room.id, 0, audiencePageSize))
  }, [searchable, room.id])

  // MARK: - Handlers

  const handleDropdownOptionSelect = useCallback(
    (option: number, selectedUsers: (User & { is_blocked: boolean })[]) => {
      if (option === 0) setDeletionCandidates(selectedUsers)
      else if (option === 1) {
        const isUnblock = selectedUsers.length === 1 && head(selectedUsers)?.is_blocked
        if (isUnblock) dispatch(unblockMembers(room.id, map(selectedUsers, 'id')))
        else setBlockCandidates(selectedUsers)
      } else if (option === 2) dispatch(exportAudience(room.id, map(selectedUsers, 'id')))
    },
    [],
  )

  const handleExportClick = useCallback(
    (selectedUsers: User[]) => {
      dispatch(exportAudience(room.id, map(selectedUsers, 'id')))
    },
    [room.id],
  )

  const handleOnEndReached = useCallback(() => {
    if (!isLoading) {
      if (searchable.length)
        dispatch(searchAudience(room.id, searchable, subscriptions.length, audiencePageSize))
      else dispatch(fetchAudience(room.id, subscriptions.length, audiencePageSize))
    }
  }, [isLoading, searchable, room.id, subscriptions.length])

  const handleSendInviteClick = useCallback(() => {
    setShowInvitationModal(true)
  }, [])

  const handleRemoveMember = useCallback(() => {
    isBlockAlert
      ? dispatch(blockMembers(room.id, map(blockCandidates, 'id')))
      : dispatch(removeMembers(room.id, map(deletionCandidates, 'id')))
  }, [isBlockAlert, room.id, blockCandidates, deletionCandidates])

  // MARK: - Render

  return (
    <Container>
      <DashboardHeader
        image={dashboardSidebarOptionsImage('audience')}
        title={t('audience')}
        description={t('audienceDescription')}
      />

      <DashboardAudienceTable
        users={users}
        query={query}
        onQueryChange={setQuery}
        isLoading={isLoading}
        isPageLoading={isPageLoading}
        onEndReached={handleOnEndReached}
        onExportClick={handleExportClick}
        onDeleteClick={setDeletionCandidates}
        onSendInviteClick={handleSendInviteClick}
        onDropdownOptionSelect={handleDropdownOptionSelect}
      />

      {showRequestsModal && (
        <DashboardAudienceRequestModal
          roomId={room.id}
          onClose={() => setShowRequestsModal(false)}
        />
      )}

      {showInvitationModal && (
        <DashboardAudienceInvitationModal
          room={room}
          onClose={() => setShowInvitationModal(false)}
        />
      )}

      <AlertModal
        error={Error.displayable(
          isBlockAlert ? t('block') : t('removeMember'),
          isBlockAlert ? t('blockMessage') : t('removeMemberMessage'),
          success => success && handleRemoveMember(),
          isBlockAlert ? t('block') : t('removeAction'),
        )}
        visible={!!deletionCandidates.length || isBlockAlert}
        onClose={() => {
          setBlockCandidates([])
          setDeletionCandidates([])
        }}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
