import i18n from '../localization'

export const dateString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleTimeString(locale, {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const prettyDateString = (timestamp: number, withHour: boolean = false) => {
  return `${dayString(timestamp)} ${withHour || isToday(timestamp) ? hourString(timestamp) : ''}`
}

export const dayString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleDateString(locale, {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
  })
}

export const hourString = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const locale = i18n.language
  return date.toLocaleTimeString(locale, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })
}

export const isSameDay = (first: number, second: number) => {
  return dayString(first) === dayString(second)
}

export const isSameMonth = (first: number, second: number) => {
  const firstDate = new Date(first * 1000)
  const secondDate = new Date(second * 1000)
  return (
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  )
}

export const isToday = (timestamp: number) => {
  const currentDate = new Date()
  return dayString(timestamp) === dayString(Math.floor(currentDate.getTime() / 1000))
}

export const prettyRangeString = (start: number, end: number) => {
  const startDate = new Date(start * 1000)
  const endDate = new Date(end * 1000)
  const locale = i18n.language

  const startHourString = hourString(start)
  const startDateString = isToday(start) ? 'Today' : dayString(start)

  const endHourString = hourString(end)

  const sameDay = isSameDay(start, end)
  const sameMonth = isSameMonth(start, end)

  if (sameDay) {
    return startDateString + ' ' + startHourString + '-' + endHourString
  } else if (sameMonth) {
    const monthString = startDate.toLocaleDateString(locale, {
      month: 'short',
      year: 'numeric',
    })
    const startDayString = startDate.toLocaleDateString(locale, {
      day: 'numeric',
    })

    const endDayString = endDate.toLocaleDateString(locale, {
      day: 'numeric',
    })
    const splitted = monthString.split(' ')
    return [splitted[0] + ` ${startDayString}-${endDayString}, ` + splitted[1]]
  } else {
    const startDayString = startDate.toLocaleDateString(locale, {
      month: 'short',
      day: '2-digit',
    })

    const endDayString = startDate.toLocaleDateString(locale, {
      month: 'short',
      day: '2-digit',
    })

    return startDayString + '-' + endDayString + startDate.getUTCFullYear()
  }
}

export const formatAMPM = (date: Date) => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const minutesString = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutesString + ' ' + ampm
  return strTime
}

export const format24 = (date: Date) => {
  return `${(date.getHours() % 24).toString().padStart(2, '0')}:${(date.getMinutes() % 60)
    .toString()
    .padStart(2, '0')} `
}

export const nearestTime = (time: string): string => {
  if (!time.includes(':') || (time.match(/:/g) || []).length > 1) return '12:00'
  let [hour, minute] = time.split(':')
  if (typeof hour === 'undefined' || typeof minute === 'undefined') return '12:00'
  const nHour = Math.min(23, Math.abs(parseInt(hour || '0', 10)))
  const nMinute = Math.min(59, Math.abs(parseInt(minute || '0', 10)))

  if (nHour < 10) hour = `0${nHour}`
  else hour = `${nHour}`

  if (nMinute < 10) minute = `0${nMinute}`
  else minute = `${nMinute}`

  return `${hour}:${minute}`
}

export const timeOptions = (): string[] => {
  const options: string[] = []
  const hours = Array.from({ length: 24 }, (_, i) => i)
  const minutes = Array.from({ length: 2 }, (_, i) => i * 30)

  for (const hour of hours) {
    for (const minute of minutes) {
      options.push(nearestTime(`${hour}:${minute}`))
    }
  }
  return options
}

export const isValidTime = (time: string): boolean => {
  if (!time.includes(':') || (time.match(/:/g) || []).length > 1) return false
  const [hour, minute] = time.split(':')
  if (typeof hour === 'undefined' || typeof minute === 'undefined') return false
  const nHour = Math.abs(parseInt(hour || '0', 10))
  const nMinute = Math.abs(parseInt(minute || '0', 10))

  return nHour < 24 && nMinute < 59
}
