import React from 'react'
import { Col, FlexCol, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { DashboardSidebarOption } from 'src/utils/helpers/dashboardHelper'
import styled from 'styled-components'

export const DashboardSidebarItem: React.FC<{
  option: DashboardSidebarOption
  isSelected: boolean
  index: number
  currentIndex: number
  onOptionSelect: (index: number) => void
}> = ({ option, currentIndex, index, isSelected, onOptionSelect }) => (
  <Container>
    <FlexCol>
      <RowContainer
        onClick={() => onOptionSelect(index)}
        isSelected={isSelected}
        isParent={!!option.subItems.length}>
        <Image src={option.icon} isSelected={isSelected} />
        <Title isSelected={isSelected}>{option.title}</Title>
      </RowContainer>

      <Col>
        {isSelected &&
          option.subItems.map((subItem, subIndex) => {
            const optionIndex = index + subIndex / 10
            const isOptionSelected = optionIndex === currentIndex
            return (
              <RowContainer
                key={subIndex}
                isParent={false}
                onClick={() => onOptionSelect(optionIndex)}
                style={{ paddingLeft: 46 }}
                isSelected={isOptionSelected}>
                <Title
                  style={{ marginLeft: isOptionSelected ? -4 : 0 }}
                  isSelected={isOptionSelected}>
                  {subItem}
                </Title>
              </RowContainer>
            )
          })}
      </Col>
    </FlexCol>
  </Container>
)

// MARK: - Styles

const Container = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  cursor: pointer;
  display: flex;
`

const RowContainer = styled(Row)<{ isSelected: boolean; isParent: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected, isParent }) =>
    !isParent && isSelected
      ? theme.palette.background.secondary
      : theme.palette.background.primary};
  border-left: ${({ isParent, isSelected }) => (!isParent && isSelected ? 4 : 0)}px solid
    ${({ theme }) => theme.palette.orange};
  cursor: pointer;
  display: flex;
  padding: 7px 16px;
  padding-left: ${({ isSelected, isParent }) => (isSelected && !isParent ? 12 : 16)}px;
`

const Title = styled(LabelMedium)<{ isSelected: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.text.primary : theme.palette.text.secondary};
`

const Image = styled.img<{ isSelected: boolean }>`
  height: 24px;
  margin-right: 12px;
  width: 24px;
`
