import { BusinessIcon } from './assets/BusinessIcon'
import { FreeIcon } from './assets/FreeIcon'
import { TeamIcon } from './assets/TeamIcon'
import React from 'react'

export type PricingOption = 'free' | 'premium' | 'business'
export type PricingBenefit = { title: string; hint?: string }

export const pricingOptionLogo = (pricingOption: PricingOption) => {
  switch (pricingOption) {
    case 'free':
      return <FreeIcon size={45} fill={'#374151'} />
    case 'premium':
      return <TeamIcon size={45} fill={'#374151'} />
    case 'business':
      return <BusinessIcon height={45} width={60} fill={'#374151'} />
  }
}

export const pricingOptionTitle = (pricingOption: PricingOption) => {
  switch (pricingOption) {
    case 'free':
      return 'Free'
    case 'premium':
      return 'Team'
    case 'business':
      return 'Business'
  }
}

export const pricingOptionCTATitle = (pricingOption: PricingOption) => {
  switch (pricingOption) {
    case 'free':
      return 'Create a free account'
    case 'premium':
      return 'Go Premium'
    case 'business':
      return 'Request a demo'
  }
}

export const pricingOptionPrice = (pricingOption: PricingOption) => {
  switch (pricingOption) {
    case 'free':
      return '$0 per lobbi'
    case 'premium':
      return 'Starts at $25 per lobbi'
    case 'business':
      return 'Custom pricing'
  }
}

export const pricingOptionBenefitTitle = (pricingOption: PricingOption) => {
  switch (pricingOption) {
    case 'free':
      return 'WHAT YOU CAN DO'
    case 'premium':
      return 'ALL OF FREE PLUS'
    case 'business':
      return 'ALL OF TEAM PLUS'
  }
}

export const pricingOptionBenefits = (pricingOption: PricingOption): PricingBenefit[] => {
  switch (pricingOption) {
    case 'free':
      return [
        { title: '1 active feature per lobbi' },
        { title: 'Unlimited visitors' },
        { title: 'Unlimited chat rooms' },
        { title: 'Audience import and export' },
      ]
    case 'premium':
      return [{ title: 'Scalable feature count per lobbi' }, { title: 'Team collaboration' }]
    case 'business':
      return [{ title: 'Custom volume features' }, { title: 'Dedicated 7/24 support service' }]
  }
}
