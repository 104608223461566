import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardPollModal } from './DashboardPollModal'
import { DashboardPollingColumn } from './DashboardPollingColumn'
import React, { useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { FlexRow } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PageLoader } from 'src/features/common/PageLoader'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading } from 'src/redux/reducers/app'
import { fetchPolls, togglePoll } from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Component, Poll, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardPolling: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Search text used in poll useSelect hook.
  const [searchText, setSearchText] = useState('')
  const [showPollModal, setShowPollModal] = useState(false)

  const isLoading = useSelect(state => getLoading(state.app, 'poll'))
  const polls = useSelect(state =>
    getEntities<Poll>(state.entity, 'poll', poll => poll.component_id === component.id)
      .filter(({ question, options }) =>
        `${options.join()}${question}`.toLowerCase().includes(searchText.toLowerCase()),
      )
      .sort((first, second) => second.updated_at - first.updated_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchPolls(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleDragEnd = (result: DropResult) => {
    const { destination, draggableId } = result
    const poll = polls.find(({ id }) => id === draggableId)
    if (!poll) return

    if (destination?.droppableId === '0' && poll.is_active) {
      dispatch(togglePoll(poll, false))
    } else if (destination?.droppableId === '1' && !poll.is_active) {
      dispatch(togglePoll(poll, true))
    }
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <HeaderContainer>
        <PrimaryButton title={t('createPoll')} onClick={() => setShowPollModal(true)} />

        <FlexRow style={{ justifyContent: 'flex-end' }}>
          <TextContainer>
            <Input
              type="search"
              placeholder={t('search')}
              hasIcon="right"
              value={searchText}
              onChange={setSearchText}>
              <SearchIcon size={16} />
            </Input>
          </TextContainer>
        </FlexRow>
      </HeaderContainer>

      <ColumnContainer>
        {isLoading && !polls.length ? (
          <PageLoader style={{ height: 550 }} />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <DashboardPollingColumn
              isActive={false}
              polls={polls.filter(({ is_active }) => !is_active)}
            />
            <DashboardPollingColumn isActive polls={polls.filter(({ is_active }) => is_active)} />
          </DragDropContext>
        )}
      </ColumnContainer>

      {showPollModal && (
        <DashboardPollModal component={component} onClose={() => setShowPollModal(false)} />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  height: 72px;
  margin: 0px 20px;
  margin-top: 28px;
  padding: 16px 0px 0px 12px;
`

const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100vh - 280px);
  margin: 12px 12px 0px 12px;
  min-height: calc(100vh - 280px);
  overflow: scroll;
  padding-bottom: 20px;
`

const TextContainer = styled.div`
  flex-basis: 30%;
  margin-bottom: 0px;
  margin-right: 2%;
`
