import { DashboardComponentSideBarItem } from './DashboardComponentSideBarItem'
import React, { useCallback } from 'react'
import { Component } from 'src/repository/types'
import styled from 'styled-components'

export const DashboardComponentSideBar: React.FC<{
  components: Component[]
  showDropdown: boolean
  currentIndex: number
  onComponentSelect: (index: number) => void
  onComponentDropdownOptionSelect?: (component: Component, option: number) => void
}> = ({
  showDropdown,
  currentIndex,
  components,
  onComponentSelect,
  onComponentDropdownOptionSelect,
}) => {
  // MARK: - Handlers

  const handleComponentSelect = useCallback(
    (componentIndex: number) => () => {
      onComponentSelect(componentIndex)
    },
    [components],
  )

  // MARK: - Render

  return (
    <Container>
      {components.map((component, index) => (
        <DashboardComponentSideBarItem
          key={component.id}
          index={index}
          totalCount={components.length}
          isSelected={index === currentIndex}
          component={component}
          showDropdown={showDropdown}
          onComponentSelect={handleComponentSelect(index)}
          onComponentDropdownOptionSelect={onComponentDropdownOptionSelect}
        />
      ))}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
  min-width: 230px;
`
