import { AnimatedFooter } from '../common/AnimatedFooter'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'

export const DashboardFooter: React.FC<{
  visible: boolean
  isLoading: boolean
  onDiscardClick: () => void
  onSaveClick: () => void
}> = ({ visible, isLoading, onDiscardClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <AnimatedFooter visible={visible} style={{ zIndex: 3 }}>
      <SecondaryButton
        style={{ width: 120, marginRight: 16 }}
        disabled={isLoading}
        title={t('discard')}
        onClick={isLoading ? () => {} : onDiscardClick}
      />

      <PrimaryButton
        style={{ marginRight: 40, width: 120 }}
        isLoading={isLoading}
        title={t('save')}
        onClick={onSaveClick}
      />
    </AnimatedFooter>
  )
}
