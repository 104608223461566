import React from 'react'
import { useTranslation } from 'react-i18next'
import { LabelLarge } from 'src/features/common/CommonStyles'
import { Separator } from 'src/features/common/Separator'
import { getMeUser } from 'src/redux/reducers/me'
import {
  Room,
  PurchaseAudienceTier,
  PurchaseDiscount,
  PurchaseUsageTier,
} from 'src/repository/types'
import { dayString } from 'src/utils/helpers/dateHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const PurchasePaymentOverview: React.FC<{
  room: Room
  selectedTiers: {
    audience: PurchaseAudienceTier | null
    featureUsage: PurchaseUsageTier | null
  }
  price: number
  discount: PurchaseDiscount | null
}> = ({ room, selectedTiers, price, discount }) => {
  // MARK: - Properties

  const { audience, featureUsage } = selectedTiers

  // MARK: - Hooks

  const { t } = useTranslation()
  const meUser = useSelect(state => getMeUser(state.me))

  // MARK: - Properties

  const purchaseInfo: { title: string; description: string }[] = [
    { title: t('billingType'), description: t('oneTime') },
    { title: t('price'), description: price + '$' },
    {
      title: t('features'),
      description:
        typeof featureUsage?.usage_limit === 'number'
          ? t('countPremiumFeatures', { count: featureUsage?.usage_limit })
          : t('noLimit'),
    },
    {
      title: t('audienceSize'),
      description: t('countMembers', {
        count: audience?.subscription_limit as number,
      }),
    },
  ]

  const paymentInfo: { title: string; description: string }[] = [
    { title: t('purchaseRoom'), description: room.title },
    { title: t('purchaseDate'), description: dayString(Date.now() / 1000) },
    { title: t('purchaserEmail'), description: meUser?.email ?? '' },
  ]

  // MARK: - Render

  const renderInfo = (title: string, description: string) => (
    <InfoItemContainer key={title}>
      <InfoTitle>{title}</InfoTitle>
      <InfoDescription>{description}</InfoDescription>
    </InfoItemContainer>
  )

  return (
    <Container>
      <LabelLarge style={{ marginBottom: 16 }}>{t('purchaseSummary')}</LabelLarge>
      <InnerContainer>
        <PlanTitle>{t('lobbiPlan', { type: audience?.name })}</PlanTitle>
        <PlanSubTitle>
          {t('scaleAudience', {
            count: audience?.subscription_limit as number,
          })}
        </PlanSubTitle>

        <Separator margin={28} />

        <InfoContainer>
          {purchaseInfo.map(info => renderInfo(info.title, info.description))}
        </InfoContainer>

        <Separator margin={28} />

        <InfoContainer>
          {paymentInfo.map(info => renderInfo(info.title, info.description))}
        </InfoContainer>
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  margin-left: 24px;
`

const InnerContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 4px;
  margin-top: 4px;
`

const InfoTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  width: 200px;
`

const InfoDescription = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 1;
  font-size: 15px;
`

const PlanTitle = styled.h6`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: 8px;
`

const PlanSubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
  white-space: pre-wrap;
`
