import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { createBrowserHistory } from 'history'
import 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import store from 'src/redux/store'
import 'src/utils/localization'
import 'src/utils/theme/globalTheme'
import { App } from './App'
import './assets/scss/style.scss'
import config from './utils/config'
import './utils/types/promise.d'

// Log app version
console.info(`${config.envName} - v${require('../package.json').version}`)
const history = createBrowserHistory()
let ErrorBoundary = ({ children }) => <div>{children}</div>

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.info('Development mode')
} else {
  Bugsnag.start({
    apiKey: config.bugsnag_api_key,
    plugins: [new BugsnagPluginReact()],
  })
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
}

ReactDOM.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </ReduxProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
