import { DashboardComponentModalContainer } from './ComponentModalContainer'
import { SaveComponentInput } from './types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'src/features/common/Input'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getFirstEntity } from 'src/redux/reducers/entity'
import { Component, ComponentType, WebPage } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'

export const WebPageModal: React.FC<{
  component?: Component
  onSaveClick: (input: SaveComponentInput) => void
  onBackClick: () => void
}> = ({ component, onBackClick, onSaveClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const webPage = useSelect(state =>
    getFirstEntity<WebPage>(state.entity, 'web_page', page => page.component_id === component?.id),
  )
  const [url, setUrl] = useState(webPage?.url ?? '')
  const [isExternal, setIsExternal] = useState(webPage?.is_external ?? false)

  // MARK: - Render

  return (
    <DashboardComponentModalContainer
      componentType={ComponentType.webPage}
      component={component}
      header={t('webPage')}
      titlePlaceholder={t('webPageTitlePlaceholder')}
      descriptionPlaceholder={t('webPageDescriptionPlaceholder')}
      onBackClick={onBackClick}
      onSaveClick={partial =>
        onSaveClick({
          ...partial,
          type: ComponentType.webPage,
          input: { key: 'web_page', url },
        })
      }>
      <Input
        label={t('webPageUrl')}
        placeholder="https://"
        value={url}
        onChange={text => setUrl(text)}
      />

      <InfoSwitch
        title={t('openExternally')}
        subtitle={t('openExternallyDescription')}
        checked={isExternal}
        onSwitchToggle={setIsExternal}
      />
    </DashboardComponentModalContainer>
  )
}
