import './App.css'
import { AppAlertProvider } from './AppAlertProvider'
import { FlexCol } from './features/common/CommonStyles'
import { ToastContainer } from './features/common/Toast'
import { getTheme } from './redux/reducers/app'
import config from './utils/config'
import Routes from './utils/router'
import { appTheme } from './utils/theme/appTheme'
import { getApps, initializeApp } from 'firebase/app'
import { useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { ThemeProvider } from 'styled-components'

export const App: React.FC = () => {
  // MARK: - Hooks

  const currentTheme = useSelect(state => getTheme(state.app))
  const theme = appTheme(currentTheme)

  // MARK: - Effects

  useEffect(() => {
    if (!getApps().length) {
      initializeApp(config.firebaseConfig)
    }
  }, [])

  // MARK: - Render

  return (
    <ThemeProvider theme={theme}>
      <AppAlertProvider>
        <Container>
          <ToastContainer
            style={{ marginTop: 80 }}
            position="top-right"
            autoClose={3200}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes />
        </Container>
      </AppAlertProvider>
    </ThemeProvider>
  )
}

// MARK: - Container

const Container = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.secondary}; ;
`
