import i18n from 'src/utils/localization'
import { Localization } from 'src/utils/types/i18n'

export type CreateRoomTheme = {
  title: string
  cover: string
  offset: number
}

export const createRoomThemes = (lng: Localization): CreateRoomTheme[] => [
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1591228127791-8e2eaef098d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwxN3x8WW9nYXxlbnwwfDB8fHwxNjU4MjQ3MzI1&ixlib=rb-1.2.1&q=80&w=1080',
    offset: 0,
  },
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1517976547714-720226b864c1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHw2fHxzcGFjZVh8ZW58MHwwfHx8MTY1ODI1NDE3MQ&ixlib=rb-1.2.1&q=80&w=1080',
    offset: -0.2,
  },
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1551263640-1c007852f616?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwyMnx8cHJvZHVjdCUyMGxhdW5jaHxlbnwwfDB8fHwxNjU4MjU0MjQ2&ixlib=rb-1.2.1&q=80&w=1080',
    offset: 0,
  },
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1611162616475-46b635cb6868?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwxfHx5b3V0dWJlfGVufDB8MHx8fDE2NTgyNTQyODU&ixlib=rb-1.2.1&q=80&w=1080',
    offset: -0.4,
  },
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwyMXx8eW91dHViZXxlbnwwfDB8fHwxNjU4MjU0Mjg1&ixlib=rb-1.2.1&q=80&w=1080',
    offset: -0.4,
  },
  {
    title: i18n.t('yourNextAmazingThing'),
    cover:
      'https://images.unsplash.com/photo-1619190324856-af3f6eb55601?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzMjU3Mzd8MHwxfHNlYXJjaHwzfHx0d2l0Y2h8ZW58MHwwfHx8MTY1ODMyOTg5MA&ixlib=rb-1.2.1&q=80&w=1080',
    offset: 0,
  },
]
