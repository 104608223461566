import {
  deleteEntities,
  getEntities,
  getFirstEntity,
  mergeEntities,
} from 'src/redux/reducers/entity'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { componentService } from 'src/repository/services/componentService'
import { spreadGameScoreService } from 'src/repository/services/spreadGameScoreService'
import { Component, SpreadGame, SpreadGameScore } from 'src/repository/types'

type State = {
  isLoading: boolean
  error: IError | null
}

// MARK: - State

export const initialState: State = {
  isLoading: false,
  error: null,
}

// MARK: - Reducer

export const dashboardSpreadGameReducer = (
  state = initialState,
  action: LoadingAction | ErrorAction | FlushAction,
): State => {
  switch (action.type) {
    case 'dashboardSpreadGame/loading':
      return { ...state, isLoading: action.isLoading }

    case 'dashboardSpreadGame/error':
      return { ...state, error: action.error }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchSpreadGameScores =
  (componentId: string, offset: number, limit: number): AsyncThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await spreadGameScoreService.fetchSpreadGameScores(componentId, offset, limit)

    if (response.success) {
      const { scores, users } = response.value
      const entity = getState().entity
      const game = getFirstEntity<SpreadGame>(
        entity,
        'spread_game',
        ({ component_id }) => component_id === componentId,
      )
      const existingScores = getEntities<SpreadGameScore>(
        entity,
        'spread_game_score',
        ({ game_id }) => game_id === game?.id,
      )
      dispatch(
        deleteEntities({
          spread_game_score: existingScores.map(score => score.id),
        }),
      )
      dispatch(mergeEntities({ spread_game_score: scores, user: users }))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const updateBlackList =
  (component: Component, blackList: string[]): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await componentService.updateComponent(
      component.id,
      component.title,
      component.overview,
      component.image_url,
      component.is_active,
      { key: 'spread_game', black_list: blackList },
    )

    if (response.success) {
      const { component: responseComponent, spread_game } = response.value
      dispatch(
        mergeEntities({
          component: [responseComponent],
          spread_game: spread_game ? [spread_game] : [],
        }),
      )
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const setError = (error: IError | null): ErrorAction => ({
  type: 'dashboardSpreadGame/error',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State) => {
  return state.isLoading
}

export const getError = (state: State) => {
  return state.error
}

// MARK: - Action Types

type LoadingAction = {
  type: 'dashboardSpreadGame/loading'
  isLoading: boolean
}

type ErrorAction = {
  type: 'dashboardSpreadGame/error'
  error: IError | null
}

type FlushAction = {
  type: 'me/logout'
}

// MARK: - Internal Actions

const setLoading = (isLoading: boolean): LoadingAction => ({
  type: 'dashboardSpreadGame/loading',
  isLoading: isLoading,
})
