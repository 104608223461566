import { LabelMedium } from './CommonStyles'
import React from 'react'
import { SVG } from 'src/utils/types/svg'
import styled, { css } from 'styled-components'

export const DropDown: React.FC<{
  style?: React.CSSProperties
  data: { title: string; icon: SVG }[]
  onItemClick: (index: number) => void
}> = ({ style, data = [], onItemClick }) => {
  const renderOption = (item: { title: string; icon: SVG }, index: number) => {
    const Icon = item.icon
    return (
      <Row key={index} onClick={() => onItemClick(index)}>
        <IconContainer>{<Icon size={20} style={{ marginTop: -2 }} />}</IconContainer>
        <LabelMedium style={{ fontWeight: 450, fontSize: 15 }}>{item.title}</LabelMedium>
      </Row>
    )
  }
  return (
    <Container style={style} className="no-select">
      {data.map((item, index) => renderOption(item, index))}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px 0px;
  position: absolute;
  right: 0px;
  top: 24px;
  width: 240px;
  z-index: 20;
`

const IconContainer = styled.div`
  height: 16px;
  margin-right: 12px;
`

const Row = styled.div`
  align-items: center;
  border-top-width: 1px;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  font-size: 16px;
  padding: 9px 12px 9px 12px;

  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`
