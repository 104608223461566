import { api } from '../api'
import { Announcement } from '../types'

export const announcementService = {
  fetchAnnouncements: async (componentId: string, lastUpdatedAt: number) =>
    api.prop<{ announcements: Announcement[] }>('announcements', {
      request: {
        method: 'GET',
        endPoint: '/announcements',
        parameters: { component_id: componentId, last_updated_at: lastUpdatedAt },
      },
    }),

  createAnnouncement: async (
    roomId: string,
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    imageOffset: number,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    actionName: string,
    type: 'info' | 'warning',
    notifyUsers: boolean,
    hasDetail: boolean,
  ) =>
    api.prop<{ announcement: Announcement }>('announcement', {
      request: {
        method: 'POST',
        endPoint: '/announcement',
        parameters: {
          room_id: roomId,
          component_id: componentId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          image_offset: imageOffset,
          publish_date: publishDate,
          is_scheduled: isScheduled,
          link: link,
          action_name: actionName,
          type: type,
          notify_users: notifyUsers,
          has_detail: hasDetail,
        },
      },
    }),

  updateAnnouncement: async (
    announcementId: string,
    title: string,
    overview: string,
    imageUrl: string,
    imageOffset: number,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    actionName: string,
    notifyUsers: boolean,
    hasDetail: boolean,
    isActive: boolean,
  ) =>
    api.prop<{ announcement: Announcement }>('announcement', {
      request: {
        method: 'PUT',
        endPoint: '/announcement',
        parameters: {
          announcement_id: announcementId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          image_offset: imageOffset,
          publish_date: publishDate,
          is_scheduled: isScheduled,
          link: link,
          action_name: actionName,
          notify_users: notifyUsers,
          has_detail: hasDetail,
          is_active: isActive,
        },
      },
    }),

  deleteAnnouncement: async (announcementId: string) =>
    api.prop<{ announcement: Announcement }>('announcement', {
      request: {
        method: 'DELETE',
        endPoint: '/announcement',
        parameters: { announcement_id: announcementId },
      },
    }),
}
