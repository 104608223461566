import { PurchasePayment } from './PurchasePayment'
import { PurchaseTierSelection } from './PurchaseTierSelection'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { pushError } from 'src/redux/reducers/app'
import { getEntities } from 'src/redux/reducers/entity'
import {
  deleteOffer,
  executeFreeOffer,
  fetchOffer,
  fetchPurchaseTiers,
  flushPurchase,
  getFreeTiers,
  getIsPurchaseCompleted,
  getOffer,
  getSelectedTiers,
} from 'src/redux/reducers/purchase'
import { Error } from 'src/repository/Error'
import { Component, Room } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const PurchaseModal: React.FC<{
  room: Room
  onComplete: () => void
  onClose: () => void
}> = ({ room, onComplete, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [showPurchasedAlert, setShowPurchasedAlert] = useState(false)
  const [showFreePlanAlert, setShowFreePlanAlert] = useState(false)
  const [activePageIndex, setActivePageIndex] = useState(0)
  const [promoCode, setPromoCode] = useState('')

  const dispatch = useDispatch()
  const components = useSelect(state =>
    getEntities<Component>(state.entity, 'component', ({ room_id }) => room_id === room.id),
  )
  const purchaseCompleted = useSelect(state => getIsPurchaseCompleted(state.purchase))
  const offer = useSelect(state => getOffer(state.purchase))
  const freeTiers = useSelect(state => getFreeTiers(state.purchase))
  const selectedTiers = useSelect(state => getSelectedTiers(state.purchase))

  // MARK: Life Cycle

  useEffect(() => {
    if (purchaseCompleted) setShowPurchasedAlert(true)
  }, [purchaseCompleted])

  useEffect(() => {
    dispatch(fetchPurchaseTiers(room.id))
  }, [dispatch, room.id])

  useEffect(() => {
    if (offer?.audience_tier.tier === 0) {
      dispatch(executeFreeOffer(offer.id))
    } else if (offer) {
      setActivePageIndex(1)
    }
  }, [offer, dispatch])

  // MARK: - Handlers

  const handleClose = () => {
    dispatch(flushPurchase())
    onClose()
  }

  const handleContinueFreePlanClick = () => {
    const { audience, featureUsage } = freeTiers
    if (!audience || !featureUsage) {
      dispatch(pushError(Error.displayable('Oopps!', 'Please refresh the page')))
      return
    }

    dispatch(fetchOffer(room.id, audience, featureUsage, promoCode))
  }

  const handleContinuePurchaseClick = () => {
    const { audience, featureUsage } = selectedTiers
    if (!audience || !featureUsage) {
      dispatch(pushError(Error.displayable('Oopps!', 'Please select all tiers')))
      return
    }

    dispatch(fetchOffer(room.id, audience, featureUsage, promoCode))
  }

  const handleConfigurePurchaseClick = () => {
    if (offer) dispatch(deleteOffer(offer.id))
    setActivePageIndex(0)
  }

  // MARK: - Render

  return (
    <ModalWrapper
      title={activePageIndex === 0 ? t('buildAPlan') : t('purchasePlan')}
      onClose={handleClose}
      size={activePageIndex === 0 ? 'tiny' : 'small'}>
      {activePageIndex === 0 && (
        <PurchaseTierSelection
          componentCount={components.length}
          promoCode={promoCode}
          onPromoCodeChange={setPromoCode}
          onContinuePurchaseClick={handleContinuePurchaseClick}
          onContinueFreeClick={() => setShowFreePlanAlert(true)}
        />
      )}

      {activePageIndex === 1 && (
        <PurchasePayment room={room} onConfigurePurchaseClick={handleConfigurePurchaseClick} />
      )}

      {showFreePlanAlert && components.length <= 2 && (
        <AlertModal
          error={Error.displayable(
            t('continueWithFreePlan'),
            t('continueWithFreePlanDescription'),
            success => {
              if (success) handleContinueFreePlanClick()
            },
            t('continue'),
          )}
          onClose={() => setShowFreePlanAlert(false)}
        />
      )}
      {showFreePlanAlert && components.length > 2 && (
        <AlertModal
          error={Error.displayable(
            t('continueWithFreePlan'),
            t('continueWithFreePlanBlockedDescription'),
          )}
          onClose={() => setShowFreePlanAlert(false)}
        />
      )}

      {showPurchasedAlert && (
        <AlertModal
          error={Error.displayable(
            t('congrats'),
            t('purchaseCompleted'),
            success => {
              if (success) {
                dispatch(flushPurchase())
                onComplete()
              }
            },
            t('letsGo'),
          )}
          hideCancel
        />
      )}
    </ModalWrapper>
  )
}
