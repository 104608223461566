import React from 'react'
import { FlexCol, FlexRow, LabelHeader, LabelNormal } from 'src/features/common/CommonStyles'
import styled from 'styled-components'

export const DashboardHeader: React.FC<{
  title: string
  image?: string
  description: string
}> = ({ title, description, image, children }) => {
  return (
    <Container>
      <FlexRow>
        {image ? <HeaderImage src={image} /> : null}

        <FlexCol style={{ marginBottom: 12 }}>
          <LabelHeader style={{ marginTop: 2, marginBottom: 4 }}>{title}</LabelHeader>
          <LabelNormal>{description}</LabelNormal>
        </FlexCol>
      </FlexRow>

      {children}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexRow)`
  align-items: center;
  margin: 0px 20px;
  padding: 40px 16px 0px 16px;
`

const HeaderImage = styled.img`
  border-radius: 8px;
  height: 48px;
  margin: 4px 16px 0px 0px;
  width: 48px;
`
