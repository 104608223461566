import { Action } from '../store'
import produce from 'immer'
import { Me, User } from 'src/repository/types'

// MARK: - Types

type State = {
  me?: Me
  idToken?: string
}

type ActionType =
  | { type: 'me/add'; me: Me }
  | { type: 'me/idToken'; idToken: string }
  | { type: 'me/logout' }
  | Action<'me/logout'>

// MARK: - Reducer

export const meReducer = (state: State = {}, action: ActionType): State => {
  switch (action.type) {
    case 'me/add':
      return produce(state, draft => {
        draft.me = action.me
        return draft
      })

    case 'me/idToken':
      return produce(state, draft => {
        draft.idToken = action.idToken
        return draft
      })

    case 'me/logout':
      return {}

    default:
      return state
  }
}

// MARK: Actions

export const setMe = (user: User): ActionType => ({
  type: 'me/add',
  me: { id: user.id, user: user },
})

export const setIdToken = (idToken: string): ActionType => ({
  type: 'me/idToken',
  idToken: idToken,
})

// MARK: - Selectors

export const getMe = (state: State): Me | undefined => {
  return state.me
}

export const getIsAuthenticated = (state: State): boolean => {
  return !!state.idToken
}

export const getMeId = (state: State): string | undefined => {
  return state.me?.id
}

export const getIdToken = (state: State): string | undefined => {
  return state.idToken
}

export const getMeUser = (state: State): User | undefined => {
  return state.me?.user
}
