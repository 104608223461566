import produce from 'immer'
import moment from 'moment'
import { popLoading, pushError, pushLoading } from 'src/redux/reducers/app'
import {
  deleteEntities,
  getEntities,
  getFirstEntity,
  MergeAction,
  mergeEntities,
} from 'src/redux/reducers/entity'
import { Action, AsyncThunk, Thunk } from 'src/redux/store'
import { componentService } from 'src/repository/services/componentService'
import { purchaseService } from 'src/repository/services/purchaseService'
import { RoomRequest, roomService } from 'src/repository/services/roomService'
import {
  AnnouncementList,
  Component,
  ComponentType,
  componentTypeKey,
  Link,
  Room,
  SpreadGame,
  WebPage,
} from 'src/repository/types'

type Request = RoomRequest & { room_id?: string }

type State = {
  request: Request | null
  drawerCollapsed: boolean
}

type ActionType =
  | { type: 'room/setUpdateRoomRequest'; updateRoomRequest: Partial<Request> }
  | { type: 'room/setDrawerCollapsed'; collapsed: boolean }
  | MergeAction
  | Action<'room/flush'>
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  request: null,
  drawerCollapsed: true,
}

// MARK: - Reducer

export const roomReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'room/setDrawerCollapsed':
      return produce(state, draft => {
        draft.drawerCollapsed = action.collapsed
        return draft
      })

    case 'room/setUpdateRoomRequest':
      return produce(state, draft => {
        draft.request = { ...(draft.request as RoomRequest), ...action.updateRoomRequest }
        return draft
      })

    case 'me/logout':
    case 'room/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const setUpdateRoomRequest = (
  roomRequest: Partial<RoomRequest & { links: Link[] }>,
): ActionType => ({
  type: 'room/setUpdateRoomRequest',
  updateRoomRequest: roomRequest,
})

export const setDrawerCollapsed = (collapsed: boolean): ActionType => ({
  type: 'room/setDrawerCollapsed',
  collapsed,
})

export const initializeUpdateRoomRequest =
  (room: Room): Thunk<ActionType> =>
  dispatch => {
    const request: RoomRequest & { room_id: string } = {
      room_id: room.id,
      title: room.title,
      overview: room.overview,
      date: {
        start: room.start_date,
        end: room.end_date,
        timezone: room.timezone ?? moment.tz?.guess(),
      },
      privacy_type: room.privacy_type,
      links: room.links,
      media: room.main_media,
      location: room.location ?? { type: 'tba', description: '' },
      access_codes: room.access_codes,
      tags: room.tags,
      config: room.config,
      api_key: null,
      api_secret: null,
    }

    dispatch(setUpdateRoomRequest({ ...request }))
  }

export const updateRoom = (): AsyncThunk<ActionType> => async (dispatch, getState) => {
  let request = getUpdateRoomRequest(getState().room)
  if (!request?.room_id) return

  const links = request.links.filter(({ name, url }) => name && url)
  request = { ...request, links }

  roomService
    .updateRoom({ ...request, room_id: request.room_id! })
    .loading('update_room')
    .done(
      ({ room }) => {
        dispatch(mergeEntities({ room: [room] }))
        dispatch(initializeUpdateRoomRequest(room))
      },
      error => dispatch(pushError(error)),
    )
}

export const fetchRoom =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    try {
      dispatch(pushLoading('room'))
      const [roomResponse, componentResponse, purchaseResponse] = await Promise.all([
        roomService.fetchRoom(roomId).wait(),
        componentService.fetchAllComponents(roomId, 0).wait(),
        purchaseService.fetchPurchases(roomId).wait(),
      ])
      dispatch(popLoading('room'))

      dispatch(
        mergeEntities({
          room: [roomResponse.room],
          user: roomResponse.publishers,
          component: componentResponse.component,
          room_purchase: purchaseResponse.purchases,
          announcement_list: componentResponse.announcement_list,
          spread_game: componentResponse.spread_game,
          web_page: componentResponse.web_page,
        }),
      )
    } catch (error: any) {
      dispatch(popLoading('room'))
      dispatch(pushError(error))
    }
  }

export const cloneComponent =
  (component: Component): AsyncThunk =>
  async (dispatch, getState) => {
    const order = getEntities<Component>(
      getState().entity,
      'component',
      item => item.room_id === component.room_id,
    ).length

    let instance: any | null = null
    switch (component.type) {
      case ComponentType.announcements:
        instance = getFirstEntity<AnnouncementList>(
          getState().entity,
          'announcement_list',
          item => item.component_id === component.id,
        )
        break

      case ComponentType.webPage:
        instance = getFirstEntity<WebPage>(
          getState().entity,
          'web_page',
          item => item.component_id === component.id,
        )
        break

      case ComponentType.spreadGame:
        instance = getFirstEntity<SpreadGame>(
          getState().entity,
          'spread_game',
          item => item.component_id === component.id,
        )
        break
    }

    const response = await componentService.createComponent(
      order,
      component.type,
      component.room_id,
      component.title + ' Clone',
      component.overview,
      component.image_url,
      false,
      { key: componentTypeKey(component.type), ...instance },
    )

    if (response.success) {
      const { component: comp, announcement_list, web_page, spread_game } = response.value
      dispatch(
        mergeEntities({
          component: [comp],
          announcement_list: announcement_list ? [announcement_list] : [],
          web_page: web_page ? [web_page] : [],
          spread_game: spread_game ? [spread_game] : [],
        }),
      )
    } else {
      dispatch(pushError(response.error))
    }
  }

export const removeComponent =
  (component: Component): AsyncThunk =>
  async dispatch => {
    dispatch(deleteEntities({ component: [component.id] }))
    const response = await componentService.deleteComponent(component.id)

    if (response.success) {
      const { announcement_list, web_page, spread_game } = response.value
      dispatch(
        deleteEntities({
          announcement_list: announcement_list ? [announcement_list.id] : [],
          web_page: web_page ? [web_page.id] : [],
          spread_game: spread_game ? [spread_game.id] : [],
        }),
      )
    } else {
      dispatch(pushError(response.error))
    }
  }

export const toggleComponent =
  (component: Component, isActive: boolean): AsyncThunk =>
  async dispatch => {
    dispatch(mergeEntities({ component: [{ ...component, is_active: isActive }] }))
    const response = await componentService.toggleComponent(component.id, isActive)

    if (response.success) {
      dispatch(mergeEntities({ component: [response.value] }))
    } else {
      dispatch(mergeEntities({ component: [component] }))
      dispatch(pushError(response.error))
    }
  }

export const flushDashboard = (): ActionType => ({
  type: 'room/flush',
})

// MARK: - Selectors

export const getUpdateRoomRequest = (state: State): Request | null => {
  return state.request
}

export const getDrawerCollapsed = (state: State): boolean => {
  return state.drawerCollapsed
}
