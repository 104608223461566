import { api } from '../api'
import { TrackedUrl } from '../types'

export const trackedUrlService = {
  fetchTrackedUrl: async (trackedUrlId: string) =>
    api.prop<{ tracked_url: TrackedUrl }>('tracked_url', {
      request: {
        method: 'GET',
        endPoint: '/webPage/trackedUrl',
        parameters: { tracked_url_id: trackedUrlId },
      },
    }),

  fetchTrackedUrls: async (componentId: string) =>
    api.prop<{ tracked_urls: TrackedUrl[] }>('tracked_urls', {
      request: {
        method: 'GET',
        endPoint: '/webPage/trackedUrls',
        parameters: { component_id: componentId },
      },
    }),

  createTrackedUrl: async (
    roomId: string,
    componentId: string,
    url: string,
    name: string,
    notifyMeLimit: number,
    notifyMeEmailAddress: string,
  ) =>
    api.prop<{ tracked_url: TrackedUrl }>('tracked_url', {
      request: {
        method: 'POST',
        endPoint: '/webPage/trackedUrl',
        parameters: {
          room_id: roomId,
          component_id: componentId,
          url: url,
          name: name,
          notify_me_limit: notifyMeLimit,
          notify_me_email_address: notifyMeEmailAddress,
        },
      },
    }),

  updateTrackedUrl: async (
    trackedUrlId: string,
    name: string,
    notifyMeLimit: number,
    notifyMeEmailAddress: string,
  ) =>
    api.prop<{ tracked_url: TrackedUrl }>('tracked_url', {
      request: {
        method: 'PUT',
        endPoint: '/webPage/trackedUrl',
        parameters: {
          tracked_url_id: trackedUrlId,
          name: name,
          notify_me_limit: notifyMeLimit,
          notify_me_email_address: notifyMeEmailAddress,
        },
      },
    }),

  deleteTrackedUrl: async (trackedUrlId: string) =>
    api.prop<{ tracked_url: TrackedUrl }>('tracked_url', {
      request: {
        method: 'DELETE',
        endPoint: '/webPage/trackedUrl',
        parameters: { tracked_url_id: trackedUrlId },
      },
    }),
}
