import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'
import { PricingOptionBenefitItem } from './PricingOptionBenefitItem'
import {
  PricingOption,
  pricingOptionBenefits,
  pricingOptionBenefitTitle,
  pricingOptionCTATitle,
  pricingOptionPrice,
  pricingOptionTitle,
} from './types'
import React from 'react'
import { LabelHeader, LabelLarge, LabelNormal } from 'src/features/common/CommonStyles'
import { Separator } from 'src/features/common/Separator'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const PricingOptionContainer: React.FC<{
  preferred: boolean
  pricingOption: PricingOption
  hideButtons?: boolean
  onActionClick: (option: PricingOption) => void
}> = ({ preferred, hideButtons, pricingOption: option, onActionClick }) => {
  // MARK: - Hooks

  const { width } = useWindowDimensions()

  // MARK: - Render
  return (
    <Container preferred={preferred} narrow={width < 1024}>
      <LabelHeader style={{ padding: 16, paddingBottom: 12, fontSize: 28 }}>
        {pricingOptionTitle(option)}
      </LabelHeader>
      <LabelNormal>{pricingOptionPrice(option)}</LabelNormal>

      {!hideButtons && preferred ? (
        <PrimaryButton
          style={{ marginTop: 20 }}
          title={pricingOptionCTATitle(option)}
          onClick={() => onActionClick(option)}
        />
      ) : null}

      {!hideButtons && !preferred ? (
        <SecondaryButton
          style={{ marginTop: 20 }}
          title={pricingOptionCTATitle(option)}
          onClick={() => onActionClick(option)}
        />
      ) : null}

      <Separator />

      <BenefitsContainer>
        <BenefitsTitle>{pricingOptionBenefitTitle(option)}</BenefitsTitle>
        {pricingOptionBenefits(option).map(benefit => (
          <PricingOptionBenefitItem key={benefit.title} benefit={benefit} />
        ))}
      </BenefitsContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div<{ preferred: boolean; narrow: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  margin: ${({ preferred, narrow }) => (!narrow && preferred ? 0 : 50)}px 1.2% 0px 1.2%;
  max-width: 450px;
  min-height: 500px;
  min-width: 280px;
  padding: 24px 20px 30px 20px;
  width: ${({ narrow }) => (narrow ? 60 : 30)}%;
`

const BenefitsTitle = styled(LabelLarge)`
  align-self: center;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: 16px;
`

const BenefitsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`
