import React from 'react'

export const Switch: React.FC<{
  disabled?: boolean
  checked: boolean
  onSwitchToggle: (checked: boolean) => void
}> = ({ disabled = false, checked, onSwitchToggle }) => {
  return (
    <label className="form-switch" style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <input
        type="checkbox"
        style={{ maxHeight: 20 }}
        disabled={disabled}
        checked={checked}
        onChange={() => onSwitchToggle(!checked)}
      />
      <span className="form-switch-icon"></span>
    </label>
  )
}
