import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol, FlexRow, LabelMedium } from 'src/features/common/CommonStyles'
import { Select } from 'src/features/common/Select'
import { Separator } from 'src/features/common/Separator'
import { getLocalization, setLocalization } from 'src/redux/reducers/app'
import { useSelect } from 'src/utils/hooks/useSelect'
import { Localization } from 'src/utils/types/i18n'

export const AccountLanguage: React.FC = () => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const localization = useSelect(state => getLocalization(state.app))

  // MARK: - Render

  return (
    <FlexCol style={{ marginTop: 24, marginBottom: 0 }}>
      <LabelMedium style={{ fontSize: 13, fontWeight: 600, marginBottom: -14 }}>
        {t('language')}
      </LabelMedium>

      <Separator />

      <FlexRow>
        <Select
          value={localization}
          onChange={local => dispatch(setLocalization(local as Localization))}>
          <option value={'en'}>{'English'}</option>
          <option value={'tr'}>{'Türkçe'}</option>
        </Select>
      </FlexRow>
    </FlexCol>
  )
}
