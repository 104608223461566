import { IconPackModal } from './IconPackModal'
import { truncate } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SearchIcon } from 'src/assets/images/svg/SearchIcon'
import { Col, FlexCol, LabelNormal, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { EmptyState } from 'src/features/common/EmptyState'
import { Input } from 'src/features/common/Input'
import { PageLoader } from 'src/features/common/PageLoader'
import { Switch } from 'src/features/common/Switch'
import { TabBar } from 'src/features/common/TabBar'
import { getLoading } from 'src/redux/reducers/app'
import {
  fetchIconPacks,
  fetchIcons,
  fetchNextIcons,
  getIconPacks,
  getIcons,
} from 'src/redux/reducers/iconPicker'
import { FlatIcon, FlatIconColor, FlatIconPack, FlatIconShape } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { css } from 'styled-components'

export const IconPickerModal: React.FC<{
  onIconPick: (icon: FlatIcon) => void
  onClose: () => void
}> = ({ onIconPick, onClose }) => {
  // MARK: - Hooks

  const iconPageSize = 100
  const iconBase = 'https://media.flaticon.com/dist/min/img/filters/'
  const iconList = useRef<HTMLDivElement | null>(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [text, setText] = useState('')
  const [query, setQuery] = useState('')
  const [color, setColor] = useState(2)
  const [shape, setShape] = useState(0)
  const [showPacks, setShowPacks] = useState(false)
  const [selectedPack, setSelectedPack] = useState<FlatIconPack | null>(null)

  const isIconsLoading = useSelect(state => getLoading(state.app, 'icon_picker_icon'))
  const isPacksLoading = useSelect(state => getLoading(state.app, 'icon_picker_pack'))
  const isNextPacksLoading = useSelect(state => getLoading(state.app, 'icon_picker_pack_next'))

  const icons = useSelect(state => getIcons(state.utils.iconPicker))
  const packs = useSelect(state => getIconPacks(state.utils.iconPicker))

  const colors: (FlatIconColor | undefined)[] = [undefined, 'black', 'color', 'gradient']
  const shapes: (FlatIconShape | undefined)[] = [
    undefined,
    'outline',
    'fill',
    'lineal-color',
    'hand-drawn',
  ]

  const showLoading = ((showPacks || !query) && isPacksLoading) || (!showPacks && isIconsLoading)

  // MARK: - Effects

  useEffect(() => {
    if (!showPacks) setSelectedPack(null)

    showPacks || !query
      ? dispatch(fetchIconPacks(query, colors[color], shapes[shape]))
      : dispatch(fetchIcons(query, colors[color], shapes[shape]))
  }, [query, color, shape, showPacks])

  useEffect(() => {
    if (!text) setQuery('')
  }, [text])

  // MARK: - Handlers

  const handleScroll = useCallback(() => {
    const list = iconList.current
    if (list && !isIconsLoading && !isNextPacksLoading) {
      const { scrollTop, offsetHeight, scrollHeight } = list

      if (scrollTop + offsetHeight === scrollHeight) {
        const offset = icons.length
        const packId = selectedPack?.id
        dispatch(fetchNextIcons(query, offset, iconPageSize, colors[color], shapes[shape], packId))
      }
    }
  }, [isIconsLoading, selectedPack, isNextPacksLoading, icons.length, iconPageSize])

  // MARK: - Render

  const renderEmptyResult = useCallback(
    () => (
      <EmptyState
        style={{ flex: 1 }}
        title={`No ${showPacks ? 'packs' : 'icons'} found`}
        subtitle={'Please double check your input or change it for better results'}
      />
    ),
    [showPacks],
  )

  const renderPacks = useCallback(() => {
    if (!packs.length) return renderEmptyResult()

    return (
      <InnerContainer>
        {packs.map(pack => (
          <PackContainer key={pack.id} onClick={() => setSelectedPack(pack)}>
            <img
              style={{ width: '90%', height: '72%', objectFit: 'contain' }}
              src={pack.images.sprite}
              alt=""
            />
            <LabelNormal style={{ marginTop: 8 }}>
              {truncate(pack.description, { length: 20 }) + ' (' + pack.pack_items + ')'}
            </LabelNormal>
          </PackContainer>
        ))}
      </InnerContainer>
    )
  }, [packs])

  const renderIcons = useCallback(() => {
    if (!icons.length) return renderEmptyResult()

    return (
      <InnerContainer ref={iconList} onScroll={handleScroll}>
        {icons.map(icon => (
          <IconContainer
            key={icon.id}
            onClick={() => {
              onIconPick(icon)
              onClose()
            }}>
            <img style={{ width: 48, height: 48 }} src={icon.images['64']} alt="" />
          </IconContainer>
        ))}
      </InnerContainer>
    )
  }, [icons])

  return (
    <ModalWrapper
      style={{ paddingBottom: 0 }}
      title={t('searchIcon')}
      size="tiny"
      onScroll={event => console.log(event)}
      height="dynamic"
      onClose={onClose}>
      <Col>
        <Row style={{ position: 'relative' }}>
          <Input
            type="search"
            value={text}
            onChange={setText}
            placeholder={t(showPacks ? 'searchIconPack' : 'searchIcon')}
            hasIcon="left"
            onEnterPress={() => setQuery(text)}>
            <SearchIcon size={16} />
          </Input>

          <Row
            style={{
              top: 8,
              alignItems: 'center',
              marginLeft: 40,
              right: 12,
              position: 'absolute',
            }}>
            <LabelSmall style={{ marginRight: 8, fontWeight: 500 }}>{t('groupByPacks')}</LabelSmall>
            <Switch checked={showPacks} onSwitchToggle={setShowPacks} />
          </Row>
        </Row>

        <TabBar
          style={{ marginBottom: 8, marginTop: -6 }}
          itemHeight={30}
          itemWidth={134}
          initialIndex={shape}
          onIndexChange={setShape}
          disabledIndexes={color === 1 ? [3] : color === 3 ? [4] : []}
          items={[
            { title: t('allShapes') },
            { title: t('outline'), icon: <Icon src={`${iconBase}outline.svg`} /> },
            { title: t('fill'), icon: <Icon src={`${iconBase}filled.svg`} /> },
            { title: t('linearColor'), icon: <Icon src={`${iconBase}lineal-color.svg`} /> },
            { title: t('handDrawn'), icon: <Icon src={`${iconBase}handrawn.svg`} /> },
          ]}
        />

        <TabBar
          itemHeight={30}
          itemWidth={135.5}
          initialIndex={color}
          onIndexChange={setColor}
          disabledIndexes={shape === 3 ? [1] : shape === 4 ? [3] : []}
          items={[
            { title: t('allColors') },
            { title: t('black'), icon: <Icon src={`${iconBase}black.svg`} /> },
            { title: t('color'), icon: <Icon src={`${iconBase}color.svg`} /> },
            { title: t('gradient'), icon: <Icon src={`${iconBase}gradient.svg`} /> },
          ]}
        />
      </Col>

      <FlexCol style={{ overflow: 'scroll' }}>
        {showLoading ? (
          <PageLoader style={{ paddingTop: '20%' }} />
        ) : (
          <>{showPacks || !query ? renderPacks() : renderIcons()}</>
        )}
      </FlexCol>

      {selectedPack && (
        <IconPackModal
          iconPack={selectedPack}
          onClose={() => setSelectedPack(null)}
          onIconPick={icon => {
            onIconPick(icon)
            setSelectedPack(null)
            onClose()
          }}
        />
      )}
    </ModalWrapper>
  )
}

// MARK: - Styles

const Icon = styled.img`
  margin: 0px 4px 2px 0px;
`

const ItemContainer = styled(Col)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? theme.palette.background.separator : 'transparent')};
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  justify-content: center;
  margin: 8px;
  overflow: hidden;
  padding: 0px 8px;
  position: relative;

  ${() =>
    css`
      &:hover {
        border-color: ${({ theme }) => (theme.isDark ? theme.palette.orange : 'transparent')};
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
      }
    `}
`

const IconContainer = styled(ItemContainer)`
  height: 79px;
  min-width: 79px;
`

const PackContainer = styled(ItemContainer)`
  height: 200px;
  max-width: 206px;
  min-width: 206px;
`

const InnerContainer = styled(Row)`
  flex-wrap: wrap;
  margin-top: 12px;
  max-height: 100%;
  overflow: scroll;
  padding-bottom: 48px;
`
