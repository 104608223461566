import { LabelHeader, LabelHeaderLarge, Row } from '../common/CommonStyles'
import { RequestDemoForm } from './RequestDemoForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallIcon } from 'src/assets/images/svg/CallIcon'
import { CreateIcon } from 'src/assets/images/svg/CreateIcon'
import { SalesIcon } from 'src/assets/images/svg/SalesIcon'
import useWindowDimensions, { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RequestDemo: React.FC = _ => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isMobile = width < 850

  // MARK: - Render

  return (
    <Container isMobile={isMobile}>
      <InnerContainer>
        <TextContainer isMobile={isMobile}>
          <Title>{t('requestDemo')}</Title>
          <Subtitle>{'Discover how Lobbi can help you design state of art lobbies'}</Subtitle>

          <DescriptionContainer isMobile={isMobile}>
            <LabelHeader style={{ marginBottom: 16 }}>{'Book a call now to:'}</LabelHeader>
            <DescriptionItem>
              <CallIcon style={{ marginRight: 8, marginTop: 2 }} fill={'#000000'} />
              <Description>
                {'Receive an overview of Lobbi platform from a product expert'}
              </Description>
            </DescriptionItem>

            <DescriptionItem>
              <SalesIcon style={{ marginRight: 8 }} fill={'#000000'} />
              <Description>
                {' Discuss ROI goals of your room and explore tools to advance them'}
              </Description>
            </DescriptionItem>

            <DescriptionItem>
              <CreateIcon style={{ marginRight: 8 }} fill={'#000000'} />
              <Description>{'Get pricing based on your needs'}</Description>
            </DescriptionItem>
          </DescriptionContainer>
        </TextContainer>

        <RightContainer isMobile={isMobile}>
          <RequestDemoForm />
        </RightContainer>
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div<{ isMobile: boolean }>`
  margin: 0px auto;
  margin-top: ${({ isMobile }) => (isMobile ? 16 : 40)}px;
  max-width: 100%;
  width: 1308px;
  padding: 0px 24px;
  padding-bottom: 50px;
`

const InnerContainer = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 80px;
  min-width: 100%;
  position: relative;
`

const TextContainer = styled.div<{ isMobile: boolean }>`
  flex-basis: ${({ isMobile }) => (isMobile ? '100%' : '42%')};
  margin-right: 4%;
  min-width: 340px;

  @media ${device.smallerThan.laptop} {
    margin-bottom: 48px;
  }
`

const DescriptionContainer = styled.div<{ isMobile: boolean }>`
  margin-bottom: 16px;
  margin-top: ${({ isMobile }) => (isMobile ? 50 : 200)}px;
  width: 100%;
`

const DescriptionItem = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const Description = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 16px;
`

const Title = styled(LabelHeaderLarge)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 32px;
  font-weight: 600;
  margin: 12px 0px;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 18px;
  margin-bottom: 30px;
`

const RightContainer = styled.div<{ isMobile: boolean }>`
  flex-basis: ${({ isMobile }) => (isMobile ? '100%' : '54%')};
  min-width: 360px;
`
