import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 20, fill = '#ECEDED', ...props }) => (
  <svg {...props} width={size} height={size} viewBox="0 0 18 14" fill="none">
    <rect x="1" y="1" width="16" height="12" rx="1" stroke={fill} />
    <path
      d="M1 4L8.10557 7.55279C8.66863 7.83431 9.33137 7.83431 9.89443 7.55279L17 4"
      stroke={fill}
    />
  </svg>
)

export const InvitationIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
