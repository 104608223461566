import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#F36538', ...props }) => (
  <svg width={size} height={size} {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9V7ZM19 8V9C19.5523 9 20 8.55228 20 8H19ZM20 3C20 2.44772 19.5523 2 19 2C18.4477 2 18 2.44772 18 3H20ZM14 9H19V7H14V9ZM20 8V3H18V8H20Z"
      fill={fill}
    />
    <path
      d="M18.7094 16.3571C17.7775 17.792 16.4102 18.8904 14.8082 19.4909C13.2062 20.0915 11.4538 20.1627 9.80833 19.6939C8.16287 19.2252 6.71103 18.2413 5.66595 16.8867C4.62086 15.5321 4.03759 13.8781 4.00176 12.1675C3.96593 10.457 4.47943 8.78004 5.46687 7.38284C6.45431 5.98564 7.86368 4.94181 9.48806 4.4046C11.1124 3.86738 12.8663 3.86509 14.492 4.39805C16.1178 4.93101 17.5299 5.97114 18.521 7.36575"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MomentumIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.orange} {...props} />
}
