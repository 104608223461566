import { DashboardQAItem } from './DashboardQAItem'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { EmptyState } from 'src/features/common/EmptyState'
import { QAQuestion, QAQuestionState } from 'src/repository/types'
import styled, { useTheme } from 'styled-components'

export const DashboardQAColumn: React.FC<{
  style?: React.CSSProperties
  state: QAQuestionState
  dropDisabled: boolean
  questions: QAQuestion[]
}> = ({ style, state, questions, dropDisabled }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  const stateColor: Record<QAQuestionState, string> = {
    pending: palette.purple,
    answered: palette.green,
    rejected: palette.orange,
  }

  const stateEmptyTitle: Record<QAQuestionState, string> = {
    pending: '',
    answered: '',
    rejected: '',
  }
  const stateEmptySubTitle: Record<QAQuestionState, string> = {
    pending: t('onceAudienceAsksQuestion'),
    answered: t('dragQuestionToAnswer'),
    rejected: t('dragQuestionToReject'),
  }

  // MARK: - Render

  return (
    <Container style={style}>
      <Title style={{ color: stateColor[state] }}>{t(state)}</Title>
      <Droppable droppableId={state} isDropDisabled={dropDisabled}>
        {(provided, snapshot) => (
          <QuestionList
            isDragging={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {questions.length ? (
              questions.map((question, index) => (
                <DashboardQAItem key={question.id} qaQuestion={question} index={index} />
              ))
            ) : (
              <EmptyState
                style={{ marginLeft: 12, marginRight: 12 }}
                title={stateEmptyTitle[state]}
                subtitle={stateEmptySubTitle[state]}
              />
            )}
            {provided.placeholder}
          </QuestionList>
        )}
      </Droppable>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 8px 8px 0px 8px;
  max-height: 100%;
  padding: 16px 0px 0px 0px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-left: 16px;
`

const QuestionList = styled.div<{ isDragging: boolean }>`
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.palette.background.secondary : 'transparent'};
  flex: 1;
  max-height: 100%;
  overflow: scroll;
`
