import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, LabelMedium, ShadowBox } from 'src/features/common/CommonStyles'
import { timeOptions } from 'src/utils/helpers/dateHelper'
import styled, { css } from 'styled-components'

export const TimePicker: React.FC<{
  selectedTime: string
  onTimeClick: (time: string) => void
}> = ({ selectedTime, onTimeClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const timeOptionHeight = 29
  const [internalTime, setInternalTime] = useState<string | undefined>('00:00')
  const listRef = useRef<HTMLDivElement | null>(null)

  // MARK: - Effects

  useEffect(() => {
    if (listRef.current && internalTime !== selectedTime) {
      const index = timeOptions().indexOf(selectedTime)
      if (index !== -1) {
        listRef.current.scrollTo({ top: (index - 3) * timeOptionHeight, behavior: 'auto' })
        setInternalTime(selectedTime)
      }
    }
  }, [selectedTime])

  // MARK: - Render

  return (
    <Container>
      <Header>{t('time')}</Header>
      <InnerContainer ref={listRef}>
        {timeOptions().map(time => (
          <TimeOption
            key={time}
            onClick={() => {
              setInternalTime(time)
              onTimeClick(time)
            }}
            selected={selectedTime === time}>
            {time}
          </TimeOption>
        ))}
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: -6px;
  overflow: hidden;
  position: absolute;
  width: 100px;
  z-index: 20;
`

const InnerContainer = styled(Col)`
  max-height: 200px;
  overflow: scroll;
  width: 100%;
`

const Header = styled(LabelMedium)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  padding: 6px 0px 4px 0px;
  text-align: center;
  width: 100%;
`

const TimeOption = styled(LabelMedium)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.orange : theme.palette.background.primary};
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? '700' : '500')};
  padding: 4px 0px;
  text-align: center;
  width: 100%;
  ${() =>
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.secondary};
      }
    `}
`
