import { absoluteFill, FlexCol, Row } from './CommonStyles'
import { EmptyState } from './EmptyState'
import { Loader } from './Loader'
import { PageLoader } from './PageLoader'
import React, { ReactNode, useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'
import styled from 'styled-components'

export const DashboardTable: React.FC<{
  emptyStateTitle: string
  emptyStateSubtitle: string
  isLoading: boolean
  isPageLoading?: boolean
  data: any[]
  onEndReached?: () => void
  renderItem: (item: any, index: number) => ReactNode
}> = ({
  emptyStateTitle,
  onEndReached,
  isPageLoading,
  emptyStateSubtitle,
  isLoading,
  data,
  renderItem,
}) => {
  // MARK: - Render

  const renderFooter = useCallback(() => {
    return (
      <LoaderContainer>
        {isPageLoading ? <Loader size={20} thickness={3} /> : <Row style={{ height: 20 }} />}
      </LoaderContainer>
    )
  }, [isPageLoading])

  return !data.length || isLoading ? (
    <PlaceholderContainer>
      {isLoading ? (
        <PageLoader />
      ) : (
        <EmptyState title={emptyStateTitle} subtitle={emptyStateSubtitle} />
      )}
    </PlaceholderContainer>
  ) : (
    <Container>
      <Virtuoso
        endReached={onEndReached}
        style={absoluteFill}
        totalCount={data.length}
        itemContent={index => renderItem(data[index], index)}
        components={{ Footer: renderFooter }}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  position: relative;
`

const LoaderContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
`

const PlaceholderContainer = styled(FlexCol)`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`

export const DashboardTableContainer = styled.div`
  border-radius: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 220px);
  margin: 0px 20px;
  padding: 16px 0px 0px 0px;
`
