import { createGlobalStyle } from 'styled-components'

export const HoverAnimation = createGlobalStyle`
  .hoverBox {
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .hoverBox::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .hoverBox:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .hoverBox:hover::after {
    opacity: 1;
  }

  .hoverBoxNoScale {
    position: relative;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .hoverBoxNoScale::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .hoverBoxNoScale:hover {
    -webkit-transform: scale(1.00, 1.00);
    transform: scale(1.00, 1.00);
  }

  .hoverBoxNoScale:hover::after {
    opacity: 1;
  }
`
