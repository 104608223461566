import { AlertModal } from './features/common/AlertModal'
import { getErrors, popError } from './redux/reducers/app'
import { useSelect } from './utils/hooks/useSelect'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export const AppAlertProvider: React.FC<{}> = ({ children }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const errors = useSelect(state => getErrors(state.app))

  const error = useMemo(() => {
    return errors[0]
  }, [errors])

  // MARK: - Effects

  const handleCloseAlertPopup = useCallback(() => {
    dispatch(popError())
  }, [])

  // MARK: - Render

  return (
    <>
      {children}
      <AlertModal error={error} visible={!!error} onClose={handleCloseAlertPopup} />
    </>
  )
}
