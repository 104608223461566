import { api } from '../api'
import { WebListItem } from '../types'

export const webListItemService = {
  fetchWebListItems: async (componentId: string, lastUpdatedAt: number) =>
    api.prop<{ web_list_items: WebListItem[] }>('web_list_items', {
      request: {
        method: 'GET',
        endPoint: '/webListItems',
        parameters: { component_id: componentId, last_updated_at: lastUpdatedAt },
      },
    }),

  createWebListItem: async (
    roomId: string,
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    link: string,
  ) =>
    api.prop<{ web_list_item: WebListItem }>('web_list_item', {
      request: {
        method: 'POST',
        endPoint: '/webListItem',
        parameters: {
          room_id: roomId,
          component_id: componentId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          link: link,
        },
      },
    }),

  updateWebListItem: async (
    webListItemId: string,
    title: string,
    overview: string,
    imageUrl: string,
    link: string,
    isActive: boolean,
  ) =>
    api.prop<{ web_list_item: WebListItem }>('web_list_item', {
      request: {
        method: 'PUT',
        endPoint: '/webListItem',
        parameters: {
          web_list_item_id: webListItemId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          link: link,
          is_active: isActive,
        },
      },
    }),

  deleteWebListItem: async (webListItemId: string) =>
    api.prop<{ web_list_item: WebListItem }>('web_list_item', {
      request: {
        method: 'DELETE',
        endPoint: '/webListItem',
        parameters: { web_list_item_id: webListItemId },
      },
    }),
}
