const tr = {
  time: 'Thu, Oct 15, 2020, 10:23 AM',
  translation: {
    accept: 'Kabul et',
    accepted: 'Kabul Edildi',
    access: 'Erişim',
    accessCode: 'Erişim Kodu',
    accessDescription:
      'Lobinize erişim seviyesini düzenleyin. Yönetilen lobilerin erişim seviyesi sadece müşteri hizmetleri desteği ile değiştilebilir.',
    accessError: 'Hatalı giriş',
    accessLevel: 'Erişim seviyesi',
    account: 'Hesap Ayarları',
    accountKeys: 'Hesap Anahtarları',
    action: 'Aksiyon',
    activate: 'Aktive et',
    activateImmediately: 'Aktive et',
    activateImmediatelyDescription: 'Özellikler yaratıldıktan sonra tekrar aktive edilebilir.',
    active: 'Aktif',
    activeFeatures: 'Eklenen özellikler {{active}}/{{total}}',
    add: 'Ekle',
    addCalendarEvent: 'Takvim etkinliği oluşturmak',
    addEvent: 'Aksiyon ekle',
    addFeature: 'Özellik Ekle',
    addItem: 'Öğe Ekle',
    addNew: 'Ekle',
    addOption: 'Seçenek ekle',
    addToSchedule: 'Takvime Ekle',
    admin: 'Yönetici',
    admins: 'Yöneticiler',
    adminsDescription:
      'Yönetici yetkisi, lobiyi yapılandırma veya silme dahil olmak üzere lobi üzerinde tam erişime sahiptir.',
    agreeAndContinue: 'Kabul ve Devam Et',
    all: 'Tümü',
    allColors: 'Tüm renkler',
    allowAskModerator: 'Moderatore Sor',
    allowAskModeratorDescription:
      'Üyelerin lobi moderatörine direkt soru sorabileceği kanalları erişime açın.',
    allowChat: 'Sohbet',
    allowChatChannels: 'Sohbet Kanalları',
    allowChatChannelsDescription:
      'Belirli konular üzerine kanallar yaratıp, üyelerin bu kanallar üzerinden konuşmasını sağlayın.',
    allowChatDescription: 'Üyelerin mesajlaşmasını sağlayın.',
    allowGeneralChat: 'Genel sohbet',
    allowGeneralChatDescription:
      'Tüm lobi üyelerinin serbestçe mesajlaşabileceği genel kanalı aktif hale getirin.',
    allowOneToOneChat: 'Bireysel Konuşmalar',
    allowOneToOneChatDescription:
      'Üyelerin birebir veya grup sohbet kanalları oluşturmasına izin verin.',
    allowSeeMore: 'Profil bağlantısı',
    allowSeeMoreDescription: 'Profilinize ve diğer lobilerinize giden bağlantıyı aktive edin.',
    allowShare: 'Paylaşma ve davet',
    allowShareDescription: 'Lobinizin üyeler tarafından paylaşılmasına izin verin.',
    allShapes: 'Tüm şekiller',
    analytics: 'Analiz',
    analyticsDescription: 'Lobinizin performansını gözlemleyin.',
    announcementDescription:
      'Kitlenizi haberler ve güncellemeler ile güncel tutun ve heyecanı artırın.',
    announcementListDescriptionPlaceholder:
      'Duyuru listesi hakkında bilgiler, takvim ve planlar...',
    announcementListTitlePlaceholder: 'Duyuru listesinin ismi',
    announcements: 'Duyuru listesi',
    answer: 'Cevap',
    answered: 'Cevaplanan',
    answerPlaceHolder: 'Lütfen cevabı buraya giriniz...',
    apiKey: 'API anahtarı',
    apiSecret: 'API kodu',
    askQuestion: 'Anonim soru sor',
    audience: 'Kitle',
    audienceCount: '{{count}} üye',
    audienceSize: 'Kitle noyutu',
    audienceSizeDescription: 'Lobinizin üye sayısı',
    authContinue: 'Giriş yap veya kayıt ol',
    authPassword: 'Şifrenizi girin',
    authRegister: 'Kayıdınızı tamamlayın',
    back: 'Geri',
    banned: 'Yasaklı',
    bannerImage: 'Afiş görseli',
    bannerLinkPlaceholder: 'Afiş CTA bağlantısı',
    banners: 'Afişler',
    basicInfo: 'Genel Bilgiler',
    billingType: 'Ödeme şekli',
    black: 'Siyah',
    branding: 'Markalaştırma',
    brandingAndNavigation: 'Bağlantılar ve Afişler',
    brandingDescription:
      'Afiş görsellerini ve tıklama bağlantılarını ekleyerek sponsorlarınızın ve markanızın görünürlüğünü artırın.',
    broadcast: 'Yayınla',
    broadcastDescription: 'Lobinize stratejik konumlarda yayınlayın.',
    buildAPlan: 'Plan oluştur',
    buy: 'Satın al',
    calendar: 'Takvim',
    calendarEvents: 'Takvim Etkinleri',
    calendarEventsDescription: 'Lobiniz için takvim etkinlikleri oluşturan kullanıcı sayısı',
    callToAction: 'Aksiyon al',
    callToActionDescription: 'Kullanıcaların tıklayarak aksiyon alabileceği buton ekleyin.',
    cancel: 'İptal',
    change: 'Değiştir',
    changeCover: 'Kapak değiştir',
    chatDisabled: 'Sohbet aktif değil',
    chatDisabledDescription:
      'Sohbet ayarlarınızı düzenlemek için lütfen genel ayarlardan sohbeti aktif duruma getirin.',
    chatPleaseGoLive: 'Lütfen sohbet kanalları oluşturmak için lobinizi yayınlayın.',
    chats: 'Sohbetler',
    chatsDescription: 'Lobi içi mesajlaşma kanalları yaratın.',
    chatSettings: 'Sohbet Ayarları',
    chatSettingsDescription:
      'Lobi üyelerinin mesajlaşarak etkileşim kurabileceği kanalları yönetin.',
    chooseAnImage: 'Resim seçin',
    clear: 'Temizle',
    clone: 'Kopyala',
    cloneRoom: 'Lobi klonla',
    color: 'Renkli',
    completed: 'Tamamlanmış',
    configuration: 'Yapılandır',
    configurationDescription: 'Lobi ayarlarını ve tercihlerini düzenleyin.',
    configurePlan: 'Planı Değiştir',
    congrats: 'Tebrikler!',
    content: 'İçerik',
    continue: 'Devam et',
    continueFreePlan: 'Ücretsiz devam et',
    continueWith: '{{provider}} ile devam et',
    continueWithFreePlan: 'Ücretsiz plan',
    continueWithFreePlanBlockedDescription:
      'Ücretsiz plan aktif sadece 1 özellik ile sınırlıdır. Devam etmek için aktif özellik sayısını düşürünüz.',
    continueWithFreePlanDescription:
      'Ücretsiz plan aktif sadece 1 özellik ile sınırlıdır. İstediğiniz zaman yükseltebilirsiniz.',
    copiedToClipboard: 'Panoya kopyalandı',
    copyLink: 'Link Kopyala',
    copyLinkDescription: 'Lobi bağlantısını panoya kopyalayın.',
    countDailyNotifications: '{{count}} günlük bildirim',
    countMembers: '{{count}} üye',
    countPremiumFeatures: '{{count}} premium özellikler',
    create: 'Oluştur',
    createAnnouncement: 'Duyuru Oluştur',
    createChatChannel: 'Kanal Oluştur',
    createFAQ: 'Sıkça sorulan soru oluştur',
    createNotification: 'Bildirim gönder',
    createPoll: 'Soru oluştur',
    createQuestion: 'Soru Ekle',
    createProject: 'Proje oluştur',
    createProjectescription:
      'Lobinize bir isim verin ve kitlenize neden katılmaları gerektiğini söyleyin. Lobiyi benzersiz yapan özellikleri vurgulayan ayrıntılar ekleyin.',
    createWebListItem: 'Listeye bağlantı ekle',
    creating: 'Taslak',
    cropImage: 'Resim Kırpma',
    currentTimezone: 'Projenizin saat dilimi',
    dailyNotifications: 'Günlük bildirimler',
    dailyNotificationsUsage: 'Lobi üyelerine gönderilen doğrudan bildirimlerin sayısı',
    dangerZone: 'Tehlikeli bölge',
    date: 'Tarih',
    dateAndTime: 'Tarih ve Zaman',
    dateAndTimeDescription: 'Projeniz ne zaman başlayıp ne zaman bitiyor?',
    datesLinks: 'Tarihler & Bağlantılar',
    deactivate: 'Devre dışı bırak',
    delete: 'Sil',
    deleteAccount: 'Hesabı sil',
    deleteAccountWarning: 'Hesabınızı silmek mi istiyorsunuz?',
    deleteAnnouncement: 'Duyuruyu Sil',
    deleteAnnouncementMessage:
      'Duyuruyu silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteAnnouncements: 'Duyuruları Sil',
    deleteAnnouncementsMessage:
      'Duyuruları silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteChatChannel: 'Sohbet Kanalını Sil',
    deleteChatChannelMessage:
      'Sohbet kanalını silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz',
    deleteChatChannels: 'Sohbet Kanallarını Sil',
    deleteChatChannelsMessage:
      'Sohbet kanallarını silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz',
    deleteFeature: 'Özelliği Sil',
    deleteFeatureMessage:
      '{{name}} özelliğini silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteInvitation: 'Daveti Sil',
    deleteInvitationMessage: 'Daveti silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteInvitations: 'Davetleri Sil',
    deleteInvitationsMessage:
      'Davetleri silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteItem: 'Öğeyi Sil',
    deleteItemMessage: 'Öğeyi silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteItems: 'Öğeleri Sil',
    deleteItemsMessage: 'Öğeleri silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteMessage:
      'Hesabınızı silmek istediğinize emin misiniz? Hesabınız silindikten sonra geri aktive edilemez.',
    deleteNotification: 'Bildirimi Sil',
    deleteNotificationMessage:
      'Bildirimi silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteNotifications: 'Bildirimleri Sil',
    deleteNotificationsMessage:
      'Bildirimleri silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteQuestion: 'Soruyu Sil',
    deleteQuestionMessage: 'Soruyu silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteQuestions: 'Soruları Sil',
    deleteQuestionsMessage: 'Soruları silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteRoom: 'Lobiyi Sil',
    deleteRoomMessage: 'Lobiyi silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteTrackedUrl: 'İzlenen adresi Sil',
    deleteTrackedUrlMessage:
      'İzlenen adresi silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    deleteTrackedUrls: 'İzlenen adresileri Sil',
    deleteTrackedUrlsMessage:
      'İzlenen adresileri silmek istediğinize emin misiniz? Bu aksiyon geri alınamaz.',
    description: 'Açıklama',
    details: 'Detaylar',
    discard: 'Yoksay',
    displayName: 'Görünüm ismi',
    done: 'Tamam',
    draftLobby: 'Taslak',
    dragDrop: '{ratio}',
    dragDropOrClick: 'Sürekle & Bırak veya Tıkla',
    dragImageToReposition: 'Resmi konumlandırmak için sürükle',
    dragPollToActivate: 'Aktive etmek için soruyu buraya sürükleyin',
    dragQuestionToAnswer: 'Cevaplamak için soruları bu alana sürükleyin.',
    dragQuestionToReject: 'Reddetmek için soruları bu alana sürükleyin.',
    edit: 'Düzenle',
    editAccount: 'Hesabı düzenle',
    email: 'Email',
    emailAddress: 'Email adresi',
    emails: 'Bildirimler',
    emailSent: 'Email Gönderildi',
    emailSentTo: 'Email {{email}} adresine gönderildi',
    emailWillSentTo: 'Sınıra ulaşıldığında email {{email}} adresine gönderilecek',
    enableAnonymousQuestions: 'Anonim sorulara izin ver',
    enableAnonymousQuestionsDescription: 'Kitlenizin anonim olarak soru sormasına izin verin.',
    enabledNotificationsDescription:
      'Bir duyuru yayınladığınızda lobi üyelerine bildirim gönderilir.',
    enableNotifications: 'Bildirimleri aktive et',
    end: 'Bitiş: {{date}}',
    endDate: 'Bitiş tarihi',
    endTime: 'Bitiş tarihi',
    enterprise: 'Kurumsal',
    enterYourEmail: 'hello@lobbi.events',
    enterYourPhoneNumber: '+1 111 111 1111',
    eventContentDescription: 'Projeniz ne hakkında ve kimler katılmalı?',
    eventName: 'Başlık',
    eventUrl: 'Proje bağlantısı',
    eventUrlDescription: 'Youtube, Google Meet, Twitch, Twitter etkinlik neredeyse.',
    expectedAudienceSize: 'Beklenen üye sayısı',
    facebook: 'Facebook',
    faq: 'Sıkça sorulan sorular',
    faqDescription: 'Sıkça sorulan soruları ve cevaplarını listeleyerek kitlenizi bilgilendirin.',
    faqDescriptionPlaceholder: 'Sıkça sorulan soru listesi hakkında daha çok bilgi...',
    faqShort: 'SSS',
    faqTitlePlaceholder: 'Sıkça sorulan soru listesinin ismi',
    featureClicks: 'Özellik Tıklamaları',
    featureClicksDescription: 'Lobinize eklenen özelliklerin tıklanma sayısı',
    featureImage: 'Özellik Resmi',
    features: 'Özellikler',
    feelingLucky: 'Şanlı hissediyorum',
    fill: 'Dolu',
    firstName: 'Ad',
    forgotPassword: 'Şifremi unuttum?',
    foundation: 'Temel',
    future: 'Gelecek',
    gallery: 'Galeri',
    gameEvents: 'Aksiyonlar & Puanlama',
    gameEventsDescription:
      'Tavsiye edilen kullanıcı aşağıdaki eylemleri gerçekleştirdiğinde, tavsiye eden puan kazanır.',
    general: 'Genel',
    generalSettings: 'Genel ayarlar',
    getStarted: 'Başla',
    goLive: 'Lobiyi yayınla',
    goLiveToShare: 'Paylaşmak için lobiyi yayınlayın.',
    goToRoom: 'Lobiye Git',
    gradient: 'Gradient',
    groupByPacks: 'Pakete göre ayır',
    handDrawn: 'Çizim',
    hello: 'Merhaba',
    hit: 'Açılma',
    home: 'Ana Sayfa',
    horizontal: 'Yatay',
    howPlansWorks: 'Lobbi planları nasıl çalışır?',
    howPlansWorksCharge:
      'Yalnızca premium özelliklerin kullanımına ve hedef kitle boyutuna göre ücretlendirilirsiniz.',
    howPlansWorksOverUsage:
      'Aşırı kullanım durumunda sizinle iletişime geçilecektir. Destek ekibimiz planınızı ölçeklendirmenize yardımcı olacaktır.',
    howPlansWorksUpgrade:
      'Lobi limitlerini artırmak istediğinizde satın aldığınız planı yükseltebilirsiniz.',
    icon: 'İkon',
    ifNonProfitOrganization: "Kar amaçlamıyorsanız veya 100.000'den fazla katılım bekliyorsanız, ",
    ifYouHaveBanners: 'Marka veya sponsor afişleriniz varsa.',
    ifYourEventHasAnEndTime: 'Projenizin bitiş tarihi varsa',
    image: 'Resim',
    imageRestriction: 'JPEG veya PNG, < 10MB.',
    imageSizeLimit: "JPEG veya PNG, 10MB'e kadar.",
    imagesLowerThan: 'Dosya başına maksimum boyut 10 mb.',
    imagesWiderThan: '1500 pikselden geniş resimler en kullanışlıdır.',
    inactive: 'Pasif',
    included: 'Plan Limiti',
    information: 'Bilgi',
    informativeLinks: 'Bilgilendirici Bağlantılar',
    inPersonWithPrefix: '🌍 Yüz yüze',
    instagram: 'Instagram',
    instagramPost: 'Instagram gönderisi',
    invalidBannerInput:
      'Afiş oluşturmak için eksik bilgi girdiğiniz girdiniz. Lütfen görselleri ve bağlantıları kontrol ediniz. ',
    invalidInput: 'Geçersiz Bilgi Girişi',
    invalidInputDescription: 'Tüm gerekli alanları eksiksiz doldurmanız gerekmektedir.',
    invitationAccepted: "Davet {{date}}'da kabul edildi.",
    invitationRequests: 'Davet istekleri',
    invitations: 'Davetiyeler',
    invite: 'Davet Et',
    inviteAudience: 'Davet gönder',
    inviteOnly: 'Davetiye',
    inviteOnlyDescription: 'Lobiye yalnızca davet edilen kişiler erişebilir.',
    inviteTeammate: 'Takım arkadaşı davet et',
    join: 'Lobiye katılmak',
    language: 'Dil',
    languageDescription: 'Lobinizin varsayılan dilini seçin.',
    lastName: 'Soy ad',
    learnMore: 'Bilgi',
    letsCreateRoom: 'İlk lobinizi oluşturun',
    letsCreateRoomDescription: 'Lobi oluşturduktan sonra tüm lobilerinizi burada görebilirsiniz',
    letsGo: 'Başla',
    limit: 'Sınır',
    linearColor: 'Doğrusal',
    link: 'Bağlantı',
    linkClicks: 'Bağlantı tıklamaları',
    linkClicksDescription: 'Lobinize bağlı bağlantıların tıklanma sayısı',
    linkedIn: 'LinkedIn',
    links: 'Bağlantılar',
    linksDescription: 'Markanıza ait bağlantıları ve afişleri lobinize ekleyebilirsiniz.',
    live: 'Canlı',
    liveLobby: 'Yayında',
    lobbi: 'Lobbi',
    lobbies: 'Lobiler',
    lobbiPlan: 'Lobbi {{type}} Plan',
    location: 'Konum',
    locationClicks: 'Konum Tıklamaları',
    locationClicksDescription: 'Lobinize bağlı konumun tıklanma sayısı',
    locationDescription: 'Projeniz nerede gerçekleşecek veya nereden yayınlanacak?',
    login: 'Giriş yap',
    logInOrSignUp: 'Giriş yap veya üye ol',
    logout: 'Çıkış Yap',
    logoutMessage: 'Hesabınızdan çıkış yapmak istediğinize emin misiniz?',
    manage: 'Yönet',
    managed: 'Yönetilen',
    managedDescription: 'Lobiye erişim, üçüncü taraf bir platform tarafından sağlanır.',
    manageRoom: 'Lobiyi yönet',
    manageRoomDescription:
      'Yönetilen lobilerin erişim seviyesi sadece müşteri hizmetleri desteği ile değiştilebilir.',
    media: 'Medya',
    mediaDescription:
      'Görseller kitlenizin ilk göreceği içerik olacaktır, bu yüzden yüksek kaliteli görseller kullanın.',
    memberCount: 'Üye sayısı',
    memberCountDescription: 'Üye sayısına göre fiyat ölçeklendirilir.',
    moderator: 'Moderatör',
    moderators: 'Moderatörler',
    moderatorsDescription:
      'Moderator yetkisi, lobiye eklenen özellikleri değiştirme, bildirim atma gibi erişimlere sahiptir.',
    name: 'Ad',
    next: 'Sonraki',
    noAdmins: 'Yönetici Yok',
    noAdminsDescription:
      'Henüz bir yönetici yok. Lobiye bir yönetici atandığında burada görünecektir.',
    noAnnouncementsPostedYet: 'Henüz bir duyuru gönderilmedi',
    noAnsweredQuestions: 'Cevaplanan soru yok',
    noAudienceParticipated: 'Henüz herhangi bir üye katılmadı.',
    noAudienceParticipatedDescription:
      'Üyeler oyuna katıldığında, skorlarını burada görebilirsiniz.',
    noChatChannelCreated: 'Henüz sohbet kanalı oluşturulmadı',
    noInvitationSent: 'Henüz bir davetiye gönderilmedi',
    noItemAdded: 'Henüz bir öğe eklenmedi',
    noLimit: 'Limitsiz',
    noModerators: 'Moderatör Yok',
    noModeratorsDescription:
      'Henüz bir moderatör yok. Lobiye bir moderatör atandığında burada görünecektir.',
    noNotificationSent: 'Henüz bir bildirim gönderilmedi',
    noPendingQuestions: 'Bekleyen soru yok',
    noPurchasedPlans: 'Satın alınan plan yok',
    noPurchasedPlansDescription:
      'Lobiniz için plan satın aldığınızda, kullanımlarını buradan izleyebilirsiniz.',
    noQuestionsCreatedYet: 'Henüz bir soru eklenmedi.',
    noRejectedQuestions: 'Reddedilen soru yok',
    notes: 'Notlar',
    notification: 'Bildirim',
    notifications: 'Bildirimler',
    notificationsDescription: 'Lobi üyelerine bildirim veya e-posta gönderin.',
    notifyMe: 'Beni Bilgilendir',
    notifyMeDescription: 'Davetiye kabul edildiğinde beni bilgilendir.',
    notifyUserDescription: 'Duyuru yayınlandığında lobi üyelerine bildirim gönderin.',
    notifyUsers: 'Kullanıcıları Bilgilendir',
    noUrlsTracked: 'Henüz bir adres izlenmedi',
    ok: 'Tamam',
    onceAudienceAsksQuestion: 'Kullanıcılar soru sorduğunda sorular burada listenelecek.',
    onceChatChannelCreated: 'Sohbet kanalları oluşturulduktan sonra burada görünürler.',
    onceInvitationSent: 'Kitlenize davetiye gönderdiğinizde davetiyelerinizi burada görebilirsiniz',
    onceItemAdded: 'Listenize öğe ekledinizde öğelerinizi burada görebilirsiniz.',
    onceNotificationSent:
      'Kitlenize bildirim gönderdiğinizde bildirimlerinizi burada görebilirsiniz.',
    oncePublisherPostedAnnouncements: 'Duyuru gönderdiğinizde duyurlarınızı burada görebilirsiniz.',
    onceQuestionsCreated: 'Soru oluşturduğunuzda sorularınızı burada görebilirsiniz.',
    onceUrlsTracked: 'Adres izlemeye başladığınızda adreslerinizi burada görebilirsiniz',
    onceYouCreatePoll: 'Soru oluşturduğunuzda burada listelenecektir',
    oneTime: 'Tek çekim',
    oopps: 'Oopps!',
    opened: 'Açılma',
    openedFromList: 'Listeden Açılma',
    openedLive: 'Açılma (Canlı)',
    openLink: 'Bağlantı aç',
    openLinkAnnouncements: 'Duyuruya basıldığında, kullanıcıyı bağlantıya yönlendirin.',
    openLinkDescription: 'Bildirime basıldığında, kullanıcıyı bağlantıya yönlendirin.',
    option: 'Seçenek',
    or: 'veya',
    outline: 'Çizgi',
    overview: 'Özet',
    participant: 'Katılımcı',
    password: 'Şifre',
    past: 'Geçmiş',
    pasteAnImageLink: 'Bir resim bağlantısı yapıştırın...',
    paymentDetails: 'Ödeme Bilgileri',
    pending: 'Beklemede',
    personalNotes: 'Kişisel Notlar',
    phoneNumber: 'Telefon numarası',
    plans: 'Planlar',
    pleaseContactUs: 'bize ulaşabilirsiniz ',
    pleaseContinueWith:
      'Hesabınız farklı bir giriş yöntemini destekliyor. Lütfen {{type}} ile devam ediniz.',
    pleaseEnterTitle: 'Lütfen başlık giriniz',
    pleaseEnterURL: 'Lütfen geçerli bir etkinlik bağlantısı giriniz',
    pleaseEnterVenue: 'Lütfen bir mekan seçiniz',
    pleaseEnterZoomID: 'Lütfen bir zoom kimliği giriniz',
    pleaseEnterZoomURL: 'Lütfen geçerli bir zoom bağlantısı giriniz',
    pleaseGoLiveToBroadcast: 'Lokasyon yayını oluştumak için lütfen lobinizi canlıya alın.',
    pleaseSelectLocation: 'Önizleme için lütfen bir konum seçiniz',
    point: 'Puan',
    polling: 'Anket',
    pollingDescription: 'Anketler düzenleyerek kitlenizin fikirlerini dinleyin ve analiz edin.',
    pollingDescriptionPlaceholder: 'Anketin konusu ve anket soruların kapsamı...',
    pollingTitlePlaceholder: 'Anketin adı veya konusu',
    premiumFeatures: 'Özellikler',
    premiumFeaturesUsage: 'Lobide kullanılan premium özelliklerin sayısı',
    preview: 'Önizleme',
    previous: 'Önceki',
    price: 'Fiyat',
    privacyAccess: 'Gizlilik ve Erişim',
    privacyAccessDescription: 'Lobinize kimler nasıl erişebilir?',
    privacyPolicy: 'Gizlilik Sözleşmesi',
    private: 'Gizli',
    privateDescription: 'Kullanıcılar erişim kodu ile lobiye erişebilir.',
    privateRoomAccessCodeError:
      'Gizli lobiler erişim anahtarına ihtiyaç duyar, lütfen bir erişim kodu giriniz.',
    pro: 'Pro',
    profile: 'Profil',
    profileImage: 'Profil resmi',
    promoCode: 'İndirim kodu',
    properties: 'Özellikler',
    proPlus: 'Pro +',
    public: 'Genel',
    publicDescription: 'Lobiye herkes erişebilir.',
    published: 'Yayında',
    punchLine: 'Viral lobi tecrübeleri dizayn et',
    purchase: 'Satın Al',
    purchaseCompleted:
      'Satın alma işleminiz tamamlandı, lobinize kullanıcıları davet edebilirsiniz.',
    purchaseDate: 'Satın alım tarihi',
    purchaseHistory: 'Satın alım geçmişi',
    purchasePlan: 'Plan satın al',
    purchaserEmail: 'Alıcı maili',
    purchaseRoom: 'Satın alınan lobi',
    purchaseSummary: 'Satın Alma Özeti',
    qa: 'Soru cevap',
    qaDescription: 'Kitlenizin size ve ekip arkadaşlarınıza doğrudan soru sormasını sağlayın.',
    qaDescriptionPlaceholder: 'Soru cevap konusu, hedefi ve soruların kapsamı...',
    qaTitlePlaceholder: 'Soru cevap adı',
    qrCode: 'QR Kod',
    qrCodeDescription:
      'Görsel reklamlara ve posterlere QRCode ekleyin, kullanıcılar kameralarla okuyabilir.',
    question: 'Soru',
    radius: 'Yarı çap',
    reachCustomerSupportForEndDate:
      'Bitiş tarihini değiştirmek için lütfen müşteri hizmetlerine ulaşın. (info@olvr.app)',
    reachCustomerSupportForStartDate:
      'Başlangıç tarihini değiştirmek için lütfen müşteri hizmetlerine ulaşın. (info@olvr.app)',
    refresh: 'Yenile',
    rejected: 'Reddilen',
    reminders: 'Hatırlatıcılar',
    remindersDescription: 'Lobinize hatırlamak için push bildirimleri ayarlayan kullanıcı sayısı',
    remove: 'Kaldır',
    reposition: 'Konumlandır',
    requestADemo: 'Demo talep et',
    requestDemo: 'Demo Planla',
    requests: '{{count}} istek',
    revoke: 'İptal Et',
    revokeAccess: 'Erişimi iptal et',
    revokeAccessDescription:
      'Takım arkadaşının erişimini iptal ettiğinizden emin misiniz? Daha sonra tekrar erişim verebilirsiniz.',
    roomAccess: 'Lobi Erişimi',
    rooms: 'Lobiler',
    save: 'Kaydet',
    savePosition: 'Konumu kaydet',
    scaleAudience: 'Kitlenizi {{count}} üyeye kadar ölçeklendirin',
    schedule: 'Planla',
    scheduleAnnouncementDescription:
      'Duyuruyu doğrudan gönderebilir veya gelecekteki bir tarih için planlayabilirsiniz.',
    scheduled: 'Planlanan',
    scheduledTo: 'Planlandı',
    scheduleNotificationDescription:
      'Bildirimleri kitlenize direkt gönderebilirsiniz veya ileri bir tarihe planlayabilirsiniz.',
    score: 'Skor',
    search: 'Ara',
    searchAnImage: 'Resim arayın...',
    searchIcon: 'İkon ara',
    searchIconPack: 'İkon paketi ara',
    searchVenue: 'Mekan veya adres arayın...',
    seeYouAround: 'Görüşmek üzere 😢',
    selected: '{{number}} seçildi',
    selectedSpreadType: 'Yöntem seç',
    selectIcon: 'İkon seç',
    send: 'Gönder',
    sendInvitation: 'Davet gönder',
    sendInvitations: 'Lobi üyelerini yönetin.',
    sendNotification: 'Bildirim gönder',
    sent: 'Gönderildi',
    sentAt: 'Gönderildi',
    setPushNotification: 'Hatırlatıcı kur',
    settings: 'Ayarlar',
    settingsDescription: 'Üyelerin ve lobinizin yeteneklerini belirleyin.',
    sharableLink: 'Paylaşma linki',
    somethingWentWrong: 'Oops! Bir şeyler yolunda gitmedi.',
    spreadGame: 'Tavsiye Oyunu',
    spreadGameDescription:
      'Kitlenizi markanızın elçilerine dönüştürmek için tavsiye etmenin gücünü kullanın.',
    spreadGameDescriptionPlaceholder: 'Oyunun konusu, kuralları ve dağıtılacak ödüller...',
    spreadGameTitlePlaceholder: 'Oyunun ismi',
    spreadTheWord: 'Paylaş',
    square: 'Kare',
    start: 'Başlangıç: {{date}}',
    startDate: 'Başlangıç tarihi',
    starter: 'Başlangıç',
    startTime: 'Başlama tarihi',
    status: 'Durum',
    submit: 'Gönder',
    tbaDescription: 'Projenin konumu haftaya duyurulacak',
    tbaHint: 'Proje erişimine dair ipuçları..',
    TBAWithPrefix: '❓ Belirsiz',
    teammates: 'Takım Arkadaşları',
    teams: 'Takımlar',
    teamSettings: 'Takım Ayarları',
    teamSettingsDescription: 'Çalışma arkadaşlarınızı lobi yönetimi için davet edin.',
    termsConditionsInfo:
      "Kabul ve Devam Et'i seçerek, Lobbi'in Kullanım Koşulları'nı ve Gizlilik Sözleşmesi'ni kabul ediyorum.",
    termsOfService: 'Kullanım Koşulları',
    tierCount: '{{count}} tane',
    time: 'Zaman',
    timezone: 'Zaman dilimi',
    title: 'Başlık',
    toGetPromoCode: '(Hesabınıza kupon kodu sağlanacaktır)',
    tracking: 'İzleniyor',
    trackUrl: 'Adres İzle',
    twitter: 'Twitter',
    twitterDescription: 'Tweetler oluşturun ve doğrudan paylaşın.',
    type: 'Tip',
    unlimited: 'Limitsiz',
    unsplash: 'Unsplash',
    upcoming: 'Yaklaşan',
    update: 'Güncelle',
    updateAnnouncement: 'Duyuru Güncelle',
    updateFAQ: 'Sıkça sorulan soru güncelle',
    updateWebListItem: 'Bağlatıyı güncelle',
    upgradePlan: 'Planı yükselt',
    upload: 'Yükle',
    uploadImage: 'Resim yükle',
    usage: 'Kullanım',
    usageBilling: 'Kullanım ve Fatura',
    usageBillingDescription: 'Planları ve ödemeleri yönetin, lobi limitlerini gözden geçirin.',
    userName: 'Kullanıcı adı',
    venueAddress: 'Proje adresi',
    verificationCode: 'Onay kodu',
    viewAllLobbies: 'Tüm lobileri görüntüle',
    virtualWithPrefix: '💻 Sanal',
    visit: 'Git',
    webLinks: 'Bağlantı listesi',
    webList: 'Bağlantı listesi',
    webListDescription:
      'İnternet sitelerinizi listeyerek markanızı, ürününüzü ve içeriğinizi tanıtın.',
    webListDescriptionPlaceholder: 'Bağlantı listesinin konusu, hedefi...',
    webListTitlePlaceholder: 'Bağlantı listesinin ismi',
    webPage: 'İnternet sitesi',
    webPageDescription: 'İnternet sitenizi ekleyerek sitenizin ziyaretçi sayısını yükseltin.',
    webPageDescriptionPlaceholder: 'Internet sitesinin konusu, hedefi...',
    webPageTitlePlaceholder: 'Internet sitesinin ismi',
    webPageUrl: 'Web sitesi bağlantısı',
    website: 'Web sitesi',
    welcomeToLobbi: "Lobbi'e hoşgeldiniz",
    worksWithAnyImage: 'Internetten herhangir bir resim uygundur.',
    yes: 'Evet',
    you: 'Siz',
    yourNextAmazingThing: 'Projenizin ismi',
    youSentInvitation: 'Davetiyeyi {{date}} tarihinde gönderdiniz',
    youtube: 'Youtube',
    zoom: 'Zoom',
    zoomMeetingId: 'Zoom toplantı kimliği',
    zoomMeetingIdDescription: "Zoom'dan aldığınız toplantı kimliği.",
    zoomMeetingIdPlaceholder: '123456789',
    zoomMeetingPassword: 'Zoom toplantı şifresi',
    zoomMeetingPasswordDescription: 'Zoom toplantısının şifresi varsa.',
    zoomMeetingPasswordPlaceholder: '22222222',
    zoomMeetingUrl: 'Zoom toplantı bağlantısı',
    zoomMeetingUrlDescription: 'Kitlenizin zoom toplantısına katılması için URL.',
    zoomMeetingUrlPlaceholder: 'https://zoom.us/j/987654321',
    zoomWithPrefix: 'Zoom',
    activeFeatureCount: 'Aktif özellik sayısı',
    currentlyActiveFeatureCount: 'Şu andaki aktif özellik sayısı {{active}}',
    chatAndMemberList: 'Sohbet ve üye listesi',
    chatAndMemberListDescription: 'Lobi üyeleri arası etkileşime izin verin.',
    joined: '{{date}} tarihinde lobiye katıldı',
    exportOrImport: 'Dışa / İçe aktar',
    audienceDescription: 'Lobi üyelerini yönetin, üyeleri içe veya dışa aktarın',
    importAudience: 'Yeni üyeleri .csv formatında ekleyin.',
    exportAudience: 'Üye raporunu .csv formatında indirin ',
    members: 'Üyeler',
    noMembersFound: 'Üye bulunamadı',
    onceMembersJoinToLobby: 'Kullanıcilar lobiye üye olduklarında burada listenecekler.',
    noMembersFoundForTheQuery:
      'Girdiğiniz sorguya uyan bir üye malesef bulunamadı. Sorgunuzu kontrol edip tekrar deneyiniz.',
    removeMember: 'Üyelikten çıkar',
    removeAction: 'Çıkar',
    removeMemberMessage:
      'Seçili kullanıcıları üyelikten çıkarmak istediğinize emin misiniz? Kullanıcılar tekrar lobiye katılabilir.',
    downloadReport: 'Rapor indir',
    block: 'Engelle',
    blockMessage:
      'Seçili kullanıcıları engellemek istediğinize emin misiniz? Kullanıcılar tekrar lobiye katılamaz.',
    removeSelectedAudience: 'Seçili kullanıcıları lobiden çıkar.',
  },
}

export default tr
