import React, { useCallback, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { LabelMedium, LabelNormal, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Poll } from 'src/repository/types'
import styled, { useTheme } from 'styled-components'

export const DashboardPollingItem: React.FC<{
  poll: Poll
  index: number
}> = ({ poll, index }) => {
  // MARK: - Hooks

  const { palette } = useTheme()
  const stateColor: Record<number, string> = {
    0: palette.purple,
    1: palette.green,
  }

  const getPercentage = useCallback(
    (optionId: string): number => {
      const totalVotes = Object.values(poll.votes ?? {}).reduce((acc, vote) => acc + vote, 0)
      if (!totalVotes) return 0

      const vote = poll.votes?.[optionId] ?? 0
      return (vote / totalVotes) * 100
    },
    [poll.votes],
  )

  const options = useMemo(() => {
    const unsortedOptions = [...poll.options]
    return unsortedOptions.sort((a, b) => a.order - b.order)
  }, [poll.options])

  // MARK: - Render

  return (
    <Draggable draggableId={poll.id} index={index}>
      {(provided, snapshot) => (
        <Container
          isDragging={snapshot.isDragging}
          color={stateColor[poll.is_active ? 1 : 0]}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <InnerContainer>
            <LabelMedium style={{ marginTop: 6, marginBottom: 8 }}>{poll.question}</LabelMedium>

            {options.map(option => (
              <AnswerContainer key={option.id}>
                <AnswerPercentage percentage={Math.max(0.5, getPercentage(option.id))} />

                <LabelNormal style={{ color: palette.text.primary, zIndex: 1 }}>
                  {option.answer}
                </LabelNormal>

                <LabelSmall style={{ zIndex: 1 }}>{`${getPercentage(option.id).toFixed(
                  0,
                )}%`}</LabelSmall>
              </AnswerContainer>
            ))}
          </InnerContainer>
        </Container>
      )}
    </Draggable>
  )
}

// MARK: - Styles

const Container = styled.div<{ color: string; isDragging: boolean }>`
  align-items: center;
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.palette.background.secondary : theme.palette.background.primary};
  border-radius: 6px;
  border-width: 1px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  margin: 4px 16px 8px 16px;
  margin-bottom: 8px;
  position: relative;
  width: calc(100% - 32px);
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 12px 9px 12px;
  width: 100%;
`

const AnswerContainer = styled(Row)`
  border-radius: 6px;
  justify-content: space-between;
  margin: 4px 0px;
  padding: 8px 8px;
  position: relative;
`

const AnswerPercentage = styled(Row)<{ percentage: number }>`
  background-color: ${({ theme }) => theme.palette.orange};
  border-radius: 6px;
  bottom: 0px;
  justify-content: space-between;
  left: 0px;
  opacity: ${({ theme }) => (theme.isDark ? 0.82 : 0.24)};
  padding: 8px 4px;
  position: absolute;
  top: 0px;
  width: ${({ percentage }) => percentage}%;
`
