import React from 'react'

export const CheckIcon: React.FC<{
  height?: number
  width?: number
  fill?: string
}> = ({ height = 6, width = 8, fill = '#ECEDED' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06533 0.21541C6.36093 -0.0718034 6.84021 -0.0718034 7.13581 0.21541C7.40454 0.476513 7.42897 0.885097 7.2091 1.17302L7.13581 1.2555L3.20171 5.07791C2.93293 5.33907 2.51231 5.36276 2.21598 5.14902L2.13109 5.07778L0.221563 3.22149C-0.0739647 2.9342 -0.0738381 2.46853 0.221846 2.18139C0.490649 1.92036 0.911178 1.89673 1.20745 2.11044L1.29233 2.18167L2.66596 3.51774L6.06533 0.21541Z"
        fill={fill}
      />
    </svg>
  )
}
