import { PurchasePaymentFooter } from './PurchasePaymentFooter'
import { PurchasePaymentForm } from './PurchasePaymentForm'
import { PurchasePaymentOverview } from './PurchasePaymentOverview'
import { Elements } from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol } from 'src/features/common/CommonStyles'
import { InfoBox } from 'src/features/common/InfoBox'
import { getLoading, getLocalization } from 'src/redux/reducers/app'
import { getOffer, getSelectedTiers } from 'src/redux/reducers/purchase'
import { Room } from 'src/repository/types'
import { stripePromise } from 'src/utils/helpers/stripeHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const PurchasePayment: React.FC<{
  room: Room
  onConfigurePurchaseClick: () => void
}> = ({ room, onConfigurePurchaseClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()

  const localization = useSelect(state => getLocalization(state.app))
  const isLoading = useSelect(state => getLoading(state.app, 'purchase'))
  const selectedTiers = useSelect(state => getSelectedTiers(state.purchase))
  const offer = useSelect(state => getOffer(state.purchase))

  // MARK: - Render

  return (
    <FlexCol>
      <InnerContainer>
        <div style={{ width: '49%' }}>
          <InfoContainer>
            <InfoBox
              title={t('howPlansWorks')}
              elements={[
                t('howPlansWorksCharge'),
                t('howPlansWorksUpgrade'),
                t('howPlansWorksOverUsage'),
              ]}
            />
          </InfoContainer>

          {offer?.payment_intent_secret ? (
            <Elements
              options={{
                clientSecret: offer?.payment_intent_secret,
                locale: localization,
                appearance: {
                  theme: 'stripe',
                  variables: {
                    colorPrimary: palette.orange,
                    colorBackground: palette.background.primary,
                    colorText: palette.text.primary,
                  },
                },
              }}
              stripe={stripePromise}>
              <PurchasePaymentForm />
            </Elements>
          ) : null}
        </div>

        <div style={{ width: '49%' }}>
          <PurchasePaymentOverview
            room={room}
            selectedTiers={selectedTiers}
            price={offer?.price ?? 0}
            discount={offer?.discount ?? null}
          />
        </div>
      </InnerContainer>

      <PurchasePaymentFooter
        isLoading={isLoading}
        onSecondaryButtonClick={onConfigurePurchaseClick}
      />
    </FlexCol>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
`

const InfoContainer = styled.div`
  margin-bottom: 72px;
`
