import { SingleDatePicker } from './SingleDatePicker'
import { TimePicker } from './TimePicker'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
// @ts-ignore
import { enUS, tr } from 'react-date-range/src/locale/index'
import { Row, ShadowBox } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getLocalization } from 'src/redux/reducers/app'
import { format24, isValidTime, nearestTime } from 'src/utils/helpers/dateHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DateTimePicker: React.FC<{
  style?: React.CSSProperties
  date: Date
  visible?: boolean
  title?: string
  isOptional?: boolean
  isRequired?: boolean
  disabled?: boolean
  hint?: string
  forceHide?: boolean
  onPickerOpen?: () => void
  onPickerClose?: () => void
  onClearClick?: () => void
  onDateChange: (timestamp: number) => void
}> = ({
  style,
  title,
  visible: isVisible = false,
  date: rawDate,
  isOptional = false,
  isRequired = false,
  disabled = false,
  forceHide = false,
  hint,
  onDateChange,
  onPickerOpen,
  onPickerClose,
  onClearClick,
}) => {
  // MARK: - Hooks

  const dateRef = React.useRef<HTMLDivElement | null>(null)
  const timeRef = React.useRef<HTMLDivElement | null>(null)

  const localization = useSelect(state => getLocalization(state.app))

  const [datePickerVisible, setDatePickerVisible] = useState(isVisible)
  const [timePickerVisible, setTimePickerVisible] = useState(false)

  const date = rawDate ?? new Date()
  const [timeInput, setTimeInput] = useState(format24(date))

  const humanizedValue = useMemo(() => {
    const dateString = date.toLocaleDateString(localization, {
      month: 'long',
      year: 'numeric',
      day: '2-digit',
    })
    return date < new Date(1000) ? '' : dateString
  }, [date])

  // MARK: - Effects

  useEffect(() => {
    if (datePickerVisible || timePickerVisible) onPickerOpen?.()
    else onPickerClose?.()
  }, [datePickerVisible, timePickerVisible])

  useEffect(() => {
    if (isValidTime(timeInput)) {
      const currenDate = date
      const [hour, minute] = nearestTime(timeInput).split(':')

      currenDate.setHours(parseInt(hour, 10))
      currenDate.setMinutes(parseInt(minute, 10))
      currenDate.setSeconds(0)
      currenDate.setMilliseconds(0)
      onDateChange(Math.round(currenDate.getTime() / 1000))
    }
  }, [timeInput])

  useEffect(() => {
    document.addEventListener('click', handleDropdownCloseClick)
    document.getElementById('modal')?.addEventListener('click', handleDropdownCloseClick)
    return () => {
      document.removeEventListener('click', handleDropdownCloseClick)
      document.getElementById('modal')?.removeEventListener('click', handleDropdownCloseClick)
    }
  }, [datePickerVisible])

  useEffect(() => {
    if (forceHide) {
      setDatePickerVisible(false)
      setTimePickerVisible(false)
    }
  }, [forceHide])

  // MARK: - Handlers

  const handleDropdownCloseClick = useCallback((event: MouseEvent | any) => {
    if (!dateRef.current?.contains(event.target)) setDatePickerVisible(false)
    if (!timeRef.current?.contains(event.target)) setTimePickerVisible(false)
  }, [])

  const handleDateChange = useCallback(
    (newDate: Date) => {
      const currenDate = date
      currenDate.setDate(newDate.getDate())
      currenDate.setMonth(newDate.getMonth())
      currenDate.setFullYear(newDate.getFullYear())
      onDateChange(Math.round(currenDate.getTime() / 1000))
      setDatePickerVisible(false)
    },
    [date],
  )

  const handleTimeTextChange = useCallback((text: string) => {
    setTimeInput(prev => {
      if (!text.includes(':') || (text.match(/:/g) || []).length > 1) return prev
      const hour = text.split(':')[0].replace(/[^\d.-]/g, '') ?? '00'
      const minute = text.split(':')[1].replace(/[^\d.-]/g, '') ?? '00'
      return `${hour}:${minute}`
    })
  }, [])

  // MARK: - Render

  return (
    <Row style={style}>
      <div ref={dateRef} style={{ width: 290 }}>
        <Input
          style={{ position: 'relative', width: 290 }}
          isRequired={isRequired}
          disabled={disabled}
          label={title}
          value={humanizedValue}
          onFocus={() => setDatePickerVisible(true)}>
          <Container visible={datePickerVisible}>
            <SingleDatePicker
              weekStartsOn={1}
              date={date}
              weekdayDisplayFormat={'eeeeee'}
              locale={localization === 'tr' ? tr : enUS}
              onChange={handleDateChange}
              color={'#F36538'}
            />
          </Container>
        </Input>
      </div>

      <div ref={timeRef}>
        <Input
          style={{ position: 'relative', maxWidth: 120, marginLeft: 10, marginTop: title ? 23 : 0 }}
          isRequired={isRequired}
          disabled={disabled}
          value={timeInput.trim()}
          onChange={handleTimeTextChange}
          onFocus={() => setTimePickerVisible(true)}
          onBlur={() => setTimeInput(nearestTime(timeInput))}>
          {timePickerVisible && (
            <TimePicker
              selectedTime={timeInput.trim()}
              onTimeClick={time => {
                setTimeInput(time)
                setTimePickerVisible(false)
              }}
            />
          )}
        </Input>
      </div>
    </Row>
  )
}

// MARK: - Styles

const Container = styled(ShadowBox)<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  margin-top: -6px;
  position: absolute;
  z-index: 100;
`
