import React, { useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ProfileIcon } from 'src/assets/images/svg/ProfileIcon'
import { LabelSmall } from 'src/features/common/CommonStyles'
import { InputForwardRef } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { answerQAQuestion } from 'src/redux/reducers/component'
import { getEntity } from 'src/redux/reducers/entity'
import { QAQuestion, QAQuestionState, User } from 'src/repository/types'
import { prettyDateString } from 'src/utils/helpers/dateHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const DashboardQAItem: React.FC<{
  qaQuestion: QAQuestion
  index: number
}> = ({ qaQuestion, index }) => {
  // MARK: - Hooks

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [focused, setFocused] = useState(false)
  const [answer, setAnswer] = useState(qaQuestion.answer ?? '')
  const user = useSelect(state => getEntity<User>(state.entity, 'user', qaQuestion.user_id))
  const { palette } = useTheme()
  const stateColor: Record<QAQuestionState, string> = {
    pending: palette.purple,
    answered: palette.green,
    rejected: palette.orange,
  }

  // MARK: - Properties

  const time = prettyDateString(qaQuestion.created_at)
  const name = `${user?.first_name} ${user?.last_name}`
  const shouldShowButtons = qaQuestion.state === 'answered' && (focused || !answer)

  // MARK: - Handlers

  const handleSaveAnswer = () => {
    if (!answer) return
    if (answer !== qaQuestion.answer) {
      dispatch(answerQAQuestion(qaQuestion, answer))
    }
    inputRef.current?.blur()
    setFocused(false)
  }

  const handleDiscardAnswer = () => {
    setAnswer(qaQuestion.answer ?? '')
    setFocused(false)
  }

  // MARK: - Render

  return (
    <Draggable
      draggableId={qaQuestion.id}
      index={index}
      isDragDisabled={shouldShowButtons || focused}>
      {(provided, snapshot) => (
        <Container
          isDragging={snapshot.isDragging}
          focused={focused || shouldShowButtons}
          color={stateColor[qaQuestion.state]}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <InnerContainer>
            <HeaderContainer>
              <AvatarContainer>
                {user?.image_url ? <Avatar src={user?.image_url} /> : <ProfileIcon size={40} />}
              </AvatarContainer>

              <HeaderTextContainer>
                <Name>{name}</Name>
                <LabelSmall style={{ fontSize: 13 }}>{time}</LabelSmall>
              </HeaderTextContainer>
            </HeaderContainer>

            <Question>{qaQuestion.question}</Question>
            {qaQuestion.state === 'answered' ? (
              <>
                <InputForwardRef
                  style={{ marginTop: 10, marginBottom: 8 }}
                  inputStyle={{
                    fontSize: 14,
                    fontWeight: 400,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: 8,
                    borderWidth: 0,
                    boxShadow: 'none',
                    borderLeftWidth: 2,
                    borderColor: palette.green,
                    borderRadius: 0,
                    minHeight: 24,
                  }}
                  ref={inputRef}
                  type="textarea"
                  placeholder={t('answerPlaceHolder')}
                  onFocus={() => setFocused(true)}
                  autoFocus={!answer}
                  value={answer}
                  size="sm"
                  onChange={setAnswer}
                />
                {shouldShowButtons ? (
                  <ButtonContainer onClick={event => event.stopPropagation()}>
                    {qaQuestion.answer ? (
                      <SecondaryButton
                        style={{ marginRight: 12, flex: 1 }}
                        title={t('discard')}
                        onClick={handleDiscardAnswer}
                      />
                    ) : null}

                    <PrimaryButton
                      style={{ flex: 1 }}
                      title={t('save')}
                      onClick={handleSaveAnswer}
                    />
                  </ButtonContainer>
                ) : null}
              </>
            ) : null}
          </InnerContainer>
        </Container>
      )}
    </Draggable>
  )
}

// MARK: - Styles

const Container = styled.div<{
  color: string
  focused: boolean
  isDragging: boolean
}>`
  align-items: center;
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.palette.background.secondary : theme.palette.background.tertiary};
  border-radius: 6px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05);
  margin: 4px 16px 8px 16px;
  position: relative;
  width: calc(100% - 32px);
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 12px 9px 12px;
  width: 100%;
`

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

const Question = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin: 12px 0px 2px 0px;
`

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40px;
`

const Avatar = styled.img`
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 20px;
  height: 40px;
  width: 40px;
`

const Name = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 2px 2px 8px 2px;
`
