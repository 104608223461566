import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.17751 11.5768L4.59324 10.6673L4.17751 11.5768ZM4.26597 9.72235L4.59324 10.6673L4.26597 9.72235ZM11.4531 15.1223L12.3314 14.6442L11.4531 15.1223ZM10.9906 14.691L10.5748 15.6005L10.9906 14.691ZM16.8788 21.484L15.9133 21.2234L16.8788 21.484ZM15.035 21.7016L14.1568 22.1797L15.035 21.7016ZM21.1884 5.51472L20.223 5.25417L21.1884 5.51472ZM19.8957 4.30924L20.223 5.25417L19.8957 4.30924ZM20.223 5.25417L15.9133 21.2234L17.8442 21.7445L22.1539 5.77527L20.223 5.25417ZM15.9133 21.2234L12.3314 14.6442L10.5748 15.6005L14.1568 22.1797L15.9133 21.2234ZM11.4063 13.7815L4.59324 10.6673L3.76178 12.4863L10.5748 15.6005L11.4063 13.7815ZM4.59324 10.6673L20.223 5.25417L19.5684 3.3643L3.93871 8.77741L4.59324 10.6673ZM4.59324 10.6673L4.59324 10.6673L3.93871 8.77741C2.23375 9.3679 2.12078 11.7362 3.76178 12.4863L4.59324 10.6673ZM12.3314 14.6442C12.1245 14.2641 11.7999 13.9614 11.4063 13.7815L10.5748 15.6005L10.5748 15.6005L12.3314 14.6442ZM15.9133 21.2234L15.9133 21.2234L14.1568 22.1797C15.0195 23.7644 17.3741 23.4865 17.8442 21.7445L15.9133 21.2234ZM22.1539 5.77527C22.5722 4.22518 21.0856 2.83886 19.5684 3.3643L20.223 5.25417L20.223 5.25417L22.1539 5.77527Z"
      fill={fill}
    />
  </svg>
)

export const NavigateIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
