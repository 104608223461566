import { CoverUpdateMethod, coverUpdateMethods } from './types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexRow, LabelSmall, Row } from 'src/features/common/CommonStyles'
import { Loader } from 'src/features/common/Loader'
import { getLoading } from 'src/redux/reducers/app'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled, { useTheme } from 'styled-components'

export const CoverImageModalTabBar: React.FC<{
  selectedMethod: CoverUpdateMethod
  onMethodSelect: (method: CoverUpdateMethod) => void
  onCoverImageRemove: () => void
}> = ({ selectedMethod, onMethodSelect, onCoverImageRemove }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const isLoading = useSelect(state => getLoading(state.app, 'cover_image'))

  // MARK: - Render

  return (
    <Container>
      <Row>
        {coverUpdateMethods.map(method => (
          <Option
            key={method}
            isSelected={selectedMethod === method}
            onClick={() => onMethodSelect(method)}>
            {t(method)}
          </Option>
        ))}
        {isLoading && <Loader style={{ marginTop: 8, marginLeft: 4 }} size={24} />}
      </Row>

      <Option
        style={{ color: palette.text.secondary, opacity: 0.8 }}
        isSelected={false}
        onClick={onCoverImageRemove}>
        {t('remove')}
      </Option>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexRow)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.separator};
  justify-content: space-between;
  padding: 0px 10px 0px 4px;
`

const Option = styled(LabelSmall)<{ isSelected: boolean }>`
  border-bottom: ${({ isSelected }) => (isSelected ? 2 : 0)}px solid
    ${({ theme }) => theme.palette.text.primary};
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  font-size: 14px;
  margin: 0px 8px;
  margin-bottom: -1px;
  padding: 10px 0px 6px 0px;
`
