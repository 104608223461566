import { api } from '../api'
import { AnnouncementList, Component, ComponentType, SpreadGame, WebPage } from '../types'

export const componentService = {
  fetchComponents: async (roomId: string, lastUpdatedAt: number) =>
    api.value<{
      component: Component[]
      announcement_list: AnnouncementList[]
      web_page: WebPage[]
      spread_game: SpreadGame[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/components',
        parameters: { room_id: roomId, last_updated_at: lastUpdatedAt },
      },
    }),

  fetchAllComponents: async (roomId: string, lastUpdatedAt: number) =>
    api.value<{
      component: Component[]
      announcement_list: AnnouncementList[]
      web_page: WebPage[]
      spread_game: SpreadGame[]
    }>({
      request: {
        method: 'GET',
        endPoint: '/allComponents',
        parameters: { room_id: roomId, last_updated_at: lastUpdatedAt },
      },
    }),

  deleteComponent: async (componentId: string) =>
    api.value<{
      component: Component
      announcement_list: AnnouncementList | null
      web_page: WebPage | null
      spread_game: SpreadGame | null
    }>({
      request: {
        method: 'DELETE',
        endPoint: '/component',
        parameters: { component_id: componentId },
      },
    }),

  createComponent: async (
    order: number,
    type: ComponentType,
    roomId: string,
    title: string,
    overview: string,
    imageUrl: string,
    isActive: boolean,
    input: CreateComponentInstanceInput,
  ) =>
    api.value<{
      component: Component
      announcement_list: AnnouncementList | null
      web_page: WebPage | null
      spread_game: SpreadGame | null
    }>({
      request: {
        method: 'POST',
        endPoint: '/component',
        parameters: {
          order: order,
          type: type,
          room_id: roomId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          is_active: isActive,
          [input.key]: input,
        },
      },
    }),

  updateComponent: async (
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    isActive: boolean,
    input: UpdateComponentInstanceInput,
  ) =>
    api.value<{
      component: Component
      announcement_list: AnnouncementList | null
      web_page: WebPage | null
      spread_game: SpreadGame | null
    }>({
      request: {
        method: 'PUT',
        endPoint: '/component',
        parameters: {
          component_id: componentId,
          title: title,
          overview: overview,
          image_url: imageUrl,
          is_active: isActive,
          [input.key]: input,
        },
      },
    }),

  toggleComponent: async (componentId: string, isActive: boolean) =>
    api.prop<{ component: Component }>('component', {
      request: {
        method: 'PUT',
        endPoint: '/toggleComponent',
        parameters: { component_id: componentId, is_active: isActive },
      },
    }),
}

export type CreateComponentInstanceInput =
  | { key: 'announcement_list'; is_notification_enabled: boolean }
  | { key: 'web_page'; url: string }
  | {
      key: 'spread_game'
      events: string[]
      point_table: { name: string; event: string; point: number }[]
      black_list: string[]
    }

export type UpdateComponentInstanceInput =
  | { key: 'announcement_list'; is_notification_enabled: boolean }
  | { key: 'web_page'; url: string }
  | { key: 'spread_game'; black_list: string[] }
  | { key: 'web_list' }
  | { key: 'faq_list' }
  | { key: 'polling' }
  | { key: 'qa'; anonymous_enabled: boolean }
