import { api } from '../api'
import { FAQ } from '../types'

export const faqService = {
  fetchFAQs: async (componentId: string, lastUpdatedAt: number) =>
    api.prop<{ faqs: FAQ[] }>('faqs', {
      request: {
        method: 'GET',
        endPoint: '/faqs',
        parameters: { component_id: componentId, last_updated_at: lastUpdatedAt },
      },
    }),

  createFAQ: async (
    roomId: string,
    componentId: string,
    question: string,
    answer: string,
    links: string[],
  ) =>
    api.prop<{ faq: FAQ }>('faq', {
      request: {
        method: 'POST',
        endPoint: '/faq',
        parameters: {
          room_id: roomId,
          component_id: componentId,
          question: question,
          answer: answer,
          links: links,
        },
      },
    }),

  updateFAQ: async (
    faqId: string,
    question: string,
    answer: string,
    links: string[],
    isActive: boolean,
  ) =>
    api.prop<{ faq: FAQ }>('faq', {
      request: {
        method: 'PUT',
        endPoint: '/faq',
        parameters: {
          faq_id: faqId,
          question: question,
          answer: answer,
          links: links,
          is_active: isActive,
        },
      },
    }),

  deleteFAQ: async (faqId: string) =>
    api.prop<{ faq: FAQ }>('faq', {
      request: {
        method: 'DELETE',
        endPoint: '/faq',
        parameters: { faq_id: faqId },
      },
    }),
}
