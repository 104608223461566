import React, { useEffect, useState } from 'react'
import { DropDown } from 'src/features/common/DropDown'
import { SVG } from 'src/utils/types/svg'
import styled from 'styled-components'

export const DropdownWrapper: React.FC<{
  style?: React.CSSProperties
  icon: any
  disabled?: boolean
  position: { right: number; top: number }
  options: { title: string; icon: SVG }[]
  onToggle?: (open: boolean) => void
  handleOptionClick: (option: number) => void
}> = ({ style, icon, position, options, disabled = false, onToggle, handleOptionClick }) => {
  // MARK: - Hooks

  const drop = React.useRef<HTMLDivElement | null>(null)
  const [displayDropdown, setDisplayDropdown] = useState(false)

  // MARK: - Effects

  useEffect(() => {
    document.addEventListener('click', handleDropdownCloseClick)
    return () => {
      document.removeEventListener('click', handleDropdownCloseClick)
    }
  }, [])

  useEffect(() => {
    onToggle?.(displayDropdown)
  }, [displayDropdown])

  // MARK: - Handlers

  const handleDropdownCloseClick = (event: MouseEvent | any) => {
    if (drop.current && !drop.current.contains(event.target)) {
      event.stopPropagation()
      setDisplayDropdown(false)
    }
  }

  const handleToggleDropdown = (event: any) => {
    if (disabled) return
    event.stopPropagation()
    setDisplayDropdown(prev => !prev)
  }

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleOptionClick?.(option)
    setTimeout(() => setDisplayDropdown(false), 300)
  }

  // MARK: - Expose

  return (
    <div style={style}>
      <DropdownIcon
        className="dropdown"
        style={{ position: 'relative' }}
        ref={drop}
        onClick={handleToggleDropdown}>
        {icon}

        {displayDropdown && (
          <DropDown
            style={{ right: position.right, top: position.top }}
            data={options}
            onItemClick={handleOptionsDropdownOptionClick}
          />
        )}
      </DropdownIcon>
    </div>
  )
}

// MARK: - Styles

const DropdownIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 10;
`
