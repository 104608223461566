import { DashboardAudienceRequestListItem } from './DashboardAudienceRequestListItem'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { respondInvitationRequest as respond } from 'src/redux/reducers/audience'
import { getEntities } from 'src/redux/reducers/entity'
import { InvitationRequest } from 'src/repository/types'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardAudienceRequestModal: React.FC<{
  roomId: string
  onClose: () => void
}> = ({ roomId, onClose }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invitationRequests = useSelect(state =>
    getEntities<InvitationRequest>(
      state.entity,
      'invitation_request',
      request => request.room_id === roomId,
    ),
  )

  // MARK: - Effects

  useEffect(() => {
    if (invitationRequests.length === 0) onClose()
  }, [invitationRequests.length])

  // MARK: - Render

  return (
    <ModalWrapper title={t('invitationRequests')} size="tiny" onClose={onClose}>
      {invitationRequests.map(request => (
        <DashboardAudienceRequestListItem
          key={request.id}
          invitationRequest={request}
          onAccept={({ id }) => dispatch(respond(roomId, id, true))}
          onReject={({ id }) => dispatch(respond(roomId, id, false))}
        />
      ))}
    </ModalWrapper>
  )
}
