import { DashboardSectionHeader } from '../../dashboard/DashboardSectionHeader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CopyIcon } from 'src/assets/images/svg/CopyIcon'
import { LockIcon } from 'src/assets/images/svg/LockIcon'
import { Col } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getLocalization } from 'src/redux/reducers/app'
import { RoomPrivacyType } from 'src/repository/types'
import { privacyTypes } from 'src/utils/helpers/privacyTypeHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const RoomPrivacySection: React.FC<{
  selectedPrivacyType: RoomPrivacyType
  hideIcon?: boolean
  accessCode: string
  apiKey: string
  apiSecret: string
  onAccessCodeChange: (accessCode: string) => void
  onPrivacyChange: (privacyType: RoomPrivacyType) => void
}> = ({
  hideIcon,
  selectedPrivacyType,
  accessCode,
  apiKey,
  apiSecret,
  onAccessCodeChange,
  onPrivacyChange,
}) => {
  // MARK: - Hooks

  const localization = useSelect(state => getLocalization(state.app))
  const { t } = useTranslation()

  // MARK: - Handlers

  const handleAPIKeyCopyClick = () => {
    if (!apiKey) return
    navigator.clipboard.writeText(apiKey)
    toast.success(t('copiedToClipboard'))
  }

  const handleAPISecretCopyClick = () => {
    if (!apiSecret) return
    navigator.clipboard.writeText(apiSecret)
    toast.success(t('copiedToClipboard'))
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardSectionHeader
        style={{ marginBottom: 4 }}
        title={t('privacyAccess')}
        description={t('privacyAccessDescription')}
        Icon={!hideIcon ? LockIcon : undefined}
      />

      {privacyTypes.all.map(privacyType => (
        <ItemContainer
          key={privacyType}
          onClick={() => onPrivacyChange(privacyType)}
          isSelected={privacyType === selectedPrivacyType}>
          <ItemInnerContainer>
            {privacyTypes.icon(privacyType)}
            <TextContainer>
              <Title>{privacyTypes.title(localization)[privacyType]}</Title>
              <SubTitle>{privacyTypes.subtitle(localization)[privacyType]}</SubTitle>
            </TextContainer>
          </ItemInnerContainer>

          {privacyType === 'private' && privacyType === selectedPrivacyType && (
            <Input
              style={{ marginLeft: 20, marginRight: 20 }}
              label={t('accessCode')}
              isRequired
              value={accessCode}
              onChange={text => onAccessCodeChange(text.toUpperCase())}
            />
          )}

          {privacyType === 'managed' && privacyType === selectedPrivacyType && (
            <InputContainer>
              <Input
                style={{ marginRight: 8, position: 'relative' }}
                label={t('apiKey')}
                value={apiKey}>
                <CopyButton onClick={handleAPIKeyCopyClick}>
                  <CopyIcon />
                </CopyButton>
              </Input>

              <Input
                style={{ marginLeft: 8, position: 'relative' }}
                type="password"
                label={t('apiSecret')}
                value={apiSecret}>
                <CopyButton onClick={handleAPISecretCopyClick}>
                  <CopyIcon />
                </CopyButton>
              </Input>
            </InputContainer>
          )}
        </ItemContainer>
      ))}
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 820px;
`

const ItemContainer = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.palette.orange : theme.palette.background.separator};
  border-radius: 12px;
  border-width: 1px;
  cursor: pointer;
  margin-top: 12px;
  width: 74%;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px;
`

const CopyButton = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 32px;
`

const ItemInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 16px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 500;
  margin-top: -4px;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  line-height: 18px;
  margin-top: -4px;
`
