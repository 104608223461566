import { PageLoader } from '../common/PageLoader'
import { getAuth } from 'firebase/auth'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from 'src/redux/reducers/account'
import { fetchInvitation } from 'src/redux/reducers/audience'
import { loginSilently } from 'src/redux/reducers/authentication'
import { getEntity } from 'src/redux/reducers/entity'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { Invitation } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'

export const InvitationRedirect: React.FC<{
  invitationId: string
}> = ({ invitationId }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { routes, navigate } = useNavigation()
  const invitation = useSelect(state =>
    getEntity<Invitation>(state.entity, 'invitation', invitationId),
  )
  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchInvitation(invitationId))
  }, [invitationId])

  useEffect(() => {
    if (!invitation?.id) return
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (isAuthenticated) {
        unsubscribe()
        return
      }
      if ((user?.email || user?.phoneNumber) && invitation?.email === user.email) {
        dispatch(loginSilently(user.email, user.phoneNumber, invitation))
      } else {
        dispatch(logout(false))
        navigate(routes.authentication.continue + `?invitation_id=${invitationId}`)
      }
      unsubscribe()
    })

    return () => {
      unsubscribe()
    }
  }, [dispatch, invitation?.id])

  useEffect(() => {
    if (isAuthenticated && invitation && typeof invitation.collaborator_access_level === 'number') {
      navigate(routes.dashboard(invitation.room_id).info)
    }
  }, [isAuthenticated, invitation?.id])

  // MARK: - Render

  return <PageLoader />
}
