import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FlexCol } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createTrackedUrl, updateTrackedUrl } from 'src/redux/reducers/component'
import { getMeUser } from 'src/redux/reducers/me'
import { Error } from 'src/repository/Error'
import { Component, Room, TrackedUrl } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardWebPageTrackedUrlModal: React.FC<{
  trackedUrl?: TrackedUrl | null
  room: Room
  component: Component
  onClose: () => void
}> = ({ trackedUrl = null, room, component, onClose }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const meUser = useSelect(state => getMeUser(state.me))
  const [url, setUrl] = useState(trackedUrl?.url ?? '')
  const [name, setName] = useState(trackedUrl?.name ?? '')
  const [notifyMe, setNotifyMe] = useState(!!trackedUrl?.notify_me_limit)
  const [notifyMeEmailAddress, setNotifyMeEmailAddress] = useState(
    (trackedUrl?.notify_me_email_address || meUser?.email) ?? '',
  )
  const [notifyMeEmailLimit, setNotifyMeEmailLimit] = useState(trackedUrl?.notify_me_limit ?? 100)

  const isLoading = useSelect(state => getLoading(state.app, 'tracked_url'))
  const shouldCloseWhenLoadingDone = useRef(false)

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleSaveClick = () => {
    if (!url) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }
    shouldCloseWhenLoadingDone.current = true
    if (trackedUrl) {
      dispatch(
        updateTrackedUrl(
          trackedUrl.id,
          name,
          notifyMe ? notifyMeEmailLimit : 0,
          notifyMe ? notifyMeEmailAddress : '',
        ),
      )
    } else {
      dispatch(
        createTrackedUrl(
          room.id,
          component.id,
          url,
          name,
          notifyMe ? notifyMeEmailLimit : 0,
          notifyMe ? notifyMeEmailAddress : '',
        ),
      )
    }
  }

  // MARK: - Render

  return (
    <ModalWrapper
      title={trackedUrl ? t('updateTrackedUrl') : t('createTrackedUrl')}
      size="small"
      onClose={onClose}>
      <FlexCol>
        <Input label="Name" isRequired value={name} onChange={text => setName(text)} />
      </FlexCol>

      <FlexCol>
        <Input
          label="Url"
          isRequired
          disabled={!!trackedUrl}
          value={url}
          onChange={text => setUrl(text)}
        />
      </FlexCol>

      <InfoSwitch
        title={t('notifyMe')}
        subtitle={t('notifyMeDescription')}
        checked={notifyMe}
        onSwitchToggle={setNotifyMe}
      />

      {notifyMe && (
        <>
          <FlexCol>
            <Input
              type="number"
              label={t('limit')}
              value={notifyMeEmailLimit.toString()}
              onChange={text => setNotifyMeEmailLimit(parseInt(text, 10))}
            />
          </FlexCol>

          <FlexCol>
            <Input
              type="email"
              label={t('emailAddress')}
              value={notifyMeEmailAddress}
              onChange={text => setNotifyMeEmailAddress(text)}
            />
          </FlexCol>
        </>
      )}

      <FooterWrapper>
        <PrimaryButton
          isLoading={isLoading}
          style={{ minWidth: 180 }}
          title={!!trackedUrl ? t('update') : t('create')}
          onClick={handleSaveClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
