import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol, LabelSmall } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import styled from 'styled-components'

export const CoverImageModalLink: React.FC<{
  onImageSelect: (image: string) => void
}> = ({ onImageSelect }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [imageLink, setImageLink] = useState('')

  // MARK: - Render

  return (
    <Container>
      <Input
        style={{ width: '100%' }}
        inputStyle={{ fontSize: 14 }}
        value={imageLink}
        onChange={setImageLink}
        onEnterPress={() => onImageSelect(imageLink)}
        placeholder={t('pasteAnImageLink')}
      />
      <PrimaryButton
        style={{ width: '50%' }}
        title={t('submit')}
        onClick={() => onImageSelect(imageLink)}
      />
      <LabelSmall style={{ marginTop: 12, fontSize: 13 }}>{t('worksWithAnyImage')}</LabelSmall>
    </Container>
  )
}

// MARK: - Style

const Container = styled(FlexCol)`
  align-items: center;
  padding: 10px;
`
