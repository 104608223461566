import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardFAQModal } from './DashboardFAQModal'
import { DashboardFAQTable } from './DashboardFAQTable'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import { deleteFAQ, fetchFAQs } from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Component, FAQ, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardFAQList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const [showFAQModal, setShowFAQModal] = useState(false)
  const [selectedFAQ, setSelectedFAQ] = useState<FAQ | null>(null)
  const [deleteCandidates, setDeleteCandidates] = useState<FAQ[]>([])

  // Selectors
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelect(state => getLoading(state.app, 'faq'))
  const faqs = useSelect(state =>
    getEntities<FAQ>(state.entity, 'faq', faq => faq.component_id === component.id).sort(
      (first, second) => second.created_at - first.created_at,
    ),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchFAQs(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleFAQDropdownItemSelect = useCallback((option: number, faq: FAQ) => {
    if (option === 0) setSelectedFAQ(faq)
    else if (option === 1) setDeleteCandidates([faq])
  }, [])

  const handleFAQBulkDropdownItemSelect = useCallback((option: number, questions: FAQ[]) => {
    if (option === 0) setDeleteCandidates(questions)
  }, [])

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <DashboardFAQTable
        faqs={faqs}
        isLoading={isLoading}
        onCreateFAQClick={() => setShowFAQModal(true)}
        handleFAQDropdownItemSelect={handleFAQDropdownItemSelect}
        handleFAQBulkDropdownItemSelect={handleFAQBulkDropdownItemSelect}
      />

      {(showFAQModal || !!selectedFAQ) && (
        <DashboardFAQModal
          faq={selectedFAQ}
          room={room}
          component={component}
          onClose={() => {
            setShowFAQModal(false)
            setSelectedFAQ(null)
          }}
        />
      )}

      <AlertModal
        error={Error.displayable(
          deleteCandidates.length > 1 ? t('deleteQuestions') : t('deleteQuestion'),
          deleteCandidates.length > 1 ? t('deleteQuestionsMessage') : t('deleteQuestionMessage'),
          success => {
            if (success && deleteCandidates.length) {
              deleteCandidates.forEach(candidate => dispatch(deleteFAQ(candidate)))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidates.length}
        onClose={() => setDeleteCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
