import { popLoading, pushError, pushLoading } from 'src/redux/reducers/app'
import { deleteEntities, mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk, Thunk } from 'src/redux/store'
import { Error } from 'src/repository/Error'
import { audienceService } from 'src/repository/services/audienceService'
import { collaboratorService } from 'src/repository/services/collaboratorService'
import { roomService } from 'src/repository/services/roomService'
import {
  AccessLevel,
  Collaborator,
  Invitation,
  Room,
  RoomConfiguration,
} from 'src/repository/types'

// MARK: - Actions

export const updateRoomConfig =
  (room: Room, config: RoomConfiguration): Thunk =>
  dispatch =>
    roomService
      .updateRoom({ room_id: room.id, config: config })
      .done(value => dispatch(mergeEntities({ room: [value.room] })))

export const fetchCollaborators =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(pushLoading('team'))

    const [collaboratorResponse, invitationResponse] = await Promise.all([
      collaboratorService.fetchCollaborators(roomId),
      collaboratorService.fetchCollaboratorInvitations(roomId),
    ])

    if (collaboratorResponse.success && invitationResponse.success) {
      const { users, collaborators } = collaboratorResponse.value
      const invitations = invitationResponse.value
      dispatch(
        mergeEntities({
          collaborator: collaborators,
          user: users,
          invitation: invitations,
        }),
      )
    } else {
      dispatch(pushError(Error.someThingWentWrong()))
    }
    dispatch(popLoading('team'))
  }

export const inviteCollaborator =
  (
    roomId: string,
    firstName: string,
    lastName: string,
    email: string,
    notes: string,
    accessLevel: AccessLevel,
    notifyMe: boolean,
    notifyMeEmail: string,
  ): Thunk =>
  dispatch =>
    collaboratorService
      .createCollaboratorInvitation({
        room_id: roomId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        notes: notes,
        collaborator_access_level: accessLevel,
        invitation_type: 'collaborator',
        notify_on_accept: notifyMe,
        notify_on_accept_email: notifyMeEmail,
      })
      .loading('team')
      .done(
        invitation => dispatch(mergeEntities({ invitation: [invitation] })),
        error => dispatch(pushError(error)),
      )

export const removeInvitation =
  (invitation: Invitation): Thunk =>
  dispatch =>
    audienceService
      .deleteInvitation(invitation.id)
      .first(() => dispatch(deleteEntities({ invitation: [invitation.id] })))
      .loading('team')
      .done(undefined, error => {
        dispatch(mergeEntities({ invitation: [invitation] }))
        dispatch(pushError(error))
      })

export const removeCollaborator =
  (roomId: string, collaborator: Collaborator): Thunk =>
  dispatch =>
    collaboratorService
      .deleteCollaborator(roomId, collaborator.id)
      .first(() => dispatch(deleteEntities({ collaborator: [collaborator.id] })))
      .loading('team')
      .done(undefined, error => {
        dispatch(mergeEntities({ collaborator: [collaborator] }))
        dispatch(pushError(error))
      })
