import { CheckIcon } from './assets/CheckIcon'
import { PricingBenefit } from './types'
import React from 'react'
import { Hint } from 'src/features/common/Hint'
import styled, { useTheme } from 'styled-components'

export const PricingOptionBenefitItem: React.FC<{
  benefit: PricingBenefit
}> = ({ benefit }) => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render
  return (
    <Container>
      <CheckIcon width={16} height={12} fill={palette.green} />
      <Title>{benefit.title}</Title>
      {benefit.hint && (
        <Hint width={200} style={{ marginTop: -2, marginLeft: 10 }} hint={benefit.hint} />
      )}
    </Container>
  )
}

// MARK: - Styled

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 18px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 15px;
  line-height: 24px;
  margin-left: 12px;
  margin-top: -7px;
`
