import React from 'react'
import { useTranslation } from 'react-i18next'
import { Separator } from 'src/features/common/Separator'
import styled from 'styled-components'

export const ThirdPartySeparator: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <OrContainer>
      <Separator />
      <OrText>{t('or')}</OrText>
      <Separator />
    </OrContainer>
  )
}

const OrContainer = styled.div`
  display: flex;
  margin-top: 32px;
`

const OrText = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
`
