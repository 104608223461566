import { FormHint } from './FormHint'
import { FormLabel } from './FormLabel'
import { Hint } from './Hint'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

type Props = {
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  label?: string
  labelHidden?: boolean
  type?:
    | 'textarea'
    | 'text'
    | 'email'
    | 'tel'
    | 'password'
    | 'number'
    | 'search'
    | 'color'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'phone'
  name?: string
  children?: React.ReactNode
  status?: 'error' | 'success' | 'default'
  disabled?: boolean
  value?: string
  formGroup?: string
  hasIcon?: string
  size?: 'default' | 'sm'
  placeholder?: string
  rows?: number
  hint?: string
  className?: string
  isRequired?: boolean
  autoFocus?: boolean
  capitalize?: 'none' | 'lowercase' | 'capitalize'
  titleHint?: string
  inverted?: boolean
  onChange?: (text: string) => void
  onEnterPress?: () => void
  onFocus?: () => void
  onBlur?: () => void
}

export const Input: React.FC<Props> = props => {
  // MARK: - Hooks

  const inputRef = useRef<HTMLInputElement | null>(null)

  // MARK: - Render

  return (
    <InputForwardRef className="form-dark mb-16" type="text" size="sm" {...props} ref={inputRef} />
  )
}

export const InputForwardRef = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      style,
      inputStyle,
      className,
      children,
      label = '',
      labelHidden = false,
      type = 'text',
      name,
      status,
      disabled = false,
      value,
      formGroup,
      hasIcon,
      size = 'sm',
      placeholder = '',
      rows = 3,
      hint,
      titleHint,
      isRequired = false,
      onChange,
      onEnterPress,
      autoFocus = false,
      inverted = false,
      capitalize = 'none',
      onFocus,
      onBlur,
      ...props
    }: Props,
    ref,
  ) => {
    const theme = useTheme()
    const [focused, setFocused] = useState(false)
    const palette = inverted ? theme.palette.inverted : theme.palette
    const wrapperClasses = classNames(
      formGroup &&
        formGroup !== '' &&
        (formGroup === 'desktop' ? 'form-group-desktop' : 'form-group'),
      hasIcon && hasIcon !== '' && 'has-icon-' + hasIcon,
    )

    const classes = classNames(
      'form-input',
      'br-8',
      size && `form-input-${size}`,
      status && `form-${status}`,
      className,
    )

    const Component = type === 'textarea' ? 'textarea' : 'input'

    return (
      <Container style={style}>
        {label && (
          <LabelContainer>
            <FormLabel labelHidden={labelHidden} isRequired={isRequired}>
              {label}
            </FormLabel>
            {titleHint && <Hint width={120} style={{ marginLeft: 8 }} hint={titleHint} />}
          </LabelContainer>
        )}
        <div className={wrapperClasses}>
          <Component
            onClick={event => event.stopPropagation()}
            ref={ref as any}
            {...props}
            type={type !== 'textarea' ? type : undefined}
            onChange={event => {
              const text = event.target.value as string
              onChange?.(text)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') onEnterPress?.()
            }}
            autoFocus={autoFocus}
            className={classes}
            name={name}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            rows={type === 'textarea' ? rows : undefined}
            style={{
              fontSize: 15,
              borderColor: focused ? palette.orange : palette.background.separator,
              backgroundColor: disabled ? palette.background.disabled : palette.background.primary,
              color: disabled ? palette.text.secondary : palette.text.primary,
              textTransform: capitalize,
              ...inputStyle,
            }}
            onFocus={() => {
              setFocused(true)
              onFocus?.()
            }}
            onBlur={() => {
              setFocused(false)
              onBlur?.()
            }}
          />
          {children}
        </div>
        {hint && status && <FormHint status={status}>{hint}</FormHint>}
      </Container>
    )
  },
)

// MARK: - Styles

const Container = styled.div`
  flex: 1;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
`
