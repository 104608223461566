import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { getCreateRoomTheme } from 'src/redux/reducers/createRoom'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const RoomDescriptionSection: React.FC<{
  title: string
  description: string
  onTitleChange: (title: string) => void
  onDescriptionChange: (description: string) => void
}> = ({ title, description, onTitleChange, onDescriptionChange }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const theme = useSelect(state => getCreateRoomTheme(state.createRoom))

  // MARK: - Render

  return (
    <Container>
      <Input
        label={t('eventName')}
        isRequired
        value={title}
        style={{ maxWidth: 607 }}
        placeholder={theme?.title}
        onChange={onTitleChange}
      />

      <Input
        type="textarea"
        label={t('description')}
        placeholder={t('eventContentDescription')}
        value={description}
        style={{ maxWidth: 607 }}
        onChange={onDescriptionChange}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  margin-bottom: 32px;
  margin-top: 15px;
`
