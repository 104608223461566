import { pushError } from 'src/redux/reducers/app'
import { mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk } from 'src/redux/store'
import { IError } from 'src/repository/Error'
import { roomService } from 'src/repository/services/roomService'
import { Room, RoomKey, RoomPrivacyType } from 'src/repository/types'

type State = {
  isLoading: boolean
  error: IError | null
  keys: RoomKey | null
}

// MARK: - State

export const initialState: State = {
  isLoading: false,
  error: null,
  keys: null,
}

// MARK: - Reducer

export const dashboardSettingsPrivacyReducer = (
  state = initialState,
  action: LoadingAction | ErrorAction | SetKeysAction | FlushAction,
): State => {
  switch (action.type) {
    case 'dashboardSettingsPrivacy/loading':
      return { ...state, isLoading: action.isLoading }

    case 'dashboardSettingsPrivacy/error':
      return { ...state, error: action.error }

    case 'dashboardSettingsPrivacy/setKeys':
      return { ...state, keys: action.keys }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchRoomKeys =
  (roomId: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await roomService.fetchRoomKeys(roomId)

    if (response.success) {
      const { room, key } = response.value
      dispatch(mergeEntities({ room: [room] }))
      dispatch(setKeys(key))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const refreshRoomKeys =
  (roomId: string, apiKey: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const response = await roomService.refreshRoomKeys(roomId, apiKey)

    if (response.success) {
      const { room, key } = response.value
      dispatch(mergeEntities({ room: [room] }))
      dispatch(setKeys(key))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const updateRoomPrivacyType =
  (room: Room, privacyType: RoomPrivacyType, accessCode?: string): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    roomService
      .updateRoom({
        room_id: room.id,
        privacy_type: privacyType,
        access_codes: accessCode ? [accessCode] : [],
      })
      .done(
        value => dispatch(mergeEntities({ room: [value.room], user: value.publishers })),
        error => dispatch(pushError(error)),
      )

    dispatch(setLoading(false))
  }

export const setError = (error: IError | null): ErrorAction => ({
  type: 'dashboardSettingsPrivacy/error',
  error: error,
})

export const flush = (): FlushAction => ({
  type: 'me/logout',
})

// MARK: - Selectors

export const getRoomKeys = (state: State) => {
  return state.keys
}

export const getIsLoading = (state: State): boolean => {
  return state.isLoading
}

export const getError = (state: State): IError | null => {
  return state.error
}

// MARK: - Action Types

type LoadingAction = {
  type: 'dashboardSettingsPrivacy/loading'
  isLoading: boolean
}

type ErrorAction = {
  type: 'dashboardSettingsPrivacy/error'
  error: IError | null
}

type SetKeysAction = {
  type: 'dashboardSettingsPrivacy/setKeys'
  keys: RoomKey
}

type FlushAction = {
  type: 'me/logout'
}

// MARK: - Internal Actions

const setLoading = (isLoading: boolean): LoadingAction => ({
  type: 'dashboardSettingsPrivacy/loading',
  isLoading: isLoading,
})

const setKeys = (keys: RoomKey): SetKeysAction => ({
  type: 'dashboardSettingsPrivacy/setKeys',
  keys: keys,
})
