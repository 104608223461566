import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, LabelLarge, LabelNormal, Row } from 'src/features/common/CommonStyles'
import { HoverBox, LabelMedium } from 'src/features/common/CommonStyles'
import { PurchaseUsageTier } from 'src/repository/types'
import styled, { keyframes } from 'styled-components'

export const PurchaseUsageTierSelectionRow: React.FC<{
  title: string
  style?: React.CSSProperties
  subTitle?: string
  tiers: PurchaseUsageTier[]
  selectedTier: PurchaseUsageTier | null
  overused?: boolean
  onTierClick: (tier: PurchaseUsageTier) => void
}> = ({ title, style, subTitle, tiers, selectedTier, overused = false, onTierClick }) => (
  <Container style={style}>
    <Row style={{ alignItems: 'center', marginBottom: 8 }}>
      <img
        src={
          'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F2779857?alt=media&token=fce1cf3b-cbaa-4bf1-8154-a3e34abb80e7'
        }
        alt=""
        style={{ height: 44, width: 44, marginLeft: 1, marginRight: 12 }}
      />

      <TitleContainer>
        <LabelLarge>{title}</LabelLarge>
        {subTitle ? <SubTitle overused={overused}>{subTitle}</SubTitle> : null}
      </TitleContainer>
    </Row>

    <GridContainer>
      {tiers.map(usageTier => (
        <PurchaseUsageTierSelectionItem
          key={usageTier.id}
          selected={usageTier.id === selectedTier?.id}
          usageTier={usageTier}
          onUsageTierClick={onTierClick}
        />
      ))}
    </GridContainer>
  </Container>
)

const PurchaseUsageTierSelectionItem: React.FC<{
  selected: boolean
  usageTier: PurchaseUsageTier
  onUsageTierClick: (usageTier: PurchaseUsageTier) => void
}> = ({ selected, usageTier, onUsageTierClick }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <ItemContainer selected={selected} onClick={() => onUsageTierClick(usageTier)}>
      <ItemTitle selected={selected}>
        {usageTier.usage_limit !== 'unlimited'
          ? t('tierCount', { count: usageTier.usage_limit })
          : t('unlimited')}
      </ItemTitle>
    </ItemContainer>
  )
}

// MARK: - Styles

const Container = styled(Col)`
  justify-content: space-between;
  margin-bottom: 8px;
`

const TitleContainer = styled(Col)`
  justify-content: center;
  min-height: 60px;
`

const SubTitle = styled(LabelNormal)<{ overused: boolean }>`
  color: ${({ theme, overused }) =>
    overused ? theme.palette.orange : theme.palette.text.secondary};
  font-size: 14px;
`

const GridContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: -8px;
`

const ShimmerKeyframe = keyframes`
  0% { background-position: -200px top; }
  70% { background-position: 200px top; }
  100% { background-position: 200px top; }
`

const ItemContainer = styled(HoverBox)<{ selected: boolean }>`
  animation-duration: 2.2s;
  animation-iteration-count: 1;
  animation-name: ${({ selected }) => (selected ? ShimmerKeyframe : '')};
  background: #f36538 -webkit-gradient(linear, 100% 0, 0 0, from(#f36538), color-stop(0.5, rgba(255, 255, 255, 0.2)), to(#f36538));
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.orange : theme.palette.background.primary};
  background-position: -200px;
  background-repeat: no-repeat;
  background-size: ${({ selected }) => (selected ? '80% 100%' : '0px 0px')};
  display: flex;
  height: 40px;
  justify-content: center;
  margin-left: 8px;
`

const ItemTitle = styled(LabelMedium)<{ selected: boolean }>`
  color: ${({ theme, selected }) => (selected ? theme.palette.white : theme.palette.text.primary)};
  font-size: 16px;
  position: absolute;
  text-align: center;
  z-index: 3;
`
