import React from 'react'
import styled from 'styled-components'

export const NotFound: React.FC = () => (
  <Container>
    <Title>Page not found</Title>
  </Container>
)

// MARK: - Styles

const Container = styled.div`
  flex: 1;
  height: calc(100vh - 260px);
  margin-top: 80px;
  padding: 20px;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.palette.text.primary};
`
