import { DashboardSpreadGameScoreTableRow } from './DashboardSpreadGameScoreTableRow'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { DashboardTableContainer, DashboardTable } from 'src/features/common/DashboardTable'
import { DashboardTableHeader } from 'src/features/common/DashboardTableHeader'
import { getEntity } from 'src/redux/reducers/entity'
import store from 'src/redux/store'
import { SpreadGameScore, User } from 'src/repository/types'

export const DashboardSpreadGameScoreTable: React.FC<{
  spreadGameScores: SpreadGameScore[]
  blackList: string[]
  isLoading: boolean
  onReloadClick: () => void
  handleSpreadGameScoreDropdownItemSelect: (index: number, spreadGameScore: SpreadGameScore) => void
  handleSpreadGameScoreBulkDropdownItemSelect: (
    index: number,
    spreadGameScores: SpreadGameScore[],
  ) => void
}> = ({
  spreadGameScores,
  blackList,
  isLoading,
  onReloadClick,
  handleSpreadGameScoreDropdownItemSelect,
  handleSpreadGameScoreBulkDropdownItemSelect,
}) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [selectedSpreadGameScoreIds, setSelectedSpreadGameScoreIds] = useState<string[]>([])
  const [status, setStatus] = useState<string>('all')

  // MARK: - Properties

  const currentSpreadGameScores = spreadGameScores
    .filter(spreadGameScore => {
      const user = getEntity<User>(store.getState().entity, 'user', spreadGameScore.user_id)
      return user?.user_name.toLowerCase().includes(searchText.toLowerCase()) ?? false
    })
    .filter(score => {
      return (
        status === 'all' ||
        (status === 'banned' && blackList.includes(score.user_id)) ||
        (status === 'active' && !blackList.includes(score.user_id))
      )
    })

  // MARK: - Effects

  useEffect(() => {
    setSelectedSpreadGameScoreIds(prev => {
      const spreadGameScoreIds = spreadGameScores.map(item => item.id)
      return prev.filter(id => spreadGameScoreIds.includes(id))
    })
  }, [spreadGameScores])

  // MARK: - Handlers

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleSpreadGameScoreBulkDropdownItemSelect(
      option,
      spreadGameScores.filter(spreadGameScore =>
        selectedSpreadGameScoreIds.includes(spreadGameScore.id),
      ),
    )
  }

  // MARK: - Render

  return (
    <DashboardTableContainer>
      <DashboardTableHeader
        primaryActionTitle={t('refresh')}
        onPrimaryActionClick={onReloadClick}
        searchText={searchText}
        setSearchText={setSearchText}
        status={status}
        setStatus={setStatus}
        statusOptions={[
          { key: 'active', label: t('active') },
          { key: 'banned', label: t('banned') },
          { key: 'all', label: t('all') },
        ]}
        columns={[
          { label: t('overview'), width: 250 },
          { label: t('score'), width: 165 },
        ]}
        dropdownOptions={[{ title: t('ban'), icon: DeleteIcon }]}
        handleBulkDropdownOptionSelect={handleOptionsDropdownOptionClick}
        totalCount={currentSpreadGameScores.length}
        selectedCount={selectedSpreadGameScoreIds.length}
        toggleAllSelected={isOn =>
          setSelectedSpreadGameScoreIds(isOn ? currentSpreadGameScores.map(({ id }) => id) : [])
        }
      />

      <DashboardTable
        data={currentSpreadGameScores}
        isLoading={isLoading}
        emptyStateTitle={t('noAudienceParticipated')}
        emptyStateSubtitle={t('noAudienceParticipatedDescription')}
        renderItem={spreadGameScore => (
          <DashboardSpreadGameScoreTableRow
            key={spreadGameScore.id}
            selected={selectedSpreadGameScoreIds.includes(spreadGameScore.id)}
            isBlackListed={blackList.includes(spreadGameScore.user_id)}
            onCheckBoxClick={isOn => {
              setSelectedSpreadGameScoreIds(prev =>
                isOn
                  ? [...prev, spreadGameScore.id]
                  : prev.filter(item => spreadGameScore.id !== item),
              )
            }}
            spreadGameScore={spreadGameScore}
            onSpreadGameScoreDropdownItemSelect={option =>
              handleSpreadGameScoreDropdownItemSelect(option, spreadGameScore)
            }
          />
        )}
      />
    </DashboardTableContainer>
  )
}
