import { Venue } from './Venue'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { LocationIcon } from 'src/assets/images/svg/LocationIcon'
import { LabelMedium, ShadowBox } from 'src/features/common/CommonStyles'
import { Loader } from 'src/features/common/Loader'
import { HoverAnimation } from 'src/utils/theme/hoverAnimation'
import styled, { useTheme } from 'styled-components'

export const VenueSearchField: React.FC<{
  style?: React.CSSProperties
  onVenueSelected: (location: Venue | null) => void
}> = ({ style, onVenueSelected }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const [address, setAddress] = useState('')
  const [focused, setFocused] = useState(false)

  // MARK: - Handlers

  const handleSelect = async (selectedAddress: string) => {
    setAddress(selectedAddress)
    try {
      const result = (await geocodeByAddress(selectedAddress))[0]
      const geoLocation = await getLatLng(result)

      const getField = (type: string): string | undefined => {
        return result.address_components.find(component => component.types.includes(type))
          ?.long_name
      }

      const venue: Venue = {
        title: selectedAddress.split(',')[0],
        address: result.formatted_address,
        province: getField('administrative_area_level_2') ?? '',
        city: getField('administrative_area_level_1') ?? '',
        country: getField('country') ?? '',
        zip_code: parseInt(getField('postal_code') ?? '0', 10),
        latitude: geoLocation.lat,
        longitude: geoLocation.lng,
      }
      onVenueSelected(venue)
    } catch (error) {
      console.error('Error', error)
    }
  }

  // MARK: - Render

  const classes = classNames('form-input', 'br-8')

  return (
    <Container style={style}>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input
              className={classes}
              filled={address.length > 0}
              style={{
                fontSize: 15,
                paddingLeft: 8,
                borderColor: focused ? palette.orange : palette.background.separator,
                backgroundColor: palette.background.primary,
                color: palette.text.primary,
              }}
              {...getInputProps({
                placeholder: t('searchVenue'),
              })}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />

            {loading && (
              <LoaderContainer>
                <Loader size={22} thickness={3} />
              </LoaderContainer>
            )}

            <AutoCompleteDropdownContainer>
              {suggestions.map(suggestion => (
                <DropdownElement {...getSuggestionItemProps(suggestion)} key={suggestion.index}>
                  <LocationIcon width={14} height={14} fill={palette.text.secondary} />
                  <SuggestionText>{suggestion.description}</SuggestionText>
                </DropdownElement>
              ))}
            </AutoCompleteDropdownContainer>
          </>
        )}
      </PlacesAutocomplete>
      <HoverAnimation />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 12px;
`

const AutoCompleteDropdownContainer = styled(ShadowBox)`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.palette.background.secondary : theme.palette.background.tertiary};
  border-radius: 8px;
  border-width: 0px;
  margin-top: 10px;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 10;
`

const Input = styled.input<{ filled: boolean }>`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 0px 8px;
  width: 100%;
`

const DropdownElement = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 8px 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.disabled};
  }
`

const SuggestionText = styled(LabelMedium)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 16px;
  margin-left: 6px;
  max-width: calc(100% - 30px);
`
