import { api } from '../api'
import {
  Collaborator,
  Link,
  Room,
  RoomConfiguration,
  RoomKey,
  RoomLocation,
  RoomPrivacyType,
  User,
} from '../types'

export const roomService = {
  fetchRoom: async (roomId: string, accessCode?: string) =>
    api.value<{ room: Room; publishers: User[] }>({
      request: {
        method: 'GET',
        endPoint: '/room',
        parameters: { id: roomId, access_code: accessCode },
      },
    }),

  fetchCollaboratedRooms: async (
    publisherId: string,
    ignoreStatus: boolean,
    lastUpdatedAt: number,
  ) =>
    api.value<{ rooms: Room[]; collaborators: Collaborator[] }>({
      request: {
        method: 'GET',
        endPoint: '/rooms/collaborated',
        parameters: {
          publisher_id: publisherId,
          last_updated_at: lastUpdatedAt,
          ignore_status: ignoreStatus,
        },
      },
    }),

  createRoom: async (request: RoomRequest) =>
    api.value<{ room: Room; publishers: User[] }>({
      request: {
        method: 'POST',
        endPoint: '/room',
        parameters: { ...request },
      },
    }),

  updateRoom: async (request: Partial<RoomRequest> & { room_id: string }) =>
    api.value<{ room: Room; publishers: User[] }>({
      request: {
        method: 'PUT',
        endPoint: '/room',
        parameters: { ...request },
      },
    }),

  deleteRoom: async (roomId: string) =>
    api.prop<{ room: Room }>('room', {
      request: {
        method: 'DELETE',
        endPoint: '/room',
        parameters: { room_id: roomId },
      },
    }),

  fetchRoomKeys: async (roomId: string) =>
    api.value<{ room: Room; key: RoomKey }>({
      request: {
        method: 'GET',
        endPoint: '/room/secret/keys',
        parameters: { room_id: roomId },
      },
    }),

  generateRoomKeys: async () =>
    api.prop<{ key: RoomKey }>('key', {
      request: {
        method: 'POST',
        endPoint: '/room/secret/keys',
        parameters: {},
      },
    }),

  refreshRoomKeys: async (apiKey: string, roomId: string) =>
    api.value<{ room: Room; key: RoomKey }>({
      request: {
        method: 'POST',
        endPoint: '/room/secret/keys',
        parameters: { api_key: apiKey, room_id: roomId },
      },
    }),
}

export type RoomRequest = {
  title: string
  overview: string
  date: { start: number; end?: number; timezone: string }
  privacy_type: RoomPrivacyType
  access_codes: string[]
  tags: string[]
  links: Link[]
  media: { url: string; offset: number }
  config: RoomConfiguration
  location: RoomLocation
  api_key: string | null
  api_secret: string | null
}
