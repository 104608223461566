import { pushError } from './app'
import { t } from 'i18next'
import produce from 'immer'
import { uniqBy } from 'lodash'
import { uploadBase64PngToFirebaseStorage } from 'src/frameworks/firebase/imageUploader'
import { Action, Thunk } from 'src/redux/store'
import { Error } from 'src/repository/Error'
import { iconService } from 'src/repository/services/iconService'
import { FlatIcon, FlatIconColor, FlatIconPack, FlatIconShape } from 'src/repository/types'

type State = {
  icons: FlatIcon[]
  packs: FlatIconPack[]
  iconPackMap: Record<number, FlatIcon[]>
  downloadedIconUrl: string | null
  selectedPack: FlatIconPack | null
}

type ActionType =
  | { type: 'iconPicker/setIcons'; icons: FlatIcon[] }
  | { type: 'iconPicker/setIconPacks'; packs: FlatIconPack[] }
  | { type: 'iconPicker/setIconPackMap'; packId: number; icons: FlatIcon[] }
  | { type: 'iconPicker/setUrl'; url: string }
  | Action<'iconPicker/flush'>

// MARK: - State

export const initialState: State = {
  icons: [],
  packs: [],
  iconPackMap: {},
  downloadedIconUrl: null,
  selectedPack: null,
}

// MARK: - Reducer

export const iconPickerReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'iconPicker/setIcons':
      return produce(state, draft => {
        draft.icons = action.icons
        return draft
      })

    case 'iconPicker/setIconPackMap':
      return produce(state, draft => {
        draft.iconPackMap[action.packId] = action.icons
        return draft
      })

    case 'iconPicker/setUrl':
      return produce(state, draft => {
        draft.downloadedIconUrl = action.url
        return draft
      })

    case 'iconPicker/setIconPacks':
      return produce(state, draft => {
        draft.packs = action.packs
        return draft
      })

    case 'iconPicker/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchIcons =
  (
    query: string,
    color?: FlatIconColor,
    shape?: FlatIconShape,
    packId?: number,
  ): Thunk<ActionType> =>
  dispatch => {
    const iconPageSize = 100

    iconService
      .fetchIcons(query, 0, iconPageSize, color, shape, packId)
      .loading(packId ? 'icon_picker_pack' : 'icon_picker_icon')
      .done(
        icons => {
          if (packId) dispatch({ type: 'iconPicker/setIconPackMap', packId, icons })
          else dispatch({ type: 'iconPicker/setIcons', icons })
        },
        error => dispatch(pushError(error)),
      )
  }

export const fetchNextIcons =
  (
    query: string,
    offset: number,
    limit: number,
    color?: FlatIconColor,
    shape?: FlatIconShape,
    packId?: number,
  ): Thunk<ActionType> =>
  (dispatch, getState) => {
    const iconPageSize = 100

    iconService
      .fetchIcons(query, offset, iconPageSize, color, shape, packId)
      .loading(packId ? 'icon_picker_pack_next' : 'icon_picker_icon_next')
      .done(
        icons => {
          const existingIcons = getIcons(getState().utils.iconPicker)
          icons = existingIcons.concat(icons)
          icons = uniqBy(icons, 'id')
          if (packId) dispatch({ type: 'iconPicker/setIconPackMap', packId, icons })
          else dispatch({ type: 'iconPicker/setIcons', icons })
        },
        error => dispatch(pushError(error)),
      )
  }

export const fetchIconPacks =
  (
    query: string,
    color?: FlatIconColor,
    shape?: FlatIconShape,
    page: number = 1,
    limit: number = 100,
  ): Thunk<ActionType> =>
  dispatch =>
    iconService
      .fetchIconPacks(query, page, limit, color, shape)
      .loading('icon_picker_pack')
      .done(
        packs => dispatch({ type: 'iconPicker/setIconPacks', packs }),
        error => dispatch(pushError(error)),
      )

export const downloadIcon =
  (iconId: number): Thunk<ActionType> =>
  dispatch =>
    iconService
      .downloadIcon(iconId)
      .loading('icon_picker_download', () => true)
      .done(async value => {
        const { base64, download_url: url, icon_id, should_upload } = value

        // Upload icon to firebase storage if needed.
        // FlatIcons provides urls with expiring tokens, so we need to store icons in own CDN.
        if (should_upload && base64) {
          try {
            const firebaseUrl = await uploadBase64PngToFirebaseStorage(base64, `icons/${icon_id}`)
            iconService
              .uploadIcon(icon_id, firebaseUrl)
              .loading('icon_picker_download')
              .done(
                ({ download_url: uri }) =>
                  dispatch(dispatch({ type: 'iconPicker/setUrl', url: uri })),
                error => dispatch(pushError(error)),
              )
          } catch (error) {
            dispatch(pushError(Error.message(t('uploadErrorMessage'))))
          }
        } else if (url) dispatch(dispatch({ type: 'iconPicker/setUrl', url }))
        else dispatch(pushError(Error.message('Unknown state occurred when downloading icon')))
      })

export const flush = (): ActionType => ({
  type: 'iconPicker/flush',
})

// MARK: - Selectors

export const getIcons = (state: State): FlatIcon[] => {
  return state.icons
}

export const getIconPacks = (state: State): FlatIconPack[] => {
  return state.packs
}

export const getIconByPack = (state: State, packId: number): FlatIcon[] => {
  return state.iconPackMap[packId] ?? []
}

export const getDownloadedIconUrl = (state: State): string | null => {
  return state.downloadedIconUrl
}
