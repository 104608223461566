import React from 'react'
import { LogoIcon } from 'src/assets/images/svg/LogoIcon'
import { Col, LabelHeader } from 'src/features/common/CommonStyles'
import { device } from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const AuthenticationHeader: React.FC<{
  title: string
}> = ({ title }) => (
  <Container>
    <LogoIcon size={60} />
    <Header>{title}</Header>
  </Container>
)

// MARK: - Styles

const Container = styled(Col)`
  align-items: center;
  padding: 64px 0px 32px 0px;
`

const Header = styled(LabelHeader)`
  font-size: 26px;
  margin-top: 24px;

  @media ${device.smallerThan.laptop} {
    font-size: 20px;
  }
`
