import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 9C9 4.58172 12.5817 1 17 1V1C21.4183 1 25 4.58172 25 9V14.0909C25 14.9375 25 15.3608 24.8739 15.6989C24.6712 16.2425 24.2425 16.6712 23.6989 16.8739C23.3608 17 22.9375 17 22.0909 17H17C12.5817 17 9 13.4183 9 9V9Z"
      stroke={fill}
    />
    <path d="M14 8L20 8" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 12H20" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.5 17V17C16.5 18.1046 15.6056 19 14.501 19C10.3888 19 5.62428 19 2.99749 19C2.05635 19 1.58579 19 1.29289 18.7071C1 18.4142 1 17.9428 1 17V11C1 7.22876 1 5.34315 2.17157 4.17157C3.34315 3 5.22876 3 9 3H11.5"
      stroke={fill}
    />
    <path d="M6 9L9 9" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 13H9" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const QAIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
