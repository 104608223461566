import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CopyIcon } from 'src/assets/images/svg/CopyIcon'
import { FlexCol, FlexRow, LabelMedium } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { Separator } from 'src/features/common/Separator'
import styled from 'styled-components'

export const AccountKeys: React.FC<{
  apiKey?: string
  apiSecret?: string
  onAPIRefreshSecret: () => void
}> = ({ apiKey, apiSecret, onAPIRefreshSecret }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Handlers

  const handleAPIKeyCopyClick = useCallback(() => {
    if (!apiKey) return
    navigator.clipboard.writeText(apiKey)
    toast.success(t('copiedToClipboard'))
  }, [apiKey])

  const handleAPISecretCopyClick = useCallback(() => {
    if (!apiSecret) return
    navigator.clipboard.writeText(apiSecret)
    toast.success(t('copiedToClipboard'))
  }, [apiSecret])

  // MARK: - Render

  return (
    <FlexCol>
      <LabelMedium style={{ fontSize: 13, fontWeight: 600, marginBottom: -14 }}>
        {t('accountKeys')}
      </LabelMedium>

      <Separator />

      <FlexRow>
        <Input style={{ position: 'relative', marginRight: 24 }} label={t('apiKey')} value={apiKey}>
          <CopyButton onClick={handleAPIKeyCopyClick}>
            <CopyIcon />
          </CopyButton>
        </Input>

        <Input
          style={{ position: 'relative' }}
          type="password"
          label={t('apiSecret')}
          value={apiSecret}>
          <CopyButton onClick={handleAPISecretCopyClick}>
            <CopyIcon />
          </CopyButton>
        </Input>
      </FlexRow>
    </FlexCol>
  )
}

// MARK: - Styles

const CopyButton = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 32px;
`
