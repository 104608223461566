import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M8 10L8 16" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 12V16" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 8V16" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <rect x="3" y="4" width="18" height="16" rx="2" stroke={fill} />
  </svg>
)

export const PollingIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
