import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DeleteIcon } from 'src/assets/images/svg/DeleteIcon'
import { Row } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { SecondaryButton } from 'src/features/common/SecondaryButton'
import { InfoSwitch } from 'src/features/platform/common/InfoSwitch'
import { getLoading, pushError } from 'src/redux/reducers/app'
import { createPoll } from 'src/redux/reducers/component'
import { Error } from 'src/repository/Error'
import { Component } from 'src/repository/types'
import { FooterWrapper } from 'src/utils/hocs/FooterWrapper'
import { ModalWrapper } from 'src/utils/hocs/ModalWrapper'
import { useSelect } from 'src/utils/hooks/useSelect'

export const DashboardPollModal: React.FC<{
  component: Component
  onClose: () => void
}> = ({ component, onClose }) => {
  // MARK: - Hook

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'poll'))
  const shouldCloseWhenLoadingDone = useRef(false)

  const [activated, setActivated] = useState(false)
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState(['', ''])

  // MARK: - Effects

  useEffect(() => {
    if (shouldCloseWhenLoadingDone.current && !isLoading) {
      shouldCloseWhenLoadingDone.current = false
      onClose()
    }
  }, [isLoading, onClose])

  // MARK: - Handlers

  const handleCreatePollClick = () => {
    if (!question || options.filter(option => option).length < 2) {
      dispatch(pushError(Error.displayable(t('invalidInput'), t('invalidInputDescription'))))
      return
    }

    shouldCloseWhenLoadingDone.current = true
    dispatch(
      createPoll(
        component.id,
        question,
        options.filter(option => option),
        activated,
      ),
    )
  }

  // MARK: - Render

  return (
    <ModalWrapper title={t('createPoll')} size="tiny" onClose={onClose}>
      <Input
        inputStyle={{ minHeight: 132 }}
        type="textarea"
        isRequired
        label={t('question')}
        value={question}
        onChange={setQuestion}
      />

      {options.map((option, index) => (
        <Row>
          <Input
            label={t('option') + ' ' + (index + 1)}
            isRequired={options.length <= 2}
            value={option}
            onChange={text =>
              setOptions(prev => {
                const copy = [...prev]
                copy[index] = text
                return copy
              })
            }
          />
          {options.length > 2 && (
            <DeleteIcon
              onClick={() =>
                setOptions(prev => {
                  const copy = [...prev]
                  copy.splice(index, 1)
                  return copy
                })
              }
              style={{ margin: 12, cursor: 'pointer' }}
            />
          )}
        </Row>
      ))}

      <SecondaryButton
        onClick={() => setOptions(prev => [...prev, ''])}
        title={t('addOption')}
        isLoading={isLoading}
        style={{ width: 180, marginTop: 4, marginBottom: 8 }}
      />

      <InfoSwitch
        style={{ marginTop: 16 }}
        title={t('activateImmediately')}
        subtitle={t('activateImmediatelyDescription')}
        checked={activated}
        onSwitchToggle={setActivated}
      />

      <FooterWrapper>
        <PrimaryButton
          style={{ minWidth: 120 }}
          title={t('create')}
          isLoading={isLoading}
          onClick={handleCreatePollClick}
        />
      </FooterWrapper>
    </ModalWrapper>
  )
}
