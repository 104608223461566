import i18n from '../localization'
import { Localization } from '../types/i18n'
import { SVG } from '../types/svg'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { FAQIcon } from 'src/assets/images/svg/FAQIcon'
import { ListIcon } from 'src/assets/images/svg/ListIcon'
import { OverviewIcon } from 'src/assets/images/svg/OverviewIcon'
import { PollingIcon } from 'src/assets/images/svg/PollingIcon'
import { QAIcon } from 'src/assets/images/svg/QAIcon'
import { SpreadGameIcon } from 'src/assets/images/svg/SpreadGameIcon'
import { WebPageIcon } from 'src/assets/images/svg/WebPageIcon'
import { ComponentType } from 'src/repository/types'

export const componentImage = (componentType: ComponentType): SVG => {
  switch (componentType) {
    case ComponentType.announcements:
      return AnnouncementIcon
    case ComponentType.webPage:
      return WebPageIcon
    case ComponentType.spreadGame:
      return SpreadGameIcon
    case ComponentType.webList:
      return ListIcon
    case ComponentType.faq:
      return FAQIcon
    case ComponentType.qa:
      return QAIcon
    case ComponentType.polling:
      return PollingIcon
    default:
      return OverviewIcon
  }
}

export const componentImageUrl = (componentType: ComponentType): string => {
  switch (componentType) {
    case ComponentType.announcements:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1292779?alt=media&token=4e54bd82-8fa4-43d8-9070-55f9aa77ccfb'
    case ComponentType.webPage:
      return 'https://cdn-icons-png.flaticon.com/256/3867/3867462.png'
    case ComponentType.spreadGame:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F6023910?alt=media&token=a0cc31a3-668d-43e0-bd76-2812bbe513f3'
    case ComponentType.webList:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1292734?alt=media&token=599a1891-b4b1-4cb7-a033-5f92190f8530'
    case ComponentType.faq:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F4403299?alt=media&token=886e45cd-71a3-4ecd-9985-4967c74a06c4'
    case ComponentType.qa:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F2190594?alt=media&token=1b8a71ee-b4d1-4708-978a-331e877a0e2c'
    case ComponentType.polling:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F5930372?alt=media&token=d6221829-a1fb-447e-8f71-0540dba6f71b'
    default:
      return 'https://cdn-icons-png.flaticon.com/256/1156/1156949.png'
  }
}

export const componentInstanceImageUrl = (componentType: ComponentType): string => {
  switch (componentType) {
    case ComponentType.announcements:
      return 'https://cdn-icons-png.flaticon.com/256/1156/1156949.png'
    case ComponentType.webPage:
      return 'https://cdn-icons-png.flaticon.com/256/3867/3867462.png'
    case ComponentType.spreadGame:
      return 'https://cdn-icons-png.flaticon.com/256/3629/3629514.png'
    case ComponentType.webList:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F1084320?alt=media&token=aad2aedf-7c94-4b18-8904-9fb4043211c2'
    case ComponentType.faq:
      return 'https://firebasestorage.googleapis.com/v0/b/oliver-development-585f8.appspot.com/o/icons%2F2608208?alt=media&token=cff3310b-686b-4f5f-81c1-905adf663fb2'
    case ComponentType.qa:
      return 'https://cdn-icons-png.flaticon.com/256/4509/4509758.png'
    case ComponentType.polling:
      return 'https://cdn-icons-png.flaticon.com/256/1533/1533932.png'
    default:
      return 'https://cdn-icons-png.flaticon.com/256/1156/1156949.png'
  }
}

export const componentIsPremium = (componentType: ComponentType) => {
  switch (componentType) {
    case ComponentType.webPage:
    case ComponentType.announcements:
    case ComponentType.webList:
    case ComponentType.faq:
      return false
    case ComponentType.spreadGame:
    case ComponentType.qa:
    case ComponentType.polling:
      return true
    default:
      return false
  }
}

export const componentName = (componentType: ComponentType, lng: Localization): string => {
  switch (componentType) {
    case ComponentType.announcements:
      return i18n.t('announcements', { lng })
    case ComponentType.webPage:
      return i18n.t('webPage', { lng })
    case ComponentType.spreadGame:
      return i18n.t('spreadGame', { lng })
    case ComponentType.webList:
      return i18n.t('webList', { lng })
    case ComponentType.qa:
      return i18n.t('qa', { lng })
    case ComponentType.faq:
      return i18n.t('faqShort', { lng })
    case ComponentType.polling:
      return i18n.t('polling', { lng })
    default:
      return ''
  }
}

export const componentDescription = (componentType: ComponentType, lng: Localization): string => {
  switch (componentType) {
    case ComponentType.announcements:
      return i18n.t('announcementDescription', { lng })
    case ComponentType.webPage:
      return i18n.t('webPageDescription', { lng })
    case ComponentType.spreadGame:
      return i18n.t('spreadGameDescription', { lng })
    case ComponentType.webList:
      return i18n.t('webListDescription', { lng })
    case ComponentType.qa:
      return i18n.t('qaDescription', { lng })
    case ComponentType.faq:
      return i18n.t('faqDescription', { lng })
    case ComponentType.polling:
      return i18n.t('pollingDescription', { lng })
    default:
      return ''
  }
}
