import { DashboardSidebarRoomSelector } from '../dashboard/DashboardSideBar/DashboardSidebarRoomSelector'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DownArrowIcon } from 'src/assets/images/svg/DownArrowIcon'
import { EditIcon } from 'src/assets/images/svg/EditIcon'
import { HamburgerIcon } from 'src/assets/images/svg/HamburgerIcon'
import { LeaveIcon } from 'src/assets/images/svg/LeaveIcon'
import { LogoTitleIcon } from 'src/assets/images/svg/LogoTitleIcon'
import { Col, LabelMedium, Row } from 'src/features/common/CommonStyles'
import { DropdownWrapper } from 'src/features/common/DropdownWrapper'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { UserImage } from 'src/features/common/UserImage'
import { logout } from 'src/redux/reducers/account'
import { getMeUser } from 'src/redux/reducers/me'
import { getDrawerCollapsed, setDrawerCollapsed } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'
import { numberOfLines } from 'src/utils/helpers/textHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { css, useTheme } from 'styled-components'

export const CreatorHeader: React.FC<{
  room: Room | null
  primaryAction: 'create' | 'purchase'
  onCreateClick: () => void
  onAccountClick: () => void
}> = ({ room, primaryAction, onCreateClick, onAccountClick }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { palette } = useTheme()
  const { routes, navigate } = useNavigation()
  const { isMobile } = useWindowDimensions()

  const drawerCollapsed = useSelect(state => getDrawerCollapsed(state.room))
  const meUser = useSelect(state => getMeUser(state.me))

  const [showRoomSelector, setShowRoomSelector] = useState(false)

  const dropdownOptions = [
    { title: t('editAccount'), icon: EditIcon },
    { title: t('logout'), icon: LeaveIcon },
  ]

  // MARK: - Handlers

  const handleRoomSelectorToggle = useCallback(
    (isOn: boolean) => () => {
      setShowRoomSelector(isOn)
    },
    [room],
  )

  const handleOptionsDropdownOptionClick = useCallback(
    (option: number) => {
      if (!meUser) return
      if (option === 0) onAccountClick()
      if (option === 1) dispatch(logout())
    },
    [meUser],
  )

  // MARK: - Render

  return (
    <Container>
      {isMobile && room ? (
        <Title>
          <Col
            style={{ padding: 8, paddingRight: 8 }}
            onClick={() => dispatch(setDrawerCollapsed(!drawerCollapsed))}>
            <HamburgerIcon size={28} />
          </Col>

          <Row onClick={handleRoomSelectorToggle(!showRoomSelector)}>
            <LabelMedium
              style={{
                fontSize: 16,
                fontWeight: '600',
                ...numberOfLines(1),
                maxWidth: 180,
                zIndex: 11,
              }}>
              {room.title}
            </LabelMedium>

            <DownArrowIcon
              size={8}
              fill={palette.text.primary}
              style={{ marginRight: -2, marginLeft: 3, minWidth: 10, width: 24, height: 20 }}
            />
          </Row>

          {showRoomSelector && (
            <DashboardSidebarRoomSelector
              style={{ left: 8, top: 50, width: 320, zIndex: 12 }}
              selectedRoomId={room.id}
              onClose={handleRoomSelectorToggle(false)}
            />
          )}
        </Title>
      ) : (
        <Row style={{ cursor: 'pointer' }} onClick={() => navigate(routes.roomList)}>
          <LogoTitleIcon size={64} fill={palette.text.primary} />
        </Row>
      )}
      <Row style={{ alignItems: 'center' }}>
        {!isMobile && (
          <PrimaryButton title={t('createProject')} size={'sm'} onClick={onCreateClick} />
        )}

        <DropdownWrapper
          style={{ width: 120, marginLeft: 16 }}
          disabled={!meUser}
          icon={
            <AccountContainer>
              <HamburgerIcon style={{ marginLeft: 6, marginRight: 12 }} />
              <UserImage size={38} url={meUser?.image_url} />
            </AccountContainer>
          }
          position={{ right: 12, top: 56 }}
          options={dropdownOptions}
          handleOptionClick={handleOptionsDropdownOptionClick}
        />
      </Row>
    </Container>
  )
}

// MARK: - Styles

const AccountContainer = styled(Row)`
  border-radius: 32px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 4px;
  ${() =>
    css`
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      }
    `};
`

const Container = styled(Row)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.primary};
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.01);
  height: 72px;
  justify-content: space-between;
  padding: 0px 16px 0px 16px;
  position: sticky;
  z-index: 11;
`

const Title = styled(Row)`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-left: -10px;
  margin-top: 8px;
  padding: 6px 0px;
`
