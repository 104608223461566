import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Resizer from 'react-image-file-resizer'
import { useDispatch } from 'react-redux'
import { uploadBlobToFirebaseStorage } from 'src/frameworks/firebase/imageUploader'
import { popLoading, pushLoading } from 'src/redux/reducers/app'
import { v4 } from 'uuid'

export const IconImagePicker: React.FC<{
  style?: React.CSSProperties
  size?: number
  icon?: string
  onIconChange: (url: string) => void
}> = ({ style, size = 200, onIconChange, children }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const [blob, setBlob] = useState<Blob | null>(null)

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if (acceptedFiles[0]) setBlob(acceptedFiles[0])
    },
  })

  // MARK: - Effects

  useEffect(() => {
    if (!blob) return

    dispatch(pushLoading('icon_picker_download'))
    new Promise<string>((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          blob,
          size,
          size,
          'PNG',
          100,
          0,
          async newBlob => {
            const fileUrl = window.URL.createObjectURL(newBlob as any)
            const downloadUrl = await uploadBlobToFirebaseStorage(fileUrl, `icons/${v4()}`)
            resolve(downloadUrl)
          },
          'blob',
        )
      } catch (error) {
        reject(error)
      }
    })
      .then(url => onIconChange(url))
      .finally(() => dispatch(popLoading('icon_picker_download')))
  }, [blob])

  // MARK: - Render

  return (
    <div style={style} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}
