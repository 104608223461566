import { appReducer } from './reducers/app'
import { audienceReducer } from './reducers/audience'
import { entityReducer } from './reducers/entity'
import { meReducer } from './reducers/me'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkAction } from 'redux-thunk'
import { dashboardSettingsAnalyticsReducer } from 'src/features/platform/dashboard/DashboardSettings/DashboardSettingsAnalytics/reducer'
import { dashboardSettingsPrivacyReducer } from 'src/features/platform/dashboard/DashboardSettings/DashboardSettingsPrivacy/reducer'
import { dashboardSpreadGameReducer } from 'src/features/platform/dashboard/DashboardSpreadGame/reducer'
import { accountReducer } from 'src/redux/reducers/account'
import { authenticationReducer } from 'src/redux/reducers/authentication'
import { componentReducer } from 'src/redux/reducers/component'
import { coverImageReducer } from 'src/redux/reducers/coverImage'
import { createRoomReducer } from 'src/redux/reducers/createRoom'
import { iconPickerReducer } from 'src/redux/reducers/iconPicker'
import { purchaseReducer } from 'src/redux/reducers/purchase'
import { roomReducer } from 'src/redux/reducers/room'
import { roomListReducer } from 'src/redux/reducers/roomList'
import { usageReducer } from 'src/redux/reducers/usage'

export type Action<T> = { type: T }

export type RootState = ReturnType<typeof rootReducer>
// @ts-ignore
export type Thunk<U = Action<any>, T = void> = ThunkAction<T, RootState, unknown, U>

export type AsyncThunk<U = Action<any>, ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  // @ts-ignore
  U
>

const rootReducer = combineReducers({
  app: appReducer,
  entity: entityReducer,
  me: meReducer,
  account: accountReducer,
  audience: audienceReducer,
  createRoom: createRoomReducer,
  component: componentReducer,
  roomList: roomListReducer,
  purchase: purchaseReducer,
  room: roomReducer,
  settings: combineReducers({
    analytics: dashboardSettingsAnalyticsReducer,
    usage: usageReducer,
    privacy: dashboardSettingsPrivacyReducer,
  }),
  spreadGame: dashboardSpreadGameReducer,
  authentication: authenticationReducer,
  utils: combineReducers({
    coverImage: coverImageReducer,
    iconPicker: iconPickerReducer,
  }),
})

const useDevTools = true
let store = createStore(rootReducer, applyMiddleware(thunk))

if (process.env.NODE_ENV !== 'production' && useDevTools) {
  // @ts-ignore
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
}

export default store
