import { DashboardHeader } from '../DashboardHeader'
import { DashboardChatModal } from './DashboardChatModal'
import { DashboardChatTable } from './DashboardChatTable'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import { deleteChatChannel, fetchChatChannels, updateChatChannel } from 'src/redux/reducers/chat'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { ChatChannel, Room } from 'src/repository/types'
import { dashboardSidebarOptionsImage } from 'src/utils/helpers/dashboardHelper'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardChat: React.FC<{ room: Room }> = ({ room }) => {
  // MARK: - Hooks

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isLoading = useSelect(state => getLoading(state.app, 'chat'))
  const chatChannels = useSelect(state => {
    const channels = getEntities<ChatChannel>(
      state.entity,
      'chat_channel',
      ({ room_id, type }) => room_id === room.id && type !== 'general',
    )
    return orderBy(channels, 'created_at', 'asc')
  })

  const [showChatChannelModal, setShowChatChannelModal] = useState(false)
  const [selectedChatChannel, setSelectedChatChannel] = useState<ChatChannel | null>(null)
  const [deleteCandidates, setDeleteCandidates] = useState<ChatChannel[]>([])

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchChatChannels(room.id))
  }, [dispatch, room])

  // MARK: - Handlers

  const handleChatChannelDropdownItemSelect = (option: number, chatChannel: ChatChannel) => {
    if (option === 0) {
      setSelectedChatChannel(chatChannel)
    } else if (option === 2) {
      setDeleteCandidates([chatChannel])
    } else if (option === 1) {
      const { id, name, image_url, description, is_active } = chatChannel
      dispatch(updateChatChannel(id, name, image_url, description, !is_active))
    }
  }

  const handleChatChannelBulkDropdownItemSelect = (option: number, items: ChatChannel[]) => {
    if (option === 0) setDeleteCandidates(items)
  }

  const handleCreateChatChannelButtonClick = () => {
    if (room.status === 'published') setShowChatChannelModal(true)
    else toast.error('chatPleaseGoLive')
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardHeader
        image={dashboardSidebarOptionsImage('chats')}
        title={t('chats')}
        description={t('chatsDescription')}
      />

      <DashboardChatTable
        chatChannels={chatChannels}
        isLoading={isLoading}
        onCreateChatChannelClick={handleCreateChatChannelButtonClick}
        handleChatChannelDropdownItemSelect={handleChatChannelDropdownItemSelect}
        handleChatChannelBulkDropdownItemSelect={handleChatChannelBulkDropdownItemSelect}
      />

      {(showChatChannelModal || !!selectedChatChannel) && (
        <DashboardChatModal
          chatChannel={selectedChatChannel}
          room={room}
          onClose={() => {
            setShowChatChannelModal(false)
            setSelectedChatChannel(null)
          }}
        />
      )}

      <AlertModal
        error={Error.displayable(
          deleteCandidates.length > 1 ? t('deleteChatChannels') : t('deleteChatChannel'),
          deleteCandidates.length > 1
            ? t('deleteChatChannelsMessage')
            : t('deleteChatChannelMessage'),
          success => {
            if (success && deleteCandidates.length) {
              deleteCandidates.forEach(candidate => dispatch(deleteChatChannel(candidate)))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidates.length}
        onClose={() => setDeleteCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
