import React from 'react'
import { LocationMarkerIcon } from 'src/assets/images/svg/LocationMarkerIcon'
import { useTheme } from 'styled-components'

export const GoogleMapMarker: React.FC<{ lat: number; lng: number }> = props => {
  // MARK: - Hooks

  const { palette } = useTheme()

  // MARK: - Render

  return <LocationMarkerIcon width={32} height={32} fill={palette.orange} />
}
