import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardAnnouncementModal } from './DashboardAnnouncementModal'
import { DashboardAnnouncementTable } from './DashboardAnnouncementTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import { deleteAnnouncement, fetchAnnouncements } from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Announcement, Component, Room } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardAnnouncementList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)

  const [selectedAnnouncement, setSelectedAnnouncement] = useState<Announcement | null>(null)
  const [deleteCandidates, setDeleteCandidates] = useState<Announcement[]>([])

  // Selectors
  const dispatch = useDispatch()
  const isLoading = useSelect(state => getLoading(state.app, 'announcement_list'))
  const announcements = useSelect(state =>
    getEntities<Announcement>(
      state.entity,
      'announcement',
      announcement => announcement.component_id === component.id,
    ).sort((first, second) => second.created_at - first.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchAnnouncements(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleAnnouncementDropdownItemSelect = async (
    option: number,
    announcement: Announcement,
  ) => {
    if (option === 0) setSelectedAnnouncement(announcement)
    else if (option === 1) setDeleteCandidates([announcement])
  }

  const handleAnnouncementBulkDropdownItemSelect = async (
    option: number,
    items: Announcement[],
  ) => {
    if (option === 0) setDeleteCandidates(items)
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <DashboardAnnouncementTable
        announcements={announcements}
        isLoading={isLoading}
        onCreateAnnouncementClick={() => setShowAnnouncementModal(true)}
        handleAnnouncementDropdownItemSelect={handleAnnouncementDropdownItemSelect}
        handleAnnouncementBulkDropdownItemSelect={handleAnnouncementBulkDropdownItemSelect}
      />

      {(showAnnouncementModal || !!selectedAnnouncement) && (
        <DashboardAnnouncementModal
          announcement={selectedAnnouncement}
          room={room}
          component={component}
          onClose={() => {
            setShowAnnouncementModal(false)
            setSelectedAnnouncement(null)
          }}
        />
      )}

      <AlertModal
        error={Error.displayable(
          deleteCandidates.length > 1 ? t('deleteAnnouncements') : t('deleteAnnouncement'),
          deleteCandidates.length > 1
            ? t('deleteAnnouncementsMessage')
            : t('deleteAnnouncementMessage'),
          success => {
            if (success && deleteCandidates.length) {
              deleteCandidates.forEach(candidate => dispatch(deleteAnnouncement(candidate)))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidates.length}
        onClose={() => setDeleteCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
