import { PurchaseModal } from '../purchase'
import { DashboardAnnouncementList } from './DashboardAnnouncementList'
import { DashboardAudience } from './DashboardAudience'
import { DashboardChat } from './DashboardChat'
import { DashboardComponentModal } from './DashboardComponent'
import { DashboardFAQList } from './DashboardFAQList'
import { DashboardPolling } from './DashboardPolling'
import { DashboardQA } from './DashboardQA'
import { DashboardRoomDetails } from './DashboardRoomInfo'
import { DashboardSettings } from './DashboardSettings'
import { DashboardSideBar } from './DashboardSideBar'
import { DashboardSpreadGame } from './DashboardSpreadGame'
import { DashboardWebList } from './DashboardWebList'
import { DashboardWebPage } from './DashboardWebPage'
import { head } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { PageLoader } from 'src/features/common/PageLoader'
import { getLoading, getLocalization } from 'src/redux/reducers/app'
import { getEntities, getEntity } from 'src/redux/reducers/entity'
import {
  cloneComponent,
  fetchRoom,
  flushDashboard,
  removeComponent,
  toggleComponent,
} from 'src/redux/reducers/room'
import { fetchCollaboratedRooms } from 'src/redux/reducers/roomList'
import { Error } from 'src/repository/Error'
import { Component, ComponentType, Room } from 'src/repository/types'
import config from 'src/utils/config'
import { dashboardSidebarOptions } from 'src/utils/helpers/dashboardHelper'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const RoomDashboard: React.FC<{ roomId: string }> = ({ roomId }) => {
  // MARK: - Hooks

  const generalItemCount = 4

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { navigate, routes, match } = useNavigation()

  const room = useSelect(state => getEntity<Room>(state.entity, 'room', roomId))
  const localization = useSelect(state => getLocalization(state.app))
  const isInitializing = useSelect(state => getLoading(state.app, 'room'))
  const rawComponents = useSelect(state =>
    getEntities<Component>(state.entity, 'component', ({ room_id }) => room_id === roomId),
  )

  const [showPurchase, setShowPurchase] = useState(false)
  const [createType, setCreateType] = useState<ComponentType | 'none' | null>(null)
  const [deleteCandidate, setDeleteCandidate] = useState<Component | null>(null)
  const [editingComponent, setEditingComponent] = useState<Component | null>(null)

  const components = useMemo(() => {
    return [...rawComponents].sort((first, second) => first.order - second.order)
  }, [rawComponents, roomId])

  const dashboard = useMemo(() => {
    return routes.dashboard(roomId)
  }, [roomId, routes, roomId])

  const componentRoutes = useMemo(() => {
    return components.reduce(
      (acc, item, index) => ({
        ...acc,
        [index + generalItemCount]: dashboard.component(item.id),
      }),
      {},
    )
  }, [components.length, dashboard, roomId])

  const dashboardRoutes: Record<number, string> = useMemo(
    () => ({
      0: dashboard.info,
      1: dashboard.chats,
      2: dashboard.audience,
      3: dashboard.settings,
      ...componentRoutes,
    }),
    [components.length, componentRoutes, dashboard, roomId],
  )

  const sideBarOptionIndex = parseFloat(
    head(Object.entries(dashboardRoutes).find(([key, val]) => match(val))) ?? '0',
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchCollaboratedRooms())
    return () => {
      dispatch(flushDashboard())
    }
  }, [])

  useEffect(() => {
    if (roomId) {
      localStorage.setItem(config.keys.activeRoomId, roomId)
      dispatch(fetchRoom(roomId))
    }
  }, [roomId])

  // MARK: - Handlers

  const handleComponentStatusToggle = useCallback((component: Component) => {
    dispatch(toggleComponent(component, !component.is_active))
  }, [])

  const handleComponentDropdownOptionSelect = useCallback(
    (component: Component, option: number) => {
      if (option === 0) setEditingComponent(component)
      else if (option === 1) dispatch(cloneComponent(component))
      else if (option === 2) handleComponentStatusToggle(component)
      else if (option === 3) setDeleteCandidate(component)
    },
    [],
  )

  const handleFeatureCreated = useCallback(
    (component: Component) => {
      navigate(routes.dashboard(roomId).component(component.id))
    },
    [roomId],
  )

  const handleAddComponent = useCallback(
    (isOn: boolean) => (componentType?: ComponentType) => {
      if (isOn) setCreateType(componentType ?? 'none')
      else setCreateType(null)
    },
    [],
  )

  const handleSideBarOptionClick = useCallback(
    (index: number) => {
      navigate(dashboardRoutes[index])
    },
    [components, dashboardRoutes, roomId],
  )

  // MARK: - Render

  const renderInnerPage = useCallback(() => {
    if (!room) return null
    switch (sideBarOptionIndex) {
      case 0:
        return <DashboardRoomDetails room={room} />
      case 1:
        return <DashboardChat room={room} />
      case 2:
        return <DashboardAudience room={room} />
      case 3:
        return <DashboardSettings room={room}>{t('configuration')}</DashboardSettings>
      case 3.1:
        return <div>{t('usage')}</div>
      case 3.2:
        return <div>{t('team')}</div>
      default:
        const componentIndex = sideBarOptionIndex - generalItemCount
        const component = components[componentIndex]

        if (!component) {
          navigate(dashboard.info)
          return
        }

        switch (component.type) {
          case ComponentType.announcements:
            return <DashboardAnnouncementList room={room} component={component} />
          case ComponentType.webPage:
            return <DashboardWebPage room={room} component={component} />
          case ComponentType.spreadGame:
            return <DashboardSpreadGame room={room} component={component} />
          case ComponentType.webList:
            return <DashboardWebList room={room} component={component} />
          case ComponentType.faq:
            return <DashboardFAQList room={room} component={component} />
          case ComponentType.qa:
            return <DashboardQA room={room} component={component} />
          case ComponentType.polling:
            return <DashboardPolling room={room} component={component} />
          default:
            break
        }
        return null
    }
  }, [room, sideBarOptionIndex, components, roomId])

  return (
    <Container>
      {isInitializing && <PageLoader />}

      {!isInitializing && room && (
        <>
          <DashboardSideBar
            room={room}
            options={dashboardSidebarOptions(localization)}
            components={components}
            currentIndex={sideBarOptionIndex}
            generalItemCount={generalItemCount}
            onOptionSelect={handleSideBarOptionClick}
            onAddComponent={handleAddComponent(true)}
            onPurchaseClick={() => setShowPurchase(true)}
            onComponentDropdownOptionSelect={handleComponentDropdownOptionSelect}
          />

          <InnerContainer>
            {renderInnerPage()}

            {createType !== null ? (
              <DashboardComponentModal
                room={room}
                componentType={createType === 'none' ? null : createType}
                onComponentSave={handleFeatureCreated}
                onClose={handleAddComponent(false)}
              />
            ) : null}
          </InnerContainer>
        </>
      )}

      <AlertModal
        error={Error.displayable(
          t('deleteFeature'),
          t('deleteFeatureMessage', { name: deleteCandidate?.title }),
          success => {
            if (success && deleteCandidate) dispatch(removeComponent(deleteCandidate))
          },
          t('delete'),
        )}
        visible={!!deleteCandidate}
        onClose={() => setDeleteCandidate(null)}
      />

      {showPurchase && room && (
        <PurchaseModal
          room={room}
          onClose={() => setShowPurchase(false)}
          onComplete={() => setShowPurchase(false)}
        />
      )}

      {editingComponent && room && (
        <DashboardComponentModal
          room={room}
          componentType={null}
          component={editingComponent}
          onComponentSave={() => {}}
          onClose={() => setEditingComponent(null)}
        />
      )}
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100vh - 72px);
  position: relative;
`

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
`
