import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol, Row } from 'src/features/common/CommonStyles'
import { FormLabel } from 'src/features/common/FormLabel'
import { VenuePicker } from 'src/features/platform/common/VenuePicker'
import { Venue } from 'src/features/platform/common/VenuePicker/Venue'
import styled from 'styled-components'

export const RoomLocationVenue: React.FC<{
  initialVenue: Venue | null
  handleVenueSelected: (venue: Venue | null) => void
}> = ({ initialVenue, handleVenueSelected }) => {
  // MARK: - Hooks

  const { t } = useTranslation()

  // MARK: - Render

  return (
    <Container>
      <FlexCol>
        <FormLabel isRequired style={{ marginBottom: 2 }}>
          {t('venueAddress')}
        </FormLabel>

        <VenuePicker
          containerStyle={{ maxWidth: 455, marginBottom: initialVenue ? -20 : 16 }}
          style={{ width: 420, maxWidth: 420 }}
          initialVenue={initialVenue}
          onVenueSelected={handleVenueSelected}
        />
      </FlexCol>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  width: 600px;
`
