import { Input, InputForwardRef } from '../common/Input'
import { TabBar } from '../common/TabBar'
import { ThirdPartyButton } from './ThirdPartyButton'
import { ThirdPartySeparator } from './ThirdPartySeparator'
import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier as Captcha,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { parsePhoneNumber } from 'libphonenumber-js'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CallIcon } from 'src/assets/images/svg/CallIcon'
import { MailIcon } from 'src/assets/images/svg/MailIcon'
import { PrimaryButton } from 'src/features/common/PrimaryButton'
import { getLoading, popLoading, pushError, pushLoading } from 'src/redux/reducers/app'
import {
  fetchContinueStatus,
  getAuthUser,
  getCredentials,
  setAuthUser,
  storeToken,
} from 'src/redux/reducers/authentication'
import { getEntity } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { AuthType, AuthUser, Invitation } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

enum AuthMethod {
  email = 0,
  phone = 1,
}

export const AuthenticationContinue: React.FC<{
  invitationId: string | null
}> = ({ invitationId }) => {
  // MARK: - Hooks

  const confirmation = useRef<ConfirmationResult | null>(null)
  const captcha = useRef<Captcha | null>(null)
  const otpRef = useRef<HTMLInputElement | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { palette } = useTheme()

  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const authUser = useSelect(state => getAuthUser(state.authentication))
  const invitation = useSelect(state =>
    getEntity<Invitation>(state.entity, 'invitation', invitationId),
  )

  const [method, setMethod] = useState<AuthMethod>(AuthMethod.email)
  const [email, setEmail] = useState(invitation?.email ?? '')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [otp, setOTP] = useState('')
  const [showOTPCode, setShowOTPCode] = useState(false)

  const captchaVerifier = useCallback(() => {
    return new Captcha('re-captcha', { size: 'invisible' }, getAuth())
  }, [])

  // MARK: - Effects

  useEffect(() => {
    setShowOTPCode(false)
    setOTP('')
  }, [phoneNumber])

  useEffect(() => {
    try {
      if (phoneNumber.length && phoneNumber?.[0] !== '+') {
        setPhoneNumber('+' + phoneNumber)
      } else {
        const parsedNumber = parsePhoneNumber(phoneNumber)
        setPhoneNumber(parsedNumber.formatInternational())
      }
    } catch (_) {}
  }, [phoneNumber])

  useEffect(() => {
    if (invitation) {
      dispatch(
        setAuthUser({
          email: invitation.email,
          firstName: invitation.first_name,
          lastName: invitation.last_name,
        }),
      )
    }
  }, [invitation?.id, dispatch])

  // MARK: - Handlers

  const handleContinueClick = useCallback(async () => {
    if (isLoading) return

    if (showOTPCode && confirmation.current && otp) {
      try {
        dispatch(pushLoading('authentication'))
        const validation = await confirmation.current?.confirm(otp)
        if (validation.user) await storeToken(validation.user)

        const authType: AuthType = { type: 'phone', phone: phoneNumber, password: '' }
        const user: AuthUser = { ...authUser, phoneNumber, authType }
        dispatch(fetchContinueStatus(user, undefined, phoneNumber))
      } catch (error: any) {
        dispatch(popLoading('authentication'))
        if (error?.message?.includes('invalid-verification-code')) {
          dispatch(pushError(Error.displayable(t('invalidCode'), t('invalidCodeInfo'))))
        } else {
          dispatch(pushError(error))
        }
      }
    } else if (method === AuthMethod.email) {
      const authType: AuthType = { type: 'email', email: email, password: '' }
      const user: AuthUser = { ...authUser, email, authType }
      dispatch(fetchContinueStatus(user, email))
    } else if (method === AuthMethod.phone) {
      dispatch(pushLoading('authentication'))
      try {
        if (!captcha.current) captcha.current = captchaVerifier()
        confirmation.current = await signInWithPhoneNumber(getAuth(), phoneNumber, captcha.current)
        setShowOTPCode(true)
        setTimeout(() => otpRef.current?.focus(), 0)
      } catch (error: any) {
        if (error?.message?.includes('invalid-phone-number')) {
          dispatch(pushError(Error.displayable(t('invalidPhone'), t('invalidPhoneInfo'))))
        } else {
          dispatch(pushError(error))
        }
      }
      dispatch(popLoading('authentication'))
    }
  }, [isLoading, email, phoneNumber, method, showOTPCode, otp])

  const handleThirdPartyClick = useCallback(
    async (type: 'google' | 'apple' | 'facebook') => {
      if (isLoading) return

      const user: AuthUser = { ...authUser, authType: { type } }
      const { token, updatedUser } = await getCredentials(dispatch, user)
      if (!token || !updatedUser?.email) return
      dispatch(fetchContinueStatus(updatedUser, updatedUser.email))
    },
    [isLoading],
  )

  // MARK: - Render

  return (
    <InnerContainer>
      <TabBar
        style={{ marginBottom: 12, paddingBottom: 10, backgroundColor: palette.background.primary }}
        itemHeight={30}
        indicatorStyle={{
          borderRadius: 0,
          boxShadow: '0 0 0 0',
          borderBottomWidth: 1,
          borderColor: palette.text.primary,
        }}
        itemWidth={Math.min(480, width - 68) / 2}
        initialIndex={method}
        onIndexChange={setMethod}
        items={[
          { title: t('email'), icon: <MailIcon style={{ marginRight: 8 }} size={20} /> },
          { title: t('phoneNumber'), icon: <CallIcon style={{ marginRight: 8 }} size={20} /> },
        ]}
      />

      {method === AuthMethod.email && (
        <Input
          type={'email'}
          placeholder={t('enterYourEmail')}
          value={email}
          onChange={setEmail}
          onEnterPress={handleContinueClick}
          autoFocus
        />
      )}

      {method === AuthMethod.phone && (
        <Input
          type={'phone'}
          placeholder={t('enterYourPhoneNumber')}
          value={phoneNumber}
          onChange={setPhoneNumber}
          onEnterPress={handleContinueClick}
          autoFocus
        />
      )}

      {showOTPCode && (
        <InputForwardRef
          ref={otpRef}
          type="number"
          placeholder={t('verificationCode')}
          value={otp}
          onChange={setOTP}
          onEnterPress={handleContinueClick}
        />
      )}

      <PrimaryButton
        style={{ width: '100%', marginTop: 12 }}
        isLoading={isLoading}
        title={t('continue')}
        onClick={handleContinueClick}
      />

      <ThirdPartySeparator />
      <ThirdPartyButton onClick={() => handleThirdPartyClick('facebook')} type="facebook" />
      <ThirdPartyButton onClick={() => handleThirdPartyClick('google')} type="google" />

      <div id={'re-captcha'} />
    </InnerContainer>
  )
}

// MARK: - Styles

const InnerContainer = styled.div`
  flex: 1;
  margin: 0px auto;
  max-width: 480px;
  padding: 32px 0px;
  z-index: 10;
`
