import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlexCol } from 'src/features/common/CommonStyles'
import { Input } from 'src/features/common/Input'
import { Select } from 'src/features/common/Select'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

export const RequestDemoForm: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isMobile = width < 850

  // MARK: - Handlers

  const sendForm = () => {
    console.log('send form')
  }

  // MARK: - Render

  return (
    <Container isMobile={isMobile}>
      <InnerContainer isMobile={isMobile}>
        <HorizontalContainer isMobile={isMobile}>
          <TextContainer isMobile={isMobile}>
            <Input type="text" status="default" label="First Name" isRequired size="default" />
          </TextContainer>

          <TextContainer isMobile={isMobile}>
            <Input status="default" label="Last Name" isRequired size="default" />
          </TextContainer>
        </HorizontalContainer>

        <HorizontalContainer isMobile={isMobile}>
          <TextContainer isMobile={isMobile}>
            <Input type="email" status="default" label="Email" isRequired size="default" />
          </TextContainer>

          <TextContainer isMobile={isMobile}>
            <Input type="phone" status="default" label="Phone" isRequired size="default" />
          </TextContainer>
        </HorizontalContainer>

        <HorizontalContainer isMobile={isMobile}>
          <TextContainer isMobile={isMobile} single>
            <Input status="default" label="Business Name" isRequired size="default"></Input>
          </TextContainer>
        </HorizontalContainer>

        <HorizontalContainer isMobile={isMobile}>
          <TextContainer isMobile={isMobile}>
            <Select
              type="search"
              status="default"
              label="Organization Type"
              isRequired
              size="default"
              placeholder="Please Select">
              <option value={'1'}>{'Individual Content Creator'}</option>
              <option value={'2'}>{'Influencer'}</option>
              <option value={'3'}>{'Media Company'}</option>
              <option value={'4'}>{'Marketing/Sales Team'}</option>
              <option value={'5'}>{'Foundation'}</option>
              <option value={'6'}>{'Government'}</option>
              <option value={'7'}>{'Non-profit'}</option>
              <option value={'8'}>{'Other'}</option>
            </Select>
          </TextContainer>

          <TextContainer isMobile={isMobile}>
            <Input
              type="number"
              status="default"
              label={t('expectedAudienceSize')}
              isRequired
              size="default"
            />
          </TextContainer>
        </HorizontalContainer>

        <HorizontalContainer isMobile={isMobile}>
          <Input
            type="textarea"
            status="default"
            label="Your links, accounts or anything to share about your business, audience"
            size="default"
          />
        </HorizontalContainer>

        <RequestDemoButton className="button button-primary" onClick={sendForm}>
          {t('requestDemo')}
        </RequestDemoButton>
      </InnerContainer>
    </Container>
  )
}

// MARK: - Styles

const Container = styled(FlexCol)<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border: 1px solid ${({ theme }) => theme.palette.background.separator};
  border-radius: 10px;
`

const InnerContainer = styled(FlexCol)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? 16 : 32)}px;
`

const TextContainer = styled.div<{ isMobile: boolean; single?: boolean }>`
  flex-basis: ${({ isMobile, single }) => (single || isMobile ? '100%' : '49%')};
  margin-bottom: ${({ isMobile }) => (isMobile ? 10 : 0)}px;
`

const HorizontalContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ isMobile }) => (isMobile ? 0 : 15)}px;
  width: 100%;
`

const RequestDemoButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.orange};
  border-radius: 30px;
  display: flex;
  height: 60px;
  width: 220px;
`
