export type Venue = {
  title: string
  address: string
  province: string
  city: string
  country: string
  zip_code: number
  longitude: number
  latitude: number
}

export const isVenueValid = (location: Venue) => {
  return (
    location.title && location.address && location.city && location.province && location.country
  )
}
