import { deleteEntities, mergeEntities } from 'src/redux/reducers/entity'
import { AsyncThunk, Thunk } from 'src/redux/store'
import { Error, IError } from 'src/repository/Error'
import { roomService, RoomRequest } from 'src/repository/services/roomService'
import { Room, RoomKey } from 'src/repository/types'

type State = {
  isLoading: boolean
  error: IError | null
  key: RoomKey | null
  roomsFetched: boolean
}

// MARK: - State

export const initialState: State = {
  isLoading: true,
  error: null,
  key: null,
  roomsFetched: false,
}

// MARK: - Reducer

export const roomListReducer = (
  state = initialState,
  action: LoadingAction | SetKeyAction | ErrorAction | FlushAction | SetRoomsFetchedAction,
): State => {
  switch (action.type) {
    case 'myRooms/loading':
      return { ...state, isLoading: action.isLoading }

    case 'myRooms/error':
      return { ...state, error: action.error }

    case 'myRooms/setRoomsFetched':
      return { ...state, roomsFetched: true }

    case 'myRooms/setKey':
      return { ...state, key: action.key }

    case 'me/logout':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const fetchCollaboratedRooms = (): AsyncThunk => async (dispatch, getState) => {
  const { me } = getState()
  const meId = me.me?.id
  if (!meId) {
    dispatch(setError(Error.default('Me not found')))
    return
  }

  dispatch(setLoading(true))
  dispatch(setError(null))

  const response = await roomService.fetchCollaboratedRooms(meId, true, 0)

  if (response.success) {
    const { rooms, collaborators } = response.value
    dispatch(mergeEntities({ room: rooms, collaborator: collaborators }))
    dispatch(setRoomsFetched())
  } else {
    dispatch(setError(response.error))
  }
  dispatch(setLoading(false))
}

export const getPrivateKeyPair = (): AsyncThunk => async dispatch => {
  dispatch(setLoading(true))
  dispatch(setError(null))

  const response = await roomService.generateRoomKeys()
  if (response.success) {
    const { api_key, api_secret } = response.value
    dispatch(setKey({ api_key, api_secret, room_id: null }))
  } else {
    dispatch(setError(response.error))
  }

  dispatch(setLoading(false))
}

export const removePrivateKeyPair = (): Thunk => async dispatch => {
  dispatch(setKey(null))
}

export const cloneRoom =
  (room: Room, apiKey: string | null = null, apiSecret: string | null = null): AsyncThunk =>
  async dispatch => {
    dispatch(setLoading(true))
    dispatch(setError(null))

    const request: RoomRequest = {
      title: room.title,
      overview: room.overview,
      media: {
        url: room.main_media.url,
        offset: room.main_media.offset,
      },
      tags: room.tags,
      api_key: apiKey,
      api_secret: apiSecret,
      access_codes: room.access_codes,
      links: room.links,
      location: room.location ?? null,
      date: { start: room.start_date, end: room.end_date, timezone: room.timezone },
      privacy_type: room.privacy_type,
      config: room.config,
    }
    const response = await roomService.createRoom(request)

    if (response.success) {
      const json = response.value
      dispatch(mergeEntities({ room: [json['room']] }))
    } else {
      dispatch(setError(response.error))
    }
    dispatch(setLoading(false))
  }

export const deleteRoom =
  (room: Room): AsyncThunk =>
  async dispatch => {
    dispatch(deleteEntities({ room: [room.id] }))
    roomService.deleteRoom(room.id)
  }

export const setError = (error: IError | null): ErrorAction => ({
  type: 'myRooms/error',
  error: error,
})

// MARK: - Selectors

export const getIsLoading = (state: State) => {
  return state.isLoading
}

export const getRoomsFetched = (state: State) => {
  return state.roomsFetched
}

export const getError = (state: State) => {
  return state.error
}

export const getKey = (state: State) => {
  return state.key
}

// MARK: - Action Types

type LoadingAction = {
  type: 'myRooms/loading'
  isLoading: boolean
}

type ErrorAction = {
  type: 'myRooms/error'
  error: IError | null
}

type SetKeyAction = {
  type: 'myRooms/setKey'
  key: RoomKey | null
}

type SetRoomsFetchedAction = {
  type: 'myRooms/setRoomsFetched'
}

type FlushAction = {
  type: 'me/logout'
}

// MARK: - Internal Actions

const setLoading = (isLoading: boolean): LoadingAction => ({
  type: 'myRooms/loading',
  isLoading: isLoading,
})

const setKey = (key: RoomKey | null): SetKeyAction => ({
  type: 'myRooms/setKey',
  key: key,
})

const setRoomsFetched = (): SetRoomsFetchedAction => ({
  type: 'myRooms/setRoomsFetched',
})
