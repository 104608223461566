import classNames from 'classnames'
import React from 'react'

export const FormHint: React.FC<{
  status: string
}> = ({ children, status }) => {
  const classes = classNames('form-hint', status && `text-color-${status}`)

  return (
    <div className={classes} style={{ textAlign: 'left', marginLeft: 2 }}>
      {children}
    </div>
  )
}
