import React, { ChangeEvent, CSSProperties, useCallback, useRef } from 'react'

export const FileInput: React.FC<{
  format?: ('.csv' | '.xlsx')[]
  style?: CSSProperties
  onFileSelect: (file: File) => void
}> = ({ format = ['.csv'], children, style, onFileSelect }) => {
  // MARK: - Hooks

  const inputRef = useRef<HTMLInputElement | null>(null)

  // MARK: - Handlers

  const handleClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const handleChange = useCallback(
    (element: ChangeEvent<HTMLInputElement>) => {
      const file = element.target?.files?.[0]
      if (file) onFileSelect(file)
    },
    [onFileSelect],
  )

  // MARK: - Render

  return (
    <div style={{ cursor: 'pointer', ...style }} onClick={handleClick}>
      <input
        ref={inputRef}
        type="file"
        style={{ opacity: 0, display: 'none' }}
        multiple={false}
        accept={format.join(',')}
        onChange={handleChange}
      />
      {children}
    </div>
  )
}
