import { Col, LabelMedium, Row, ShadowBox } from './CommonStyles'
import React, { useEffect, useState } from 'react'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled from 'styled-components'

type TabItem = {
  title: string
  icon?: React.ReactNode
  content?: React.ReactNode
}

export const TabBar: React.FC<{
  style?: React.CSSProperties
  indicatorStyle?: React.CSSProperties
  items: TabItem[]
  itemWidth?: number
  itemHeight?: number
  initialIndex?: number
  disabledIndexes?: number[]
  onIndexChange?: (index: number) => void
}> = ({
  style,
  indicatorStyle,
  items,
  initialIndex = 0,
  disabledIndexes = [],
  itemHeight = 34,
  itemWidth = 132,
  onIndexChange,
}) => {
  // MARK: - Hooks

  const { width } = useWindowDimensions()
  const [selectedIndex, setSelectedIndex] = useState(initialIndex)

  const maxItemWidth = Math.min(itemWidth, (width - 40) / items.length)

  // MARK: - Effects

  useEffect(() => {
    onIndexChange?.(selectedIndex)
  }, [selectedIndex])

  // MARK: - Render

  return (
    <Col>
      <Container style={{ width: items.length * itemWidth, ...style, maxWidth: width - 40 }}>
        {items.map((item, index) => (
          <TabBarItem
            key={index}
            item={item}
            disabled={disabledIndexes.includes(index)}
            height={itemHeight}
            width={maxItemWidth}
            isSelected={selectedIndex === index}
            onItemClick={() => setSelectedIndex(index)}
          />
        ))}
        <TabBarItemIndicator
          style={indicatorStyle}
          itemWidth={maxItemWidth}
          selectedIndex={selectedIndex}
        />
      </Container>
      {items[selectedIndex]?.content}
    </Col>
  )
}

const TabBarItem: React.FC<{
  item: TabItem
  isSelected: boolean
  width: number
  maxWidth?: string | number
  height?: number
  disabled?: boolean
  onItemClick: () => void
}> = ({ item, width, maxWidth, height = 34, disabled = false, isSelected, onItemClick }) => {
  // MARK: - Hooks

  const [focused, setFocused] = useState(false)

  // MARK: - Render

  return (
    <ItemContainer
      style={{
        width,
        height,
        opacity: disabled ? 0.4 : focused || isSelected ? 1 : 0.6,
        cursor: disabled ? 'not-allowed' : 'pointer',
        maxWidth,
      }}
      onClick={() => {
        if (!disabled) onItemClick()
      }}
      onPointerEnter={() => setFocused(!disabled && true)}
      onPointerLeave={() => setFocused(false)}>
      {item.icon}
      <Title focused={focused || isSelected}>{item.title}</Title>
    </ItemContainer>
  )
}

const TabBarItemIndicator: React.FC<{
  style?: React.CSSProperties
  selectedIndex: number
  itemWidth: number
}> = ({ selectedIndex, style, itemWidth }) => {
  return (
    <Indicator
      style={{
        ...style,
        width: itemWidth - 6,
        left: selectedIndex * itemWidth + 3,
      }}></Indicator>
  )
}

// MARK: - Styles

const Container = styled(Row)`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.palette.background.tertiary : theme.palette.background.disabled};
  border-radius: 8px;
  overflow: hidden;
  padding: 4px;
  position: relative;
`

const Indicator = styled(ShadowBox)`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.palette.background.separator : theme.palette.background.primary};
  border-radius: 7px;
  border-width: 0px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  height: calc(100% - 6px);
  position: absolute;
  top: 3px;
  transition: left 0.2s ease-in-out;
`

const ItemContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  user-select: none;
  z-index: 2;
`

const Title = styled(LabelMedium)<{ focused: boolean }>`
  color: ${({ theme, focused }) =>
    focused ? theme.palette.text.primary : theme.palette.text.primary};
  text-align: center;
`
