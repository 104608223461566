import produce from 'immer'
import { pushError } from 'src/redux/reducers/app'
import { deleteEntities, getEntities, MergeAction, mergeEntities } from 'src/redux/reducers/entity'
import { Action, Thunk } from 'src/redux/store'
import { announcementService } from 'src/repository/services/announcementService'
import {
  componentService,
  CreateComponentInstanceInput,
  UpdateComponentInstanceInput,
} from 'src/repository/services/componentService'
import { faqService } from 'src/repository/services/faqService'
import { notificationService } from 'src/repository/services/notificationService'
import { pollService } from 'src/repository/services/pollService'
import { qaQuestionService } from 'src/repository/services/qaQuestionService'
import { trackedUrlService } from 'src/repository/services/trackedUrlService'
import { webListItemService } from 'src/repository/services/webListItemService'
import {
  Announcement,
  Component,
  ComponentType,
  FAQ,
  Notification,
  Poll,
  QAQuestion,
  TrackedUrl,
  WebListItem,
} from 'src/repository/types'
import { localTime } from 'src/utils/helpers/timeHelper'

type State = {
  component: Component | null
}

type ActionType =
  | { type: 'component/setComponent'; component: Component | null }
  | MergeAction
  | Action<'component/flush'>
  | Action<'me/logout'>

// MARK: - State

export const initialState: State = {
  component: null,
}

// MARK: - Reducer

export const componentReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'component/setComponent':
      return produce(state, draft => {
        draft.component = action.component
        return draft
      })

    case 'me/logout':
    case 'component/flush':
      return initialState

    default:
      return state
  }
}

// MARK: - Actions

export const createComponent =
  (
    roomId: string,
    type: ComponentType,
    title: string,
    overview: string,
    imageUrl: string,
    isActive: boolean,
    input: CreateComponentInstanceInput,
  ): Thunk<ActionType> =>
  (dispatch, getState) => {
    const order = getEntities<Component>(
      getState().entity,
      'component',
      ({ room_id }) => room_id === roomId,
    ).length

    componentService
      .createComponent(order, type, roomId, title, overview, imageUrl, isActive, input)
      .loading('component')
      .done(
        value => {
          dispatch(
            mergeEntities({
              component: [value.component],
              announcement_list: value.announcement_list ? [value.announcement_list] : [],
              web_page: value.web_page ? [value.web_page] : [],
              spread_game: value.spread_game ? [value.spread_game] : [],
            }),
          )
          dispatch({ type: 'component/setComponent', component: value.component })
        },
        error => dispatch(pushError(error)),
      )
  }

export const updateComponent =
  (
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    isActive: boolean,
    input: UpdateComponentInstanceInput,
  ): Thunk<ActionType> =>
  dispatch =>
    componentService
      .updateComponent(componentId, title, overview, imageUrl, isActive, input)
      .loading('component')
      .done(
        value => {
          dispatch(
            mergeEntities({
              component: [value.component],
              announcement_list: value.announcement_list ? [value.announcement_list] : [],
              web_page: value.web_page ? [value.web_page] : [],
              spread_game: value.spread_game ? [value.spread_game] : [],
            }),
          )
          dispatch({ type: 'component/setComponent', component: value.component })
        },
        error => dispatch(pushError(error)),
      )

export const flushComponentModal = (): ActionType => ({
  type: 'component/flush',
})

// Announcements

export const fetchAnnouncements =
  (componentId: string): Thunk =>
  dispatch =>
    announcementService
      .fetchAnnouncements(componentId, 0)
      .loading('announcement_list')
      .done(
        announcement => dispatch(mergeEntities({ announcement })),
        error => dispatch(pushError(error)),
      )

export const createAnnouncement =
  (
    roomId: string,
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    imageOffset: number,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    actionName: string,
    type: 'info' | 'warning',
    notifyUsers: boolean,
    hasDetail: boolean,
  ): Thunk =>
  dispatch =>
    announcementService
      .createAnnouncement(
        roomId,
        componentId,
        title,
        overview,
        imageUrl,
        imageOffset,
        publishDate,
        isScheduled,
        link,
        actionName,
        type,
        notifyUsers,
        hasDetail,
      )
      .loading('announcement_list')
      .done(
        announcement => dispatch(mergeEntities({ announcement: [announcement] })),
        error => dispatch(pushError(error)),
      )

export const updateAnnouncement =
  (
    announcementId: string,
    title: string,
    overview: string,
    imageUrl: string,
    imageOffset: number,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    actionName: string,
    notifyUsers: boolean,
    hasDetail: boolean,
    isActive: boolean,
  ): Thunk =>
  dispatch =>
    announcementService
      .updateAnnouncement(
        announcementId,
        title,
        overview,
        imageUrl,
        imageOffset,
        publishDate,
        isScheduled,
        link,
        actionName,
        notifyUsers,
        hasDetail,
        isActive,
      )
      .loading('announcement_list')
      .done(
        announcement => dispatch(mergeEntities({ announcement: [announcement] })),
        error => dispatch(pushError(error)),
      )

export const deleteAnnouncement =
  (announcement: Announcement): Thunk =>
  dispatch =>
    announcementService
      .deleteAnnouncement(announcement.id)
      .first(() => dispatch(deleteEntities({ announcement: [announcement.id] })))
      .done(undefined, error => {
        dispatch(mergeEntities({ announcement: [announcement] }))
        dispatch(pushError(error))
      })

// Polling

export const fetchPolls =
  (componentId: string): Thunk =>
  dispatch =>
    pollService
      .fetchPolls(componentId)
      .loading('polling')
      .done(
        polls => dispatch(mergeEntities({ poll: polls })),
        error => dispatch(pushError(error)),
      )

export const createPoll =
  (componentId: string, question: string, options: string[], isActive: boolean): Thunk =>
  dispatch =>
    pollService
      .createPoll(componentId, question, options, isActive)
      .loading('polling')
      .done(
        poll => dispatch(mergeEntities({ poll: [poll] })),
        error => dispatch(pushError(error)),
      )

export const togglePoll =
  (poll: Poll, isActive: boolean): Thunk =>
  dispatch =>
    pollService
      .updatePoll(poll.id, isActive)
      .first(() => {
        poll = { ...poll, is_active: isActive, updated_at: Date.now() / 1000 }
        dispatch(mergeEntities({ poll: [poll] }))
      })
      .done(
        updatedPoll => dispatch(mergeEntities({ poll: [updatedPoll] })),
        error => dispatch(pushError(error)),
      )

// QA

export const fetchQAQuestions =
  (componentId: string): Thunk =>
  dispatch =>
    qaQuestionService
      .fetchQAQuestions(componentId)
      .loading('qa')
      .done(
        ({ qa_questions, users }) =>
          dispatch(mergeEntities({ qa_question: qa_questions, user: users })),
        error => dispatch(pushError(error)),
      )

export const moveQAQuestionToAnswered =
  (qaQuestion: QAQuestion): Thunk =>
  dispatch => {
    const question = {
      ...qaQuestion,
      answer: '',
      state: 'answered' as const,
      updated_at: localTime(),
    }
    dispatch(mergeEntities({ qa_question: [question] }))
  }

export const answerQAQuestion =
  (qaQuestion: QAQuestion, answer: string): Thunk =>
  dispatch =>
    answer &&
    qaQuestionService
      .answerQAQuestion(qaQuestion.id, answer)
      .first(() => {
        const question = {
          ...qaQuestion,
          answer: answer,
          state: 'answered' as const,
          updated_at: localTime(),
        }
        dispatch(mergeEntities({ qa_question: [question] }))
      })
      .loading('qa')
      .done(
        question => dispatch(mergeEntities({ qa_question: [question] })),
        error => dispatch(pushError(error)),
      )

export const rejectQAQuestion =
  (qaQuestion: QAQuestion): Thunk =>
  dispatch =>
    qaQuestionService
      .rejectQAQuestion(qaQuestion.id)
      .first(() => {
        const question = {
          ...qaQuestion,
          answer: '',
          state: 'rejected' as const,
          updated_at: localTime(),
        }
        dispatch(mergeEntities({ qa_question: [question] }))
      })
      .loading('qa')
      .done(
        question => dispatch(mergeEntities({ qa_question: [question] })),
        error => dispatch(pushError(error)),
      )

// FAQ

export const fetchFAQs =
  (componentId: string): Thunk =>
  dispatch =>
    faqService
      .fetchFAQs(componentId, 0)
      .loading('faq')
      .done(
        faq => dispatch(mergeEntities({ faq })),
        error => dispatch(pushError(error)),
      )

export const createFAQ =
  (roomId: string, componentId: string, question: string, answer: string, links: string[]): Thunk =>
  dispatch =>
    faqService
      .createFAQ(roomId, componentId, question, answer, links)
      .loading('faq')
      .done(
        faq => dispatch(mergeEntities({ faq: [faq] })),
        error => dispatch(pushError(error)),
      )

export const updateFAQ =
  (itemId: string, question: string, answer: string, links: string[], isActive: boolean): Thunk =>
  dispatch =>
    faqService
      .updateFAQ(itemId, question, answer, links, isActive)
      .loading('faq')
      .done(
        faq => dispatch(mergeEntities({ faq: [faq] })),
        error => dispatch(pushError(error)),
      )

export const deleteFAQ =
  (faq: FAQ): Thunk =>
  dispatch =>
    faqService
      .deleteFAQ(faq.id)
      .first(() => dispatch(deleteEntities({ faq: [faq.id] })))
      .done(undefined, error => {
        dispatch(mergeEntities({ faq: [faq] }))
        dispatch(pushError(error))
      })

// Notifications

export const fetchNotifications =
  (roomId: string, offset: number, limit: number): Thunk =>
  dispatch =>
    notificationService
      .fetchNotifications(roomId, offset, limit)
      .loading('notification')
      .done(
        notifications => dispatch(mergeEntities({ notification: notifications })),
        error => dispatch(pushError(error)),
      )

export const createNotification =
  (
    roomId: string,
    title: string,
    overview: string,
    imageUrl: string,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    featureId: string,
  ): Thunk =>
  dispatch =>
    notificationService
      .createNotification(
        roomId,
        title,
        overview,
        imageUrl,
        publishDate,
        isScheduled,
        link,
        featureId,
      )
      .loading('notification')
      .done(
        notification => dispatch(mergeEntities({ notification: [notification] })),
        error => dispatch(pushError(error)),
      )

export const updateNotification =
  (
    notificationId: string,
    title: string,
    overview: string,
    imageUrl: string,
    publishDate: number,
    isScheduled: boolean,
    link: string,
    featureId: string,
  ): Thunk =>
  dispatch =>
    notificationService
      .updateNotification(
        notificationId,
        title,
        overview,
        imageUrl,
        publishDate,
        isScheduled,
        link,
        featureId,
      )
      .loading('notification')
      .done(
        notification => dispatch(mergeEntities({ notification: [notification] })),
        error => dispatch(pushError(error)),
      )

export const deleteNotification =
  (notification: Notification): Thunk =>
  dispatch =>
    notificationService
      .deleteNotification(notification.id)
      .first(() => dispatch(deleteEntities({ notification: [notification.id] })))
      .done(undefined, error => dispatch(pushError(error)))

// Web List

export const fetchWebListItems =
  (componentId: string): Thunk =>
  dispatch =>
    webListItemService
      .fetchWebListItems(componentId, 0)
      .loading('web_list')
      .done(
        listItems => dispatch(mergeEntities({ web_list_item: listItems })),
        error => dispatch(pushError(error)),
      )

export const createWebListItem =
  (
    roomId: string,
    componentId: string,
    title: string,
    overview: string,
    imageUrl: string,
    link: string,
  ): Thunk =>
  dispatch =>
    webListItemService
      .createWebListItem(roomId, componentId, title, overview, imageUrl, link)
      .loading('web_list')
      .done(
        listItem => dispatch(mergeEntities({ web_list_item: [listItem] })),
        error => dispatch(pushError(error)),
      )

export const updateWebListItem =
  (
    itemId: string,
    title: string,
    overview: string,
    imageUrl: string,
    link: string,
    isActive: boolean,
  ): Thunk =>
  dispatch =>
    webListItemService
      .updateWebListItem(itemId, title, overview, imageUrl, link, isActive)
      .loading('web_list')
      .done(
        listItem => dispatch(mergeEntities({ web_list_item: [listItem] })),
        error => dispatch(pushError(error)),
      )

export const deleteWebListItem =
  (webListItem: WebListItem): Thunk =>
  dispatch =>
    webListItemService
      .deleteWebListItem(webListItem.id)
      .first(() => dispatch(deleteEntities({ web_list_item: [webListItem.id] })))
      .done(
        listItem => dispatch(mergeEntities({ web_list_item: [listItem] })),
        error => dispatch(pushError(error)),
      )

// Web Page

export const fetchTrackedUrls =
  (componentId: string): Thunk<ActionType> =>
  dispatch =>
    trackedUrlService
      .fetchTrackedUrls(componentId)
      .loading('tracked_url')
      .done(
        urls => dispatch(mergeEntities({ tracked_url: urls })),
        error => dispatch(pushError(error)),
      )

export const createTrackedUrl =
  (
    roomId: string,
    componentId: string,
    url: string,
    name: string,
    notifyMeLimit: number,
    notifyMeEmailAddress: string,
  ): Thunk =>
  dispatch =>
    trackedUrlService
      .createTrackedUrl(roomId, componentId, url, name, notifyMeLimit, notifyMeEmailAddress)
      .loading('tracked_url')
      .done(
        trackedUrl => dispatch(mergeEntities({ tracked_url: [trackedUrl] })),
        error => dispatch(pushError(error)),
      )

export const updateTrackedUrl =
  (
    trackedUrlId: string,
    name: string,
    notifyMeLimit: number,
    notifyMeEmailAddress: string,
  ): Thunk =>
  dispatch =>
    trackedUrlService
      .updateTrackedUrl(trackedUrlId, name, notifyMeLimit, notifyMeEmailAddress)
      .loading('tracked_url')
      .done(
        trackedUrl => dispatch(mergeEntities({ tracked_url: [trackedUrl] })),
        error => dispatch(pushError(error)),
      )

export const deleteTrackedUrl =
  (trackedUrl: TrackedUrl): Thunk =>
  dispatch =>
    trackedUrlService
      .deleteTrackedUrl(trackedUrl.id)
      .first(() => dispatch(deleteEntities({ tracked_url: [trackedUrl.id] })))
      .done(undefined, error => {
        dispatch(mergeEntities({ tracked_url: [trackedUrl] }))
        dispatch(pushError(error))
      })

// MARK: - Selectors

export const getComponent = (state: State) => {
  return state.component
}
