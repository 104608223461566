import { CreateRoom } from '../CreateRoom'
import { AccountModal } from '../account'
import { CreatorFooter } from './CreatorFooter'
import { CreatorHeader } from './CreatorHeader'
import { getAuth } from '@firebase/auth'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getLoading } from 'src/redux/reducers/app'
import { loginSilently } from 'src/redux/reducers/authentication'
import { flush } from 'src/redux/reducers/createRoom'
import { getEntity } from 'src/redux/reducers/entity'
import { getIsAuthenticated } from 'src/redux/reducers/me'
import { flushDashboard } from 'src/redux/reducers/room'
import { Room } from 'src/repository/types'
import { useNavigation } from 'src/utils/hooks/useNavigation'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const CreatorLayout: React.FC<{
  roomId?: string
}> = ({ children, roomId }) => {
  // MARK: - Hooks

  const [modalType, setModalType] = useState<'create' | 'account' | null>(null)

  const dispatch = useDispatch()
  const { navigate, routes } = useNavigation()

  const isAuthenticated = useSelect(state => getIsAuthenticated(state.me))
  const isLoading = useSelect(state => getLoading(state.app, 'authentication'))
  const room = useSelect(state => getEntity<Room>(state.entity, 'room', roomId))

  // MARK: - Effects

  useEffect(() => {
    setTimeout(() => {
      const unsubscribe = getAuth().onAuthStateChanged(user => {
        if (isAuthenticated || isLoading) {
          unsubscribe()
          return
        }

        if (user?.email || user?.phoneNumber) {
          dispatch(loginSilently(user?.email, user?.phoneNumber, null))
        } else {
          dispatch(flushDashboard())
          navigate(routes.authentication.continue)
        }
        unsubscribe()
      })
    })
  }, [dispatch, isAuthenticated, isLoading])

  // MARK: - Handlers

  return (
    <Container>
      <CreatorHeader
        room={room}
        primaryAction={room ? 'purchase' : 'create'}
        onCreateClick={() => setModalType('create')}
        onAccountClick={() => setModalType('account')}
      />

      <main className="site-content">{children}</main>

      <CreateRoom
        visible={modalType === 'create'}
        onClose={() => {
          setModalType(null)
          dispatch(flush())
        }}
      />

      {modalType === 'account' && <AccountModal onClose={() => setModalType(null)} />}

      <CreatorFooter />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  flex: 1;
  min-height: 100vh;
`
