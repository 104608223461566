import { DashboardComponentHeader } from '../DashboardComponentHeader'
import { DashboardWebListItemModal } from './DashboardWebListItemModal'
import { DashboardWebListItemTable } from './DashboardWebListItemTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'src/features/common/AlertModal'
import { getLoading } from 'src/redux/reducers/app'
import { deleteWebListItem, fetchWebListItems } from 'src/redux/reducers/component'
import { getEntities } from 'src/redux/reducers/entity'
import { Error } from 'src/repository/Error'
import { Component, Room, WebListItem } from 'src/repository/types'
import { useSelect } from 'src/utils/hooks/useSelect'
import styled from 'styled-components'

export const DashboardWebList: React.FC<{
  room: Room
  component: Component
}> = ({ room, component }) => {
  // MARK: - Hooks

  const [showWebListItemModal, setShowWebListItemModal] = useState(false)
  const [selectedWebListItem, setSelectedWebListItem] = useState<WebListItem | null>(null)
  const [deleteCandidates, setDeleteCandidates] = useState<WebListItem[]>([])

  // Selectors
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isLoading = useSelect(state => getLoading(state.app, 'web_list'))
  const webListItems = useSelect(state =>
    getEntities<WebListItem>(
      state.entity,
      'web_list_item',
      webListItem => webListItem.component_id === component.id,
    ).sort((first, second) => second.created_at - first.created_at),
  )

  // MARK: - Effects

  useEffect(() => {
    dispatch(fetchWebListItems(component.id))
  }, [component.id])

  // MARK: - Handlers

  const handleWebListItemDropdownItemSelect = async (option: number, webListItem: WebListItem) => {
    if (option === 0) setSelectedWebListItem(webListItem)
    else if (option === 1) setDeleteCandidates([webListItem])
  }

  const handleWebListItemBulkDropdownItemSelect = async (option: number, items: WebListItem[]) => {
    if (option === 0) setDeleteCandidates(items)
  }

  // MARK: - Render

  return (
    <Container>
      <DashboardComponentHeader room={room} component={component} />

      <DashboardWebListItemTable
        webListItems={webListItems}
        isLoading={isLoading}
        onCreateWebListItemClick={() => setShowWebListItemModal(true)}
        handleWebListItemDropdownItemSelect={handleWebListItemDropdownItemSelect}
        handleWebListItemBulkDropdownItemSelect={handleWebListItemBulkDropdownItemSelect}
      />

      {(showWebListItemModal || !!selectedWebListItem) && (
        <DashboardWebListItemModal
          webListItem={selectedWebListItem}
          room={room}
          component={component}
          onClose={() => {
            setShowWebListItemModal(false)
            setSelectedWebListItem(null)
          }}
        />
      )}

      <AlertModal
        error={Error.displayable(
          deleteCandidates.length > 1 ? t('deleteItems') : t('deleteItem'),
          deleteCandidates.length > 1 ? t('deleteItemsMessage') : t('deleteItemMessage'),
          success => {
            if (success && deleteCandidates.length) {
              deleteCandidates.forEach(candidate => dispatch(deleteWebListItem(candidate)))
            }
          },
          t('delete'),
        )}
        visible={!!deleteCandidates.length}
        onClose={() => setDeleteCandidates([])}
      />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  max-width: 100%;
  width: 1200px;
`
