import { DashboardPollingItem } from './DashboardPollingItem'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { EmptyState } from 'src/features/common/EmptyState'
import { Poll } from 'src/repository/types'
import styled, { useTheme } from 'styled-components'

export const DashboardPollingColumn: React.FC<{
  style?: React.CSSProperties
  isActive: boolean
  polls: Poll[]
}> = ({ style, isActive, polls }) => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const state = isActive ? 1 : 0
  const stateColor: Record<number, string> = {
    0: palette.purple,
    1: palette.green,
  }
  const stateEmptySubTitle: Record<number, string> = {
    0: t('onceYouCreatePoll'),
    1: t('dragPollToActivate'),
  }

  // MARK: - Render

  return (
    <Container style={style}>
      <Title style={{ color: stateColor[state] }}>
        {(isActive ? t('active') : t('inactive')) + `  (${polls.length})`}
      </Title>

      <Droppable droppableId={state.toString()}>
        {(provided, snapshot) => (
          <QuestionList
            isDragging={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {polls.length ? (
              polls.map((poll, index) => (
                <DashboardPollingItem key={poll.id} poll={poll} index={index} />
              ))
            ) : (
              <EmptyState
                style={{ marginLeft: 12, marginRight: 12 }}
                title={''}
                subtitle={stateEmptySubTitle[state]}
              />
            )}
            {provided.placeholder}
          </QuestionList>
        )}
      </Droppable>
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 8px 8px 0px 8px;
  max-height: 100%;
  padding: 16px 0px 0px 0px;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-left: 16px;
`

const QuestionList = styled.div<{ isDragging: boolean }>`
  background-color: ${({ theme, isDragging }) =>
    isDragging ? theme.palette.background.secondary : 'transparent'};
  flex: 1;
  max-height: 100%;
  overflow: scroll;
`
