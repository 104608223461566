import React from 'react'
import styled from 'styled-components'

export const UserImage: React.FC<{
  style?: React.CSSProperties
  url?: string | null
  size: number
  onClick?: () => void
}> = ({ url, size, style, children, onClick }) => (
  <Container onClick={onClick} style={style} size={size}>
    <Image src={url || require('src/assets/images/profile_placeholder.png')} />
    {children}
  </Container>
)

// MARK: - Styles

const Container = styled.div<{ size: number }>`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  border-radius: 50%;
  height: ${({ size }) => size}px;
  overflow: hidden;
  width: ${({ size }) => size}px;
  z-index: 2;
`

const Image = styled.img`
  height: 100%;
  width: 100%;
`
