import React from 'react'
import { Switch } from 'src/features/common/Switch'
import styled from 'styled-components'

export const InfoSwitch: React.FC<{
  style?: React.CSSProperties
  title: string
  subtitle?: string
  checked: boolean
  disabled?: boolean
  onSwitchToggle: (checked: boolean) => void
}> = ({ style, title, subtitle, checked, disabled = false, onSwitchToggle }) => {
  return (
    <Container style={style} disabled={disabled}>
      <InfoWrapper>
        <InfoContainer>
          <Title>{title}</Title>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </InfoContainer>
      </InfoWrapper>
      <Switch disabled={disabled} checked={checked} onSwitchToggle={onSwitchToggle} />
    </Container>
  )
}

// MARK: - Styles

const Container = styled.div<{ disabled: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  padding: 10px 0px 10px 0px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Title = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 15px;
  font-weight: 500;
  user-select: none;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 0px;
  user-select: none;
`
