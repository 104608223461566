import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AnnouncementIcon } from 'src/assets/images/svg/AnnouncementIcon'
import { CreateIcon } from 'src/assets/images/svg/CreateIcon'
import { HypeIcon } from 'src/assets/images/svg/HypeIcon'
import { MomentumIcon } from 'src/assets/images/svg/MomentumIcon'
import { SalesIcon } from 'src/assets/images/svg/SalesIcon'
import { UsersIcon } from 'src/assets/images/svg/UsersIcon'
import { Col, LabelBold, LabelNormal } from 'src/features/common/CommonStyles'
import useWindowDimensions from 'src/utils/hooks/useWindowDimensions'
import styled, { useTheme } from 'styled-components'

export const ValueProposition: React.FC = () => {
  // MARK: - Hooks

  const { t } = useTranslation()
  const { palette } = useTheme()
  const { width } = useWindowDimensions()
  const displaySwiper = width < 850

  // MARK: - Render
  return (
    <Container isMobile={displaySwiper}>
      <InnerContainer className="container">
        <TextContainer>
          <Title>{'Unleash the full potential of your event with Lobbi!'}</Title>
          <LabelNormal style={{ fontSize: 20, lineHeight: '30px' }}>
            <Subtitle>
              {
                'All-in-one solution empowers your community to drive progress and generate excitement as you bring your event to life.'
              }
            </Subtitle>
          </LabelNormal>
        </TextContainer>
        <RequestDemoButton to="/demo" className="button button-primary">
          {t('requestDemo')}
        </RequestDemoButton>

        <Wrapper>
          <ValuePropositionItem
            title={'Design a lobby'}
            description={
              "Use Lobbi's customizable features to create a lobby specifically tailored to your event."
            }>
            <CreateIcon size={35} />
          </ValuePropositionItem>

          <ValuePropositionItem
            title={'Spread the word'}
            description={
              'Attract your events audience to your lobby by including a join link in your marketing campaigns.'
            }>
            <HypeIcon size={35} />
          </ValuePropositionItem>

          <ValuePropositionItem
            title={'Build hype'}
            description={
              "Use Lobbi's announcements, notifications, and various engagement tools to keep your audience informed and engaged."
            }>
            <MomentumIcon size={35} />
          </ValuePropositionItem>

          <ValuePropositionItem
            title={'Listen to your audience'}
            description={
              "Understand your audience's needs, analyze their behavior, and design your event to meet their expectations."
            }>
            <UsersIcon size={35} />
          </ValuePropositionItem>

          <ValuePropositionItem
            title={'Boost pre-launch revenue'}
            description={
              "Accept pre-orders, increase your sponsors' visibility and sell privileges to your audience."
            }>
            <SalesIcon size={35} />
          </ValuePropositionItem>

          <ValuePropositionItem
            title={'Generate buzz'}
            description={
              'Allow your audience to set reminders and calendar events for your event start date to increase engagement.'
            }>
            <AnnouncementIcon fill={palette.orange} size={35} />
          </ValuePropositionItem>
        </Wrapper>
      </InnerContainer>
    </Container>
  )
}
const ValuePropositionItem: React.FC<{
  title: string
  description: string
}> = props => {
  // MARK: - Properties

  const { children, title, description } = props
  const { width } = useWindowDimensions()
  const displaySwiper = width < 850

  // MARK: - Render

  return (
    <ItemContainer isNarrow={displaySwiper}>
      {children}
      <ItemTitle>{title}</ItemTitle>
      <ItemSubtitle>{description}</ItemSubtitle>
    </ItemContainer>
  )
}

// MARK: - Styles

const Container = styled(Col)<{ isMobile: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.black};
  margin-top: 80px;
  padding-bottom: 50px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 4%;
  margin-right: 4%;
  max-width: 1260px;
  padding-top: 80px;
  position: relative;
`

const TextContainer = styled.div`
  max-width: 800px;
`

const Title = styled(LabelBold)`
  color: ${({ theme }) =>
    theme.isDark ? theme.palette.text.primary : theme.palette.inverted.text.primary};
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 16px;
  margin-top: 0px;
`

const Subtitle = styled.span`
  color: ${({ theme }) =>
    theme.isDark ? theme.palette.text.secondary : theme.palette.inverted.text.secondary};
  margin-bottom: 30px;
`

const RequestDemoButton = styled(Link)`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.orange};
  border-radius: 30px;
  display: flex;
  height: 60px;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1200px;
  padding-top: 50px;
  position: relative;
  width: 100%;
`

const ItemContainer = styled.div<{ isNarrow: boolean }>`
  flex-basis: ${({ isNarrow }) => (isNarrow ? '100%' : '30%')};
  margin-bottom: 30px;
  margin-right: 40px;
  max-width: 500px;
  min-height: 250px;
  min-width: 250px;
`

const ItemTitle = styled(LabelBold)`
  color: ${({ theme }) =>
    theme.isDark ? theme.palette.text.primary : theme.palette.inverted.text.primary};
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 12px;
`

const ItemSubtitle = styled(LabelNormal)`
  color: ${({ theme }) =>
    theme.isDark ? theme.palette.text.secondary : theme.palette.inverted.text.secondary};
  font-size: 18px;
  line-height: 26px;
`
