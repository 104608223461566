import React from 'react'
import { SVG } from 'src/utils/types/svg'
import { useTheme } from 'styled-components'

const Icon: SVG = ({ size = 24, fill = '#ECEDED', ...props }) => (
  <svg
    {...props}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.17188 14.8287L14.8287 9.17188"
      stroke={fill}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.75725 12.0001C8.14777 11.6096 8.14777 10.9764 7.75725 10.5859C7.36672 10.1953 6.73356 10.1953 6.34303 10.5859L7.75725 12.0001ZM13.4141 17.6569C13.8046 17.2664 13.8046 16.6332 13.4141 16.2427C13.0236 15.8522 12.3904 15.8522 11.9999 16.2427L13.4141 17.6569ZM6.34303 10.5859L4.92882 12.0001L6.34304 13.4143L7.75725 12.0001L6.34303 10.5859ZM11.9999 19.0711L13.4141 17.6569L11.9999 16.2427L10.5857 17.6569L11.9999 19.0711ZM4.92882 19.0711C6.88144 21.0238 10.0473 21.0238 11.9999 19.0711L10.5857 17.6569C9.4141 18.8285 7.51461 18.8285 6.34304 17.6569L4.92882 19.0711ZM4.92882 12.0001C2.9762 13.9527 2.9762 17.1185 4.92882 19.0711L6.34304 17.6569C5.17146 16.4854 5.17146 14.5859 6.34304 13.4143L4.92882 12.0001Z"
      fill={fill}
      strokeWidth="1"
    />
    <path
      d="M10.5859 6.34303C10.1953 6.73356 10.1953 7.36672 10.5859 7.75725C10.9764 8.14777 11.6096 8.14777 12.0001 7.75725L10.5859 6.34303ZM16.2427 11.9999C15.8522 12.3904 15.8522 13.0236 16.2427 13.4141C16.6332 13.8046 17.2664 13.8046 17.6569 13.4141L16.2427 11.9999ZM12.0001 7.75725L13.4143 6.34304L12.0001 4.92882L10.5859 6.34303L12.0001 7.75725ZM17.6569 10.5857L16.2427 11.9999L17.6569 13.4141L19.0711 11.9999L17.6569 10.5857ZM17.6569 6.34304C18.8285 7.51461 18.8285 9.4141 17.6569 10.5857L19.0711 11.9999C21.0238 10.0473 21.0238 6.88144 19.0711 4.92882L17.6569 6.34304ZM13.4143 6.34304C14.5859 5.17146 16.4854 5.17146 17.6569 6.34304L19.0711 4.92882C17.1185 2.9762 13.9527 2.9762 12.0001 4.92882L13.4143 6.34304Z"
      fill={fill}
      strokeWidth="1"
    />
  </svg>
)

export const LinksIcon: SVG = props => {
  const { palette } = useTheme()
  return <Icon fill={palette.text.primary} {...props} />
}
